import React, {useCallback, useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {useNavigate} from 'react-router-dom'

import {get} from 'lodash'

import {makeGetPerformanceById} from '../../../selectors'

import {destroy, showAlert} from '../../../actions'

import {fullURL, formatMoney} from '../../../utils'


import PerformanceFlier from '../../generics/flyers/PerformanceFlier'

import PerformanceForm from '../PerformanceForm'



import { 
  EditIconButton,
  ButtonGroupRound, 
  DeleteIconButton,
  ShareIconButton,
  StatsIconButton,
  QRCodeIconButton,
  GradientPlayButton
} from '../../generics'




const styles = {
  buttonRow: css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 10px 0;
    gap: 15px;

    @media (max-width: 550px) {
      flex-wrap: nowrap;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  `,
  ticketContainer: css`
    height: 48px;
    background-color: #fff;
    color: rgb(62, 166, 255);
    font-weight: 500;
    font-size: 13px;
    border-radius: 23px;
    min-width: 140px;
  `,
  priceContainer: css`
    height: 48px;
    background-color: #000;
    color: rgb(62, 166, 255);
    border: 1px solid #696969;
    font-weight: 500;
    font-size: 13px;
    border-radius: 23px;
    min-width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  `,
  link: css`
    color: #000;
    text-decoration: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      line-height: 1.1;
      transition: all 0.3s linear;

      &:hover {
        letter-spacing: 0.05rem
      }
    }
  `
}




const PerformanceButtons = props => {

  const navigate = useNavigate()

  const isExtraSmallScreen = useMediaQuery('(max-width:550px')

  const [open, setOpen] = useState(false)
  

  const { 
    performanceId,
    start,
    destroy,
    price,
    tickets,
    imageURL,
    videoURL,
    showAlert,
    permission,
    originalImage
  } = props




  const deletePerformance = useCallback(() => {
    destroy('/performances/' + performanceId, 'PERFORMANCE').then(response => {
      navigate(-1)
      showAlert('Performance deleted', 'info')  
    })
    .catch(error => {
      showAlert('Couldn\'t delete performance', 'error')
    })
  }, [performanceId, showAlert, destroy, navigate])




  const close = useCallback(() => setOpen(false), [])




  const Buttons = useCallback(() => {
    switch (permission) {
      case 'owner':
        return (
          <>
            {videoURL && <GradientPlayButton />}

            <EditIconButton open={open} setOpen={setOpen} type='performance'>
              <PerformanceForm performanceId={performanceId} close={close} />
            </EditIconButton>

            <ShareIconButton id={performanceId} type='performance' />
            <StatsIconButton id={performanceId} type='performance' />
            
            <QRCodeIconButton>
              <PerformanceFlier id={performanceId} image={originalImage} start={start} />
            </QRCodeIconButton>

            <DeleteIconButton 
              imageURL={imageURL}
              title='Delete Performance'
              text='Are you sure you want to delete this performance?'
              primaryAction={deletePerformance}
            />
          </>
        )
      case 'editor':
        return (
          <>
            {videoURL && <GradientPlayButton />}

            <EditIconButton open={open} setOpen={setOpen} type='performance'>
              <PerformanceForm performanceId={performanceId} close={close} />
            </EditIconButton>

            <ShareIconButton id={performanceId} type='performance' />
            <StatsIconButton id={performanceId} type='performance' />

            <QRCodeIconButton>
              <PerformanceFlier id={performanceId} image={originalImage} start={start} />
            </QRCodeIconButton>
          </>
        )
      case 'member':
        return (
          <>
            {videoURL && <GradientPlayButton />}

            <ShareIconButton id={performanceId} type='performance' />
            <StatsIconButton id={performanceId} type='performance' />

            <QRCodeIconButton>
              <PerformanceFlier id={performanceId} image={originalImage} start={start} />
            </QRCodeIconButton>
          </>
        )
      default:
        return (
          <>
            {videoURL && <GradientPlayButton />}

            <ShareIconButton id={performanceId} type='performance' />

            <QRCodeIconButton>
              <PerformanceFlier id={performanceId} image={originalImage} start={start} />
            </QRCodeIconButton>
          </>
        )
    }
  }, [
    open,
    start,
    close,
    setOpen,
    imageURL,
    videoURL,
    permission, 
    performanceId,
    originalImage,
    deletePerformance
  ])





  return (
    <div 
      css={styles.buttonRow} 
      style={{paddingLeft: (tickets || !isExtraSmallScreen) ? '0px' : '25px'}}
    >
      {tickets &&
        <div id='ticket-link' css={styles.ticketContainer}>
          <a css={styles.link} href={fullURL(tickets)} target='_blank' rel='noopener noreferrer'>
            <p>{`TICKETS${price ? ` - ${formatMoney(price)}` : ''}`}</p>
          </a>
        </div>
      }


      {!tickets &&
        <div css={styles.priceContainer}>
          <p>{`ENTRY${price ? ` - ${formatMoney(price)}` : ''}`}</p>
        </div>
      }


      <ButtonGroupRound>
        <Buttons />
      </ButtonGroupRound>
    </div>
  )
}




const makeMapStateToProps = () => {
  const getPerformanceById = makeGetPerformanceById()
  
  const mapStateToProps = (state, props) => {

    const id = props.performanceId

    const performance = getPerformanceById(state, id).attributes
    
    const role = get(state, ['user', 'attributes', 'role'])
    
    const permission = ['admin', 'editor'].includes(role) ? 'owner' : performance.permission


    return {
      start: performance.start,
      price: performance.price,
      tickets: performance.tickets,
      permission: permission,
      imageURL: performance.imageDerivatives.small,
      videoURL: performance.video,
      originalImage: performance.image
    }
  }

  return mapStateToProps
}


const actions = {destroy, showAlert}


export default connect(makeMapStateToProps, actions)(PerformanceButtons)

