import {useEffect} from 'react'

import {connect} from 'react-redux'

import {useNavigate} from 'react-router-dom'

import useMediaQuery from '@mui/material/useMediaQuery'

import withRouter from '../../hocs/withRouter'

import {Helmet} from 'react-helmet-async'

import {formatDate, formatError} from '../../../utils'


import {
  makeGetPerformanceById,
  makeGetPerformancePerformers,
  makeGetPerformanceFetchedAssociations 
} from '../../../selectors'


import {show, incrementViewCount} from '../../../actions'

import GroupIcon from '@mui/icons-material/Group'
import AlbumIcon from '@mui/icons-material/Album'
import ImageIcon from '@mui/icons-material/Image'

import DetailPage from '../../pages/DetailPage'
import PerformanceInfo from './PerformanceInfo'
import PerformanceTracks from './PerformanceTracks'
import PerformanceImages from './PerformanceImages'
import PerformancePerformers from './PerformancePerformers'






const PerformanceDetailPage = props => {
  const navigate = useNavigate()

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))


  const {
    id,
    show,
    name,
    start,
    isLoaded,
    imageURL,
    videoURL,
    description,
    originalImage,
    externalImageURL,
    externalImageSrcset,
    incrementViewCount,
    showPerformerTab
  } = props



  const jsonLdData = {
    "@context": "http://schema.org",
    "@type": "Event",
    "url": "https://www.ckord.com/performances/" + id,
    "name": name,
    "description": description,
    "image": [imageURL]
  }




  useEffect(() => {
    const url = '/performances/' + id

    show(url, 'PERFORMANCE').catch(error => {
      error = formatError(error)

      if (error.status === 404) {
        navigate('/page-not-found', {replace: true, state: {page: 'performance'}})
      }
    })
  }, [id, show, navigate])




  useEffect(() => {
    if (isLoaded) {
      incrementViewCount('/performances/' + id + '/views', 'PERFORMANCE', id)
    }
  }, [incrementViewCount, id, isLoaded])






  return (isLoaded &&
    <div>
      <Helmet>
        <title>{name}</title>
        <meta property='og:title' content={name} />
        <meta property='og:url' content={process.env.REACT_APP_API_URL + id} />
        <meta property='og:image' content={imageURL} />
        <meta property='og:description' content={'Performance on ' + formatDate(start)} />

        <script type='application/ld+json'>{JSON.stringify(jsonLdData)}</script>
      </Helmet>




      <DetailPage
        type='performance'
        image={imageURL || externalImageURL}
        imageSrcset={externalImageSrcset}
        video={videoURL}
        originalImage={originalImage}
        information={<PerformanceInfo performanceId={id} />}
        
        tab1={showPerformerTab && <PerformancePerformers performanceId={id} />} 
        tab1Label={ 
          showPerformerTab &&
          (isExtraSmallScreen ? <GroupIcon fontSize='large' /> : 'lineup') 
        }

        tab2={<PerformanceTracks performanceID={id} />} 
        tab2Label={isExtraSmallScreen ? <AlbumIcon /> : 'tracks' }
        
        tab3={<PerformanceImages performanceId={id} />}
        tab3Label={isExtraSmallScreen ? <ImageIcon fontSize='large' /> : 'images'}
      />
    </div>
  )
}




const makeMapStateToProps = () => {
  const getPerformanceById = makeGetPerformanceById()
  const getPerformancePerformers = makeGetPerformancePerformers()
  const getFetchedAssociations = makeGetPerformanceFetchedAssociations()
  

  const mapStateToProps = (state, props) => {
    const id = props.id

    const performance = getPerformanceById(state, id)

    const attributes = performance?.attributes || {}

    const imageURL = attributes.imageDerivatives?.large || attributes.image

    const externalImageURL = attributes.externalImageUrl
    const externalImageSrcset = attributes.externalImageSrcset

    const videoURL = attributes.video


    // Used to either show or not show the performers tab
    // ------------------------------------------------------------------------
    const role = state?.user?.attributes?.role

    const permission = ['admin', 'editor'].includes(role) ? 'owner' : performance?.permission

    const isEditor = ['owner', 'editor'].includes(permission)

    const didFetchPerformers = getFetchedAssociations(state, id).includes('performers')

    const performers = getPerformancePerformers(state, id)

    // If the person can edit the event or if there are performers, then show the lineup tab
    const showPerformerTab = isEditor || !didFetchPerformers || (didFetchPerformers && performers.length)

    // ------------------------------------------------------------------------


    return {
      isLoaded: Boolean(performance.id),
      name: attributes.name || '',
      start: attributes.start || '',
      imageURL: imageURL,
      videoURL: videoURL,
      description: attributes.description || '',
      originalImage: attributes.image,
      externalImageURL: externalImageURL,
      externalImageSrcset: externalImageSrcset,
      showPerformerTab: showPerformerTab
    }
  }

  return mapStateToProps
}


const actions = {show, incrementViewCount}


export default withRouter(connect(makeMapStateToProps, actions)(PerformanceDetailPage))


