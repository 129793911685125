
import styles from '../../../styles/detail-pages/detailPageTabMessage.module.scss'


const DetailPageTabMessage = props => {
  return (
    <div className={styles.root}>
      <p>
        {props.children}
      </p>
    </div>
  )
}


export default DetailPageTabMessage