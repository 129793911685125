import React, { useState, useRef, useEffect, useMemo } from 'react'
import { debounce } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { useIsMounted } from '../../hooks'
import useClickedOutsideComponent from '../../hooks/useClickedOutsideComponent'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'


const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flexGrow: 1
  },
  dropdown: {
    position: 'absolute',
    width: '100%'
  },
  list: {
    position: 'absolute',
  }
}))




const withSearchAutocomplete = (Component, getSuggestions) => props => {
  const classes = useStyles()
  const [items, setItems] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const isMounted = useIsMounted()
  const dropdownRef = useRef()
  const textfieldRef = useRef()
  const clickedOutsideCounter = useClickedOutsideComponent(dropdownRef)
  const { 
    InputProps,
    LabelProps, 
    placeholder, 
    label 
  } = props


  const debouncedGetSuggestions = useMemo(() => {
    return debounce(val => (
      getSuggestions(val)
        .then(response => {
          if (isMounted.current) setItems(response.data.data)
        })
    ), 200)
  }, [isMounted])


  useEffect(() => {
    setIsOpen(false)
  }, [clickedOutsideCounter])


  function handleInputChange(value) {
    if (value) {
      setIsOpen(true)
      debouncedGetSuggestions(value)
    }
    else setIsOpen(false)
  }


  return (
    <Grid container direction='column' ref={ dropdownRef }> 
      <TextField
        variant='filled'
        fullWidth
        inputRef={ textfieldRef }
        label={ label || 'Search' }
        placeholder={ placeholder || null }
        InputLabelProps={ LabelProps }
        InputProps={ InputProps } 
        onChange={ e => handleInputChange(e.target.value) } 
      />

      <Grid className={ classes.root } item>
        { isOpen &&
          <Paper className={ classes.dropdown }>
            <Component 
              suggestions={ items } 
              setIsOpen={ setIsOpen }
              textfieldRef={ textfieldRef }
              passedProps={ props }  
            />
          </Paper>
        }
      </Grid>
    </Grid>
  )
}

export default withSearchAutocomplete

