import React, {useEffect} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {get} from 'lodash'

import {Helmet} from 'react-helmet-async'

import {useNavigate} from 'react-router-dom'

import {connect, useDispatch} from 'react-redux'

import {show, addShopToMerchant} from '../../../actions'

import {formatError} from '../../../utils'

import {merchantSelectors, makeGetProductById} from '../../../selectors'

import withRouter from '../../hocs/withRouter'

import ProductDetailInfo from './ProductDetailInfo'
import ProductDetailImages from './ProductDetailImages'




const cssStyles = ({isPreview}) => ({
  root: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: ${isPreview ? '50px' : '90px'};

    @media (min-width: 960px) {
      margin-top: ${isPreview ? '50px' : '150px'};
    }
  `,
  contentContainer: css`
    width: 100%;
    max-width: 1120px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: ${isPreview ? '50px' : '30px'};

    @media (max-width: 800px) {
      flex-direction: column;
      padding-bottom: 100px;
    }
  `,
  leftSide: css`
    padding: 20px 20px 20px 30px;
    width: 100%;
    max-width: 840px;

    @media (max-width: 800px) {
      max-width: 450px;
    }
  `,
  rightSide: css`
    padding: 20px 30px 20px 20px;
    width: 100%;
    max-width: 450px;
  `
})





const ProductDetailPage = props => {

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const {
    id, 
    name,
    show,
    merchantId,
    merchantType,
    shippingDetails, 
    imageURL,
    imageURLJPEG,  
    isLoaded, 
    isPreview,
    areMerchantAndShopLoaded,
    addShopToMerchant
  } = props


    const styles = cssStyles({isPreview})



  useEffect(() => {
    if (isPreview) return

    const url = '/products/' + id

    show(url, 'PRODUCT').catch(error => {
      error = formatError(error)

      if (error.status === 404) {
        navigate('/page-not-found', {replace: true, state: {page: 'product'}})
      }
    })
  }, [id, show, navigate, isPreview])




  // Fetch the merchant and shop if not loaded
  useEffect(() => {
    if (isPreview) return

    // If the merchant hasn't been fetched then get the merchant
    if (merchantId && !areMerchantAndShopLoaded) {
      const url = '/merchants/' + merchantType.toLowerCase() + '/' + merchantId

      show(url, merchantType.toUpperCase()).then(response => {
        const shop = response.meta
        const shopId = shop.data.id


        // Along with the merchant, the merchant's shop object is passed. The shop
        // object is dispatched to the store and added to the merchants relationships
        dispatch({type: 'SHOP_SHOW_SUCCESS', payload: shop})
        addShopToMerchant(merchantId, merchantType, shopId)
      })
    }
  }, [
    show,
    dispatch,
    isPreview,
    merchantId, 
    merchantType,
    addShopToMerchant,
    areMerchantAndShopLoaded
  ]
)




  return (isLoaded &&
    <div css={styles.root}>
      <Helmet>
        <title>{name}</title>

        <meta name='description' content={`Check out ${name} on Ckord`} />

        <meta property='og:title' content={name + ' | Ckord'} />
        <meta property='og:url' content={ process.env.REACT_APP_API_URL + id } />
        <meta property='og:image' content={ imageURLJPEG } />
        <meta property='og:description' content={`Check out ${name} on Ckord`} />
      </Helmet>

      <div css={styles.contentContainer}>
        <div css={styles.leftSide}>
          <ProductDetailImages id={id} />
        </div>


        <div css={styles.rightSide}>
          <ProductDetailInfo 
            productId={id} 
            isPreview={isPreview}
            shippingDetails={shippingDetails}
          />
        </div>
      </div>
    </div>
  )
}


const makeMapStateToProps = () => {
  const getProductById = makeGetProductById()

  const mapStateToProps = (state, props) => {

    let merchant = {}

    const product = getProductById(state, props.id)


    if (product?.attributes?.merchantId) {
      merchant = merchantSelectors.merchant(
        state, 
        product?.attributes?.merchantId, 
        product?.attributes?.merchantType.toLowerCase()
      )
    }


    return {
      name: get(product, ['attributes', 'name'], ''),
      imageURL: get(product, ['attributes', 'imageDerivatives', 'small'], ''),
      imageURLJPEG: product?.attributes?.imageDerivatives?.largeFallback,
      merchantId: get(product, ['attributes', 'merchantId'], ''),
      merchantType: get(product, ['attributes', 'merchantType'], ''),
      isLoaded: Boolean(product.id),
      areMerchantAndShopLoaded: Boolean(merchant?.id && merchant?.relationships?.shop)
    }
  }

  return mapStateToProps
}


const actions = {show, addShopToMerchant}


export default withRouter(connect(makeMapStateToProps, actions)(ProductDetailPage))



