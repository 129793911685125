import {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'


import Slider from '@mui/material/Slider'
import VolumeUp from '@mui/icons-material/VolumeUp'
import VolumeDown from '@mui/icons-material/VolumeDown'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'

import IconButton from '@mui/material/IconButton'




const styles = {
  root: css`
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    min-height: 10px;
    min-width: 100px;
  `,
  slider: css`
    display: flex;
    flex-grow: 1;
    align-items: center;
    align-content: center;
  `,
  iconLeft: css`
    margin-right: 0px;
  `,
  iconRight: css`
    margin-left: 0px;
  `
}




const VolumeControl = ({showLeftIcon = true, ...props}) => {
  

  const {
    volume,
    isMuted,
    setIsMuted, 
    callback, 
    showRightIcon
  } = props


  const [value, setValue] = useState(volume)




  function updateVolume(event, newValue) {
    setValue(newValue)

    if (callback) {
      callback(newValue)
    }
  }



  function mute() {
    setIsMuted(true)
  }



  function unmute() {
    setIsMuted(false)
  }



  return (
    <div data-draggable='false' css={styles.root}>
      {showLeftIcon && 
        <div css={styles.iconLeft}>
          {(value <= 0 || isMuted)
            ? <IconButton onClick={unmute}>
                <VolumeOffIcon fontSize='large' color='primary' />
              </IconButton>
            : <IconButton onClick={mute}> 
                <VolumeDown fontSize='large' color='primary' />
              </IconButton>
          }
        </div>
      }

      <div css={styles.slider}>
        <Slider value={value} onChange={updateVolume} />
      </div>

      {showRightIcon &&
        <div css={styles.iconRight}>
          <VolumeUp style={{color: '#818181'}} />
        </div>
      }
    </div>
  )
}



export default VolumeControl


