import { css } from '@emotion/react'

export const performanceCardMobileStyles = ({ theme }) => (
  {
    card: css`
      width: 100%;
      height: 150px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      border-radius: 6px;
      padding: 10px;
      cursor: pointer;
      transition: 0.3s;
    `,
    imageContainer: css`
      width: 86.7px;
      height: 130px;
      border-radius: 6px;
      position: relative;
      flex-shrink: 0;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px;
    `,
    image: css`
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
    `,
    infoContainer: css`
      padding: 12px;
      flex-grow: 1;
      display: flex;
      min-width: 0;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: center;
    `,
    title: css`
      width: 100%;
      font-weight: 500;
      font-size: 1.05rem;
      margin-bottom: 2px;
      overflow: hidden;
      line-height: 1.2;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
    `,
    city: css`
      font-size: 0.9rem;
      color: #606060;
    `,
    buttons: css`
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-height: 52px;
      padding-right: 0px;
      padding-left: 10px;
    `,
    overlay: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      height: 100%;
      margin-right: 10px;
      min-width: 33px;
      max-width: 33px;
    `,
    dayAndMonth: css`
      font-size: 0.9rem;
      color: #303030;
      text-transform: uppercase;
    `,
    day: css`
      font-weight: 900;
      font-size: 1.3rem;
      line-height: 0.9;
    `,
    link: css`
      text-decoration: none;
      color: inherit;
    `,
    tags: css`
      overflow: hidden;
      display: flex;
      width: 100%;
    `
  }
)


export default performanceCardMobileStyles



