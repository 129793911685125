import { crudAPI } from '../apis'

export const crudActions = {
  index,
  create, 
  show,
  update,
  patch,
  destroy,
  reset
}


function request(type) { return { type } }
function success(type, payload) { return { type, payload } }



export function index(url, type, data, options) {
  return dispatch => {
    dispatch(request(type + '_REQUESTED'))

    return crudAPI.index(url, data, options).then(response => {
      dispatch(success(type + '_INDEX_SUCCESS', response.data))
      return response.data
    })
    .catch(error => {
      dispatch(request(type + '_ERROR'))
      throw(error)
    })
  }
}



export function show(url, type, data, options) {
  return dispatch => {
    dispatch(request(type + '_REQUESTED'))

    return crudAPI.show(url, data, options).then(response => {
      dispatch(success(type + '_SHOW_SUCCESS', response.data))
      return response.data
    })
    .catch(error => {
      dispatch(request(type + '_ERROR'))
      throw(error)
    })
  }
}



export function create(url, type, data, options) {
  return dispatch => {
    dispatch(request(type + '_REQUESTED'))

    return crudAPI.create(url, data, options).then(response => {
      dispatch(success(type + '_CREATE_SUCCESS', response.data))
      return response.data
    })
    .catch(error => {
      dispatch(request(type + '_ERROR'))
      throw(error)
    })
  }
}



export function destroy(url, type, data, options) {
  return dispatch => {
    dispatch(request(type + '_REQUESTED'))

    return crudAPI.destroy(url, data, options).then(response => {
      dispatch(success(type + '_DESTROY_SUCCESS', response.data))
      return response.data
    })
    .catch(error => {
      dispatch(request(type + '_ERROR'))
      throw(error)
    })
  }
}



export function update(url, type, data, options) {
  return dispatch => {
    dispatch(request(type + '_REQUESTED'))

    return crudAPI.update(url, data, options).then(response => {
      dispatch(success(type + '_UPDATE_SUCCESS', response.data))
      return response.data
    })
    .catch(error => {
      dispatch(request(type + '_ERROR'))
      throw(error)
    })
  }
}



export function patch(url, type, data, options) {
  return dispatch => {
    dispatch(request(type + '_REQUESTED'))

    return crudAPI.patch(url, data, options).then(response => {
      dispatch(success(type + '_UPDATE_SUCCESS', response.data))
      return response.data
    })
    .catch(error => {
      dispatch(request(type + '_ERROR'))
      throw(error)
    })
  }
}



export function reset(type) {
  return dispatch => {
    dispatch({ type: type + '_RESET_STATE' })
  }
}




