import React, {useRef, useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {useSwipeable} from 'react-swipeable'

import {updateTagFilter, initiateSearch, panTo} from '../../../actions'

import Button from '@mui/material/Button'

import GeneralSearchMobile from './GeneralSearchMobile'
import LocationSearchMobile from './LocationSearchMobile'

import CloseIcon from '@mui/icons-material/Close'



const styles = {
  root: css`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    z-index: 1001;
    background-color: #fff;
    align-items: stretch;
    flex-direction: column;
    background-color: #fafafa;
  `,
  header: css`
    position: relative;
    padding: 20px 20px 10px 20px;
    background-color: #000;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    top: 0;
    width: 100%;
    max-height: 125px;
    z-index: 2000;
  `,
  listContainer: css`
    flex: 1;
    display: flex;
    margin-top: 110px;
    padding-bottom: 80px;
    background-color: #fafafa;
  `,
  list: css`
    height: 1px;
    width: 100%;
    background-color: #fafafa;
  `,
  footer: css`
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    z-index: 2002;
    background-color: #fff;
    box-shadow: 0px 0 5px -1px #888;

  `,
  searchButton: css`
    padding-left: 25px;
    padding-right: 25px;
    box-shadow: none;
  `,
  closeButton: css`
    text-transform: uppercase;
    font-weight: 500;
  `
}



const SearchPageMobile = props => {

  const searchRef = useRef()

  const locationRef = useRef()

  const containerRef = useRef()

  const [open, setOpen] = useState('')

  
  const {
    lat, 
    lng, 
    tags,
    close,
    panTo,
    updateTagFilter,
    initiateSearch
  } = props


  const handlers = useSwipeable({
    onSwipedDown: handleSwipeDown,
    swipeDuration: 250
  })



  function handleTouchStart(e) {
    if (searchRef.current) {
      searchRef.current.blur()
    }

    if (locationRef.current) {
      locationRef.current.blur()
    }
  }



  function handleSearch() {
    const value = searchRef.current.value || tags

    // Update the search query
    updateTagFilter(value)

    // Pan the map to the new locartion if one was selected
    panTo({lat, lng})
    
    // Conduct a new search
    initiateSearch()
    
    // Close the sheet
    close()
  }



  function handleSwipeDown(data) {
    const node = data.event.target

    const isNodeInsideList = containerRef.current.contains(data.event.target)

    const isShowingResults = containerRef.current.hasChildNodes()

    // If results are showing in the list then don't dismiss the sheet
    // since the user could be scrolling through the results
    if (isShowingResults && isNodeInsideList) {
      return
    }

    close()
  }



  return (
    <div css={styles.root} {...handlers} onTouchStart={handleTouchStart}>
      <div css={styles.header}>
        <GeneralSearchMobile 
          close={close}
          open={open}
          setOpen={setOpen}
          searchRef={searchRef}
          containerRef={containerRef}
        />
                
        <LocationSearchMobile
          open={open}
          setOpen={setOpen}
          locationRef={locationRef}
          containerRef={containerRef} 
        />
      </div>



      <div css={styles.listContainer}>
        <div css={styles.list} ref={containerRef}></div>
      </div>

      <div css={styles.footer}>
        <Button css={styles.closeButton} variant='text' onClick={close}>
          <CloseIcon fontSize='small' />Close
        </Button>

        <Button css={styles.searchButton} onClick={handleSearch}>
          Search
        </Button>
      </div>
    </div>
  )
}



const mapStateToProps = state => {
  return {
    lat: state.search.filters.lat,
    lng: state.search.filters.lng,
    tags: state.search.filters.tags
  }
}



export default connect(mapStateToProps, {panTo, updateTagFilter, initiateSearch})(SearchPageMobile)






