import React, { useState } from 'react'
import { range } from 'lodash'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import IconButton from '@mui/material/IconButton'
import ScheduleIcon from '@mui/icons-material/Schedule'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'

import CloseButton from '../../../generics/forms/CloseButton'
import SlideTransition from '../../../generics/SlideTransition'


const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer'
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 40,
    borderRadius: 3
  },
  minuteSelect: {
    marginLeft: 5,
    marginRight: 5
  },
  select: {
    paddingLeft: 8,
    paddingRight: 8,
    display: 'flex'
  },
  selectMobile: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'top',
    flexWrap: 'wrap'
  },
  mobileTimeDisplay: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 30,
    paddingBottom: 30
  },
  section: {
    marginLeft: 8,
    marginRight: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  sectionHeader: {
    paddingBottom: 10,
    fontWeight: 600,
    textAlign: 'center',
    borderBottom: 'solid 1px grey',
    marginBottom: 8
  },
  menuItemMobile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 35,
    borderRadius: 3
  }
}))



const TimePickerField = ({ field, form, disabled, ...props }) => {
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const { setFieldValue, isSubmitting, handleBlur } = form
  const { name, value } = field
  const hour = value.getHours()
  const minute = value.getMinutes()
  const ampm = hour >= 12 ? 'PM' : 'AM'
  const textFieldValue = value.toLocaleString('en-US', 
    { 
      hour: 'numeric',
      minute: 'numeric',
      hour12: true 
    }
  ) 


  function handleClick(e) {
    setAnchorEl(e.currentTarget)
  }


  function handleClose() {
    setAnchorEl(null)
  }


  function handleHourChange(e) {
    const newDate = new Date(value.getTime())
    let newHour = e.target.value

    if (newHour === 12 && ampm === 'AM') {
      newHour = 0
    } else if (ampm === 'PM' && newHour < 12) {
      newHour = newHour + 12
    } 

    newDate.setHours(newHour)
    setFieldValue(name, newDate)

    if (props.onChange) {
      props.onChange(newDate)
    }
  }


  function handleMinuteChange(e) {
    const newDate = new Date(value.getTime())
    newDate.setMinutes(e.target.value)
    setFieldValue(name, newDate)

    if (props.onChange) {
      props.onChange(newDate)
    }
  }


  function handleAmpmChange(newValue) {
    if (newValue === ampm) { return }

    const newDate = new Date(value.getTime())
    const hours = newDate.getHours()

    if (newValue === 'PM' && hours < 12) {
      newDate.setHours(hour + 12)
      setFieldValue(name, newDate)
    } else if (newValue === 'AM' && hours >= 12)  {
      newDate.setHours(hour - 12)
      setFieldValue(name, newDate)
    }

    if (props.onChange) {
      props.onChange(newDate)
    }
  }



  return (
    <div className={ classes.root }>
      <div onClick={ handleClick }>
        <TextField
          name={ name }
          disabled={ isSubmitting }
          value={ textFieldValue }
          sx={{input: {cursor: 'pointer'}}}
          InputProps={{
            endAdornment: 
              <IconButton onBlur={ handleBlur(name) }>
                <ScheduleIcon />
              </IconButton>
          }}
          { ...props }
        />
      </div>
      { !isExtraSmallScreen && 
          <Menu
            anchorEl={ anchorEl }
            open={ Boolean(anchorEl) }
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={ handleClose }>
            <div className={ classes.select }>
              <div>
                { range(1, 13).map(h => {
                    const hourFormatted = hour > 12 ? hour - 12 : hour
                    const comparisonHour = hourFormatted === 0 ? 12 : hourFormatted

                    return (
                      <MenuItem
                        className={ classes.menuItem }
                        style={{ 
                          backgroundColor: comparisonHour === h ? '#000' : null,
                          color: comparisonHour === h ? '#fff' : null
                        }} 
                        key={ h }
                        value={ h }
                        onClick={ handleHourChange }>
                        { h }
                      </MenuItem>
                    )
                })}
              </div>
              <div className={ classes.minuteSelect }>
                { range(0, 56, 5).map(m => {
                    const displayMinute = m < 10 ? `0${m}` : m
                    
                    return (
                      <MenuItem 
                        className={ classes.menuItem }
                        style={{ 
                          backgroundColor: minute === m ? '#000' : null,
                          color: minute === m ? '#fff' : null
                        }} 
                        key={ m }
                        value={ m }
                        onClick={ handleMinuteChange }>
                        { displayMinute }
                      </MenuItem>
                    )
                })}
              </div>
              <div>
                { ['AM', 'PM'].map(amOrpm => (
                    <MenuItem 
                      className={ classes.menuItem } 
                      style={{
                        backgroundColor: amOrpm === ampm ? '#000' : null,
                        color: amOrpm === ampm ? '#fff' : null
                      }}
                      key={ amOrpm } 
                      value={ amOrpm }
                      onClick={ () => handleAmpmChange(amOrpm) }>
                      { amOrpm }
                    </MenuItem>
                ))}
              </div>
            </div>
          </Menu>
      }

      { isExtraSmallScreen && 
          <Dialog
            open={ Boolean(anchorEl) }
            onClose={ handleClose }
            fullScreen={ true }
            disableScrollLock={ false }
            fullWidth
            TransitionComponent={ SlideTransition }>
            <div className={ classes.selectMobile }>
              <CloseButton onClick={ handleClose } />
              <div className={ classes.mobileTimeDisplay }>
                <Typography variant='h2'>
                  { textFieldValue }
                </Typography>
              </div>

              <div className={ classes.section }>
                <Typography className={ classes.sectionHeader }>HOUR</Typography>
                { range(1, 13).map(h => {
                    const hourFormatted = hour > 12 ? hour - 12 : hour
                    const comparisonHour = hourFormatted === 0 ? 12 : hourFormatted


                    return (
                      <MenuItem
                        className={ classes.menuItemMobile }
                        style={{ 
                          backgroundColor: comparisonHour === h ? '#000' : null,
                          color: comparisonHour === h ? '#fff' : null
                        }} 
                        key={ h }
                        value={ h }
                        onClick={ handleHourChange }>
                        { h }
                      </MenuItem>
                    )
                })}
              </div>
              <div className={ classes.section }>
                <Typography className={ classes.sectionHeader }>MIN</Typography>
                { range(0, 56, 5).map(m => {
                    const displayMinute = m < 10 ? `0${m}` : m
                    
                    return (
                      <MenuItem 
                        className={ classes.menuItemMobile }
                        style={{ 
                          backgroundColor: minute === m ? '#000' : null,
                          color: minute === m ? '#fff' : null
                        }} 
                        key={ m }
                        value={ m }
                        onClick={ handleMinuteChange }>
                        { displayMinute }
                      </MenuItem>
                    )
                })}
              </div>
              <div className={ classes.section }>
                <Typography className={ classes.sectionHeader }>AM / PM</Typography>
                { ['AM', 'PM'].map(amOrpm => (
                    <MenuItem 
                      className={ classes.menuItemMobile } 
                      style={{
                        backgroundColor: amOrpm === ampm ? '#000' : null,
                        color: amOrpm === ampm ? '#fff' : null
                      }}
                      key={ amOrpm } 
                      value={ amOrpm }
                      onClick={ () => handleAmpmChange(amOrpm) }>
                      { amOrpm }
                    </MenuItem>
                ))}
              </div>
            </div>
          </Dialog>
      }
    </div>
  )
}

export default TimePickerField


