import {useState} from 'react'

import axios from 'axios'

import * as Yup from 'yup'

import {ThreeDotProgress} from '../generics'

import styles from '../../styles/footer/newsletterSubscribeField.module.scss'




const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email().required()
})




const NewsletterSubscribeField = props => {
  
  const [email, setEmail] = useState('')

  const [isSubmitting, setIsSubmitting] = useState(false)


  const [state, setState] = useState({
    error: false,
    success: false,
    message: ''
  })






  async function handleSubmit(e) {
    e.preventDefault()

    setIsSubmitting(true)


    // Check if the email is valid and show error and return if its not
    try {
      await emailValidationSchema.validate({email: email})
    } catch(error) {
      setState({
        error: true,
        success: false,
        message: 'Invalid email'
      })

      setIsSubmitting(false)
      return
    }


    // Post to the blog server to subscribe to the newsletter
    try {
      const url = 'https://blog.ckord.com/api/newsletter'
    
      const data = {
        profiles: [{email: email}]
      }

      const response = await axios.post(url, data, {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        }
      })

      setIsSubmitting(false)

      // If the user subscribed succesfully then update the state and return...
      if (response.status === 200) {
        setState({
          error: false,
          success: true,
          message: 'You successfully signed up.'
        })

        return
      } 
        
      // Otherwise show an error 
      handleError()
    
    } catch(error) {
      handleError()
      setIsSubmitting(false)
    }
  }



  function handleError(error) {
    setState({
      error: true,
      success: false,
      message: 'Something went wrong.'
    })
  }




  function handleChange(e) {
    if (state.error) {
      setState({
        error: false,
        success: false,
        message: ''
      })
    }

    setEmail(e.target.value)
  }



  return (
    <div className={styles.root}>
      <div>
        <h3>Newsletter Signup</h3>
        <p className={styles.caption}>Get the latest music news</p>

        <form onSubmit={handleSubmit}>
          <input 
            className={styles.input} 
            type='email'
            name='email' 
            required 
            placeholder='Email'
            value={email}
            onChange={handleChange}
          />

          <button type='submit' aria-disabled={isSubmitting} disabled={isSubmitting}>
            {isSubmitting ? <ThreeDotProgress radius={5} color='#fff' /> : 'Subscribe'}
          </button>
        </form>

        <p className={state.error ? styles.error : styles.success}>
          {state.message}
        </p>
      </div>
    </div>
  )
}




export default NewsletterSubscribeField



