import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import BackIcon from '@mui/icons-material/KeyboardBackspace'
import CloseIcon from '@mui/icons-material/Close'

import PerformanceFormPerformersAutocomplete from './PerformanceFormPerformersAutocomplete'



const styles = {
  root: css`
    padding: 40px;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
  `,
  backContainer: css`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    p {
      font-size: 1rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
    }
  `,
  inviteList: css`
    padding: 20px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;


    & > div {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;

      h2 {
        font-weight: 500;
        flex-grow: 1;
      }
    }
  `,
  button: css`
    height: 50px;
    margin-top: auto;
  `
}




const PerformanceFormPerformers = props => {


  const {performers, setFieldValue, close} = props




  const uninvite = id => e => {
    e.stopPropagation()

    const newValue = performers.filter(performer => performer.id !== id)

    setFieldValue('performers', newValue)
  }



  return (
    <div css={styles.root }>
      <div css={styles.backContainer}>
        <IconButton onClick={close} size='large'>
          <BackIcon fontSize='large' />
        </IconButton>

        <p onClick={close}>
          Back to Form
        </p>
      </div>



      <PerformanceFormPerformersAutocomplete 
        performers={performers}
        setFieldValue={setFieldValue}
        label='Performer Search'
      />



      <div css={styles.inviteList}>
        {performers.map(performer => (
          <div key={performer.id}>
            <ListItemAvatar>
              <Avatar src={performer.image} sx={{width: 50, height: 50}} />
            </ListItemAvatar>
            
            <h2>{performer.name}</h2>

            <IconButton onClick={uninvite(performer.id)}>
              <CloseIcon fontSize='24px' />
            </IconButton>
          </div>
        ))}
      </div>


      <Button css={styles.button} onClick={close}>
        DONE
      </Button>
    </div>
  )
}

export default PerformanceFormPerformers




