import { combineReducers } from 'redux'
import { filter, omit, map, uniq, get } from 'lodash'
import {
  mergeObjects,
  mergeRelationships, 
  mergeLinks,
  removeDataKeyFromRelationships,
  compareAndAddNewValues
} from '../utils'
import normalize from 'json-api-normalizer'



const byId = (state = {}, action) => {
  let id = ''

  switch (action.type) {
    case 'BANDS_INDEX_SUCCESS':
      if (action.payload.data.length) {
        let normalizedResponse = normalize(action.payload).band
        normalizedResponse = removeDataKeyFromRelationships(normalizedResponse)
        normalizedResponse = mergeObjects(normalizedResponse, state)

        return { ...state, ...normalizedResponse }
      } else {
        return state
      }
    case 'BAND_SHOW_SUCCESS':
    case 'BAND_UPDATE_SUCCESS':
    case 'BAND_CREATE_SUCCESS':
      id = action.payload.data.id
      
      const existingRecord = state[id]
      let normalizedResponse = normalize(action.payload).band
      normalizedResponse = removeDataKeyFromRelationships(normalizedResponse)

      if (existingRecord) {
        normalizedResponse[id] = mergeRelationships(normalizedResponse[id], existingRecord)
        normalizedResponse[id] = mergeLinks(normalizedResponse[id], existingRecord)
      }

      return { ...state, ...normalizedResponse }
    case 'BAND_UPDATE_VIDEO':
      id = action.payload.id

      return {
        ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...state[id].attributes,
            video: action.payload.video,
            videoDerivatives: action.payload.videoDerivatives
          }
        }
      }
    case 'BAND_DESTROY_SUCCESS':
      return omit(state, action.payload.data.id)
    case 'ADD_PERFORMANCES_TO_BAND':
      const existingPerformanceArray = get(state[action.payload.id], ['relationships', 'performances'], [])
      const newPerformanceArray = compareAndAddNewValues(existingPerformanceArray, action.payload.data)
      const showPast = action.payload.showPast


      if (newPerformanceArray.length !== existingPerformanceArray.length || showPast === true) {
        return { ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            relationships: {
              ...get(state[action.payload.id], 'relationships', {}),
              performances: showPast ? [ ...action.payload.data] : [ ...newPerformanceArray ]
            }
          }
        }
      } else return state
    case 'ADD_PERFORMANCE_TO_BAND':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            performances: [ 
              ...action.payload.data, ...get(state[action.payload.id], ['relationships', 'performances'], [])
            ]
          }
        }
      }
    case 'ADD_NEARBY_PERFORMANCES_TO_BAND':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            nearbyPerformances: action.payload.data
          }
        }
      }
    case 'REMOVE_PERFORMANCE_FROM_BAND':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            performances: filter(state[action.payload.id].relationships.performances, e => {
              return e.id !== action.payload.relationshipId
            })
          }
        }
      }
    case 'ADD_ARTIST_TO_BAND':
      const existingArtistArray = get(state[action.payload.id], ['relationships', 'artists'], [])
      const newArtistArray = compareAndAddNewValues(existingArtistArray, action.payload.data)


      if (newArtistArray.length !== existingArtistArray.length) {
        return { ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            relationships: {
              ...get(state[action.payload.id], 'relationships', {}),
              artists: [ ...newArtistArray ]
            } 
          }
        }
      } else return state
    case 'REMOVE_ARTIST_FROM_BAND':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            artists: filter(state[action.payload.id].relationships.artists, e => {
              return e.id !== action.payload.relationshipId
            })
          }
        }
      }
    case 'ADD_TRACKS_TO_BAND':
      const existingTrackArray = get(state[action.payload.id], ['relationships', 'tracks'], [])
      const newTrackArray = compareAndAddNewValues(existingTrackArray, action.payload.data)

      if (newTrackArray.length !== existingTrackArray.length) {
        return { ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            relationships: {
              ...get(state[action.payload.id], 'relationships', {}),
              tracks: [ ...newTrackArray ]
            }
          }
        }
      } else return state
    case 'ADD_TRACK_TO_BAND':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            tracks: [ 
              ...action.payload.data, ...get(state[action.payload.id], ['relationships', 'tracks'], [])
            ]
          }
        }
      }
    case 'REMOVE_TRACK_FROM_BAND':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            tracks: filter(state[action.payload.id].relationships.tracks, e => {
              return e.id !== action.payload.relationshipId
            }) 
          }
        }
      }
    case 'REMOVE_SPOTIFY_TRACKS_FROM_BAND':
      return { ...state,
        [action.payload]: {
          ...state[action.payload],
          relationships: {
            ...get(state[action.payload], 'relationships', {}),
            tracks: filter(state[action.payload].relationships.tracks, e => {
              return !e.attributes?.isSpotifyTrack
            }) 
          }
        }
      }
    case 'ADD_SHOP_TO_BAND':
      return {...state, 
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            shop: action.payload.shopId
          }
        }
      }
    case 'BAND_IMAGE_UPDATE_SUCCESS':
      id = action.payload.data.id

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            image: action.payload.data.attributes.image,
            imageDerivatives: action.payload.data.attributes.imageDerivatives,
          }
        }
      }
    case 'BAND_IMAGES_INDEX_SUCCESS':
      id = action.payload.meta.imagableId

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            images: action.payload.data
          }
        }
      }
    case 'BAND_IMAGES_CREATE_SUCCESS':
      id = action.payload.data.attributes.imagableId

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            images: [
              action.payload.data, 
              ...get(state[id], ['attributes', 'images'], [])
            ] 
          }
        }
      }
    case 'BAND_IMAGES_DESTROY_SUCCESS':
      id = action.payload.data.attributes.imagableId

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            images: filter(state[id].attributes.images, e => {
              return e.id !== action.payload.data.id
            }) 
          }
        }
      }
    case 'SET_BAND_FETCHED_ASSOCIATIONS':
      let fetched = state[action.payload.id].fetched

      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          fetched: fetched ? [ ...fetched, action.payload.value ] : [action.payload.value],
        }
      }
    case 'BAND_INCREMENT_VIEW_COUNT_UPDATE_SUCCESS':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          attributes: { ...state[action.payload.id].attributes,
            views: action.payload.count
          }
        }
      }
    case 'BAND_SAVE_LINKS':
      return { ...state, 
        [action.payload.id]: {
          ...state[action.payload.id],
          links: { ...(state[action.payload.id]?.links || {}),
            [action.payload.association]: action.payload.links
          }
        }
      }
    case 'BAND_MERCHANT_ACCOUNT_CREATE_SUCCESS':
      return { ...state, 
        [action.payload.id]: {
          ...state[action.payload.id],
          attributes: {
            ...state[action.payload.id].attributes,
            isMerchant: true,
            stripeId: action.payload.stripeId
          }
        }
      }
    default:
      return state
  }
}

const all = (state = [], action) => {
  switch (action.type) {
    case 'BANDS_INDEX_SUCCESS':
      return uniq([...state, ...map(action.payload.data, 'id')])
    case 'BAND_SHOW_SUCCESS':
    case 'BAND_CREATE_SUCCESS':
      return uniq([...state, action.payload.data.id])
    case 'BAND_DESTROY_SUCCESS':
      return filter(state, (e) => e !== action.payload.data.id)
    case 'BAND_RESET_STATE':
      return []
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case 'BANDS_REQUESTED':
    case 'BAND_REQUESTED':
      return true
    case 'BANDS_INDEX_SUCCESS':
    case 'BAND_SHOW_SUCCESS':
    case 'BAND_CREATE_SUCCESS':
    case 'BAND_DESTROY_SUCCESS':
    case 'BAND_RESET_STATE':
    case 'BAND_SEARCH_INDEX_SUCCESS':
    case 'BAND_ERROR':
    case 'BANDS_ERROR':
      return false
    default:
      return state
  }
}

export default combineReducers({
  byId,
  all,
  isFetching
})

