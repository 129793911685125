import React, { useState } from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { sentenceCase } from '../../../utils'
import Typography from '@mui/material/Typography'
import ProductColorOptions from './ProductColorOptions'
import ProductVariantSelect from './ProductVariantSelect'



const cssStyles = props => ({
  root: css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 30px;
  ` ,
  title: css`
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
  `,
  optionsContainer: css`
    display: flex;
    flex-direction: row;
    gap: 10px;
  `,
  button: css`
    border: 1px solid red;
    color: red;
    font-size: 0.75rem;
  `
})




const ProductVariant = props => {

  const styles = cssStyles()
  
  const { variant, options, callback } = props

  const [color, setColor] = useState('')



  function selectColor(color) {
    setColor(color)
    
    if (callback) callback(variant, color)
  }



  return (
    <div css={ styles.root }>
      <Typography css={ styles.title }>
        { color ? variant + ' - ' + color : variant }
      </Typography>


      <div css={ styles.optionsContainer }>
        { ['color', 'colors'].includes(variant) 
            ? <ProductColorOptions 
                colors={ options }
                callback={ selectColor }
              />
            : <ProductVariantSelect
                variant={ variant }
                options={ options } 
                placeholder={ 'Select '+ sentenceCase(variant) }
                callback={ callback }
              />
        }
      </div>
    </div>
  )
}



export default ProductVariant



