import React, {useEffect} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {day, formatDate} from '../../../utils'

import Image from '../Image'

import QRCode from 'react-qr-code'

import Button from '@mui/material/Button'
import PrintIcon from '@mui/icons-material/Print'



const styles = {
  root: css`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column;
    position: relative;
    overflow: hidden;

    @media print {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      margin: 0;
      -webkit-print-color-adjust: exact;

      @page {
        margin: 0; 
      }
    }
  `,
  aspectRatioBox: css`
    width: 100%;
    height: 0;
    padding-top: calc(100% * 11 / 8.5);
    position: relative;
    margin: 0;
    border-radius: 4px;
    overflow: hidden;

    @media print {
      border-radius: 0px;
    }
  `,
  aspectRatioBoxInside: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
  imageContainer: css`
    width: 100%;
  `,
  image: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  title: css`
    text-transform: uppercase;
    color: #fff;
    font-size: 3rem;
    z-index: 10;
    font-weight: 900;
    margin-top: 40px;
  `,
  qrCodeContainer: css`
    width: 18%;
    position: absolute;
    bottom: 2%;
    left: 3%;
    border-radius: 7px;
    overflow: hidden;
  `,
  dateContainer: css`
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15%;
    padding-bottom: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    background-color: #fff;
  `,
  caption1: css`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.8vw;
    line-height: 1;
  `,
  caption2: css`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.8vw;
    line-height: 0.3;
  `,
  day: css`
    font-size: 6vw;
    line-height: 1.2;
    color: #f24385;
    font-family: Gravity, Avenir Next, Arial Black, sans-serif;
  `,
  qrcode: css`
    width: 100%;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;
    padding: 10%;
    background-color: #fff;
  `,
  controlsContainer: css`
    margin-top: 15px;
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media print {
      display: none;
    }
  `,
  button: css`
    padding-right: 20px;
    padding-left: 20px; 
  `
}




const PerformanceFlier = props => {

  const {id, image, start} = props


  const url = process.env.REACT_APP_API_URL + '/performances/' + id 



  useEffect(() => {
    window.addEventListener('beforeprint', handlePrint)
    window.addEventListener('afterprint', handlePrintComplete)


    function handlePrint(e) {
      const root = document.getElementById('root')

      root?.classList.add('hide-from-print')
    }


    function handlePrintComplete(e) {
      const root = document.getElementById('root')

      root?.classList.remove('hide-from-print')
    }


    return () => {
      window.removeEventListener('beforeprint', handlePrint)
      window.removeEventListener('afterprint', handlePrintComplete)
    }
  }, [])



  function print() {
    window.print()
  }


  return (
    <div id='performance-flier' css={styles.root}>
      <div css={styles.aspectRatioBox}>
        <div css={styles.aspectRatioBoxInside}>
          <Image css={styles.image} src={image} alt='performance profile' />
        </div>

        <div css={styles.qrCodeContainer}>
          <div css={styles.dateContainer}>
            <p css={styles.caption1}>{day(start, 'eee')}</p>
            <p css={styles.day}>{formatDate(start, 'd')}</p>
            <p css={styles.caption2}>{formatDate(start, 'MMM')}</p>
          </div>


          <div css={styles.qrcode}>
            <QRCode value={url} style={{height: 'auto', maxWidth: '100%', width: '100%'}} />
          </div>
        </div>
      </div>

      <div css={styles.controlsContainer}>
        <Button css={styles.button} onClick={print} endIcon={<PrintIcon />}>
          PRINT 
        </Button>
      </div>
    </div>
  )
}


export default PerformanceFlier




