import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

import Confirmation from '../Confirmation'



const cssStyles = ({color}) => ({
  menuItem: css`
    color: ${color ? color : 'rgba(0, 0, 0, 0.54)'};
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    
    svg {
      width: 24px;
      margin-right: 10px;
    }

    span {
      color: #434343;
    }
  `
})



const DeleteIconButton = props => {

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const [open, setOpen] = useState(false)

  const {
    title,
    text,
    size, 
    color,
    variant,
    imageURL,
    primaryAction,
    isAuthenticated, 
    ...rest
  } = props


  const styles = cssStyles({color})

  const iconSize = size || (isExtraSmallScreen ? 'small' : 'medium')




  function handleClick() {
    setOpen(true)
  }



  function close() {
    setOpen(false)
  }




  return (
    <>
      {variant === 'menuItem' &&
        <MenuItem onClick={handleClick} css={styles.menuItem} {...rest}>
          <DeleteIcon fontSize={iconSize || 'medium'} />
          <span>Delete</span>
        </MenuItem>
      }


      {(variant === 'button' || !variant) &&
        <IconButton onClick={handleClick} size={iconSize || 'medium'} {...rest}>
          <DeleteIcon fontSize={iconSize || 'medium'} style={{color: (color || '#fff')}} />
        </IconButton>
      }


      <Confirmation 
        open={open}
        close={close}
        title={title}
        text={text}
        image={imageURL} 
        primaryText='DELETE'
        primaryAction={primaryAction}
        primaryColor='red'
        secondaryAction={close}
      />
    </>
  )
}


export default DeleteIconButton




