import { markAsRead, markNotificationsAsSeen } from '../apis'


export function changeNotificationStatusToRead(id) {
  return dispatch => {
    return markAsRead(id).then(() => {
      dispatch({ type: 'NOTIFICATION_READ', payload: { id }})
    })
  }
}


export function changeNotificationStatusesToSeen() {
  return dispatch => {
    return markNotificationsAsSeen().then(() => {
      dispatch({ type: 'NOTIFICATIONS_SEEN' })
    })
  }
}