import { crudAPI } from '../apis'


export const viewCountActions = {
  incrementViewCount
}


export function incrementViewCount(url, type, id) {
  return dispatch => {
    dispatch({type: type + 'INCREMENT_VIEW_COUNT_REQUESTED'})

    return crudAPI.update(url).then(response => {
      const count = response.data.data.count

      dispatch({type: type + '_INCREMENT_VIEW_COUNT_UPDATE_SUCCESS', payload: {id, type, count}})
      return response.data
    })
    .catch(error => {
      dispatch({type: type + '_INCREMENT_VIEW_COUNT_UPDATE_ERROR'})
      throw(error)
    })
  }
}