import { reduceFormErrors } from '../utils'



export function formSubmittedSuccessfully() {
  return dispatch => {
    dispatch({ type: 'FORM_SUBMITTED_SUCCESS' })
  }
}


export function formSubmittedWithErrors(messages, values = {}) {
  const errors = reduceFormErrors(messages, values)

  return dispatch => {
    dispatch({ type: 'FORM_SUBMITTED_ERROR', payload: errors })
  }
}


export function resetFormState() {
  return dispatch => {
    dispatch({ type: 'RESET_FORM_STATE' })
  }
}