import instance from './instance'

export const invitationAPI = {
  accept,
  decline
}

export function accept(id) {
  return instance.patch('invitations/' + id + '/accept')
}

export function decline(id) {
  return instance.patch('invitations/' + id + '/decline')
}
