import React, {useState, useCallback} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {destroy, showAlert} from '../../actions'

import spotifyClientId from '../../helpers/spotify.js'

import useMediaQuery from '@mui/material/useMediaQuery'

import Menu from '@mui/material/Menu'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'

import {PiSpotifyLogoFill} from 'react-icons/pi'

import {TrackForm} from '../track'

import { 
  LikeIconButton, 
  EditIconButton,
  MoreIconButton,
  DeleteIconButton,
  FavoriteIconButton
} from '../generics'



const styles = {
  buttonsContainer: css`
    display: flex;
    gap: 5px;
  `,
  spotifyContainer: css`
    display: flex;
    align-items: center;
    padding-right: 5px;
  `,
  spotifyIcon: css`
    width: 32px;
    height: 32px;
    color: #1DB954;
  `,
  spotifyIconSmall: css`
    color: #1DB954;
    width: 22px;
    height: 22px;
    position: absolute;
    bottom: -5px;
    left: 4px;
  `,
  modal: css`
    max-width: 400px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h2 {
      font-size: 1.7rem;
      font-weight: 500;
      margin-bottom: 15px;
    }


    i {
      position: relative;
      display: inline-flex;
      width: 30px;
    }


    p {
      margin-bottom: 36px;
    }


    button {
      background-color: black;
      color: #fff;
      padding: 10px 24px;
    }
  `,
  menu: css`
    li {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `
}



const TrackCardButtons = props => {

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  // Edit form
  const [open, setOpen] = useState(false)

  // Spotify authentication modal
  const [show, setShow] = useState(false)

  // Menu
  const [anchorEl, setAnchorEl] = useState(null)


  const {
    track, 
    imageURL, 
    destroy, 
    showAlert, 
    permission,
    spotifyAccessToken,
    spotifyAccessTokenExpiration
  } = props




  const deleteTrack = useCallback(() => {
    destroy('/tracks/' + track.id, 'TRACK').then(response => {
      showAlert('Track deleted', 'info')
    })
    .catch(error => {
      showAlert('Couldn\'t delete track', 'error')
    })
  }, [track.id, showAlert, destroy])
  



  const handleMoreClick = useCallback(e => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }, [setAnchorEl])




  function handleSpotifyClick() {
    if (!spotifyAccessToken || spotifyAccessTokenExpiration < Date.now) {
      setShow(true)
    }
  }



  function authenticateWithSpotify() {

    const attributes = track?.attributes 


    let url = 'https://accounts.spotify.com/authorize?'

    url += 'client_id=' + spotifyClientId
    url += '&redirect_uri=http://localhost:3001/spotify-callback'
    url += '&response_type=code'
    url += '&scope=user-read-email%20user-library-modify'
    url += '&state=' + attributes?.composerType.toLowerCase() + '%20' + attributes?.composerId


    window.location.replace(url)
  }




  function close(e) {
    e.stopPropagation()
    setAnchorEl(null)
  }



  function closeForm() {
    setOpen(false)
  }



  function closeSpotifyModal() {
    setShow(false)
  }




  return (
    <>
      <div css={styles.buttonsContainer}>
        {track?.attributes?.isSpotifyTrack &&
          <div css={styles.spotifyContainer}>
{/*            <IconButton onClick={handleClick} {...rest} size={iconSize}>
              <FavoriteIcon
                fontSize={isExtraSmallScreen ? 'small' : 'medium'}
                style={{  
                  color: isFavorited ? theme.palette.pink.main : (color || '#fff')
                }}
              />
            </IconButton>*/}

            <PiSpotifyLogoFill 
              css={styles.spotifyIcon} 
              // onClick={handleSpotifyClick}
            />
          </div>
        }



        {!track?.attributes?.isSpotifyTrack &&
          <>
            {isExtraSmallScreen &&
              <>
                <FavoriteIconButton 
                  key='favorite'
                  id={track.id}
                  type='track'
                  color='rgba(0, 0, 0, 0.54)'
                />

                <MoreIconButton
                  key='more'
                  onClick={handleMoreClick}
                  color='rgba(0, 0, 0, 0.54)'
                />
              </>
            }


            {!isExtraSmallScreen &&
              <>
                <LikeIconButton 
                  key='like' 
                  id={track.id} 
                  type='track'
                  color='rgba(0, 0, 0, 0.54)'
                />

                <FavoriteIconButton 
                  key='favorite'
                  id={track.id}
                  type='track'
                  color='rgba(0, 0, 0, 0.54)'
                />

                {['owner', 'editor'].includes(permission) &&
                  <MoreIconButton
                    key='more'
                    onClick={handleMoreClick}
                    color='rgba(0, 0, 0, 0.54)'
                  />
                }
              </>
            }
          </>
        }
      </div>



      <Dialog open={show} onClose={closeSpotifyModal}>
        <div css={styles.modal}>
          <h2>Sign in to Spotify</h2>

          <p>
            To listen to the full versions of tracks and to be able to save tracks marked with
            the
            <i>
              <PiSpotifyLogoFill 
                css={styles.spotifyIconSmall} 
                onClick={handleSpotifyClick}
               />
            </i> 
            logo to your Spotify account, you will need to grant Ckord certain access to 
            your account as indicated on the next page. Your privacy and security 
            are important to us. You can manage or revoke access at any time in your Spotify settings. 
            
            <br/><br/>

            If you wish to continue, click the continue button below and you will be redirected 
            to the Spotify login page.

            <br/><br/>

            If you decline, you will still be able to listen to 30 second previews of tracks.
          </p>

          <Button onClick={authenticateWithSpotify}>
            Continue
          </Button>
        </div>
      </Dialog>




      <Menu css={styles.menu} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={close}>
        {isExtraSmallScreen && 
          <LikeIconButton 
            id={track.id} 
            type='track' 
            variant='menuItem' 
            color='rgba(0, 0, 0, 0.54)'
          />
        }


        {['owner', 'editor'].includes(permission) && [
          <EditIconButton 
            key='edit' 
            open={open} 
            setOpen={setOpen}
            maxWidth='xs' 
            variant='menuItem'
          >
            <TrackForm trackId={track.id} close={closeForm} />
          </EditIconButton>,


          <DeleteIconButton
            key='delete'
            imageURL={imageURL}
            variant='menuItem'
            title='Delete Track'
            text='Are you sure you want to delete this track?'
            primaryAction={deleteTrack}
          />
        ]}
      </Menu>
    </>
  )
}



const actions = {destroy, showAlert}



export default connect(null, actions)(TrackCardButtons)





