const shippingURLS = {
  fedex: 'https://www.fedex.com/fedextrack/?trknbr=',
  usps: 'http://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=',
  ups: 'https://www.fedex.com/fedextrack/?trknbr=',
} 



export function shippingURLFor(carrier, trackingNumber) {
  const key = carrier.toLowerCase()
  
  return shippingURLS[key] + trackingNumber
}