import React, {useState} from 'react'

import {parsePhoneNumber, AsYouType} from 'libphonenumber-js'

import TextField from '@mui/material/TextField'




const helperText = `
  If outside the US, use the international format starting with 
  the country code e.g. +381
`



const FormikPhoneNumberField = ({field, form, ...props}) => {

  const [countryCode, setCountryCode] = useState('US')

  const {name, value} = field

  const {touched, errors, setFieldValue, setFieldError} = form


  const {label, variant, placeholder} = props


  const error = errors[name]

  const isError = error && touched[name]


  const handleChange = e => {
    let value = e.target.value


    if (!value) {
      setFieldValue(name, '')
      return
    }

    
    // This handles the issue where a US National number cannot 
    // be deleted when the input string has 3 characters
    if (value.includes('(') && !value.includes(')')) {
      value = value.replace('(', '')
      
      setFieldValue(name, value)
      return
    }


    // If the fist character is a + then treat the number as internationl, otherwise
    // treat it as a US number
    if (value?.charAt(0) === '+') {
      const asYouType = new AsYouType()

      value = asYouType.input(value)

      setFieldValue(name, value)
      setCountryCode(asYouType.getCountry())
    
    } else {
      const asYouType = new AsYouType('US')

      setFieldValue(name, asYouType.input(value))
      setCountryCode('US')
    }
  }



  const validate = e => {
    let value = e.target.value
    
    if (!value) return


    try {
      const parsed = parsePhoneNumber(value, {
        defaultCountry: countryCode, 
        extract: false
      })

    
      if (parsed.isPossible()) {
        if (parsed.country === 'US') {
          setFieldValue(name, parsed.formatNational())
        } else {
          setFieldValue(name, parsed.formatInternational())
        }
      } else {
        setFieldError('phoneNumber', 'Invalid phone number')
      }

    } catch {
      setFieldError('phoneNumber', 'Invalid phone number')
    }
  }



  return (
    <TextField
      name={name}
      label={label || 'Phone Number'}
      variant={variant || 'outlined'}
      value={value}
      fullWidth
      error={isError}
      helperText={error || helperText}
      placeholder={placeholder || '(718) 867-5309'}
      onChange={handleChange}
      onBlur={validate}
      {...props}
    />
  )
}



export default FormikPhoneNumberField




