import React from 'react'
import TextField from '@mui/material/TextField'
import { formatMoney } from '../../../../utils'




const MoneyField = ({ field, form, disabled, startAdornment, ...props }) =>{
  const { name, value, onBlur, onChange } = field
  const { touched, errors, setFieldValue, isSubmitting } = form
  const error = errors[name]
  const showError = touched[name] && !!error


  function formatInputToCurrency(e) {
    let price = e.target.value.match(/\d+\.?/g)
    price = (price === null) ? 0 : parseFloat(price.join(''))
    return formatMoney(price).replace('$', '')
  }


  return ( 
    <TextField
      name={ name }
      value={ value }
      error={ showError }
      helperText={ showError ? error : props.helperText }
      disabled={ disabled !== undefined ? disabled : isSubmitting }
      onChange={ e => setFieldValue(name, e.target.value) }
      onBlur={ e => {
        onBlur(e)
        setFieldValue(name, formatInputToCurrency(e))
      }}
      InputProps={{ startAdornment: startAdornment }}
      { ...props } 
    />
  )
}

export default MoneyField