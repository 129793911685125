import { createSelector } from 'reselect'
import { get } from 'lodash'



const selectProductId = (state, id) => {
  return get(state.products.byId[id], 'id', false)
}


const selectProductAttributesById = (state, id) => {
  return get(state.products.byId[id], 'attributes')
}

export const selectProductIds = state => state.products.byId


export const makeGetProductById = () => {
  return createSelector(
    [selectProductId, selectProductAttributesById], 
    (id, attributes) => ({ id, attributes, type: 'product' })
  )
}


