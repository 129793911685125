import {useRef, useEffect} from 'react'

import {usePlaces} from '../../../../hooks'

import {defaultAddress} from '../../../../helpers'


import TextField from '@mui/material/TextField'

import '../../../../styles/google/autocomplete.css'



const GooglePlacesField = ({field, form, disabled, ...props}) => {

  const input = useRef()

  const {name, onBlur, onChange, value} = field

  const {touched, errors, setFieldValue, isSubmitting} = form


  const isError = touched[name] && Boolean(errors[name])



  const [place, text] = usePlaces(input.current)



  useEffect(() => {
    if (place) {
      const name = place.name

      const address = getAddress()

      const latitude = place.geometry.location.lat()
      const longitude = place.geometry.location.lng()
      const coordinates = [longitude, latitude].join(' ')


      setFieldValue('address', address)
      setFieldValue('location', name)
      setFieldValue('coordinates', coordinates)
      setFieldValue('location_service', 'google')
      setFieldValue('location_service_id', place.place_id)
    }



    function getAddress() {
      return place.address_components.reduce((acc, address) => {

        if (address.types.includes('street_number')) {
          acc['number'] = address.long_name
          return acc
        }

        if (address.types.includes('route')) {
          acc['street'] = address.long_name
          return acc
        }

        if (address.types.includes('sublocality')) {
          acc['neighborhood'] = address.long_name
          return acc
        }

        if (address.types.includes('locality')) {
          acc['city'] = address.long_name
          return acc
        }

        if (address.types.includes('administrative_area_level_1')) {
          acc['state'] = address.long_name
          return acc
        }

        if (address.types.includes('country')) {
          acc['country'] = address.long_name
          return acc
        }

        if (address.types.includes('postal_code')) {
          acc['postal'] = address.long_name
          return acc
        }

        else return acc
      }, defaultAddress)
    }
  }, [place, text, setFieldValue])




  return (
    <TextField
      name={name}
      value={value}
      error={isError}
      label='Location'
      onBlur={onBlur}
      onChange={onChange} 
      fullWidth
      inputRef={input}
      helperText={isError ? errors[name] : props.helperText}
      disabled={disabled || isSubmitting}
      {...props}
    />
  ) 
}

export default GooglePlacesField

