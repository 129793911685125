import React from 'react'

import {connect} from 'react-redux'

import {Navigate, useLocation} from 'react-router-dom'

import {ThreeDotProgress} from './generics'



const RequireAuth = ({children, isAuthenticated}) => {

  const location = useLocation()


  if (isAuthenticated === false) {
    return <Navigate to='/sign-in' state={{from: location}} replace />
  }

  if (isAuthenticated === null) {
    return (
      <div>
        <ThreeDotProgress position='absolute' radius={10} />
      </div>
    )
  }

  return children
}




const mapStateToProps = state => {
  return { 
    isAuthenticated: state.user.isAuthenticated 
  }
}


export default connect(mapStateToProps)(RequireAuth)


