import React from 'react'

import {connect} from 'react-redux'

import {useTheme} from '@mui/styles'

import {useNavigate} from 'react-router-dom'

import useMediaQuery from '@mui/material/useMediaQuery'

import {get} from 'lodash'

import {create, destroy, showAlert} from '../../../actions'

import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import FavoriteIcon from '@mui/icons-material/Favorite'

import {closeSnackbar} from 'notistack'




const FavoriteIconButton = props => {
  
  const theme = useTheme()

  const navigate = useNavigate()

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))


  const {
    id, 
    type,
    size,
    color,
    create, 
    destroy, 
    showAlert,
    favoriteId, 
    isFavorited, 
    isAuthenticated,
    ...rest
  } = props

  
  const iconSize = size || (isExtraSmallScreen ? 'small' : 'medium')



  function navigateToSignupPage() {
    navigate('/sign-up', {
      state: {
        redirect: `/${type}s/${id}`
      }
    })
  }




  function handleClick() {
    if (!isAuthenticated) {
      const action = snackbarID => (
        <>
          <Button 
            variant='text' 
            sx={{
              fontWeight: 600,
              color: 'rgb(62, 166, 255)'
            }}
            onClick={navigateToSignupPage}
          >
            Sign Up
          </Button>

          <IconButton 
            aria-label='close'
            sx={{
              fontWeight: 600,
              color: '#fff'
            }}
            onClick={() => closeSnackbar(snackbarID)}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </>
      )


      showAlert(`Sign up to favorite this ${type}`, 'standard', action)
      return
    }


    if (isFavorited) {
      destroy('/favorites/' + favoriteId, 'FAVORITE')
    } else {
      create(`/favorites/${type}/${id}`, 'FAVORITE').catch(error => {
        showAlert(error.apiMessage(), 'error')
      })
    }
  }




  return (
    <IconButton onClick={handleClick} {...rest} size={iconSize}>
      <FavoriteIcon
        fontSize={iconSize}
        style={{  
          color: isFavorited ? theme.palette.pink.main : (color || '#fff')
        }}
      />
    </IconButton>
  )
}



const mapStateToProps = (state, props) => {
  
  const {id, type} = props

  const pluralType = type + 's'

  const favoriteId = get(state, ['user', 'favorites', pluralType, id, 'id'])


  return {
    favoriteId: favoriteId,
    isFavorited: Boolean(favoriteId),
    isAuthenticated: state.user.isAuthenticated
  }
}



const actions = {create, destroy, showAlert}


export default connect(mapStateToProps, actions)(FavoriteIconButton)



