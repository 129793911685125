import {
  IoLogoInstagram as InstagramIcon,
  IoLogoTiktok as TikTokIcon,
  IoLogoApple as AppleIcon,
  IoLogoAndroid as AndroidIcon,
  IoLogoYoutube as YouTubeIcon
} from 'react-icons/io5'

import NewsletterSubscribeField from './NewsletterSubscribeField'

import {CkordLogo} from '../generics'


import styles from '../../styles/footer/footer.module.scss'





const LandingPageFooter = props => {
  return (
    <footer id={styles.footer}>
      <div className={styles.container}>
        <div className={styles.column}>
          <CkordLogo />


          <p>
            At Ckord, our mission is to create memorable experiences 
            and build community through live music. As a result, artists 
            and venues are at the center of everything that we do. 
            Artists move people from all walks of life. 
            Venues provide a safe environment for people to come together and 
            share experiences. The combination of the two creates an unparalleled human experience.
          </p>


          <div className={styles.contact}>
            <h4>Contact Us</h4>

            <a href='mailto:support@ckord.com'>support@ckord.com</a>
          </div>

          <div className={styles.socialMediaContainer}>
            <h4>Follow Us</h4>

            <div className={styles.icons}>
              <div className={styles.instagramIcon}>
                <a href='https://www.instagram.com/ckord.app'>
                  <InstagramIcon size='24px' color='#fff' />
                </a>
              </div>

              <div className={styles.tikTokIcon}>
                <a href='https://www.tiktok.com/@ckord.app'>
                  <TikTokIcon size='24px' color='#fff' />
                </a>
              </div>

              <div className={styles.youtubeIcon}>
                <a href='https://www.youtube.com/@ckord-app'>
                  <YouTubeIcon size='24px' color='#fff' />
                </a>
              </div>
            </div>
          </div>
        </div>


        <div className={styles.column}>
          <NewsletterSubscribeField />


          <div className={styles.downloadContainer}>
            <a className={styles.link} href='https://apps.apple.com/us/app/ckord/id1575293616'>
              <AppleIcon size='20px' />iOS
            </a>

            
            <div className={styles.androidContainer}>
              <AndroidIcon size='20px' />
              <div>
                <p>Android</p>
                <span>Coming Soon</span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className={styles.horizontalLineContainer}>
        <hr />
      </div>


      <div className={styles.lastRow}>
        <p>Ckord, Inc.</p>
        
        <div className={styles.lastRowLinks}>
          <a href='https://www.termsfeed.com/live/620e35fc-423c-478b-8fad-b454b3cb46b6'>Terms & Conditions</a>

          <a href='https://www.termsfeed.com/live/06d83963-7e0d-42ff-a4c8-951823b246ca'>Privacy Policy</a>
        </div>
      </div>
    </footer>
  )
}



export default LandingPageFooter

