import React, { useState } from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { SketchPicker } from 'react-color'


const cssStyles = ({ color }) => (
  {
    color: css`
      width: 36px;
      height: 14px;
      border-radius: 2px;
      background: ${color ? 
        `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : 
        `rgba(${defaultColor.r}, ${defaultColor.g}, ${defaultColor.b}, ${defaultColor.a})`
      }
    `,
    swatch: css`
      padding: 5px;
      background: #fff;
      border-radius: 1px;
      box-shadow: 0 0 0 1px rgba(0,0,0,.1);
      display: inline-block;
      cursor: pointer;
    `,
    popover: css`
      position: absolute;
      z-index: 2;
    `,
    cover: css`
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    `
  }
)


const defaultColor = {
  r: '0',
  g: '0',
  b: '0',
  a: '1'
}


const ColorPicker = props => {
  const { color, setColor } = props
  const [isShowing, setIsShowing] = useState()

  
  const styles = cssStyles({ color, defaultColor })


  function handleClick() {
    setIsShowing(!isShowing)
  }


  function handleClose() {
    setIsShowing(false)
  }


  function handleChange(color) {
    setColor(color.rgb)
  }


  return (
    <div>
      <div css={ styles.swatch } onClick={ handleClick }>
        <div css={ styles.color } />
      </div>

      { isShowing && 
          <div css={ styles.popover }>
            <div css={ styles.cover } onClick={ handleClose } />
            <SketchPicker color={ color || defaultColor } onChange={ handleChange } />
          </div>
      }
    </div>
  )
}



export default ColorPicker



