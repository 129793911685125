import {useRef, useState,  useEffect, useCallback} from 'react'

import {FaPlay, FaPause} from 'react-icons/fa6'

import styles from '../../../styles/gradientPlayButton.module.scss'




const GradientPlayButton = props => {

  const videoRef = useRef()
  const buttonRef = useRef()

  const [isPlaying, setIsPlaying] = useState(false)


  const {showText} = props




  const pause = () => { 
    if (!isPlaying) return

    setIsPlaying(false)
    videoRef.current?.pause()
  }



  const play = useCallback(() => {
    const video = videoRef.current
    const button = buttonRef.current

    if (!video) return

    button.style.opacity = '1'

    video.style.transform = 'scale(1)'


    function handlePlayPromise() {
      setIsPlaying(true)
    }


    function handlePlayError(error) {
      console.log(error)
    }


    video.play()
      .then(handlePlayPromise)
      .catch(handlePlayError)

  }, [setIsPlaying])




  useEffect(() => {
    videoRef.current = window.document.getElementById('profile-video')
    buttonRef.current = window.document.getElementById('profile-video-close-button')


    function ended() {
      setIsPlaying(false)
    }


    videoRef.current?.addEventListener('pause', ended)
    videoRef.current?.addEventListener('ended', ended, {once: true})
    videoRef.current?.addEventListener('play', play, {once: true})


    return () => {
      videoRef.current?.removeEventListener('play', play)
      videoRef.current?.removeEventListener('pause', ended)
      videoRef.current?.removeEventListener('ended', ended)
    }
  }, [play])





  function handleClick() {
    if (isPlaying) {
      pause()
    } else {
      videoRef.current.muted = false
      play()
    }
  }



  return (
    <div className={styles.gradientButtonWrapper}>
      <button className={styles.gradientButton} onClick={handleClick}>
        {!isPlaying &&
          <>
            <FaPlay /> {showText && 'play'}
          </>
        }

        {isPlaying &&
          <>
            <FaPause /> {showText && 'pause'}
          </>
        }
      </button>
    </div>
  )
}



export default GradientPlayButton



