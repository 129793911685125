import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {cartQuantitySelector} from '../../../selectors'

import Badge from '@mui/material/Badge'
import BagIcon from '@mui/icons-material/LocalMall'
import IconButton from '@mui/material/IconButton'

import CartMenu from '../cart/CartMenu'

import {CkordLogo} from '../../generics'



const styles = {
  root: css`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  `,
  leftSide: css`
    display: flex;
    gap: 5px;
    align-items: center;
  `,
  rightSide: css`
    display: flex;
    align-items: center;
  `,
  menu: css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  `,
  item: css`
    color: #000;
    width: auto;
    font-weight: 500;
  `
}





const HomePageUnauthenticatedHeader = props => {


  const [anchorEl, setAnchorEl] = useState(null)


  const {Sidebar, itemCount} = props



  function showCart(e) {
    setAnchorEl(e.currentTarget)
  }


  return (
    <div css={styles.root}>
      <div css={styles.leftSide}>
        {Sidebar}

        <CkordLogo style={{fontSize: '21px', cursor: 'pointer'}} />
      </div>
      

      <div css={styles.rightSide}>
        <IconButton onClick={showCart} sx={{zIndex: 100}}> 
          <Badge 
            sx={{
              '.MuiBadge-badge': {
                top: -1,
                right: -1,
                color: '#fff',
                backgroundColor: '#ff0055'
              }
            }}
            overlap='circular'
            badgeContent={itemCount}
          >
            <BagIcon color='primary' />
          </Badge>
        </IconButton>
      </div>

      <CartMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </div>
  )
}



const mapStateToProps = state => {
  return {
    itemCount: cartQuantitySelector(state)
  }
}



export default  connect(mapStateToProps)(HomePageUnauthenticatedHeader)





