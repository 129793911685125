import { createSelector } from 'reselect'


const selectAccountAttributes = state => state.user.account.attributes
const selectAccountRelationships = state => state.user.account.relationships


export const accountAttributesSelector = createSelector(
  selectAccountAttributes, attributes => attributes
)


export const accountRelationshipsSelector = createSelector(
  selectAccountRelationships, relationships => relationships
)


export const accountSettingsSelector = createSelector(
  selectAccountAttributes, attributes => attributes.settings || {}
)