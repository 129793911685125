
const INITIAL_STATE = {
  error: false,
  method: null,
  isLocated: false,
  isFetching: false,
  lat: window.localStorage.getItem('searchLocationLat') || 40.7128,
  lng: window.localStorage.getItem('searchLocationLng') || -74.0060,
  countryCallingCode: '+1'
}



const locationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'TRY_NEXT_GEOLOCATION_METHOD':
      return {
        ...state, 
        method: action.payload
      }
    case 'REQUESTING_CURRENT_LOCATION':
      return {
        ...state, 
        isFetching: true
      }
    case 'SET_USER_LOCATION':
      return {
        ...state,
        isFetching: false,
        method: action.payload.method,
        lat: action.payload.lat,
        lng: action.payload.lng,
        isLocated: true
      } 
    default:
      return state
  }
}




export default locationReducer