import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {useNavigate} from 'react-router-dom'

import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import {ReactComponent as StoreFront} from '../../../svgs/StoreFront.svg'



const styles = {
  root: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;

    @media (max-width: 600px) {
      max-width: 100%;
      height: 100vh;
    }
  `,
  innerContainer: css`
    padding: 40px 50px 50px 50px;
    max-width: 380px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
      font-size: 1.5rem;
      font-weight: 500;
      letter-spacing: -0.1rem;
      margin-bottom: 10px;
      width: 100%;
    }

    p {
      font-size: 1rem;
      letter-spacing: 0;
      font-weight: 400;
      color: #606060;

      span {
        font-weight: 500;
        color: #000;
      }
    }


    & > button {
      margin-top: 50px;
      width: 250px;
      height: 60px;
      border-radius: 30px;
    }
  `,
  imageContainer: css`
    width: 70%;
    margin-bottom: 50px;
    padding-top: 20px;
  `,
  closeButtonContainer: css`
    position: absolute;
    top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `
}



const MerchantSignUpNotice = props => {

  const navigate = useNavigate()

  const {id, type, stripeId, name, close} = props



  function handleClick() {
    navigate('/merchants/' + type + '/' + id + '/registration', {replace: false})
  }



  return (
    <div css={styles.root}>
      <div css={styles.closeButtonContainer}>
        <IconButton onClick={close}> 
          <CloseIcon fontSize='large' />
        </IconButton>
      </div>


      <div css={styles.innerContainer}>
        <div css={styles.imageContainer}>
          <StoreFront />
        </div>

        <h2>Merchant Account</h2>

        {['artist', 'band'].includes(type) && 
          <p>
            Registering as a merchant is free, quick, and easy. After registering as merchant, you will
            be able to sell your <span>{name}</span> merchandise and accept contributions directly 
            from users through your Ckord account.
          </p>
        }


        {['venue'].includes(type) &&
          <p>
            Registering as a merchant is quick and easy. After registering as merchant, you will
            be able to sell your  <span>{name}</span> merchandise.
          </p>
        }


        <Button css={styles.button} onClick={handleClick}>
          {stripeId ? 'Complete Registration' : 'Find Out More'}
        </Button>
      </div>
    </div>
  )
}



export default MerchantSignUpNotice



