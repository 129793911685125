import {combineReducers} from 'redux'

import status from './searchStatusReducer'
import filters from './filterReducer'
import venue from './searchVenueReducer'
import performance from './searchPerformanceReducer'
import tracks from './searchTracksReducer'
import counter from './searchCounterReducer'


const search = combineReducers({
  counter,
  status,
  filters,
  venue,
  performance,
  tracks
})



export default search