import { combineReducers } from 'redux'
import { isAuthenticated, authService } from './authReducer'


import account from './accountReducer'
import location from './locationReducer'
import notifications from './notificationReducer'
import favorites from './favoritesReducer'
import following from './followsReducer'
import likes from './likesReducer'
import lff from './lffReducer'
import cart from './cartReducer'
import id from './idReducer'
import attributes from './attributesReducer'
import settings from './settingsReducer'
import spotify from './spotifyReducer'


export default combineReducers({
  id,
  isAuthenticated,
  attributes,
  settings,
  authService,
  account,
  location,
  notifications,
  favorites,
  following,
  likes,
  lff,
  cart,
  spotify
})
