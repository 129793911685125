import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Avatar from '@mui/material/Avatar'

import Button from '@mui/material/Button'




const cssStyles = props => ({
  body: css`
    padding: 40px 20px 20px 20px;
    max-width: 320px;
  `,
  imageContainer: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  image: css`
    width: ${props.imageWidth ? props.imageWidth + 'px' : '100px'};
    height: ${props.imageHeight ? props.imageHeight + 'px' : '100px'};
    margin-bottom: 10px;
  `,
  title: css`
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  `,
  text: css`
    font-size: 1rem;
    font-weight: 500;
    color: #7b7b7b;
  `,
  primaryAction: css`
    font-weight: 500;
    box-shadow: none;

    &.MuiButton-contained {
      background-color: ${props.primaryColor || '#000'};

      &:hover {
        background-color: ${props.primaryColor || '#000'};
      }
    }
  `,
  secondaryAction: css`
    color: #7b7b7b;
    font-weight: 500;
    margin-right: 10px;

    &.MuiButton-contained {
      background-color: ${props.secondaryColor || '#000'};

      &:hover {
        background-color: ${props.secondaryColor || '#000'};
      }
    }
  `,
  actionsContainer: css`
    padding: 20px 15px;
    background-color: #e9e9e9;
  `
})



const Confirmation = props => {

  const { 
    open, 
    close, 
    title, 
    text,
    image,
    imageWidth,
    imageHeight,
    imageVariant,
    primaryText,
    primaryAction,
    primaryColor,
    secondaryText,
    secondaryColor,
    secondaryAction
  } = props


  const styles = cssStyles({imageWidth, imageHeight, primaryColor, secondaryColor})


  function _secondaryAction() {
    if (secondaryAction) {
      secondaryAction()
      return
    }

    if (close) {
      close()
    }
  }
  
  

  return (
    <Dialog open={open} disableScrollLock onClose={close}>
      <div css={styles.body}>
        <div css={styles.imageContainer}>
          <Avatar 
            css={styles.image} 
            alt='profile' 
            src={image} 
            variant={imageVariant} 
          />
        </div>

        <DialogTitle css={styles.title}>
          {title}
        </DialogTitle>

        <DialogContent>
          {typeof text === 'string' 
            ? <DialogContentText css={styles.text}>
                {text}
              </DialogContentText> 
            : text
          }
        </DialogContent>
      </div>


      <DialogActions css={styles.actionsContainer}>
        <Button css={styles.secondaryAction} variant='text' onClick={_secondaryAction}>
          {secondaryText || 'CANCEL'}
        </Button>

        <Button css={styles.primaryAction} onClick={primaryAction}>
          {primaryText || 'CONFIRM'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Confirmation


