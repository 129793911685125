import React, {useState, useRef} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {omit} from 'lodash'

import * as Yup from 'yup'
import {withFormik, Form, Field} from 'formik'

import {makeGetTrackById} from '../../selectors'


import { 
  create, 
  update, 
  showAlert,
  addTrackToBand,
  addTrackToArtist
} from '../../actions'


import {audioMimes} from '../../helpers/mimeTypes' 

import AttachmentIcon from '@mui/icons-material/Attachment'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'


import {
  Errors,
  TagField,
  FormTitle,
  AspectBox,
  FormButton,
  ImageField,
  CloseButton,  
  FormikTextField,
  ThreeDotProgress
} from '../generics'





const cssStyles = ({isExtraSmallScreen}) => ({
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${isExtraSmallScreen ? '40px' : '60px'};
    max-width: 450px;

    form {
      width: 100%;
      max-width: 420px;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      flex-direction: column;
      padding-top: 20px;
    }
  `,
  content: css`
    display: flex;
    flex-direction: column;
    gap: 5px;

  `,
  imageContainer: css`
    width: 100%;
    max-width: 420px;
  `,
  attachmentContainer: css`
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 25px;


    p > span {
      font-weight: 500;
      color: rgb(62, 166, 255);
      text-decoration: underline;
    }
  `,
  attachmentIcon: css`
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  `,
  attachmentSelection: css`
    padding: 5px;
    background-color: #f5f5f5;
    border-radius: 7px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 25px;

    p {
      flex-grow: 1;
      padding-left: 5px;
      padding-right: 5px;
    }
  `
})





function isValidFile(file) {
  const type = file.type
  const size = (file.size / 1024 / 1024).toFixed(2)


  if (!audioMimes.includes(type)) {
    return {
      result: false,
      message: 'File must be an mp3 or wav type file'
    }
  } 
  

  if (size > 60) {
    return {
      result: false,
      message: 'File size must be less than 60MB'
    }
  }


  return {result: true}
}






const TrackForm = props => {

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))
  
  const styles = cssStyles({isExtraSmallScreen})

  
  const input = useRef()
  

  const [fileName, setFileName] = useState('')
  const [attached, setAttached] = useState(false)


  const {
    close,
    track, 
    values,
    errors,
    handleBlur,
    isSubmitting,
    setFieldValue,
    setFieldError
  } = props


  const image = track?.attributes.image



  function fileSelectedHandler(e) {
    const reader = new FileReader()
    const file = e.target.files[0]


    if (file) {
      const isValid = isValidFile(file)


      if (isValid.result) {
        reader.readAsDataURL(file)
        attachFile(file)
      } else {
        setFieldError('audio', isValid.message)
      }
    }
  }



  function selectFile(e) {
    e.stopPropagation()
    input.current.click()
  }



  function attachFile(file) {
    setFieldValue('audio', file)
    setAttached(true)
    setFileName(file.name)
  }




  function removeAttachment() {
    setFileName('')
    setAttached(false)
    setFieldValue('audio', null)
  }




  function handleDrop(e) {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault()


    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...e.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile()

          const isValid = isValidFile(file)

          if (isValid.result) {
            attachFile(file)
          } else {
            setFieldError('audio', isValid.message)
          }
        
          return
        }
      })
    } else {
      // Use DataTransfer interface to access the file(s)
      [...e.dataTransfer.files].forEach((file, i) => {
        const isValid = isValidFile(file)

        if (isValid.result) {
          attachFile(file)
        } else {
          setFieldError('audio', isValid.message)
        }
        
        return
      })
    }  
  }



  function handleDragOver(e) {
    e.preventDefault()
  }




  const trimValue = (value, field) => e => {
    handleBlur(e)


    if (typeof value === 'string' || value instanceof String) {
      const trimmed = value.trim()

      if (trimmed !== value && values[field]) {
        setFieldValue(field, trimmed)
      }
    }
  }




  return (
    <div id='drop-zone' onDrop={handleDrop} onDragOver={handleDragOver} css={styles.root}>
      <Form autoComplete='off'>

        <CloseButton onClick={close} />
         

        <div css={styles.content}>
          <div css={styles.imageContainer}>
            <AspectBox>
              <ImageField 
                image={image} 
                fieldValue={values.image}
                setFieldValue={setFieldValue} 
                setFieldError={setFieldError}
              />
            </AspectBox>
          </div>

         

          <FormTitle title={Boolean(track) ? 'Edit Track' : 'Create Track'} />
              
          
          <Errors errors={errors?.unmatched} />


          <Field 
            name='name'
            label='Track Name'
            variant='filled'
            fullWidth
            sx={{marginBottom: '20px'}}
            component={FormikTextField}
            autoComplete='off'
            onBlur={trimValue(values.name, 'name')}
          />


          <Field
            variant='filled'
            fullWidth 
            component={TagField}
            name='tags'
            label='Tags (5 Max)'
            placeholder='Jazz, Rock, Chill...'
          />


          {!attached &&
            <div css={styles.attachmentContainer} onClick={selectFile}>
              <IconButton onClick={selectFile}>
                <AttachmentIcon css={styles.attachmentIcon} fontSize='large' />
              </IconButton>

              <p>
                <span>Click here</span>&nbsp;
                to attach a file or drag and drop the file here.
              </p>
            </div>
          }



          {attached && 
            <div css={styles.attachmentSelection}>
              <AttachmentIcon css={styles.attachmentIcon} fontSize='large' />

              <p>{fileName}</p>

              <IconButton onClick={removeAttachment} size='small'>
                <CloseIcon fontSize='small'/>
              </IconButton>
            </div>
          }



          <FormButton
            type='submit'
            disabled={isSubmitting || (!attached && !track)}
            variant='contained'
          >
            {isSubmitting 
              ? <ThreeDotProgress color='#fff' radius={5} />   
              : Boolean(track) ? 'Update Track' : 'Upload Track'
            }
          </FormButton>
        </div>



        <input
          ref={input} 
          type='file'
          style={{display: 'none'}}
          onChange={fileSelectedHandler}
        />
      </Form>
    </div>
  )
}




const EnhancedTrackForm = withFormik({
  mapPropsToValues: ({track}) => {
    
    const {name, tags} = track ? track.attributes : {}

    return { 
      name: name || '',
      tags: tags || [] 
    }
  },


  validationSchema: Yup.object().shape({
    name: Yup.string()
     .trim('name cannot start or end with a space')
     .strict(true)
     .required('required')
     .max(60)
  }),


  handleSubmit: (values, formikBag) => {
    const {
      close,
      create, 
      update, 
      creatorType,
      creatorId,
      track, 
      showAlert,
      addTrackToArtist,
      addTrackToBand
    } = formikBag.props

    
    const {setSubmitting, setErrors} = formikBag


    // If the track exists then update the record with the new values
    if (track) {

      const form = new FormData()

      if (values.image) form.append('image', values.image)
      if (values.audio) form.append('audio', values.audio)


      form.append('name', values.name)
      form.append('tags', JSON.stringify(values.tags))


      update('/tracks/' + track.id, 'TRACK', form).then(response => {
        showAlert('Track updated', 'success')
        close()
      })
      .catch(error => {
        let apiErrors = error.apiFormErrors(values)

        if ('audio' in apiErrors) {
          apiErrors['unmatched'].push(apiErrors['audio'])
          apiErrors = omit(apiErrors, ['audio'])
        }

        setErrors(apiErrors)
        setSubmitting(false)
      })
    
    // If the track does not exist create a new record with the form values
    } else {

      const url = '/' + creatorType + 's/' + creatorId + '/tracks'
      
      const form = new FormData()


      if (values.image) form.append('image', values.image)

        
      form.append('audio', values.audio)
      form.append('name', values.name)
      form.append('tags', JSON.stringify(values.tags))


      create(url, 'TRACK', form).then(response => {
        const track = response.data

        if (creatorType === 'band') addTrackToBand(creatorId, track)
        if (creatorType === 'artist') addTrackToArtist(creatorId, track)

        showAlert('Track created', 'success')
        close()
      })
      .catch(error => {
        let apiErrors = error.apiFormErrors(values)

        if ('audio' in apiErrors) {
          apiErrors['unmatched'].push(apiErrors['audio'])
          apiErrors = omit(apiErrors, ['audio'])
        }

        setErrors(apiErrors)
        setSubmitting(false)
      })
    }
  }
})(TrackForm)




const makeMapStateToProps = () => {
  const getTrackById = makeGetTrackById()

  const mapStateToProps = (state, props) => {
    const id = props.trackId

    return {
      track: id ? getTrackById(state, id) : null
    }
  }

  return mapStateToProps
}



const actions = { 
  create, 
  update,
  showAlert,
  addTrackToArtist,
  addTrackToBand
}


export default connect(makeMapStateToProps, actions)(EnhancedTrackForm)




