import {useEffect, useRef, useState, useCallback} from 'react'




const options = {
  fields: [
    'name',
    'geometry',
    'place_id',
    'address_component',
    'formatted_address', 
    'adr_address',
  ]
}





export function usePlaces(input) {
  
  
  const [state, setState] = useState({
    place: false,
    text: ''
  })


  const autocomplete = useRef()



  const getPlace = useCallback(() => {
    const place = autocomplete.current.getPlace()

    setState({ 
      place: place,
      text: place.name + ', ' + place.formatted_address
    })
  }, [autocomplete])




  useEffect(() => {
    if (!autocomplete.current && input) {
      initPlaces()


      async function initPlaces() {
        await window.google.maps.importLibrary('places')

        autocomplete.current = new window.google.maps.places.Autocomplete(input, options)
        autocomplete.current.addListener('place_changed', getPlace)
      }
    }
  }, [autocomplete, input, getPlace])

  


  useEffect(() => {
    return () => { 
      const googleContainer = document.getElementsByClassName('pac-container')
      
      if (googleContainer.length) {
        googleContainer[0].remove()
      }
    }
  }, [])


  return [state.place, state.text]
}


export default usePlaces