
export function saveLinks(type, id, association, links) {
  return dispatch => {
    dispatch({ type: type.toUpperCase() + '_SAVE_LINKS', payload: { id, association, links }})
  }
}


export function saveNotificationLinks(links) {
  return dispatch => {
    dispatch({ type: 'NOTIFICATIONS_SAVE_LINKS', payload: { links }})
  }
}


export function saveFavoriteLinksForType(type, links) {
  return dispatch => {
    dispatch({ type: 'FAVORITES_SAVE_LINKS', payload: { type, links }})
  }
}


export function saveFollowLinksForType(type, links) {
  return dispatch => {
    dispatch({ type: 'FOLLOWS_SAVE_LINKS', payload: { type, links }})
  }
}
