import instance from './instance'


export const adminAPI = {
  createVenue, 
  createArtist, 
  fetchArtists, 
  fetchVenues
}


export function createArtist(artist) {
  return instance.post('/admin/artists', artist)
}

export function fetchArtists() {
  return instance.get('/admin/artists')
}


export function createVenue(venue) {
  return instance.post('/admin/venues', venue)
}

export function fetchVenues() {
  return instance.get('/admin/venues')
}