import React from 'react'

import {fullURL} from '../../utils'

import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon from '@mui/icons-material/Instagram'


import {FaXTwitter} from 'react-icons/fa6'
import {IoLogoTiktok} from 'react-icons/io5'
import {PiSpotifyLogoFill} from 'react-icons/pi'



const iconStyle = {color: '#fff', width: '25px', height: '25px'}


const linkIcons = {
  x: <FaXTwitter style={iconStyle} />,
  twitter: <FaXTwitter style={iconStyle} />,
  spotify: <PiSpotifyLogoFill style={iconStyle} />,
  instagram: <InstagramIcon style={iconStyle} />,
  youtube: <YouTubeIcon style={iconStyle} />,
  facebook: <FacebookIcon style={iconStyle} />,
  tiktok: <IoLogoTiktok style={iconStyle} />
}





const LinksList = ({links}) => {
  return (
    <div style={{display: 'flex', marginTop: '25px'}}>
      {Object.keys(links).map((key, index) => {
          const value = links[key]
          
          if (value) {
            return (
              <a 
                key={key} 
                target='_blank' 
                rel='noopener noreferrer'
                href={fullURL(value)}
                style={{ 
                  width: '44px',
                  height: '44px',
                  display: 'flex',
                  padding: '10px',
                  borderRadius: '4px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '15px', 
                  backgroundColor: '#262626'
                }}
              >
                {linkIcons[key]}
              </a>
            )
          } else {
            return null
          }
        })
      }
    </div>
  )
}



export default LinksList