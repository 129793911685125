import React, {useState, useEffect} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import useMediaQuery from '@mui/material/useMediaQuery'

import {index} from '../../../actions'

import {get} from 'lodash'


import {makeGetVenueById, makeGetPerformanceById} from '../../../selectors'

import {
  day, 
  formatDate, 
  formatTime,
  getTopAddress,
  getBottomAddress
} from '../../../utils'


import GlobeIcon from '@mui/icons-material/Language'


import PerformanceButtons from './PerformanceButtons'
import PerformanceAddressWithVenue from './PerformanceAddressWithVenue'

import VideoForm from '../../generics/forms/formik/VideoForm'


import { 
  Tags, 
  // ViewCount,
  WebsiteLink, 
  DetailPageSection,
  InfoExpandingText,
  DetailPageTitle, 
  FavoriteIconButton,
  VideoIconButton,
} from '../../generics'




const styles = {
  root: css`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 550px;
  `,
  titleContainer: css`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 20px;


    @media (max-width: 1120px) {
      justify-content: space-between;
      padding: 0px 25px;
    }

    @media (max-width: 768px) {
      gap: 10px;
      padding: 0px 10px 0px 25px;
    }
  `,
  innerTitleContainer: css`
    max-width: 80vw;
  `,
  mainButtonContainer: css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #262626;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    z-index: 100;

    svg {
      width: 27px;
      height: 27px;
    }


    @media (max-width: 1120px) {
      position: absolute;
      top: -60px;
      right: 20px;
    }
  `,
  buttonContainer: css`
    @media (max-width: 1120px) {

      padding: 0px 25px;
    }

    @media (max-width: 550px) {
      padding: 0px;

      #ticket-link {
        margin-left: 25px;
      } 

      #button-row {
        width: auto;
        padding-right: 25px;
      }
    }
  `,
  body: css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    margin-top: 10px;
  `,
  paddedRow: css`
    @media (max-width: 1120px) {
      padding: 0px 25px;
    }
  `,
  footer: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 1120px) {
      padding: 0px 25px;
    }
  `,
  dateAndTimeContainer: css`
    color: rgb(62, 166, 255);
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    font-weight: 500;

    p {
      line-height: 1.1;
      text-transform: uppercase;
    }

    @media (max-width: 1120px) {
      padding: 0px 25px;
    }
  `
}



const PerformanceInfo = props => {


  const [open, setOpen] = useState(false)

  const isLargeScreen = useMediaQuery('(min-width:1120px)')



  const {
    permission,
    performanceId,
    name,
    location,
    address,
    start, 
    end, 
    description,
    website,
    tags,
    // views,
    venueId,
    areVenueImagesLoaded,
    venueImages,
    index
  } = props




  useEffect(() => {
    if (venueId && !areVenueImagesLoaded) {
      index('/venues/' + venueId + '/images', 'VENUE_IMAGES')
    }
  }, [areVenueImagesLoaded, venueId, index])



  useEffect(() => {
    const mainButtonContainer = document.getElementById('main-button')

    if (isLargeScreen) {
      const imageContainer = document.getElementById('image-container')
      

      if (imageContainer.contains(mainButtonContainer)) return

      mainButtonContainer.style.bottom = '15px'
      mainButtonContainer.style.right = '15px'
      mainButtonContainer.style.top = null

      imageContainer.appendChild(mainButtonContainer)
    

    } else {
      const titleContainer = document.getElementById('title-container')

      if (titleContainer.contains(mainButtonContainer)) return

      mainButtonContainer.style.top = permission ? '-45px' : '-25px'
      mainButtonContainer.style.right = '20px'
      mainButtonContainer.style.bottom = null

      
      titleContainer.appendChild(mainButtonContainer)
    }
  }, [permission, isLargeScreen])






  function close() {
    setOpen(false)
  }




  return (
    <div css={styles.root}>
      <div id='title-container' css={styles.titleContainer}>
        <div css={styles.innerTitleContainer}>
          <DetailPageTitle caption='event' title={name} />
        </div>


        {['owner', 'editor'].includes(permission) &&
          <div 
            id='main-button' 
            css={styles.mainButtonContainer}
            style={{
              width: '60px',
              height: '60px',
              top: !isLargeScreen ? '-45px' : null
            }}
          >
            <VideoIconButton open={open} setOpen={setOpen}>
              <VideoForm id={performanceId} type='performance' close={close} />
            </VideoIconButton>
          </div>
        }


        {!['owner', 'editor'].includes(permission) && 
          <div 
            id='main-button' 
            css={styles.mainButtonContainer}
            style={{
              width: '60px',
              height: '60px',
              top: !isLargeScreen ? '-45px' : null
            }}
          >
            <FavoriteIconButton id={performanceId} type='performance' />
          </div>
        }
      </div>


      {/*<ViewCount views={ views } />*/}

      <div css={styles.dateAndTimeContainer}>
        <p> 
          {
            day(start, 'eee') + 
            '\u00A0\u00A0•\u00A0\u00A0' + 
            formatDate(start, 'MMM do') + 
            '\u00A0\u00A0•\u00A0\u00A0' + 
            formatTime(start) + 
            ' to ' + 
            formatTime(end)
          }
        </p>
      </div>


      <div css={styles.buttonContainer}>
        <PerformanceButtons performanceId={performanceId} />
      </div>




      <div css={styles.body}>
        {Boolean(venueId) && 
          <PerformanceAddressWithVenue 
            venueId={venueId}
            address={address} 
            location={location} 
            images={venueImages}
          />
        }


        {!Boolean(venueId) &&
          <div css={styles.paddedRow}>
            <DetailPageSection
              title={location}
              text={getTopAddress(address)}
              subtext={getBottomAddress(address)}
            />
          </div>
        }


        {description && 
          <div css={styles.paddedRow}>
            <InfoExpandingText text={description} /> 
          </div>
        }
        


        {website && 
          <div css={styles.paddedRow}>
            <DetailPageSection 
              icon={<GlobeIcon />} 
              Content={<WebsiteLink url={website} />} 
            />
          </div>
        }
      </div>



      <div css={styles.footer}>
        <Tags tags={tags} margin='top' />
      </div>
    </div>
  )
}





const makeMapStateToProps = () => {
  const getVenueById = makeGetVenueById()
  const getPerformanceById = makeGetPerformanceById()
  

  const mapStateToProps = (state, props) => {

    const id = props.performanceId

    const performance = getPerformanceById(state, id).attributes

    const role = get(state, ['user', 'attributes', 'role'])

    const permission = ['admin', 'editor'].includes(role) ? 'owner' : performance.permission


    const venueId = performance.venueId
    const venue = getVenueById(state, venueId)
    const venueImages = get(venue, ['attributes', 'images'], false)

    
    return {
      name: performance.name,
      location: performance.location,
      address: performance.address,
      start: performance.start,
      end: performance.end,
      website: performance.website,
      tags: performance.tags,
      views: performance.views,
      description: performance.description,
      permission: permission,
      venueId: performance.venueId,
      venueImages: venueImages || [],
      areVenueImagesLoaded: venueImages !== false
    }
  }

  return mapStateToProps
}



export default connect(makeMapStateToProps, {index})(PerformanceInfo)


