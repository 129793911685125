/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {UAParser} from 'ua-parser-js'

import Dialog from '@mui/material/Dialog'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import CameraIcon from '@mui/icons-material/Camera'

import FadeTransition from '../FadeTransition'
import SlideTransition from '../SlideTransition'




const cssStyles = ({color}) => ({
  menuItem: css`
    color: ${color ? color : 'rgba(0, 0, 0, 0.54)'};
    
    svg {
      width: 24px;
      margin-right: 10px;
    }

    span {
      color: #434343;
    }
  `
})



const parser = new UAParser().getDevice()

const isMobile = parser.type === 'mobile'




const VideoIconButton = props => {

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))


  const {
    type,
    open,
    size, 
    color,
    setOpen, 
    variant, 
    maxWidth,
    fullWidth,
    children, 
    ...rest
  } = props



  const styles = cssStyles({color})

  const iconSize = size || (isExtraSmallScreen ? 'small' : 'medium')




  function handleClick() {
    setOpen(true)

    const video = document.getElementById('profile-video')

    // Pause the profile video if its playing
    if (video) {
      if (!video.paused && !video.ended && video.currentTime > 0) {
        video.pause()
      }
    }
  }



  function handleClose() {
    setOpen(false)
  }




  return (
    <>
      {variant === 'menuItem' && 
        <MenuItem onClick={handleClick} css={styles.menuItem} {...rest}>
          <CameraIcon fontSize={iconSize || 'medium'} />
          <span>Video</span>
        </MenuItem>
      }

      {(variant === 'button' || !variant) &&
        <IconButton onClick={handleClick} size={iconSize} {...rest}>
          <svg style={{position: 'absolute'}} width={0} height={0}>
            <linearGradient id='linearColors' x1={0} y1={0} x2={1} y2={1}>
              <stop offset={0} stopColor='#ff0095' />
              <stop offset={1} stopColor='#fbde54' />
            </linearGradient>
          </svg>

          <CameraIcon 
            fontSize={iconSize} 
            style={{
              color: 'transparent',
              MozBackgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              fill: 'url(#linearColors)'
            }} 
          />
        </IconButton>
      }


      <Dialog 
        open={open}
        scroll={isMobile ? 'paper' : 'body'}
        sx={{
          '& .MuiDialog-container': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor: isMobile && '#000',

            '& .MuiPaper-root': {
              maxWidth: isMobile ? '100vw' : '500px',
              height: isMobile ? '100%' : 'auto',
              backgroundColor: 'black',
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden'
            }
          }
        }}
        onClose={handleClose}
        fullWidth={true}
        fullScreen={isExtraSmallScreen}
        TransitionComponent={isExtraSmallScreen ? SlideTransition : FadeTransition}
      >
        {children}
      </Dialog>
    </>
  )
}


export default VideoIconButton
