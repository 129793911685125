import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {getImageOfSize} from '../../utils'

import {pause, play, playNewTrack} from '../../actions' 

import PlayIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'


import TrackCardButtons from './TrackCardButtons'

import {Image} from '../generics'


import styles from '../../styles/cards/trackCard.module.scss'



const TrackCard = props => {

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const { 
    track,
    play, 
    pause,
    isPlaying, 
    playNewTrack,
    playerTrackId,
    spotifyAccessToken,
    // spotifyAccessTokenExpiration
  } = props



  const {name, composer, permission, image} = track.attributes


  const imageURL = getImageOfSize(track.attributes, 'small') || image




  function playTrack() {
    if (playerTrackId === track.id) { 
      play()
      return
    } 

    playNewTrack(track, track.id, 'track')
  }



  function handleClick(e) {
    e.stopPropagation()
    isPlaying ? pause() : playTrack()
  }






  return (
    <div className={styles.card}>
      <div className={styles.imageContainer} onClick={handleClick}>
        <Image src={imageURL} alt='track' />
        
        {!isExtraSmallScreen &&
          <div className={styles.overlay}>
            <div className={styles.iconContainer}>
              {isPlaying 
                ? <PauseIcon className={styles.icon} /> 
                : <PlayIcon className={styles.icon} /> 
              }
            </div>
          </div>
        }
      </div>

      <div className={styles.infoContainer} onClick={handleClick}>
        <h3>{name}</h3>
        
        <p>{composer}</p>
      </div>


      <div className={styles.buttons}>
        <TrackCardButtons
          track={track}
          name={name}
          imageURL={imageURL}
          permission={permission}
          spotifyAccessToken={spotifyAccessToken}
        />
      </div>
    </div>
  )
}






const mapStateToProps = (state, props) => {
  const id = props.track.id

  const player = state.player
  const playerTrack = player.queue[player.position]


  return {
    track: props.track,
    isPlaying: player.isPlayRequested && playerTrack?.id === id,
    playerTrackId: playerTrack?.id,
    spotifyAccessToken: state.user.spotify.accessToken,
    spotifyAccessTokenExpiration: state.user.spotify.expires
  }
}



const actions = {play, pause, playNewTrack}


export default connect(mapStateToProps, actions)(TrackCard)





