import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {sentenceCase} from '../../../../utils'
import Typography from '@mui/material/Typography'
import ProductColorOptions from '../../../product/variants/ProductColorOptions'
import ProductVariantSelect from '../../../product/variants/ProductVariantSelect'



const cssStyles = props => ({
  root: css`
    width: 100%;
    max-width: 400px;
    margin-bottom: 15px;
  `,
  variantContainer: css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 30px;
  `,
  sectionHeaderContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 5px;
  `,  
  variant: css`
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
  `,
  optionsContainer: css`
    display: flex;
    flex-direction: row;
    gap: 10px;
  `
})




const MerchantProductFormVariant = props => {
  
  const styles = cssStyles()
  
  const {variant, options} = props

  const lowercaseVariant = variant.toLowerCase()




  return (
    <div css={styles.root}>
      <div css={styles.variantContainer}>
        <div css={styles.sectionHeaderContainer}>
          <Typography css={styles.variant}>
            {variant}
          </Typography>
        </div>

        <div css={styles.optionsContainer}>
          {(() => {
            if (lowercaseVariant.startsWith('color')) {
              return <ProductColorOptions colors={options} />
            } else {
              return (
                <ProductVariantSelect
                  options={options} 
                  placeholder={'Select '+ sentenceCase(variant)} 
                />
              )
            }
          })()}
        </div>
      </div>
    </div>
  )
}



export default MerchantProductFormVariant


