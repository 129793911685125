import React, {useRef, useState, useEffect} from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {get} from 'lodash'

import {useNavigate} from 'react-router-dom'

import {useWindowSize} from '../../../hooks'

import {getTopAddress, getBottomAddress} from '../../../utils'

import withSwipableImages from '../../hocs/withSwipableImages'

import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import {Swiper, SwiperSlide} from 'swiper/react'

import {FreeMode} from 'swiper/modules'

import 'swiper/scss'
import 'swiper/css/free-mode'


import styles from '../../../styles/detail-pages/performance/addressWithVenueLink.module.scss'




const PerformanceAddressWithVenue = props => {

  const ref = useRef()

  const navigate = useNavigate()

  const windowSize = useWindowSize()

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))


  const {
    index,
    setRef,
    images, 
    location, 
    address, 
    venueId, 
    showImage,
    setIndex,
    nextImage,
    previousImage
  } = props



  const [width, setWidth] = useState('100vw')



  useEffect(() => {
    if (!ref.current) return

    const rect = ref.current.getBoundingClientRect()

    setWidth(window.innerWidth - rect.left)

  }, [ref, windowSize.width])




  function close() {
    setIndex(null)
  }



  function handleClick() {
    navigate('/venues/' + venueId)
  }



  function imageURL(index) {
    if (index === null) return ''

    const image = images[index]
    const originalImageURL = get(image, ['attributes', 'image'], '')
    const originalImageURLWebP = get(image, ['attributes', 'imageDerivatives', 'original'], false)

    return originalImageURLWebP ? originalImageURLWebP : originalImageURL
  }




  return (
    <div ref={ref} className={styles.root}>
      <div className={styles.addressContainer}> 
        <div onClick={handleClick}>
          <h3 className={styles.link}>
            {location}
          </h3>

          <p className={styles.text}>
            {getTopAddress(address)}
          </p>

          <p className={styles.text}>
            {getBottomAddress(address)}
          </p>
        </div>
      </div>



      {Boolean(images?.length) &&
        <div className={styles.imagesContainer} style={{width: width}}>
          <Swiper spaceBetween={15} slidesPerView='auto' freeMode={true} modules={[FreeMode]}>
            {images.map(image => {
                const imageURL = image.attributes.imageDerivatives.small || image.attributes.image

                return (
                  <SwiperSlide key={image.id} className={styles.slide}>
                    <img
                      alt='venue'
                      src={imageURL}
                      loading='lazy'
                      className={styles.image}
                      onClick={showImage(image)}
                    />
                  </SwiperSlide>
                )
            })}
          </Swiper>
        </div>
      }





      <Dialog 
        open={index !== null} 
        onClose={close}
        maxWidth='md'
        PaperProps={{ 
          style: {
            backgroundColor: 'transparent'
          }
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.95)'
          }
        }}>
        <div className={styles.modalContainer}>
          <IconButton
            size={isExtraSmallScreen ? 'medium' : 'large'}
            onClick={previousImage}
            className={styles.leftIconButton}
          >
            <ChevronLeftIcon fontSize='large' />
          </IconButton>


          <img
            alt=''
            ref={setRef}
            src={imageURL(index)}
            className={styles.modalImage}
          />


          <IconButton
            size={isExtraSmallScreen ? 'medium' : 'large'}
            onClick={nextImage}
            className={styles.rightIconButton}
          >
            <ChevronRightIcon fontSize='large' />
          </IconButton>


          <div className={styles.directions}>
            <p>Swipe or click</p>
          </div>
        </div>
      </Dialog>
    </div>
  )
}



export default withSwipableImages(PerformanceAddressWithVenue)





