import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect, useDispatch} from 'react-redux'

import {showAlert, removePerformerFromPerformance} from '../../../actions'

import {destroy} from '../../../apis'


import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import {CloseButton} from '../../generics'





const styles = {
  root: css`
    width: 100%;
    padding: 30px;
  `,
  title: css`
    font-size: 1.7rem;
    font-weight: 500;
    letter-spacing: -0.05rem;
  `,
  avatar: css`
    width: 48px;
    height: 48px;
    margin-right: 15px;
  `,
  item: css`
    min-width: 270px;
    padding: 12px;
    cursor: pointer;
    transition: all 0.3s ease

    &:hover {
      background-color: red;
    }
  `,
  button: css`
    margin-top: 0;
    margin-left: 24px;
    min-width: 75px;
    height: 36px;
    font-size: 0.75rem;
  `,
  ownerText: css`
    width: 80px;
    margin: 0 auto;
  `
}






const RemovePerformers = props => {

  const dispatch = useDispatch()


  const { 
    showAlert,
    performers,
    handleClose, 
    performanceId,
    removePerformerFromPerformance
  } = props




  const handleClick = performer => () => {
    const data = {performer_id: performer.id, performer_type: performer.type}


    destroy('performances/' + performanceId + '/performers/remove', data).then(response => {
      const performance = response.data.data

      delete performance.relationships


      removePerformerFromPerformance(performance, performer.id, performer.type)
      dispatch({type: 'PERFORMANCE_SHOW_SUCCESS', payload: response.data})

      showAlert(performer.attributes.name + ' was removed from performance', 'info')
    })
    .catch(error => showAlert('Something went wrong', 'error'))
  }




  return (
    <div css={styles.root}>
      <CloseButton onClick={handleClose} />

      <h3 css={styles.title}>
        Remove Performers
      </h3>


      <List>
        {Object.values(performers).map(performer => {
          const {name, image, imageDerivatives} = performer.attributes
          
          const imageURL = imageDerivatives.small || image

          
          return (
            <ListItem key={performer.id} css={styles.item}>

              <ListItemAvatar>
                <Avatar
                  css={styles.avatar}
                  alt={name} 
                  src={imageURL}
                  variant={performer.type === 'artist' ? 'circular' : 'rounded'} 
                />
              </ListItemAvatar>

              <ListItemText primary={name} primaryTypographyProps={{noWrap: true}} />
                
              <Button css={styles.button} onClick={handleClick(performer)}>
                REMOVE
              </Button>
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}


export default connect(null, {showAlert, removePerformerFromPerformance})(RemovePerformers)


