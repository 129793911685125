import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {updateTagFilter, initiateSearch} from '../../../actions'

import {ReactComponent as MagnifyingGlassIcon} from '../../../svgs/MagnifyingGlass.svg'

import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'




const styles = {
  root: css`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #000;
    cursor: pointer;
  `,
  searchContainer: css`
    display: flex;
    align-items: center;
    height: 36px;
    width: 100%;
    background-color: #fff;
    border-radius: 18px;
    padding: 10px;
  `,
  search: css`
    width: 100%;
    display: flex;
    gap: 5px;
    padding-left: 5px;
    align-items: center;
  `,
  placeholder: css`
    color: #7b7b7b;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
  `
}



const SearchBarMobile = props => {


  const {showSearchModal, query, updateTagFilter, initiateSearch} = props



  function show() {
    showSearchModal(true)
  }



  function clear() {
    if (query) {
      updateTagFilter('')
      initiateSearch()
    }
  }




  return (
    <div css={styles.root}>
      <div css={styles.searchContainer}>
        <div css={styles.search} onClick={show}>
          <MagnifyingGlassIcon height='16px' width='16px' />

          <p css={styles.placeholder}>
            {query === '' ? 'Search' : query}
          </p>
        </div>


        {Boolean(query) && 
          <IconButton aria-label='clear' size='small' onClick={clear}>
            <ClearIcon color='primary' />
          </IconButton>
        }
      </div>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    query: state.search.filters.tags
  }
}



export default connect(mapStateToProps, {updateTagFilter, initiateSearch})(SearchBarMobile)





