import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {useTheme} from '@mui/styles'

import {useNavigate} from 'react-router-dom'

import useMediaQuery from '@mui/material/useMediaQuery'

import {get} from 'lodash'

import {create, destroy, showAlert} from '../../../actions'


import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'

import {closeSnackbar} from 'notistack'



const cssStyles = ({color}) => ({
  menuItem: css`
    color: ${color ? color : 'rgba(0, 0, 0, 0.54)'};
    
    svg {
      width: 24px;
      margin-right: 10px;
    }

    span {
      color: #434343;
    }
  `
})



const LikeIconButton = props => {

  const theme = useTheme()

  const navigate = useNavigate()

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const {
    id, 
    type,
    size,
    color,
    likeId, 
    isLiked,
    variant,
    create, 
    destroy, 
    showAlert,
    isAuthenticated,
    ...rest
  } = props


  const styles = cssStyles({color})

  const iconSize = size || (isExtraSmallScreen ? 'small' : 'medium')



  function navigateToSignupPage() {
    navigate('/sign-up', {
      state: {
        redirect: `/${type}s/${id}`
      }
    })
  }



  function handleClick() {
    if (!isAuthenticated) {
      const action = snackbarID => (
        <>
          <Button 
            variant='text' 
            sx={{
              fontWeight: 600,
              color: 'rgb(62, 166, 255)'
            }}
            onClick={navigateToSignupPage}
          >
            Sign Up
          </Button>

          <IconButton 
            aria-label='close'
            sx={{
              fontWeight: 600,
              color: '#fff'
            }}
            onClick={() => closeSnackbar(snackbarID)}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </>
      )


      showAlert(`Sign up to like this ${type}`, 'standard', action)
      return
    }


    if (isLiked) {
      destroy('/likes/' + likeId, 'LIKE')
    } else {
      create(`/${type}s/${id}/likes`, 'LIKE', {sentiment: true})
    }
  }




  return (
    <>
      {variant === 'menuItem' && 
        <MenuItem onClick={handleClick} css={styles.menuItem} {...rest}>
          <ThumbUpIcon
            fontSize={iconSize}
            style={{  
              color: isLiked ? theme.palette.pink.main : (color || '#fff')
            }}
          />
          <span>Like</span>
        </MenuItem>
      }

      
      {(variant === 'button' || !variant) &&
        <IconButton onClick={handleClick} size={iconSize} {...rest}>
          <ThumbUpIcon
            fontSize={iconSize}
            style={{  
              color: isLiked ? theme.palette.pink.main : (color || '#fff')
            }}
          />
        </IconButton>
      }
    </>
  )
}



const mapStateToProps = (state, props) => {
  
  const {id, type} = props

  const pluralType = type + 's'

  const likeId = get(state, ['user', 'likes', pluralType, id, 'id'])


  return {
    likeId: likeId,
    isLiked: Boolean(likeId),
    isAuthenticated: state.user.isAuthenticated
  }
}



const actions = {create, destroy, showAlert}



export default connect(mapStateToProps, actions)(LikeIconButton)



