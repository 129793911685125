import { useState, useEffect } from 'react'


export function useClickedOutsideComponent(ref) {
  const [isOutside, setIsOutside] = useState(0)


  useEffect(() => {
    function handleClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsOutside(prev => prev + 1)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setIsOutside])


  return isOutside
}


export default useClickedOutsideComponent