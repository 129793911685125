export const isAuthenticated = (state = null, action) => {
  switch (action.type) {
    case 'CONFIRMING_EMAIL':
    case 'SIGNING_UP':
    case 'SIGNING_IN':
    case 'AUTH_STATUS_REQUESTED':
      return null
    case 'EMAIL_CONFIRMATION_FAILURE':
    case 'SIGN_UP_FAILURE':
    case 'EMAIL_SIGN_UP_SUCCESS':
    case 'AUTH_STATUS_FAILURE':
    case 'SIGN_IN_FAILURE':
    case 'SIGNING_OUT':
    case 'SIGN_OUT_SUCCESS':
    case 'SIGN_OUT_FAILURE':
      return false
    case 'EMAIL_CONFIRMED':
    case 'SIGN_IN_WITH_GOOGLE_SUCCESS':
    case 'SIGN_IN_WITH_APPLE_SUCCESS':
    case 'SIGN_IN_WITH_EMAIL_SUCCESS':
    case 'AUTH_STATUS_SUCCESS':
    case 'OAUTH_SIGN_UP_SUCCESS':
      return true
    default:
      return state
  }
}

export const authService = (state = 'default', action) => {
  switch (action.type) {
    case 'SIGN_IN_WITH_EMAIL_SUCCESS':
      return 'default'
    case 'SIGN_IN_WITH_GOOGLE_SUCCESS':
      return 'google'
    case 'SIGN_IN_WITH_APPLE_SUCCESS':
      return 'apple'
    default:
      return state
  }
}
