import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {destroy, showAlert} from '../../actions'

import Menu from '@mui/material/Menu'

import BandForm from '../band/BandForm'


import {
  EditIconButton,
  DeleteIconButton,
  FavoriteIconButton,
  StatsIconButton,
  MoreIconButton
} from '../generics'




const styles = {
  buttonsContainer: css`
    display: flex;
    gap: 5px;
  `,
  menu: css`
    li {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `
}



const BandCardButtons = props => {

  const [open, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  

  const {bandId, permission, imageURL, destroy, showAlert} = props




  function deleteBand() {
    destroy('/bands/' + bandId, 'BAND').then(response => {
      showAlert('Band deleted', 'info')
    })
    .catch(error => {
      showAlert('Couldn\'t delete band', 'error')
    })
  }




  
  function handleMoreClick(e) {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }




  function close(e) {
    e.stopPropagation()
    setAnchorEl(null)
  }



  function closeForm() {
    setOpen(false)
  }


  return (
    <>
      <div css={styles.buttonsContainer}>
        <FavoriteIconButton 
          key='favorite'
          id={bandId} 
          type='band' 
          color='rgba(0, 0, 0, 0.54)' 
        />


        {['owner', 'editor', 'member'].includes(permission) &&
          <MoreIconButton
            key='more'
            onClick={handleMoreClick}
            color='rgba(0, 0, 0, 0.54)'
          />
        }
      </div>



      <Menu css={styles.menu} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={close}>
        {['owner', 'editor', 'member'].includes(permission) && 
          <StatsIconButton id={bandId} type='band' variant='menuItem'/>
        }


        {['owner', 'editor'].includes(permission) && [
          <EditIconButton key='edit' open={open} setOpen={setOpen} variant='menuItem'>
            <BandForm bandId={bandId} close={closeForm} />
          </EditIconButton>,

         <DeleteIconButton
            key='delete'
            imageURL={imageURL}
            variant='menuItem'
            title='Delete Band'
            text='Are you sure you want to delete this band?'
            primaryAction={deleteBand}
          />
        ]}
      </Menu>
    </>
  )
}



const actions = {destroy, showAlert}


export default connect(null, actions)(BandCardButtons)





