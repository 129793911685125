const INITIAL_STATE = {
  action: false,
  message: '',
  variant: '',
  isShowing: false
}



const alertReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SHOW_ALERT':
      return {...state,
        action: action.payload.action,
        message: action.payload.message,
        variant: action.payload.variant,
        isShowing: true
      }
    case 'DELETE_ALERT':
      return {...state,
        isShowing: false
      }
    default: 
      return state
  }
}



export default alertReducer