import instance from './instance'

export function searchPerformers(query) {
  return instance.get('/performers/search', {
    params: {
      name: query
    }
  })
}

