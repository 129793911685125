import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button'


const FormButton = withStyles(theme => ({
  root: {
    width: '100%',
    minWidth: '130px',
    height: '50px',
    boxShadow: 'none',
  }
}))(Button)


export default FormButton