import React, { useState } from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { connect } from 'react-redux'
import { useIsMounted } from '../../hooks'
import useMediaQuery from '@mui/material/useMediaQuery'
import { destroy, showAlert } from '../../actions'
import { formatMoney } from '../../utils'
import Dialog from '@mui/material/Dialog'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'

import MerchantProductForm from '../ecommerce/merchant/products/MerchantProductForm'

import { MoreIconButton, SlideTransition, Confirmation, CloseButton } from '../generics'



const styles = {
  buttonsContainer: css`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    & > svg {
      margin: 5px;
    }
  `,
  price: css`
    font-weight: 700;
    font-size: 20px;
  `,
  content: css`
    max-width: 100vw;
    padding: 10px;

    & > h1 {
      font-size: 4rem;
      font-weight: 500;
      letter-spacing: -0.3rem;
      margin-top: 30px;
      margin-bottom: 0;
    }

    
    @media (max-width: 720px) {
      & > h1 {
        font-size: 2.3rem;
        letter-spacing: -0.2rem;
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
  `
}






const ProductCardButtons = props => {

  const isMounted = useIsMounted()

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('lg'))

  const [showForm, setShowForm] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)


  const {
    productId,
    name,
    imageURL,
    isOwner,
    price,
    showAlert,
    edit,
    cancel,
    destroy
  } = props
 


  function _cancel() {
    cancel ? cancel() : setShowForm(false)
  }



  function _edit() {
    edit ? edit(productId) : setShowForm(true)
  }



  function _showConfirmation() {
    setShowConfirmation(true)
  }



  function closeConfirmation() {
    setShowConfirmation(false)
  }



  function handleMoreClick(e) {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }



  function closeMenu(e) {
    e.stopPropagation()
    setAnchorEl(null)
  }



  function deleteProduct() {
    destroy('/products/' + productId, 'PRODUCT').then(response => {
      if (isMounted.current) {
        setShowConfirmation(false)
      }
    })
    .catch(error => showAlert('Couldn\'t delete product', 'error'))
  }



  return (
    <>
      <div css={styles.buttonsContainer}>
        <Typography css={styles.price}>
          {formatMoney(price)}
        </Typography>

        {isOwner && <MoreIconButton onClick={handleMoreClick} />}
      </div>


      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        <MenuItem onClick={_edit}>
          <ListItemIcon>
            <EditIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>

        <MenuItem onClick={_showConfirmation}>
          <ListItemIcon>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>


      <Dialog
        fullWidth
        fullScreen={true}
        open={showForm}
        onClose={cancel}
        disableScrollLock={isSmallScreen ? false : true}
        PaperProps={{ 
          sx: {
            maxWidth: '100%',
            alignItems: 'center',
            backgroundColor: '#e9e9e9'
          }
        }}
        TransitionComponent={SlideTransition}
      >
        <div>
          <CloseButton onClick={_cancel} />

          <div css={styles.content}>
            <h1>Product Form</h1>
        
            <MerchantProductForm 
              productId={productId} 
              cancel={_cancel}
              showTips={false}
            />
          </div>
        </div>
      </Dialog>


      <Confirmation 
        open={showConfirmation}
        close={() => setShowConfirmation(false)}
        title='Delete Product'
        text={'Are you sure you want to delete ' + name + '?'}
        primaryText='Delete'
        primaryAction={deleteProduct}
        primaryColor='red'
        secondaryAction={closeConfirmation}
        image={imageURL} 
        imageVariant='rounded' 
      />
    </>
  )
}


const mapStateToProps = (state, props) => {
  const id = state.user?.account.id
  const type = state.user?.account?.type.toLowerCase()

  const merchantId = props.merchantId
  const merchantType = props.merchantType.toLowerCase()

  const isOwner = (id === merchantId && type === merchantType)

  return {
    isOwner: isOwner,
    isAuthenticated: state.user.isAuthenticated
  }
}

const actions = {destroy, showAlert}


export default connect(mapStateToProps, actions)(ProductCardButtons)





