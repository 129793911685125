import {AxiosError} from 'axios'


// this returns a string from an array of error messages
AxiosError.prototype.apiMessage = function() {
  if (this.response?.data?.error) {
    const error = this.response.data.error
    const messages = error?.messages


    if (Array.isArray(messages)) {
      return messages[0]
    } else if (typeof messages === 'object' && messages !== null) {
      return Object.values(messages)[0]
    } else if (typeof messages === 'string') {
      return messages
    } else {
      return this.message
    }
  }

  return this.message
}


// This returns an object with the keys being the field
// that errored and the value being the message
AxiosError.prototype.apiFormErrors = function(formValues) {
  let result = {}

  if (this.response?.data?.error) {
    const errors = this.response.data.error.messages


    if (Array.isArray(errors)) {
      result.unmatched = errors
      return result
    }


    if (typeof errors === 'object') {
      result = Object.keys(errors).reduce((acc, key) => {
        // If there is a match in keys between the form and the 
        // api errors object, then add it to the reduce object and return
        if (key in formValues) {
          acc[key] = errors[key][0]
          return acc
        }

        // If the backend returns an error for coordinates, then apply 
        // that error to the location field so its displated on the form field
        if (key === 'coordinates') {
          acc['location'] = errors[key][0]
          return acc
        }

        // Otherwise add the error to the unamtched array
        acc['unmatched'].push(`${key} ${errors[key]}`)

        return acc
      }, {
        unmatched: []
      })
    }
  }

  return result
}