import { createSelector } from 'reselect'


import { get, pick, map } from 'lodash'


const emptyArray = []
const emptyObject = {}


const selectBandIds = state => state.bands.byId
const selectTrackIds = state => state.tracks.byId
const selectProductIds = state => state.products.byId
const selectPerformances = state => state.performances.byId


const selectArtistId = (state, id) => {
  return get(state.artists.byId[id], 'id', false)
}

const selectArtistAttributesById = (state, id) => {
  return get(state.artists.byId[id], 'attributes')
}

const selectArtistTracks = (state, id) => {
  return get(state.artists.byId[id], ['relationships', 'tracks'], emptyArray)
}

const selectArtistBands = (state, id) => {
  return get(state.artists.byId[id], ['relationships', 'bands'], emptyArray)
}

const selectArtistProducts = (state, id) => {
  return get(state.artists.byId[id], ['relationships', 'products'], emptyArray)
}

const selectArtistPerformances = (state, id) => {
  return get(state.artists.byId[id], ['relationships', 'performances'], emptyArray)
}

const selectNearbyArtistPerformances = (state, id) => {
  return get(state.artists.byId[id], ['relationships', 'nearbyPerformances'], emptyArray)
}

const selectArtistFetchedAssociations = (state, id) => {
  return get(state.artists.byId[id], 'fetched', emptyArray)
}

const selectArtistLinks = (state, id, association) => {
  return get(state.artists.byId[id], ['links', association], emptyObject)
}

export const selectArtistIds = state => state.artists.byId


export const makeGetArtistById = () => {
  return createSelector(
    [selectArtistId, selectArtistAttributesById], 
    (id, attributes) => ({ id, attributes, type: 'artist' })
  )
}

export const makeGetArtistFetchedAssociations = () => {
  return createSelector(
    selectArtistFetchedAssociations, 
    fetchedAssociations => fetchedAssociations
  )
}


export const makeGetArtistTracks = () => {
  return createSelector([selectArtistTracks, selectTrackIds],
    (artistTracks, tracks) => {
      const trackIds = map(artistTracks, 'id')

      return Object.values(pick(tracks, trackIds))
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetArtistBands = () => {
  return createSelector([selectArtistBands, selectBandIds],
    (artistBands, bands) => {
      const bandIds = map(artistBands, 'id')

      return Object.values(pick(bands, bandIds))
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetArtistProducts = () => {
  return createSelector([selectArtistProducts, selectProductIds],
    (artistProducts, products) => {
      const productIds = map(artistProducts, 'id')

      return Object.values(pick(products, productIds))
    }, 
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetArtistPerformances = () => {
  return createSelector([selectArtistPerformances, selectPerformances],
    (artistPerformances, performances) => {
      const performanceIds = map(artistPerformances, 'id')

      return Object.values(pick(performances, performanceIds))
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetNearbyArtistPerformances = () => {
  return createSelector([selectNearbyArtistPerformances, selectPerformances],
    (nearbyPerformances, performances) => {
      const performanceIds = map(nearbyPerformances, 'id')

      return Object.values(pick(performances, performanceIds))
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetArtistLinks = () => {
  return createSelector([selectArtistLinks], (artistLinks) => artistLinks)
}



const artistSelectors = {
  selectArtistIds,
  makeGetArtistById,
  makeGetArtistLinks,
  makeGetArtistBands,
  makeGetArtistTracks,
  makeGetArtistProducts,
  makeGetArtistPerformances,
  makeGetNearbyArtistPerformances,
  makeGetArtistFetchedAssociations
}


export default artistSelectors

