import {get} from 'lodash'


const INITIAL_STATE = {
  user: {},
  authorization: {
    scope: '',
    tokenType: '',
    accessToken: '',
    refreshToken: '',
    expiresIn: Date.now()
  }
}



const spotifyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {...state, ...get(action.payload, ['attributes', 'spotify'], {})}
    case 'SET_SPOTIFY_AUTHORIZATION_CREDENTIALS':
      return {
        ...state, 
        authorization: {
          ...action.payload
        }
      }
    default:
      return state
  }
}



export default spotifyReducer