import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import ImageIcon from '@mui/icons-material/ImageOutlined'



const placeholderStyles = {
  root: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    padding: 30%;
  `,
  icon: css`
    height: 100%;
    width: 100%;
    color: #979797;
    max-width: 80px;
  `
}



const cssStyles = ({paddingTop, borderRadius}) => ({
  aspectRatioBox: css`
    width: 100%;
    border-radius: ${borderRadius ? borderRadius : '7px'};
    height: 0;
    padding-top: ${paddingTop};
    position: relative;
    overflow: hidden;
  `,
  aspectRatioBoxInside: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
  image: css`
    width: 100%;
    height: 100%;
    object-fit: cover;  
  `
})




const DefaultPlaceholder = () => (
  <div css={placeholderStyles.root}>
    <ImageIcon css={placeholderStyles.icon}/>
  </div> 
)




const AspectImage = React.forwardRef(({loading = 'eager', borderRadius, ...props}, ref) => {


  const {src, alt, aspect, placeholder} = props


  const aspectRatio = aspect || '1:1'

  const [denominator, numerator] = aspectRatio.split(':')

  const paddingTop = (parseInt(numerator) / parseInt(denominator) * 100).toString() + '%'

  const styles = cssStyles({paddingTop, borderRadius})




  return (
    <div css={styles.aspectRatioBox}>
      <div css={styles.aspectRatioBoxInside}>
        {src 
          ? <img src={src} alt={alt} loading={loading} ref={ref} css={styles.image} {...props} /> 
          : Boolean(placeholder) ? placeholder : <DefaultPlaceholder />
        }
      </div>
    </div>
  )
})



export default AspectImage




