import React, { useRef, useState, useCallback } from 'react'



const withSwipableImages = Component => props => {
  const imageRef = useRef()
  const [index, setIndex] = useState(null)
  const { images } = props


  function nextImage() {
    setIndex(prev => {
      if (prev === null || (prev === images.length - 1)) {
        return 0
      } else {
        return prev + 1
      }
    })
  }


  function previousImage() {
    setIndex(prev => {
      if (prev === null || (prev === 0)) {
        return images.length - 1
      } else {
        return prev - 1
      }
    })
  }

  const memoizedNextImage = useCallback(nextImage, [images.length])
  const memoizedPreviousImage = useCallback(previousImage, [images.length])

  const setRef = useCallback(node => {
    let touchStartX = 0
    let touchStartY = 0
    let touchEndX = 0
    let touchEndY = 0
    
    if (node) {
      node.addEventListener('touchstart', handleTouchStart)
      node.addEventListener('touchend', handleTouchEnd)
      imageRef.current = node
    }

    function handleTouchStart(e) {
      touchStartX = e.changedTouches[0].screenX
      touchStartY = e.changedTouches[0].screenY
    } 

    function handleTouchEnd(e) {
      touchEndX = e.changedTouches[0].screenX
      touchEndY = e.changedTouches[0].screenY
      handleGesture()
    }

    function didPassThreshold() {
      const horizontalThreshold = 150
      const verticalThreshold = 100

      return (
        Math.abs(touchStartX - touchEndX) > horizontalThreshold &&
        Math.abs(touchStartY - touchEndY) < verticalThreshold
      )
    }

    function handleGesture() {
      if (didPassThreshold()) {
        if (touchEndX < touchStartX) {
          memoizedNextImage()
        }
        else if (touchEndX > touchStartX) {
          memoizedPreviousImage()
        }
      }
    }

    if (node == null) {
      imageRef.current.removeEventListener('touchstart', handleTouchStart)
      imageRef.current.removeEventListener('touchend', handleTouchEnd)
    }
  }, [memoizedNextImage, memoizedPreviousImage])


  const showImage = item => () => {
    const indexOfClickedImage = images.findIndex(i => {
      return i.attributes.image === item.attributes.image
    })

    setIndex(indexOfClickedImage)
  }

  return (
    <Component
      index={ index }
      setIndex={ setIndex }
      showImage={ showImage }
      setRef={ setRef }
      nextImage={ nextImage }
      previousImage={ previousImage }
      { ...props } 
    />
  )
}

export default withSwipableImages



