import React from 'react'

import {searchPerformers} from '../../apis'

import List from '@mui/material/List'

import withSearchAutocomplete from '../hocs/withSearchAutocomplete'

import PerformanceFormPerformerInvitation from './PerformanceFormPerformerInvitation'





const PerformanceFormPerformersAutocomplete = props => {

  const {suggestions, setIsOpen, textfieldRef} = props

  const {performers, setFieldValue} = props.passedProps

  const performerIds = performers.map(performer => performer.id)



  return (
    <List 
      sx={{
        zIndex: 100,

        '&.MuiList-root': {
          backgroundColor: '#fff',
        }
      }}
    >
      {suggestions.map((suggestion, index) => {
        const isInvited = performerIds.includes(suggestion.id)      
          
        return (
          <PerformanceFormPerformerInvitation
            key={suggestion.id}
            setIsOpen={setIsOpen}
            isInvited={isInvited}
            performers={performers}
            suggestion={suggestion}
            textfieldRef={textfieldRef}
            setFieldValue={setFieldValue}
          />
        )
      })}
    </List>
  )
}

export default withSearchAutocomplete(PerformanceFormPerformersAutocomplete, searchPerformers)
