import {map} from 'lodash'



const INITIAL_STATE = {
  links: {},
  results: []
}



const serarchPerformanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'PERFORMANCE_SEARCH_SUCCESS':
      return {
        links: action.payload.links || {},
        results: map(action.payload.data, 'id')
      }
    case 'PERFORMANCE_PAGINATION_SEARCH_SUCCESS':
      return {
        links: action.payload.links || {},
        results: [...state.results, ...map(action.payload.data, 'id')]
      }
    case 'PERFORMANCE_SEARCH_MERGE_SUCCESS':
      return {
        ...state,
        links: action.payload.links || {},
        results: [...new Set([...state.results, ...map(action.payload.data, 'id')])]
      }
    default:
      return state
  }
}


export default serarchPerformanceReducer

