export function sentenceCase(string) {
  return string[0].toUpperCase() + string.slice(1)
}

export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1)
}

export function fullURL(string) {
  const full = /^https?:\/\//i
  if (full.test(string)) return string
  else return 'https://' + string 
}