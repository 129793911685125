import {createRoot} from 'react-dom/client'

import {StrictMode} from 'react'

import {Provider} from 'react-redux'
import {store} from './helpers/store'

import {BrowserRouter as Router} from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'

import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'

import {HelmetProvider} from 'react-helmet-async'


import App from './components/App'

// import GlobalErrorCatcher from './components/GlobalErrorCatcher'

import theme from './styles/theme'

import {PlayButtonProvider} from './components/contexts/PlayButtonContext'

import './extensions/axiosErrorExtensions.js'

// import * as serviceWorker from './serviceWorker';


const container = document.getElementById('app')

const root = createRoot(container)


root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PlayButtonProvider>
          <HelmetProvider>
            <Router>
              <App />
            </Router>
          </HelmetProvider>
        </PlayButtonProvider>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
)



// Strict version for better testing
// root.render(
//   <StyledEngineProvider injectFirst>
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Provider store={store}>
//         <HelmetProvider>
//           <Router>
//             <StrictMode>
//               <App />
//             </StrictMode>
//           </Router>
//         </HelmetProvider>
//       </Provider>
//     </ThemeProvider>
//   </StyledEngineProvider>
// )




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
