import React, {useState, useMemo} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {startOfToday} from 'date-fns'

import {debounce} from 'lodash'

import {index} from '../../../apis'

import {useIsMounted} from '../../../hooks'

import {dateToUTC} from '../../../utils'

import {updateTagFilter, initiateSearch} from '../../../actions'

import {Link, useNavigate} from 'react-router-dom'

import Paper from '@mui/material/Paper'
import Avatar from '@mui/material/Avatar'
import Popper from '@mui/material/Popper'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'


import ClearIcon from '@mui/icons-material/Clear'


import {PerformanceSearchResult} from '../../performance'

import {ReactComponent as MagnifyingGlassIcon} from '../../../svgs/MagnifyingGlass.svg'



const styles = {
  dropdown: css`
    box-shadow: none;
    padding-bottom: 250px;
    min-height: calc(100vh - 110px);
    box-shadow: none;
    background-color: transparent;
  `,
  groupingTitle: css`
    padding: 7px 10px;
    text-transform: uppercase;
    color: #262626;
    font-weight: 600;
    background-color: #e9e9e9;
    margin: 10px 0;
    font-size: 0.8rem;
  `,
  prediction: css`
    padding: 8px 10px;
    font-size: 1rem;
  `,
  link: css`
    width: 100%;
    display: flex;
    wrap: nowrap;
    gap: 10px;
    align-items: center;
    text-decoration: none;
    color: #000;
    font-size: 0.93rem;
    letter-spacing: -0.01em;
  `
}




const GeneralSearchMobile = props => { 
  
  const isMounted = useIsMounted()

  const navigate = useNavigate()

  const [value, setValue] = useState(null)

  const [inputValue, setInputValue] = useState('')



  const [tagPredictions, setTagPredictions] = useState([])
  const [venuePredictions, setVenuePredictions] = useState([])
  const [performerPredictions, setPerformerPredictions] = useState([])
  const [performancePredictions, setPerformancePredictions] = useState([])
  
  const predictions = [
    ...tagPredictions, 
    ...performerPredictions, 
    ...performancePredictions,
    ...venuePredictions
  ]


  const {
    tags, 
    close,
    open,
    setOpen,
    searchRef, 
    containerRef, 
    updateTagFilter, 
    initiateSearch
  } = props




  const fetchTagPredictions = useMemo(() => {
    return (newValue) => {
      index('/tags/search', {query: newValue, limit: 4}).then(response => {
        if (isMounted.current) {
          setTagPredictions(response.data.data)
        }
      })
    }
  }, [isMounted])


  const fetchPerformancePredictions = useMemo(() => {
    return newValue => {
      const name = newValue
      const time = dateToUTC(startOfToday())

      index('search/performances/name', {name, time, limit: 3}).then(response => {
        if (isMounted.current) {
          setPerformancePredictions(response.data.data)
        }
      })
    }
  }, [isMounted])


  const fetchPerformerPredictions = useMemo(() => {
    return newValue => {
      index('search/performers', {name: newValue, limit: 3}).then(response => {
        if (isMounted.current) {
          setPerformerPredictions(response.data.data)
        }
      })
    }
  }, [isMounted])


  const fetchVenuePredictions = useMemo(() => {
    return newValue => {
      index('search/venues/name', {name: newValue, limit: 3}).then(response => {
        if (isMounted.current) {
          setVenuePredictions(response.data.data)
        }
      })
    }
  }, [isMounted])



  const debounceFetchTagPredictions = useMemo(
    () => debounce(fetchTagPredictions, 200), [fetchTagPredictions]
  )

  const debounceFetchPerformancePredictions = useMemo(
    () => debounce(fetchPerformancePredictions, 200), [fetchPerformancePredictions]
  )

  const debounceFetchPerformerPredictions = useMemo(
    () => debounce(fetchPerformerPredictions, 200), [fetchPerformerPredictions]
  )

  const debounceFetchVenuePredictions = useMemo(
    () => debounce(fetchVenuePredictions, 200), [fetchVenuePredictions]
  )




  function handleChange(e, newValue) {
    if (newValue) {
      if (typeof newValue === 'string' || newValue?.type === 'tag') {
        const tag = newValue?.attributes?.tag || newValue

        updateTagFilter(tag)
        initiateSearch()
        navigate('/home', {state: {searchModal: true}})
        close()
      }
    }

    setValue(newValue || '')
  }



  function handleInputChange(e, newInputValue) {
    setInputValue(newInputValue)

    debounceFetchTagPredictions(newInputValue)
    debounceFetchPerformerPredictions(newInputValue)
    debounceFetchPerformancePredictions(newInputValue)
    debounceFetchVenuePredictions(newInputValue)
  }



  function handleClear() {
    if (tags) {
      updateTagFilter('')
    }

    setValue('')
    setInputValue('')
    initiateSearch()
  }



  function handleGrouping(prediction) {
    return (prediction.type === 'tag') ? 'TAG / GENRE' : prediction.type
  }



  function getPredictionText(prediction) {
    if (typeof prediction === 'string') {
      return prediction
    } 
    
    return prediction.type === 'tag' ? prediction.attributes.tag : prediction.attributes.name
  }



  function handleFocus() {
    setOpen('search')
  }




  return (
    <Autocomplete
      freeSolo
      sx={{
        width: '100%',
        height: '100%',

        '& .MuiAutocomplete-endAdornment': {
          marginRight: '10px'
        }
      }}
      onFocus={handleFocus}
      getOptionLabel={getPredictionText}
      options={predictions}
      includeInputInList
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      groupBy={handleGrouping}
      filterOptions={x => x}
      open={open === 'search'}
      value={value}
      disableClearable
      PaperComponent={params => (
        <Paper {...params} css={styles.dropdown} />
      )}
      PopperComponent={params => (
        <Popper 
          {...params}
          anchorEl={containerRef.current}
          container={containerRef.current}
        />
      )}
      ListboxProps={{sx: {maxHeight: '100%'}}} 
      renderInput={params => (
        <TextField 
          {...params}
          inputRef={searchRef}
          variant='standard'
          placeholder={tags || 'Search - genre, artist, band...'}
          InputProps={{
            ...params.InputProps,
            
            disableUnderline: true,

            onTouchStart: e => e.stopPropagation(),

            sx: {
              backgroundColor: '#fff',
              height: '40px',
              borderRadius: '20px',

              '&.MuiInput-root': {
                height: '40px',
                padding: '3px 9px 3px 3px'
              }
            },
            startAdornment: (
              <InputAdornment position='start' sx={{width: '36px'}}>
                <IconButton>
                  <MagnifyingGlassIcon 
                    width={20}
                    height={18}
                    fill={open === 'search' ? 'rgb(62, 166, 255)' : '#000'} 
                  />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment 
                position='end' 
                sx={{visibility: (tags || inputValue) ? 'visible' : 'hidden'}}
              >
                <IconButton onClick={handleClear} size='small'>
                  <ClearIcon 
                    fontSize='small' 
                    sx={{color: open === 'search' ? '#000' : '#7b7b7b'}} 
                  />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
      renderGroup={props => (
        <div key={props.key}>
          <h4 css={styles.groupingTitle}>{props.group + 'S'}</h4>

          {props.children}
        </div>
      )}
      renderOption={(props, prediction, {selected}) => (
        <div {...props} className='' key={prediction.id} css={styles.prediction}>
          {(() => {
            switch (prediction.type) {
            case 'tag':
              return <p>{prediction?.attributes?.tag}</p>
            case 'performance':
              return <PerformanceSearchResult close={close} performance={prediction} />
            default:
              return (
                <Link onClick={close} to={`/${prediction.type}s/${prediction.id}`} css={styles.link}>
                  <Avatar 
                    sx={{width: 40, height: 40}}
                    alt={prediction.attributes.name} 
                    src={prediction.attributes.imageDerivatives.small}
                    variant={prediction.type === 'artist' ? 'circular' : 'rounded' } 
                  />

                  <p>{prediction.attributes.name}</p>
                </Link>
              )
            }
          })()}
        </div>
      )}
    />
  )
}




const mapStateToProps = state => {
  return {
    tags: state.search.filters.tags
  }
}


export default connect(mapStateToProps, {updateTagFilter, initiateSearch})(GeneralSearchMobile)



