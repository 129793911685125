import React from 'react'

import {Helmet} from 'react-helmet-async'



const SEO = props => {
  const {
    url, 
    type,
    name,
    image,
    title,
    imageWidth,
    imageHeight,
    description,
    jsonLdData,
  } = props



  return (
    <Helmet>
      <title>{title}</title>

      <meta name='description' content={description} />

      <meta property='og:url' content={url} />
      <meta property='og:type' content={type} />
      <meta property='og:title' content={title} />
      <meta property='og:image' content={image} />
      <meta property='og:description' content={description} />

      <meta name='twitter:creator' content={name} />
      <meta name='twitter:card' content={type} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />

      <script type='application/ld+json'>{JSON.stringify(jsonLdData)}</script>
    </Helmet>
  )
}


export default SEO