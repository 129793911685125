export const backgroundColors = {
  pending: '#ffdb9b',
  received: '#d7f0ff',
  shipped: '#d7f0ff',
  completed: '#c3f3d7',
  cancelled: '#ededed',
  refunded: '#4d4d4d',
  disputed: '#ffe0e3'
}


export const textColors = {
  pending: '#ce8500',
  received: '#3eb6ff',
  shipped: '#3eb6ff',
  completed: '#23ad5c',
  cancelled: '#a1a1a1',
  refunded: '#ccc',
  disputed: '#ff4757'
}