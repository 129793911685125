export function formatAddress(address, exclude = []) {
  const fields = [
    'number', 
    'street', 
    'neighborhood', 
    'city', 
    'state', 
    'country', 
    'postal'
  ].filter(e => exclude.indexOf(e) < 0)


  function isLastComponent(component) {
    if (fields.length === 0) return true
    
    return fields[fields.length - 1] === component
  }


  let formattedAddress = ''
  if (typeof address === 'object' && address !== null) {
    fields.forEach(key => {
      if ((key === 'number' || key === 'state') && address[key]) {
        if (isLastComponent(key)) {
          formattedAddress += address[key]
        } else formattedAddress += address[key] + ' '
      }
      else if (address[key]) {
        if (isLastComponent(key)) {
          formattedAddress += address[key]
        } else formattedAddress += address[key] + ', '
      }
    })
  }

  return formattedAddress
}


export function formatInfoPageAddress(address = {}) {
  const { city, state, country } = address
  const filteredAddress = [city, state, country].filter(e => e !== '')
  const addressArray = [...new Set(filteredAddress)]
  const lastItem = addressArray[addressArray.length - 1]
    
  if ((lastItem === 'United States' || lastItem === 'USA') && addressArray.length > 1) {
    addressArray.pop()
  }
    
  return addressArray.join(', ')
}


export function getTopAddress(address) {
  return formatAddress(address, ['neighborhood', 'city', 'state', 'country', 'postal'])
}

export function getBottomAddress(address) {
  return formatAddress(address, ['number', 'street', 'neighborhood', 'country'])
}

