export const stringColorsToHex = {
  tan: '#E3C497',
  red: '#E1000F',
  blue: '#6495ED',
  cyan: '#36BFA8',
  gray: '#808080',
  grey: '#808080',
  pink: '#FABFCE',
  lime: '#E5E896',
  teal: '#1D8489',
  navy: '#192343',
  white: '#FFFFFF',
  black: '#000000',
  olive: '#BFAE2B',
  peach: '#F8BFA8',
  brown: '#964B00',
  beige: '#C3B499',
  green: '#1CA350',
  indigo: '#51428F',
  violet: '#6770AE',
  maroon: '#800000',
  silver: '#C0C0C0',
  purple: '#5E0A80',
  orange: '#FF7034',
  yellow: '#FFDD00',
  magenta: '#C519C1',
  turquoise: '#56C2BB'
}


export default stringColorsToHex