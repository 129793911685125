import React from 'react'
/** @jsxImportSource @emotion/react */
import { getImageOfSize } from '../../utils'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import VenueCardButtons from './VenueCardButtons'
import Typography from '@mui/material/Typography'
import { Image } from '../generics'


import venueCardStyles from '../../styles/cards/desktop/venueCardStyles'
import venueCardMobileStyles from '../../styles/cards/mobile/venueCardMobileStyles'



const VenueCard = props => {
  const navigate = useNavigate()
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const { venue } = props
  const { name, image } = venue.attributes
  const { city, state } = venue.attributes.address
  
  const imageURL = getImageOfSize(venue.attributes, 'small') || image
  const href = process.env.REACT_APP_BASE_URL + '/venues/' + venue.id

  const styles = isExtraSmallScreen ? venueCardMobileStyles()
                                    : venueCardStyles()

  function handleClick(e) {
    e.preventDefault()
    e.stopPropagation()
    navigate('/venues/' + venue.id)
  }


  return (
    <div css={ styles.card }>
      <a css={ styles.link } href={ href } onClick={ e => e.preventDefault() }>
        <div css={ styles.imageContainer } onClick={ handleClick }>
          <Image css={ styles.image } src={ imageURL } alt='venue' />
        </div>
      </a>

      <div css={ styles.infoContainer } onClick={ handleClick }>
        <a css={ styles.link } href={ href } onClick={ e => e.preventDefault() }>
          <Typography css={ styles.title }>
            { name }
          </Typography>

          <Typography noWrap>
            { city ? city : state }
          </Typography>
        </a>
      </div>

      <div css={ styles.buttons }>
        <VenueCardButtons
          venueId={ venue.id } 
          name={ name } 
          imageURL={ imageURL }
        />
      </div>
    </div>
  )
}


export default VenueCard

