import {useEffect, useState} from 'react'

import {delay} from 'lodash'

import {show, create, update, destroy} from '../../apis'


import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import {FaInfoCircle} from 'react-icons/fa'
import DeleteIcon from '@mui/icons-material/Delete'



import styles from '../../styles/broadcast.module.scss'




const wordLimit = 400

const tooltipText = `
When you broadcast a new message, all your followers will get an email 
and any follower that has the app installed on their phone will get a push notification. 
Edits will not be broadcast. If there's a critical edit that needs to be broadcast, 
wait 5 minutes from the last message and create a new message. 
`


const Broadcast = props => {

  // The id and type are the broadcaster id and type
  const {id, type, isOwner} = props

  
  const [isError, setIsError] = useState(false)


  const [isEditing, setIsEditing] = useState(false)

  const [action, setAction] = useState('')


  const [caption, setCaption] = useState('')

  
  const [newContent, setNewContent] = useState('')

  const [currentContent, setCurrentContent] = useState('')
  const [currentBroadcastId, setCurrentBroadcastId] = useState('')




  useEffect(() => {
    show('/broadcasts', {broadcaster_id: id, broadcaster_type: type}).then(response => {
      const data = response.data?.data

      if (data?.attributes) {        
        setCurrentContent(data.attributes.content)
        setCurrentBroadcastId(data.id)
      }
    })
    .catch(error => {
      console.log(error)
    })
  }, [id, type, setCurrentContent, setCurrentBroadcastId])






  function handleChange(e) {
    const value = e.target.value


    if (!validate(value)) {
      setIsError(true)
      setCaption('The maximum number of characters is 400')
      return
    } else if (isError) {
      setIsError(false)
      setCaption('')
    }


    setNewContent(value)
  }




  function handleSubmit(e) {
    e.preventDefault()


    if (!validate(newContent)) {
      setIsError(true)
      setCaption('The maximum number of characters is 400')
      return
    }


    if (!(newContent.length > 0)) {
      setIsError(true)
      setCaption('No text entered')
      return
    }

    

    let payload = {
      broadcaster_id: id, 
      broadcaster_type: type,
      content: newContent
    }



    function handleSuccess(id, message) {
      setIsEditing(false)
      setIsError(false)
      setCurrentContent(newContent)
      setCurrentBroadcastId(id)
      setCaption(message)
    }


    function handleError(message) {
      setIsError(true)
      setCaption(message)
    }




    if (action === 'create') {
      create('/broadcasts', payload).then(response => {
        const data = response.data?.data


        if (data) {
          handleSuccess(data.id, 'Message has been broadcast to your followers')
        } else {
          handleError('An error occured trying to broadcast your message')
        }

        delay(() => setCaption(''), 5000)
      
      })
      .catch(error => handleError(error.apiMessage()))
    }



    if (action === 'update') {
      update('/broadcasts/' + currentBroadcastId, payload).then(response => {
        const data = response.data?.data


        if (data) {
          handleSuccess(data.id, 'Updated successfully')
        } else {
          handleError('An error occured trying to update your message')
        }

        delay(() => setCaption(''), 5000)

      })
      .catch(error => handleError(error.apiMessage()))
    }
  }




  function handleFocus(e) {
    e.target.setSelectionRange(e.target.value.length, e.target.value.length)
  }



  function handleCancel() {
    setIsEditing(false)
  }



  function handleDelete() {
    if (!currentBroadcastId || !isOwner) {
      return
    }

    destroy('/broadcasts/' + currentBroadcastId, {broadcaster_id: id}).then(response => {
      setIsError(false)
      setCurrentContent('')
      setCurrentBroadcastId('')
      setCaption('Deleted succesfully')

      delay(() => setCaption(''), 5000)
    })
    .catch(error => {
      setIsError(true)
      setCaption(error.apiMessage())
    })
  }




  function validate(text) {
    return text.length <= wordLimit
  }



  const edit = method => () => {
    if (!isOwner) return

    setAction(method)
    setIsEditing(true)

    if (method === 'update') {
      setNewContent(currentContent)
    } else {
      setNewContent('')
    }
  }



  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {!isEditing &&
          <>
            {(() => {
              if (isOwner && !Boolean(currentContent) ) {
                return (
                  <p className={styles.placeholder} onClick={edit('create')}>
                    Broadcast information to your followers here. Let fans know when you 
                    release a new single or when a new show gets added.
                  </p>
                )
              } else if (!Boolean(currentContent)) {
                return (
                  <p className={styles.placeholder}>
                    The latest news and updates from the {type} will appear here
                  </p>
                )
              } else {
                return (
                  <p onClick={edit('update')}>
                    {currentContent}
                  </p>
                )
              }
            })()}
          </>
        }


        {isOwner && !isEditing && 
          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={edit('create')}>
              New
            </button>

            {currentContent && 
              <button className={styles.button} onClick={edit('update')}>
                Edit
              </button>
            }

            <div className={styles.iconsContainer}>
              <Tooltip title={tooltipText} placement='bottom'>
                <IconButton aria-label='delete' size='small'>
                  <FaInfoCircle size={21} />
                </IconButton>
              </Tooltip>

              <hr/>

              <IconButton aria-label='delete' size='small' onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        }



        {isEditing && 
          <form onSubmit={handleSubmit} className={styles.form}>
            <textarea
              row='4'
              autoFocus
              onFocus={handleFocus}
              name='content' 
              value={newContent}
              className={styles.input}
              onChange={handleChange}
            />


            <div className={styles.buttonContainer}>
              <button type='submit' className={styles.button}>
                {action === 'update' ? 'Update' : 'Post'}
              </button>

              <button className={styles.button} onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </form>
        }
      </div>

      
      <p className={isError ? styles.error : styles.success}>
        {caption}
      </p>
    </div>
  )
}


export default Broadcast



