import { createSelector } from 'reselect'
import { get, pick, map } from 'lodash'


const emptyArray = []
const emptyObject = {}


const selectTrackIds = state => state.tracks.byId
const selectArtistIds = state => state.artists.byId
const selectPerformances = state => state.performances.byId



const selectBandId = (state, id) => {
  return get(state.bands.byId[id], 'id', false)
}

const selectBandAttributesById = (state, id) => {
  return get(state.bands.byId[id], 'attributes')
}

const selectBandTracks = (state, id) => {
  return get(state.bands.byId[id], ['relationships', 'tracks'], emptyArray)
}

const selectBandArtists = (state, id) => {
  return get(state.bands.byId[id], ['relationships', 'artists'], emptyArray)
}

const selectBandPerformances = (state, id) => {
  return get(state.bands.byId[id], ['relationships', 'performances'], emptyArray)
}

const selectNearbyBandPerformances = (state, id) => {
  return get(state.bands.byId[id], ['relationships', 'nearbyPerformances'], emptyArray)
}

const selectBandFetchedAssociations = (state, id) => {
  return get(state.bands.byId[id], 'fetched', emptyArray)
}


const selectBandLinks = (state, id, association) => {
  return get(state.bands.byId[id], ['links', association], emptyObject)
}


export const selectBandIds = state => state.bands.byId


export const makeGetBandById = () => {
  return createSelector(
    [selectBandId, selectBandAttributesById], 
    (id, attributes) => ({ id, attributes, type: 'band' })
  )
}

export const makeGetBandFetchedAssociations = () => {
  return createSelector(
    selectBandFetchedAssociations, 
    fetchedAssociations => fetchedAssociations
  )
}


export const makeGetBandTracks = () => {
  return createSelector([selectBandTracks, selectTrackIds],
    (bandTracks, tracks) => {
      const trackIds = map(bandTracks, 'id')

      return Object.values(pick(tracks, trackIds))
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetBandArtists = () => {
  return createSelector([selectBandArtists, selectArtistIds],
    (bandArtists, artists) => {
      const artistIds = map(bandArtists, 'id')

      return Object.values(pick(artists, artistIds))
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetBandPerformances = () => {
  return createSelector([selectBandPerformances, selectPerformances],
    (bandPerformances, performances) => {
      const performanceIds = map(bandPerformances, 'id')

      return Object.values(pick(performances, performanceIds))
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetNearbyBandPerformances = () => {
  return createSelector([selectNearbyBandPerformances, selectPerformances],
    (nearbyPerformances, performances) => {
      const performanceIds = map(nearbyPerformances, 'id')

      return Object.values(pick(performances, performanceIds))
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetBandLinks = () => {
  return createSelector([selectBandLinks], (bandLinks) => bandLinks)
}




const bandSelectors = {
  selectBandIds,
  makeGetBandById,
  makeGetBandLinks,
  makeGetBandTracks,
  makeGetBandArtists,
  makeGetBandPerformances,
  makeGetNearbyBandPerformances,
  makeGetBandFetchedAssociations,
}


export default bandSelectors





