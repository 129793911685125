import React, {useState} from 'react'

import {connect} from 'react-redux'

import useMediaQuery from '@mui/material/useMediaQuery'

import {useNavigate} from 'react-router-dom'

import {showAlert} from '../../../actions'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import MessageIcon from '@mui/icons-material/Email'

import MessageForm from '../../messages/MessageForm'

import FadeTransition from '../FadeTransition'
import SlideTransition from '../SlideTransition'

import {closeSnackbar} from 'notistack'





const MessageIconButton = props => {
  
  const navigate = useNavigate()
  
  const [open, setOpen] = useState(false)

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))


  const {
    id, 
    type,
    name,
    imageURL,
    size,
    showAlert,
    isAuthenticated,
    ...rest
  } = props

  
  const iconSize = size || (isExtraSmallScreen ? 'small' : 'medium')



  function navigateToSignupPage() {
    navigate('/sign-up', {
      state: {
        redirect: `/${type}s/${id}`
      }
    })
  }



  function handleClick() {
    if (!isAuthenticated) {
      const action = snackbarID => (
        <>
          <Button 
            variant='text' 
            sx={{
              fontWeight: 600,
              color: 'rgb(62, 166, 255)'
            }}
            onClick={navigateToSignupPage}
          >
            Sign Up
          </Button>

          <IconButton 
            aria-label='close'
            sx={{
              fontWeight: 600,
              color: '#fff'
            }}
            onClick={() => closeSnackbar(snackbarID)}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </>
      )

      showAlert('Sign up to send messages', 'standard', action)
      return
    }

    setOpen(true)
  }


  function close() {
    setOpen(false)
  }



  return (
    <>
      <IconButton onClick={handleClick} {...rest} size={iconSize || 'medium' }>
        <MessageIcon fontSize={iconSize || 'medium'} style={{color: '#fff'}} />
      </IconButton>


      <Dialog
        open={open}
        onClose={close}
        fullScreen={isExtraSmallScreen}
        fullWidth={true}
        maxWidth='xs'
        disableScrollLock={isExtraSmallScreen ? false : true}
        TransitionComponent={isExtraSmallScreen ? SlideTransition : FadeTransition}
      >
        <MessageForm
          image={imageURL}
          recipientId={id}
          recipientType={type}
          recipientName={name}
          close={close} 
        />
      </Dialog>
    </>
  )
}


const mapStateToProps = state => {
  return {
    isAuthenticated: state.user.isAuthenticated
  }
}


export default connect(mapStateToProps, {showAlert})(MessageIconButton)


