import {crudAPI} from '../../apis'



export function initiateSearch() {
  return dispatch => {
    dispatch({type: 'SEARCH_REQUESTED'})
  }
}



export function search(url, data, type) {
  const uppercased = type.toUpperCase()

  return dispatch => {
    return crudAPI.index(url, data).then(response => {
      dispatch({type: 'SEARCH_COMPLETED'})

      dispatch({type: uppercased + 'S_INDEX_SUCCESS', payload: response.data})
      dispatch({type: uppercased + '_SEARCH_SUCCESS', payload: response.data})

      if (['venue', 'performance'].includes(type.toLowerCase())) {
        dispatch({type: 'ADD_TO_' + uppercased + '_MAP_MARKER_QUEUE', payload: response.data})
      }


      // TODO: Is it better to ENQUEUE_TRACKS or ENQUEUE_MAP_TRACKS
      if (type.toLowerCase() === 'track') {
        dispatch({
          type: 'ENQUEUE_MAP_TRACKS', 
          payload: {
            tracks: response.data.data,
            links: response.data.links
          }
        })
      }
    })
    .catch(error => dispatch({type: 'SEARCH_COMPLETED'}))
  }
}




export function mergeSearch(url, data, type) {
  const uppercased = type.toUpperCase()


  return dispatch => {
    return crudAPI.index(url, data).then(response => {
      dispatch({type: 'SEARCH_COMPLETED'})

      dispatch({type: uppercased + 'S_INDEX_SUCCESS', payload: response.data})
      dispatch({type: uppercased + '_SEARCH_MERGE_SUCCESS', payload: response.data})

      if (['venue', 'performance'].includes(type.toLowerCase())) {
        dispatch({type: 'ADD_TO_' + uppercased + '_MAP_MARKER_QUEUE', payload: response.data})
      }

      if (type.toLowerCase() === 'track') {
        dispatch({
          type: 'ADD_TO_AUDIO_QUEUE', 
          payload: {
            tracks: response.data.data,
            links: response.data.links
          }
        })
      }
    })
    .catch(error => dispatch({type: 'SEARCH_COMPLETED'}))
  }

}



export function paginationSearch(url, type) {
  const uppercased = type.toUpperCase()

  return dispatch => {
    return crudAPI.index(url).then(response => {
      dispatch({type: 'SEARCH_COMPLETED'})

      dispatch({type: uppercased + 'S_INDEX_SUCCESS', payload: response.data})
      dispatch({type: uppercased + '_PAGINATION_SEARCH_SUCCESS', payload: response.data})

      if (['venue', 'performance'].includes(type.toLowerCase())) {
        dispatch({type: 'ADD_TO_' + uppercased + '_MAP_MARKER_QUEUE', payload: response.data})
      }
    })
    .catch(error => dispatch({type: 'SEARCH_COMPLETED'}))
  }
}



