import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {fullURL} from '../../utils'



const styles = {
  root: css`
    display: inline-block;
  `,
  link: css`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    text-decoration: none;
  `
}



const WebsiteLink = props => {
  
  const {url, isMailLink} = props
  
  const href = isMailLink ? 'mailto:' + url + '?subject=User Message From Ckord' : fullURL(url)


  function trimUrl(url) {
    // Create a URL object to easily parse the URL
    const parsedUrl = new URL(url)

    // Extract the hostname (e.g., "www.ticketmaster.com")
    const hostname = parsedUrl.hostname

    // Return the trimmed URL format
    return `${hostname}/...`
  }

  
  return (
    <div css={styles.root}>
      <a css={styles.link} target='_blank' rel='noopener' href={href}>
        {isMailLink ? url : trimUrl(url)}
      </a>
    </div>
  )
}


export default WebsiteLink