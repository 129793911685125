import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {sentenceCase} from '../../../utils'


const cssStyles = ({errors, margin}) => ({
  root: css`
    width: 100%;
    color: red;
    list-style: ${errors?.length === 1 && 'none'};
    margin: ${margin ? margin : '10px 0px'};
    background-color: rgba(255, 0, 0, 0.1);
    padding: 15px;
    padding-left: ${errors?.length === 1 ? '15px' : '30px'};
    border-radius: 4px;
    margin-bottom: 10px;
  `,
  text: css`
    font-size: 0.9rem;
    letter-spacing: -0.02rem;
    line-height: 1.2
  `
})



const Errors = ({errors, margin, ...props}) => {

  if (typeof errors === 'string') {
    errors = [errors]
  }

  const styles = cssStyles({errors, margin})


  return (
    <>
      {errors && Boolean(errors.length) &&
        <ul css={styles.root} {...props}>
          {errors.map((error, i) => (
            <li key={i}>
              <p css={styles.text}>{sentenceCase(error)}</p>
            </li>
          ))}
        </ul>
      }
    </>
  )
}



export default Errors