import React, {useState, useEffect} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {useSearchParams} from 'react-router-dom'

import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

import {crudAPI} from '../../../apis'

import {showAlert} from '../../../actions'

import {makeGetBandById} from '../../../selectors'

import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import StripeTipForm from '../StripeTipForm'
import MerchantSignUpNotice from '../../ecommerce/notices/MerchantSignUpNotice'

import {ReactComponent as Applause} from '../../../svgs/Applause.svg'

import FadeTransition from '../../generics/FadeTransition'
import SlideTransition from '../../generics/SlideTransition'



const styles = {
  button: css`
    border-radius: 24px;
    height: 48px;
    width: 48px;
    overflow: hidden;
    padding: 0px 10px;
    min-width: 44px;
    display: flex;
    padding: 7px;

    &.MuiButton-contained {
      background-color: #262626;

      &:hover {
        background-color: #262626;
      }
    }

    svg {
      width: 25px;
    }
  `,
  closeButtonContainer: css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 40px 0px 40px;
  `,
  formContainer: css`
    position: relative;
    max-width: 380px;
    background-color: #fff;
  `,  
  placeholder: css`
    top: 0;
    position: absolute;
    width: 100%;
    min-height: 420px;
    background-color: #fff;
  `
}



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)



const TipButton = props => {

  const [open, setOpen] = useState(false)

  const [params] = useSearchParams()

  const tipIdFromURLQuery = params.get('tip_id')

  const [tipId, setTipId] = useState(tipIdFromURLQuery || '')


  const [options, setOptions] = useState({clientSecret: false})

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))


  const {id, type, stripeId, userId, name, isOwner, isMerchant, showAlert} = props



  // This handles the instance where the StripeTipForm is submitted and 
  // a redirect URL is required
  useEffect(() => {
    if (tipIdFromURLQuery) {
      crudAPI.patch('/tips/complete/' + tipIdFromURLQuery).then(response => {
        if (response.data?.data?.id) {
          showAlert(`Thank you for supporting ${name} and live music!`, 'success')
        }
      })
    }
  }, [tipIdFromURLQuery, name, showAlert])




  function handleClick() {
    // If the owner is not a merchant yet, then show a notice on becoming a merchant...
    if (isOwner && !isMerchant) {
      setOpen(true)
      
    // ...otherwise show the tip form
    } else if (isMerchant) {

      const params = {
        tipper: userId,
        recipient_id: id,
        recipient_type: type
      }

      setOpen(true)

      if (!options.clientSecret) {
        crudAPI.create('/tips', params).then(response => {
          setOptions(prev => ({...prev, clientSecret: response.data.client_secret}))
          setTipId(response.data.tip_id)
        })
      }
    }
  }



  function handleClose() {
    setOpen(false)
  }



  return (
    <>
      <Button css={styles.button} onClick={handleClick}>
        <Applause style={{color: '#fff'}} fill='#fff' />
      </Button>


      <Dialog 
        open={open}
        scroll={isExtraSmallScreen ? 'paper' : 'body'}
        onClose={handleClose} 
        keepMounted={true}
        fullScreen={isExtraSmallScreen}
        TransitionComponent={isExtraSmallScreen ? SlideTransition : FadeTransition}
        sx={{
          '.MuiPaper-root': {
            display: isExtraSmallScreen ? 'flex' : null,
            justifyContent: isExtraSmallScreen ? 'center' : null,
            alignItems: 'center',
            maxWidth: isExtraSmallScreen ? '100%' : '380px'
          }
        }}
      >
        {isOwner && !isMerchant &&
          <MerchantSignUpNotice 
            id={id} 
            type={type}
            name={name}
            close={handleClose}
            stripeId={stripeId} 
          />
        }

        {isMerchant && options.clientSecret &&
          <div css={styles.formContainer}>
            {isExtraSmallScreen &&
              <div css={styles.closeButtonContainer}>
                <IconButton onClick={handleClose} size='large'> 
                  <CloseIcon color='disabled' fontSize='large' />
                </IconButton>
              </div>
            }

            <Elements stripe={options.clientSecret ? stripePromise : null} options={options}>
              <StripeTipForm 
                name={name}
                tipId={tipId} 
                close={handleClose} 
                setOptions={setOptions} 
              />
            </Elements>


            {!options.clientSecret && <div css={styles.placeholder} />}
          </div>
        }
      </Dialog>
    </>
  )
}



const makeMapStateToProps = () => {
  const getBandById = makeGetBandById()


  const mapStateToProps = (state, props) => {

    const {id, type} = props

    // If the type is a band, then look at the permission...
    if (type.toLowerCase() === 'band') {

      const band = getBandById(state, id)

      return {
        userId: state.user?.id,
        isOwner: band?.attributes?.permission === 'owner',
        stripeId: band?.attributes?.stripeId,
        isMerchant: band?.attributes?.isMerchant
      }
    // ...Otherwise look at the account id and store
    } else {
      return {
        userId: state.user?.id,
        isOwner: state.user?.account.id === props.id,
        stripeId: state.user?.account?.attributes?.stripeId,
        isMerchant: state[type + 's']?.byId[id]?.attributes?.isMerchant
      }
    }
  }

  return mapStateToProps
}


export default connect(makeMapStateToProps, {showAlert})(TipButton)







