import {createContext, useContext, useState} from 'react'


// Create a Context for the global state
const PlayButtonContext = createContext()

// Custom hook to use the global state context
export const usePlayButtonState = () => {
  return useContext(PlayButtonContext)
}


// GlobalStateProvider component to wrap the application
export const PlayButtonProvider = ({children}) => {
  const [mapQueue, setMapQueue] = useState([])
  
  const [registeredLinks, setRegisteredLinks] = useState(new Set())
  const [registeredEvents, setRegisteredEvents] = useState(new Set())


  return (
    <PlayButtonContext.Provider 
      value={{
        mapQueue,
        setMapQueue,
        registeredLinks,
        registeredEvents,
        setRegisteredLinks, 
        setRegisteredEvents
      }}
    >
      {children}
    </PlayButtonContext.Provider>
  )
}


export default PlayButtonProvider