import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { showAlert } from '../../../actions'
import { index, patch, destroy } from '../../../apis'
import { omit } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { useIsMounted } from '../../../hooks'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Slide from '@mui/material/Slide'
import { ThreeDotProgress } from '../../generics'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 24,
    overflowX: 'hidden'
  },
  avatar: {
    marginRight: 16
  },
  listItem: {
    padding: '12px 0px',
    display: 'flex',
    marginBottom: 12
  },
  listItemSecondaryAction: {
    width: 180,
    paddingLeft: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selectContainer: {
    minWidth: 90
  },
  select: {
    width: '100%'
  },
  remove: {
    color: 'red'
  },
  updateButton: {
    fontSize: 11,
    padding: 10,
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: 'black'
    }
  },
  removeButton: {
    fontSize: 11,
    padding: 10,
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'red'
    }
  }
}))


const CurrentPerformancePermissions = props => {
  const classes = useStyles()
  const [permissions, setPermissions] = useState({})
  const [isFetching, setIsFetching] = useState(true)
  const [updateSet, setUpdateSet] = useState(new Set())
  const [removeSet, setRemoveSet] = useState(new Set())
  const [isUpdateButtonMounted, setIsUpdateButtonMounted] = useState(false)
  const [isRemoveButtonMounted, setIsRemoveButtonMounted] = useState(false)
  const isMounted = useIsMounted()
  const { performanceId, showAlert, users, setUsers } = props


  useEffect(() => {
    index('performances/' + performanceId + '/permissions')
      .then(response => {
        if (isMounted.current) {
          setIsFetching(false)
          const normalized = response.data.data.reduce((acc, user) => {
            acc[user.id] = user
            return acc
          }, {})

          setUsers(normalized)
        }
      })
      .catch(error => {
        if (isMounted.current) {
          setIsFetching(false)
          setUsers({})
        }
      })
  }, [performanceId, isMounted, setIsFetching, setUsers])



  const changePermissionFor = userId => e => {
    const value = e.target.value

    if (value === 'remove') {
      removePermissions(userId)
    } else {
      if (value === users[userId].permission) {
        updateSet.delete(userId)
      } else {
        updateSet.add(userId)
      }

      if (removeSet.has(userId)) removeSet.delete(userId)
      setPermissions({ ...permissions, [userId]: value })
    }
  }


  function removePermissions(userId) {
    const newUpdateSet = new Set(updateSet)
    const newRemoveSet = new Set(removeSet)

    if (newUpdateSet.has(userId)) {
      newUpdateSet.delete(userId)
      setUpdateSet(newUpdateSet)
    }

    newRemoveSet.add(userId)
    setRemoveSet(newRemoveSet)
    setPermissions({ ...permissions, [userId]: 'remove' })
  }



  const handleClick = permission => () => {
    if (permission === 'owner') {
      showAlert('Cannot change an owners permission', 'error')
    }
  }


  const handleUpdateButtonClick = userId => () => {
    const newPermission = permissions[userId]
    const updatedUser = { ...users[userId], permission: newPermission }

    if (newPermission) {
      patch('performances/' + performanceId + '/permissions', {
        permission: newPermission,
        user_id: userId
      })
      .then(() => {
        if (isMounted.current) {
          const newUpdateSet = new Set(updateSet)
          
          newUpdateSet.delete(userId)
          setUpdateSet(newUpdateSet)
          setUsers(prev => ({ 
            ...prev, 
            [userId]: { ...updatedUser }
          }))
          showAlert('Updated', 'success')
        }
      })
      .catch(error => {
        showAlert('Something went wrong', 'error')
      })
    }
  }



  const handleRemoveButtonClick = userId => () => {
    destroy('performances/' + performanceId + '/permissions', { user_id: userId })
      .then(() => {
        if (isMounted.current) {
          const newRemoveSet = new Set(removeSet)
          const newUpdateSet = new Set(updateSet)

          newRemoveSet.delete(userId)
          newUpdateSet.delete(userId)
          setUpdateSet(newUpdateSet)
          setRemoveSet(newRemoveSet)
          setUsers(omit(users,userId))
          setPermissions(omit(permissions, userId))
          showAlert('User removed', 'success')
        }
      })
      .catch(error => {
        showAlert('Something went wrong', 'error')
      })
  }


  return (
    <List className={ classes.root }>
      { isFetching && <ThreeDotProgress /> }
      { Object.values(users).map(user => {
          const { id, permission, username, image, imageDerivatives, accountName } = user
          const imageURL = imageDerivatives.small || image

          return (
            <ListItem
              className={ classes.listItem }
              key={ id } 
              onClick={ handleClick(permission) }>

              <ListItemAvatar>
                <Avatar className={ classes.avatar } alt={ username } src={ imageURL } />
              </ListItemAvatar>

              <ListItemText 
                primary={ username } 
                secondary={ accountName }
                primaryTypographyProps={{ noWrap: true }}
                secondaryTypographyProps={{ noWrap: true }}
              />

              <div className={ classes.listItemSecondaryAction }>
                { permission === 'owner'
                  ? <Typography>Owner</Typography>
                  : <div className={ classes.selectContainer }> 
                      <Select
                        className={ classes.select }
                        color='primary'
                        onChange={ changePermissionFor(id) }
                        value={ permissions[id] || permission }>
                        <MenuItem value='member'>Member</MenuItem>
                        <MenuItem value='editor'>Editor</MenuItem>
                        <MenuItem value='owner'>Owner</MenuItem>
                        <MenuItem value='remove' className={ classes.remove }>
                          Remove
                        </MenuItem>
                      </Select>
                    </div>
                }
                <Slide 
                  direction='left' 
                  in={ updateSet.has(id) && !isRemoveButtonMounted } 
                  mountOnEnter 
                  unmountOnExit
                  onExited={ () => setIsUpdateButtonMounted(false) }
                  onEntered={ () => setIsUpdateButtonMounted(true) }>
                  <Button
                    className={ classes.updateButton }
                    variant='contained'
                    disableElevation
                    onClick={ handleUpdateButtonClick(id) }>
                    UPDATE
                  </Button>
                </Slide>
                <Slide 
                  direction='left' 
                  in={ removeSet.has(id) && !isUpdateButtonMounted }
                  mountOnEnter 
                  unmountOnExit
                  onExited={ () => setIsRemoveButtonMounted(false) }
                  onEntered={ () => setIsRemoveButtonMounted(true) }>
                  <Button
                    className={ classes.removeButton }
                    variant='contained'
                    disableElevation
                    onClick={ handleRemoveButtonClick(id) }>
                    REMOVE
                  </Button>
                </Slide>
              </div>

            </ListItem>
        )})
      }
    </List>
  )
}



export default connect(null, { showAlert })(CurrentPerformancePermissions)
