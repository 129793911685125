import {useEffect} from 'react'

import {startOfDay, addYears} from 'date-fns'

import {useSelector, useDispatch} from 'react-redux'

import {dateToUTC} from '../utils'

import {search, mergeSearch, clearMarkersFor} from '../actions'

import {filterSelector, searchStatusSelector} from '../selectors'




const date = new Date()


export function useSearchPerformances() {

  const dispatch = useDispatch()
  
  const filters = useSelector(filterSelector)

  const status = useSelector(searchStatusSelector)




  useEffect(() => {
    if (status === 'requested' || status === 'mergeRequested') {
      const data = {} 


      // Add Location to search filters
      const {lat, lng, radius} = filters.location


      if (!radius) return


      data.location = {lat, lng, radius}


      // Add time to search filters
      const start = filters.time.start || startOfDay(date)
      const end = filters.time.end || addYears(startOfDay(date), 1)
      const utcDate = {start: dateToUTC(start), end: dateToUTC(end)}

      data.time = utcDate

    
      // Add price to search filters if it exists 
      const min = filters.price.min
      const max = filters.price.max


      if (typeof min === 'number' && typeof max === 'number') {
        if (min === 0) {
          data.price = max
        } else {
          data.price_range = {min, max}
        }
      } else if (typeof max === 'number') {
        data.price = max
      }


      // Add tags if any are preent
      if (filters.tags) {
        data.tags = filters.tags
      }




      if (status === 'requested') {
        dispatch(clearMarkersFor('PERFORMANCE'))
        dispatch(search('search/performances', data, 'PERFORMANCE'))
      }

      if (status === 'mergeRequested') {
        dispatch(mergeSearch('search/performances', data, 'PERFORMANCE'))
      }
    }
  }, [filters, status, dispatch])

}



export default useSearchPerformances

