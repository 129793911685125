import { createSelector } from 'reselect'
import { pick } from 'lodash'
import { capitalizeFirstLetter } from '../utils'


const selectFavoritesFromType = (state, type) => {
  return state.user.favorites['ordered' + capitalizeFirstLetter(type)]
}


const selectObjectsFromType = (state, type) => {
  return state[type].byId
}


export const makeGetFavoritesFromType = () => {
  return createSelector(
    [selectFavoritesFromType, selectObjectsFromType], 
    (favorites, objects) => {
      const favoriteObjects = pick(objects, favorites)

      return Object.values(favoriteObjects)
    }
  )
}
