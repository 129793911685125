import React, {useEffect} from 'react'

import {DatePicker} from '@mui/x-date-pickers'

import {LocalizationProvider} from '@mui/x-date-pickers'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'

import TextField from '@mui/material/TextField'





const DatePickerField = ({field, form, disabled, inputProps, helperText, ...props}, ref) => {

  const {name, value, onBlur} = field

  const {touched, errors, setFieldValue, isSubmitting} = form

  const error = errors[name]

  const isError = touched[name] && Boolean(error)
  


  useEffect(() => {
    if (props.minDate > value || value === undefined) {
      setFieldValue(name, props.minDate)
    }
  }, [props.minDate, value, name, setFieldValue])




  function handleChange(date) {
    setFieldValue(name, date)
  }




  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        autoOk
        disablePast
        name={name}
        value={value}
        format='MMMM do, yyyy'
        error={touched[name] && Boolean(error)}
        helperText={isError ? error : helperText}
        disabled={disabled || isSubmitting}
        onChange={handleChange}
        onBlur={onBlur}
        renderInput={params => (
          <TextField 
            variant='filled' 
            fullWidth
            sx={{input: {cursor: 'pointer'}}}
            {...params} 
            {...inputProps}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  )
}

export default DatePickerField


