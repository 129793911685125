import { createSelector } from 'reselect'
import { pick } from 'lodash'


const selectFollowsFromType = (state, type) => {
  return state.user.following[type]
}


const selectObjectsFromType = (state, type) => {
  return state[type].byId
}


export const makeGetFollowsFromType = () => {
  return createSelector(
    [selectFollowsFromType, selectObjectsFromType], 
    (following, objects) => {
      const followingObjects = pick(objects, Object.keys(following))

      return Object.values(followingObjects)
    }
  )
}


export const makeGetFollowObjects = () => {
  return createSelector(
    [selectFollowsFromType, selectObjectsFromType], 
    (following, objects) => {
      const followingObjects = pick(objects, Object.keys(following))

      return !(Object.keys(following).length === Object.keys(followingObjects).length)
    }
  )
} 