import { useEffect, useState } from 'react'
import useScript from './useScript'


export function useFonts(quantity, fontFamily = 'Roboto') {
  const [isLoaded] = useScript('https://apis.google.com/js/api.js')
  const [isFetching, setIsFetching] = useState(true)
  const [fonts, setFonts] = useState([])
  const [selectedFont, setSelectedFont] = useState({})


  useEffect(() => {
    if (isLoaded && window.gapi) {
      function start() {
        window.gapi.client.setApiKey('AIzaSyAYDKXSq794FGknIan6IP-lvuM1XQkNTBo')
        window.gapi.client.load('https://www.googleapis.com/discovery/v1/apis/webfonts/v1/rest')
          .then(fetchFonts)
      }


      function fetchFonts() {
        window.gapi.client.webfonts.webfonts.list({
          sort: 'POPULARITY'
        })
        .then(
          response => {
            const result = response.result.items.slice(0, quantity)
            const font = result.find(f => f.family === fontFamily) || {}

            setIsFetching(false)
            setFonts(result)
            setSelectedFont(font)
          },
          error => {
            setIsFetching(false)
            console.log(error)
          }
        )
      }

      window.gapi.load('client', start)
    }
  }, [isLoaded, quantity, fontFamily, setIsFetching])


  return [fonts, selectedFont, isFetching]
}