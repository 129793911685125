import { combineReducers } from 'redux'
import { filter, omit, map, uniq, get } from 'lodash'
import {
  mergeObjects,
  mergeRelationships, 
  mergeLinks,
  removeDataKeyFromRelationships
} from '../../utils'
import normalize from 'json-api-normalizer'



const byId = (state = {}, action) => {
  let id = ''

  switch (action.type) {
    case 'PRODUCTS_INDEX_SUCCESS':
      if (action.payload.data.length) {
        let normalizedResponse = normalize(action.payload).product
        normalizedResponse = removeDataKeyFromRelationships(normalizedResponse)
        normalizedResponse = mergeObjects(normalizedResponse, state)

        return { ...state, ...normalizedResponse }
      } else {
        return state
      }
    case 'PRODUCT_SHOW_SUCCESS':
    case 'PRODUCT_UPDATE_SUCCESS':
    case 'PRODUCT_CREATE_SUCCESS':
      id = action.payload.data.id


      if (action.payload.isPreview) {
        return { ...state, 
          [id]: {
            ...action.payload.data 
          }
        }
      }
      
      const existingRecord = state[id]
      const normalizedResponse = removeDataKeyFromRelationships(normalize(action.payload).product)


      if (existingRecord) {
        normalizedResponse[id] = mergeRelationships(normalizedResponse[id], existingRecord)
        normalizedResponse[id] = mergeLinks(normalizedResponse[id], existingRecord)
      }
      
      return { ...state, ...normalizedResponse }
    case 'PRODUCT_DESTROY_SUCCESS':
      return omit(state, action.payload.data.id)
    case 'PRODUCT_RESET_STATE':
      return {}
    case 'PRODUCT_INCREMENT_VIEW_COUNT_UPDATE_SUCCESS':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          attributes: { ...state[action.payload.id].attributes,
            views: action.payload.count
          }
        }
      }
    case 'PRODUCT_IMAGES_UPDATE_SUCCESS':
      id = action.payload.data.attributes.imagableId

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            images: map(state[id].attributes.images, e => {
              if (e.id === action.payload.data.id) {
                return action.payload.data
              } else return e
            })
          }
        }
      }
    case 'PRODUCT_IMAGES_INDEX_SUCCESS':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          attributes: {
            ...get(state[action.payload.id], 'attributes', {}),
            images: action.payload.data
          }
        }
      }
    case 'PRODUCT_IMAGES_CREATE_SUCCESS':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          attributes: {
            ...get(state[action.payload.id], 'attributes', {}),
            images: [
              action.payload.data, 
              ...get(state[action.payload.id], ['attributes', 'images'], [])
            ] 
          }
        }
      }
    case 'PRODUCT_IMAGES_DESTROY_SUCCESS':
      id = action.payload.data.attributes.imagableId

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            images: filter(state[id].attributes.images, e => {
              return e.id !== action.payload.data.id
            }) 
          }
        }
      }
    case 'PRODUCT_SAVE_LINKS':
      return { ...state, 
        [action.payload.id]: {
          ...state[action.payload.id],
          links: { ...(state[action.payload.id]?.links || {}),
            [action.payload.association]: action.payload.links
          }
        }
      }
    default:
      return state
  } 
}


const all = (state = [], action) => {
  switch (action.type) {
    case 'PRODUCTS_INDEX_SUCCESS':
      return uniq([...state, ...map(action.payload.data, 'id')])
    case 'PRODUCT_SHOW_SUCCESS':
    case 'PRODUCT_CREATE_SUCCESS':
      return [...state, action.payload.data.id]
    case 'PRODUCT_DESTROY_SUCCESS':
      return filter(state, e => e !== action.payload.data.id)
    case 'PRODUCT_RESET_STATE':
      return []
    default:
      return state
  }
}


const isFetching = (state = false, action) => {
  switch (action.type) {
    case 'PRODUCTS_REQUESTED':
    case 'PRODUCT_REQUESTED':
      return true
    case 'PRODUCTS_INDEX_SUCCESS':
    case 'PRODUCT_SHOW_SUCCESS':
    case 'PRODUCT_CREATE_SUCCESS':
    case 'PRODUCT_DESTROY_SUCCESS':
    case 'PRODUCT_RESET_STATE':
    case 'PRODUCT_ERROR':
    case 'PRODUCTS_ERROR':
      return false
    default:
      return state
  }
}


export default combineReducers({
  byId,
  all,
  isFetching
})

