import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {useNavigate} from 'react-router-dom'

import {cartQuantitySelector} from '../../../selectors'

import Badge from '@mui/material/Badge'
import BagIcon from '@mui/icons-material/LocalMall'
import IconButton from '@mui/material/IconButton'
import NotificationsIcon from '@mui/icons-material/Notifications'

// import Sidebar from '../../sidebar/Sidebar'
import CartMenu from '../cart/CartMenu'
import SearchBar from '../search-bar/SearchBar'
import SearchBarMobile from './SearchBarMobile'
import HeaderNotificationMenu from '../notifications/HeaderNotificationMenu'


import {CkordLogo} from '../../generics'



const cssStyles = ({isMobileSearchBar, isExtraSmallScreen}) => ({
  root: css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    background-color: #000;
    padding-left: ${isExtraSmallScreen ? '15px' : '20px'};
    padding-right: ${isExtraSmallScreen ? '15px' : '20px'};
    gap: ${isExtraSmallScreen ? '10px' : '25px'};
  `,
  leftSide: css`
    display: flex;
    align-items: center;
    gap: 5px;
  `,
  rightSide: css`
    display: flex;
    align-items: center;
  `,
})



const AuthenticatedHeaderMobile = props => {

  const navigate = useNavigate()

  const isMobileSearchBar = useMediaQuery('(max-width: 1200px)')
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  
  const styles = cssStyles({isExtraSmallScreen})


  const [cartAnchorEl, setCartAnchorEl] = useState(null)

  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null)

  
  const {Sidebar, showSearchModal, itemCount, unreadCount} = props




  function goHome() {
    navigate('/home')
  }



  function showCart(e) {
    setCartAnchorEl(e.currentTarget)
  }


  function showNotifications(e) {
    setNotificationAnchorEl(e.currentTarget)
  }



  return (
    <div css={styles.root}>
      <div css={styles.leftSide}>
        {Sidebar}



        {!isExtraSmallScreen &&
          <div css={styles.logo} onClick={goHome}>
            <CkordLogo style={{fontSize: '21px', color: '#fff', cursor: 'pointer'}} />
          </div>
        }
      </div>


      {!isMobileSearchBar ? 
        <SearchBar /> : 
        <SearchBarMobile showSearchModal={showSearchModal} />
      }


      <div css={styles.rightSide}>
        <IconButton onClick={showNotifications}>
          <Badge 
            sx={{
              '.MuiBadge-badge': {
                top: -1,
                right: -1,
                color: '#fff',
                backgroundColor: '#ff0055'
              }
            }}
            overlap='circular' 
            badgeContent={unreadCount} 
          >
            <NotificationsIcon color='secondary' />
          </Badge>
        </IconButton>


        <IconButton onClick={showCart}> 
          <Badge 
            sx={{
              '.MuiBadge-badge': {
                top: -1,
                right: -1,
                color: '#fff',
                backgroundColor: '#ff0055'
              }
            }}
            overlap='circular'
            badgeContent={itemCount}
          >
            <BagIcon color='secondary' />
          </Badge>
        </IconButton>
      </div>


      <HeaderNotificationMenu 
        anchorEl={notificationAnchorEl} 
        setAnchorEl={setNotificationAnchorEl} 
      />


      <CartMenu anchorEl={cartAnchorEl} setAnchorEl={setCartAnchorEl} />
    </div>
  )
}



const mapStateToProps = state => {
  return {
    itemCount: cartQuantitySelector(state),
    unreadCount: state.user.notifications.unread
  }
}



export default connect(mapStateToProps)(AuthenticatedHeaderMobile)

