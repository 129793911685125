import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchLikesOfType } from '../actions'



export function useFetchLikesOfType(type) {
  const pluralType = type + 's'
  const shouldFetchLikes = useSelector(state => !state.user.likes.fetched.includes(pluralType))
  const isFetching = useSelector(state => state.user.likes.isFetching)
  const isAuthenticated = useSelector(state => state.user.isAuthenticated)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuthenticated && shouldFetchLikes && !isFetching) {
      dispatch(fetchLikesOfType(pluralType))
    }
  }, [
    isAuthenticated, 
    shouldFetchLikes, 
    pluralType, 
    dispatch, 
    isFetching
  ])
}