import instance from './instance'



export function markAsRead(id) {
  return instance.patch(`notification/${id}/read`)
}


export function markNotificationsAsSeen(id) {
  return instance.patch(`notifications/seen`)
}