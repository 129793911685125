export function validate(file) {
  
  const size = file.size
  
  const result = {passed: true, message: ''}


  if (size > 300*1024*1024) {
    result.passed = false
    result.message = 'Maximum allowable video size is 300MB'
  }

  return result
}