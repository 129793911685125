import {mapKeys, mapValues, snakeCase, transform, isArray, isObject} from 'lodash'



export function snakeCaseObject(object) {
  return mapKeys(object, function(value, key) {
    return snakeCase(key)
  })
}


export function snakeCaseObjectValues(object) {
  return mapValues(object, function(value, key) {
    return snakeCase(value)
  })
}



export function snakeCaseNestedObject(object) { 
  return transform(object, (acc, value, key, target) => {
    const snakeCaseKey = isArray(target) ? key : snakeCase(key)

    acc[snakeCaseKey] = isObject(value) ? snakeCaseNestedObject(value) : value
  })
}


export default snakeCaseObject