// https://usehooks.com/
import {useState, useEffect} from 'react'

const cachedScripts = []
const loadingScripts = []



export function useScript(src, defer = false) {

  // Only used for Google services
  window.initMap = () => {
    setIsMapReady(true)
  }


  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false
  })


  const [isMapReady, setIsMapReady] = useState(cachedScripts.includes(src))





  useEffect(() => {
    // If cachedScripts array already includes src that means another instance ...
    // ... of this hook already loaded this script, so no need to load again.
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false
      })
    } else {
      // Create script
      let script
      let loading = false

      for (const loadingScript of loadingScripts) {
        if (loadingScript.src === src) {
          script = loadingScript
          loading = true
          break
        }
      }
      
      if (!loading) {
        script = document.createElement('script')
      
        script.src = src
        script.async = true
        script.defer = defer

        // Add script to document body
        document.body.appendChild(script)


        loadingScripts.push(script)
      } 

      // Script event listener callbacks for load and error
      const onScriptLoad = () => {
        cachedScripts.push(src)

        
        setState({
          loaded: true,
          error: false
        })
      }

      const onScriptError = () => {
        // Remove from cachedScripts we can try loading again
        const index = cachedScripts.indexOf(src)
        if (index >= 0) cachedScripts.splice(index, 1)
        script.remove()

        setState({
          loaded: true,
          error: true
        })
      }

      script.addEventListener('load', onScriptLoad)
      script.addEventListener('error', onScriptError)

      // Remove event listeners on cleanup
      return () => {
        script.removeEventListener('load', onScriptLoad)
        script.removeEventListener('error', onScriptError)
      }
    }
  }, [src, defer])

  return [state.loaded, state.error, isMapReady]
}



export default useScript


