import React, { useState, useMemo } from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {connect} from 'react-redux'
import {useNavigate, useLocation, Link} from 'react-router-dom'
import {useIsMounted} from '../../../hooks'
import {index} from '../../../apis'
import {debounce} from 'lodash'
import {startOfToday} from 'date-fns'
import {dateToUTC} from '../../../utils'
import {updateTagFilter, initiateSearch} from '../../../actions'

import Avatar from '@mui/material/Avatar'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

import {ReactComponent as MagnifyingGlassIcon} from '../../../svgs/MagnifyingGlass.svg'

import {PerformanceSearchResult} from '../../performance'





const cssStyles = props => ({
  startAdornment: css`
    margin: 0;
  `,
  iconButton: css`
    width: 40px;
    height: 40px;
    margin-left: 5px;
    margin-right: 5px;
  `,
  dropdown: css`
    margin-top: ${props.isHomePage ? '20px' : '5px'};
    padding-top: ${props.isHomePage ? '10px' : 0};
    padding-bottom: ${props.isHomePage ? '10px' : 0};
    min-height: 65px;
    width: ${props.isHomePage ? '401px' : null};
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    border-top-left-radius: 19px;

  `,
  textField: css`
    height: 100%;
    width: 100%;
  `,
  link: css`
    width: 100%;
    display: flex;
    wrap: nowrap;
    gap: 10px;
    align-items: center;
    text-decoration: none;
    color: #000;
    font-size: 0.93rem;
    letter-spacing: -0.01em;
  `,
  prediction: css`
    padding: 5px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1rem;
  `,
  groupingTitle: css`
    padding: 7px 10px;
    text-transform: uppercase;
    color: #262626;
    font-weight: 600;
    background-color: #e9e9e9;
    margin: 10px 0;
    font-size: 0.8rem;
  `
})





const GeneralSearch = props => { 

  const isMounted = useIsMounted()

  const location = useLocation()
  const navigate = useNavigate()

  const isHomePage = location.pathname === '/home'

  const styles = cssStyles({isHomePage})


  const [value, setValue] = useState(null)

  const [inputValue, setInputValue] = useState('')

  const [isFocused, setIsFocused] = useState(false)


  const {tags, updateTagFilter, initiateSearch, searchRef} = props
  

  // Individial prediction results
  const [tagPredictions, setTagPredictions] = useState([])
  const [performerPredictions, setPerformerPredictions] = useState([])
  const [performancePredictions, setPerformancePredictions] = useState([])
  const [venuePredictions, setVenuePredictions] = useState([])

  // All the above predictions get concantenated into one array thats 
  // fed to the Autocomplete component
  const predictions = [
    ...tagPredictions,
    ...venuePredictions,
    ...performerPredictions,
    ...performancePredictions,
  ]



  const fetchTagPredictions = useMemo(() => {
    return newValue => {
      index('search/tags', {query: newValue, limit: 4}).then(response => {
        if (isMounted.current) {
          setTagPredictions(response.data.data)
        }
      })
    }
  }, [isMounted])



  const fetchPerformancePredictions = useMemo(() => {
    return newValue => {
      const name = newValue
      const time = dateToUTC(startOfToday())

      index('search/performances/name', {name, time, limit: 3}).then(response => {
        if (isMounted.current) {
          setPerformancePredictions(response.data.data)
        }
      })
    }
  }, [isMounted])



  const fetchPerformerPredictions = useMemo(() => {
    return newValue => {
      index('search/performers', {name: newValue, limit: 3}).then(response => {
        if (isMounted.current) {
          setPerformerPredictions(response.data.data)
        }
      })
    }
  }, [isMounted])



  const fetchVenuePredictions = useMemo(() => {
    return newValue => {
      index('search/venues/name', {name: newValue, limit: 3}).then(response => {
        if (isMounted.current) {
          setVenuePredictions(response.data.data)
        }
      })
    }
  }, [isMounted])



  const debounceFetchTagPredictions = useMemo(
    () => debounce(fetchTagPredictions, 200), [fetchTagPredictions]
  )

  const debounceFetchPerformancePredictions = useMemo(
    () => debounce(fetchPerformancePredictions, 200), [fetchPerformancePredictions]
  )

  const debounceFetchPerformerPredictions = useMemo(
    () => debounce(fetchPerformerPredictions, 200), [fetchPerformerPredictions]
  )

  const debounceFetchVenuePredictions = useMemo(
    () => debounce(fetchVenuePredictions, 200), [fetchVenuePredictions]
  )



  function handleChange(e, newValue) {
    // If there's only one prediction and its not a tag, then
    // its most likely a user that wants to visit a venue, artist, or band
    if (e.key === 'Enter' && e.target.value) {
      if (predictions.length === 1 && predictions[0].type !== 'tag') {
        e.preventDefault()

        const prediction = predictions[0]

        const id = prediction.id
        const type = prediction.type
        
        const url = '/' + type + 's/' + id

        navigate(url)

        return
      }


      // Loop through the predictions and if the query has an 
      // exact match to a name then visit the profile
      for (const prediction of predictions) {
        if (prediction?.type === 'tag') {
            const {tag, alias_1, alias_2, alias_3} = prediction

            // If the query has an exact match to a tag, then break the loop 
            // and search using the last if statement in this function
            if ([tag, alias_1, alias_2, alias_3].includes(e.target.value)) {
              break
            }
        } else {
          const sanitizedName = prediction?.attributes?.name.toLowerCase().replace(/[^a-z0-9 ]/g, '')
          const sanitizedValue = e.target.value.toLowerCase().replace(/[^a-z0-9 ]/g, '')


          if (sanitizedName === sanitizedValue) {
            const id = prediction.id
            const type = prediction.type

            const url = '/' + type + 's/' + id

            navigate(url)

            return
          }
        }
      }
    }


    // Otherwise search using the query
    if (newValue) {
      if (typeof newValue === 'string' || newValue?.type === 'tag') {
        const tag = newValue?.attributes?.tag || newValue

        updateTagFilter(tag)
        initiateSearch()
        navigate('/home')
      }
    }

    setValue(newValue || '')
  }



  function handleInputChange(e, newInputValue) {
    setInputValue(newInputValue)

    debounceFetchTagPredictions(newInputValue)
    debounceFetchVenuePredictions(newInputValue)
    debounceFetchPerformerPredictions(newInputValue)
    debounceFetchPerformancePredictions(newInputValue)
  }



  function handleClear() {
    if (tags) {
      updateTagFilter('')
    }

    setValue('')
    setInputValue('')
    initiateSearch()
  }



  function handleGrouping(prediction) {
    return (prediction.type === 'tag') ? 'TAG / GENRE' : prediction.type
  }




  function getPredictionText(prediction) {
    if (typeof prediction === 'string') {
      return prediction
    } 
    
    return prediction.type === 'tag' ? prediction.attributes.tag : prediction.attributes.name
  }



  const handleFocusAndBlur = boolean => () => {
    setIsFocused(boolean)
  }





  return (
    <Autocomplete
      freeSolo
      sx={{
        width: '100%',
        height: '100%',
        '& .MuiAutocomplete-endAdornment': {
          marginRight: '10px'
        }
      }}
      onFocus={handleFocusAndBlur(true)}
      onBlur={handleFocusAndBlur(false)}
      getOptionLabel={getPredictionText}
      options={predictions}
      includeInputInList
      disableClearable
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      groupBy={handleGrouping}
      filterOptions={x => x}
      value={value}
      PaperComponent={params => <Paper {...params} css={styles.dropdown} />}
      ListboxProps={{style: {maxHeight: 700}}} 
      renderInput={params => (
        <TextField 
          {...params}
          css={styles.textField}
          inputRef={searchRef}
          variant='standard'
          placeholder={tags || 'Genre, artist, band...'}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            sx: {
              height: '100%',

              '& .MuiInputBase-input': {
                fontSize: '0.9rem'
              }
            },
            startAdornment: (
              <InputAdornment position='start' css={styles.startAdornment}>
                <IconButton css={styles.iconButton}>
                  <MagnifyingGlassIcon height='18px' width='18px' fill={isFocused ? 'rgb(62, 166, 255)' : '#000'} />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment 
                position='end' 
                css={styles.startAdornment}
                sx={{visibility: (tags || inputValue) ? 'visible' : 'hidden'}}
              >
                <IconButton onClick={handleClear} size='small'>
                  <ClearIcon fontSize='small' sx={{color: isFocused ? '#000' : '#7b7b7b'}} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
      renderGroup={props => (
        <div key={props.key}>
          <h4 css={styles.groupingTitle}>{props.group}</h4>
          
          {props.children}
        </div>
      )}
      renderOption={(props, prediction, {selected}) => (
        <div {...props} key={prediction.id} css={styles.prediction}>
          {(() => {
            switch (prediction.type) {
            case 'tag':
              return <p>{prediction?.attributes?.tag}</p>
            case 'performance':
              return <PerformanceSearchResult performance={prediction} />
            default:
              return (
                <Link to={`/${prediction.type}s/${prediction.id}`} css={styles.link}>
                  <Avatar 
                    css={styles.avatar}
                    sx={{width: 40, height: 40}}
                    alt={prediction.attributes.name} 
                    src={prediction.attributes.imageDerivatives.small}
                    variant={prediction.type === 'artist' ? 'circular' : 'rounded' } 
                  />
                  <p>{prediction.attributes.name}</p>
                </Link>
              )
            }
          })()}
        </div>
      )}
    />
  )
}



const mapStateToProps = state => {
  return {
    tags: state.search.filters.tags
  }
}


export default connect(mapStateToProps, {updateTagFilter, initiateSearch})(GeneralSearch)





