
const CkordLogo = ({style, ...rest}) => {
  return (
    <h5 
      style={{
        fontFamily: 'Gravity',
        fontStretch: 'expanded',
        letterSpacing: '-0.5px',
        lineHeight: '1',
        margin: '0px',
        ...style
      }}
      {...rest}
    >
      CKORD
    </h5>
  )
}



export default CkordLogo