import React from 'react'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'



const GroupedButton = ({tip, text, icon, onClick, size, ...rest}) => {

  return (
    <Tooltip title={tip}>
      <Button 
        onClick={onClick } 
        startIcon={icon}
        sx={{
          fontSize: 12,
          fontWeight: 600,
          textTransform: 'uppercase'
        }}
        {...rest}
      >
       {text}
      </Button>
    </Tooltip>
  )
}





export default GroupedButton