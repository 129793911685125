import {useEffect} from 'react'





export function useUTMParams() {


  useEffect(() => {
    const referrer = window.document.referrer

    const params = new URL(window.location).searchParams


    const utmSource = params.get('utm_source')
    const utmMedium = params.get('utm_medium')
    const utmCampaign = params.get('utm_campaign')



    sessionStorage.setItem('UTMSource', utmSource || referrer || null)
    sessionStorage.setItem('UTMMedium', utmMedium)
    sessionStorage.setItem('UTMCampaign', utmCampaign)
  }, [])


}


export default useUTMParams