import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {Field} from 'formik'

import InputAdornment from '@mui/material/InputAdornment'

import {FormikTextField, MoneyField} from '../../../generics'




const styles = {
  root: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #d9d9d9;

    @media (max-width: 800px) {
      padding: 30px;
    }
  `,
  header: css`
    margin-bottom: 20px;
    font-size: 15px;
    letter-spacing: 0;

    & > h2 {
      font-weight: 700;
    }
  `,
  body: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    max-width: 500px;
  `
}



const MerchantProductFormDetails = props => {
  

  const {values, setFieldValue, handleBlur} = props



  const trimValue = (value, field) => e => {
    handleBlur(e)

    if (typeof value === 'string' || value instanceof String) {
      const trimmed = value.trim()

      if (trimmed !== value && values[field]) {
        setFieldValue(field, trimmed)
      }
    }
  }



  return (
    <div css={styles.root}>
      <div css={styles.header}>
        <h2>Product Details</h2>
        <p>Tell people about your product and why it's great</p>
      </div>
      

      <div css={styles.body}>
        <Field
          fullWidth
          name='name' 
          label='Product Name'
          variant='filled'
          autoComplete='off'
          component={FormikTextField}
          onBlur={trimValue(values.name, 'name')}
        />

        <Field
          fullWidth
          name='caption' 
          label='Caption'
          variant='filled'
          autoComplete='off'
          component={FormikTextField}
          helperText={(50 - values.caption.length).toString() + ' characters left'}
          onBlur={trimValue(values.name, 'caption')}
        />


        <Field
          fullWidth
          rows={12}
          multiline={true}
          variant='filled'
          component={FormikTextField}
          name='description'
          label='Product Description'
          helperText={(3500 - values.description.length).toLocaleString() + ' characters left'}
          onBlur={trimValue(values.description, 'description')}
          sx={{'.MuiInputBase-root': {padding: 0}}}
        />


        <Field
          fullWidth
          name='price'
          label='Price'
          variant='filled'
          component={MoneyField}
          startAdornment={<InputAdornment position='start'>$</InputAdornment>}
        />
      </div>
    </div>
  )
}



export default MerchantProductFormDetails


