import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {cartQuantitySelector} from '../../selectors'

import Badge from '@mui/material/Badge'
import BagIcon from '@mui/icons-material/LocalMall'
import IconButton from '@mui/material/IconButton'

import CartMenu from './cart/CartMenu'
import SearchBar from './search-bar/SearchBar'

import {CkordLogo} from '../generics'




const cssStyles = props => ({
  root: css`
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    gap: 25px;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
  `,
  leftSide: css`
    display: flex;
    align-items: center;
    gap: 5px;

    .logoContainer {
      padding-bottom: 5px;
    }
  `,
  rightSide: css`
    display: flex;
    gap: 5px;
    align-items: center;
  `,
  menu: css`
    display: flex;
    flex-wrap: nowrap;
  `,
  item: css`
    color: #fff;
    width: auto;
    font-weight: 400;
    white-space: nowrap;
  `,
  logo: css`
    cursor: pointer;
  `
})




const UnauthenticatedHeader = props => {

  const navigate = useNavigate()

  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.only('md'))

  const styles = cssStyles({isMediumScreen})

  const [anchorEl, setAnchorEl] = useState(null)


  const {Sidebar, itemCount} = props
  


  function goHome() {
    navigate('/home')
  }



  function showCart(e) {
    setAnchorEl(e.currentTarget)
  }



  return (
    <div css={styles.root}>
      <div css={styles.leftSide}>
        {Sidebar}

        <div onClick={goHome}>
          <CkordLogo style={{color: '#fff', fontSize: '21px', cursor: 'pointer'}} />
        </div>
      </div>

      <SearchBar />

      <div css={styles.rightSide}>
        <IconButton onClick={showCart}> 
          <Badge
            sx={{
              '.MuiBadge-badge': {
                top: -1,
                right: -1,
                color: '#fff',
                backgroundColor: '#ff0055'
              }
            }}
            overlap='circular' 
            badgeContent={itemCount} 
          >
            <BagIcon fontSize='medium' color='secondary' />
          </Badge>
        </IconButton>
      </div>


      <CartMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </div>
  )
}



const mapStateToProps = state => {
  return { 
    itemCount: cartQuantitySelector(state)
  }
}



export default connect(mapStateToProps)(UnauthenticatedHeader)


