
function decimalFormatter(locale) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
}


function integerFormatter(locale) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })
}



export function validateCurrency(value) {
  return value.toString().match(/^(\$?\d{1,3}(?:,?\d{3})*(?:\.\d{2})?|\.\d{2})?$/)
}



export function formatMoney(number = 0, locale = 'en-US', params = {}) {

  const {displayFreeForZeroValues} = params


  if (number <= 0 && displayFreeForZeroValues) {
    return 'Free'
  } else if (number % 1 === 0) {
    return integerFormatter(locale).format(number)
  } else {
    return decimalFormatter(locale).format(number)
  }
}



export function formatInputToCurrency(value, lengthLimit = 10) {
  if (!value) {
    return ''
  }

  // Remove commas
  value = value.replace(/,/g, '')

  // Ensure that the number does not pass the character limit
  if (value.length > lengthLimit) {
    value = value.slice(0, -1)
  }

  // Prevent users from using more than one decimal
  const decimalCount = value.match(/\./g)?.length

  if (decimalCount && decimalCount > 1) {
    value = value.slice(0, -1)
  }
  

  // Split the value by decimal into an array with two values
  let parsed = value.match(/\d+\.?/g)

  if (parsed) {
    if (parsed.length === 2) {
      if (parsed[1].length > 2) {
        const tenth = parsed[1][0]
        const thousandths = parsed[1][2]

        parsed[1] = tenth + thousandths
      }
    }


    // Format the value 
    if (parsed[0].length > 3) {
      if (parsed[0].endsWith('.')) {
        parsed[0] = formatMoney(parsed[0].slice(0, -1)).replace('$', '') + '.'
      } else {
        parsed[0] = formatMoney(parsed[0]).replace('$', '')
      }
    }


    return parsed.join('')
  } 
    
  return ''
}





