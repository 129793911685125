import { combineReducers } from 'redux'
import { filter, omit, map, uniq } from 'lodash'
import normalize from 'json-api-normalizer'

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'NOTIFICATIONS_INDEX_SUCCESS':
      if (action.payload.data.length) {
        return { ...state,
          ...normalize(action.payload).notification
        }
      } else return state
    case 'NOTIFICATION_SHOW_SUCCESS':
    case 'NOTIFICATION_UPDATE_SUCCESS':
    case 'NOTIFICATION_CREATE_SUCCESS':
      return { ...state,
        ...normalize(action.payload).notification
      }
    case 'NOTIFICATION_DESTROY_SUCCESS':
      return omit(state, action.payload.data.id)
    case 'NOTIFICATION_READ':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          attributes: {
            ...state[action.payload.id].attributes,
            status: 'read'
          }
        }
      }
    case 'NOTIFICATIONS_SEEN':
      const newState = { ...state }

      for (const key in newState) {
        if (newState[key].attributes.status === 'unread') {
          newState[key].attributes.status = 'seen'
        }
      }

      return newState
    case 'INVITATION_NOTIFICATION_RESPONSE_SUCCESS':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          attributes: {
            ...state[action.payload.id].attributes,
            message: action.payload.message,
            details: {
              ...state[action.payload.id].attributes.details,
              accepted: action.payload.accepted
            }
          }
        }
      }
    case 'NOTIFICATION_RESET_STATE':
      return {}
    default:
      return state
  }
}

const all = (state = [], action) => {
  switch (action.type) {
    case 'NOTIFICATIONS_INDEX_SUCCESS':
      return uniq([...state, ...map(action.payload.data, 'id')])
    case 'NOTIFICATION_SHOW_SUCCESS':
    case 'NOTIFICATION_CREATE_SUCCESS':
      return [...state, action.payload.data.id]
    case 'NOTIFICATION_DESTROY_SUCCESS':
      return filter(state, (e) => e !== action.payload.data.id)
    case 'NOTIFICATION_RESET_STATE':
      return []
    default:
      return state
  }
}

const unread = (state = 0, action) => {
  switch (action.type) {
  case 'NOTIFICATIONS_INDEX_SUCCESS':
    return action.payload.meta.unread
  case 'NOTIFICATION_READ':
    return Math.max(0, state - 1)
  case 'NOTIFICATIONS_SEEN':
    return 0
  default:
    return state
  }
}


const links = (state = {}, action) => {
  switch (action.type) {
  case 'NOTIFICATIONS_SAVE_LINKS':
    return action.payload.links
  default:
    return state
  }
}


export default combineReducers({
  byId,
  all,
  unread,
  links
})

