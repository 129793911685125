import React, {useCallback, useEffect, useRef} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {useNavigate, useLocation} from 'react-router-dom'

import {initiateSearch} from '../../../actions'

import GeneralSearch from './GeneralSearch'
import LocationSearch from './LocationSearch'




const styles = {
  root: css`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 38px;
    border-radius: 19px;
    max-width: 900px;
  `,
  line: css`
    width: 1px;
    height: 15px;
    display: inline-block; 
  `,
  button: css`
    margin: 2px 2px 2px 5px;
    width: 42px;
    height: 36px;
    border-radius: 18px;
    border: 1px solid black;
    background-color: #f0f0f0;
    font-weight: 500;
    padding: 0;
    min-width: 46px;
  `
}


const SearchBar = props => {

  const searchRef = useRef()

  const locationRef = useRef(null)


  const navigate = useNavigate()

  const routerLocation = useLocation()


  const {filters, isFetching} = props

  const {tags, location} = filters



  const goHome = useCallback(() => {
    if (routerLocation.pathname !== '/home') {
      navigate('/home')
    }
  }, [routerLocation, navigate])



  useEffect(() => {
    if (isFetching && tags) {
      goHome()
    }
  }, [isFetching, tags, goHome])



  useEffect(() => {
    if (location.id && locationRef.current === null) {
      locationRef.current = location.id
    }
  }, [location.id, locationRef]) 



  //  Currently not in use:
  //  The below shows a button on the SearchBar and contains the function
  //  for when the button is pressed
  //  -----------------------------------------------------------------------
  //  {isButtonShowing &&
  //    <Button css={styles.button} variant='outlined' onClick={handleClick}>
  //      GO
  //    </Button>
  //  }

  // function handleClick() { 
  //   if (searchRef.current && searchRef.current.value) {
  //     const newValue = searchRef.current.value

  //     if (newValue !== tags) {
  //       updateTagFilter({ tags: newValue, update: true })
  //       goHome()
  //       return 
  //     }
  //   }

  //   if (searchRef.current && searchRef.current.value === '') {
  //     if (locationRef.current !== location.id) {
  //       initiateSearch()
  //       goHome()
  //     }
  //   }
  // }
  // -----------------------------------------------------------------------



  return (
    <div css={styles.root}>
      <GeneralSearch searchRef={ searchRef } />

      <hr css={styles.line} color='#dedede'/>

      <LocationSearch />
    </div>
  )
}



const mapStateToProps = state => {
  return {
    filters: state.search.filters,
    isFetching: state.search.status === 'fetching'
  }
}


const actions = {initiateSearch}


export default connect(mapStateToProps, actions)(SearchBar)






