import {combineReducers} from 'redux'

import normalize from 'json-api-normalizer'



const byId = (state = {}, action) => {
  switch (action.type) {
    case 'SHOP_UPDATE_SUCCESS':
    case 'SHOP_SHOW_SUCCESS':
      const normalized = normalize(action.payload).shop

      return {...state, ...normalized}
    default:
      return state
  }
}



const all = (state = [], action) => {
  switch (action.type) {
    case 'SHOP_SHOW_SUCCESS':
      return [...new Set([...state, action.payload.data.id])]
    default:
      return state
  }
}





export default combineReducers({byId, all})