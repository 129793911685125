import React, { useEffect, useState } from 'react'
import { searchPerformers, index } from '../../../apis'
import { map, get } from 'lodash'
import { useIsMounted } from '../../../hooks'
import withSearchAutocomplete from '../../hocs/withSearchAutocomplete'
import { InvitationListItem } from '../../generics'
import List from '@mui/material/List'

const FindPerformers = props => {
  const isMounted = useIsMounted()
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [sentInvites, setSentInvites] = useState({})
  const { suggestions } = props
  const { performanceId, performers } = props.passedProps
  const performerIds = map(performers, 'id')


  useEffect(() => {
    if (isLoaded) return
    else {
      setIsLoaded(true)
      index('/performances/' + performanceId + '/sent-invitations')
        .then(response => {
          if (isMounted.current && response.data) {
            setSentInvites(normalizeResponse(response.data.data))
          }
        })
        .catch(error => setError(true))
    }
  }, [isMounted, isLoaded, performanceId])


  function normalizeResponse(sentInvitations) {
    return sentInvitations.reduce((acc, value) => {
      const invitee = get(value, 'relationships.invitee.data', {})
      acc[invitee.id] = invitee.type 
      return acc
    }, {})
  }



  return (
    <List>
      { isLoaded && !error && suggestions.map((suggestion, index) => {
          const sentInviteType = sentInvites[suggestion.id] || ''
          const member = performerIds.includes(suggestion.id)
          const disabled = sentInviteType.toLowerCase() === suggestion.type             
          
          return (
            <InvitationListItem
              key={ suggestion.id }
              suggestion={ suggestion }
              index= { index } 
              inviter='performance'
              inviterId={ performanceId }
              disabled={ member ? member : disabled }
              member={ member } 
            />
          )
        })
      }
      { error && 
          <div style={{ marginLeft: 15, fontStyle: 'italic' }}>
            Something went wrong
          </div> 
      }
    </List>
  )
}

export default withSearchAutocomplete(FindPerformers, searchPerformers)
