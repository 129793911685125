
const INITIAL_STATE = {}


const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_USER':
      return action.payload.attributes.settings 
    case 'USER_SETTINGS_UPDATE_SUCCESS':
      return {...state, ...action.payload}
    case 'DELETE_USER':
      return INITIAL_STATE
    default:
      return state
  }
}



export default settingsReducer
