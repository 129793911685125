import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'


import Dialog from '@mui/material/Dialog'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'

import FadeTransition from '../FadeTransition'
import SlideTransition from '../SlideTransition'



const cssStyles = ({color}) => ({
  menuItem: css`
    color: ${color ? color : 'rgba(0, 0, 0, 0.54)'};
    
    svg {
      width: 24px;
      margin-right: 10px;
    }

    span {
      color: #434343;
    }
  `
})




const EditIconButton = props => {

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))
  const isLessThanLargeScreen = useMediaQuery(theme => theme.breakpoints.down('lg'))
  const isLessThanMediumScreen = useMediaQuery(theme => theme.breakpoints.down('md'))


  const {
    type,
    open,
    size, 
    color,
    setOpen, 
    variant, 
    maxWidth,
    fullWidth,
    children, 
    ...rest
  } = props



  const styles = cssStyles({color})

  const iconSize = size || (isExtraSmallScreen ? 'small' : 'medium')




  function handleClick() {
    setOpen(true)
  }



  function handleClose() {
    setOpen(false)
  }




  return (
    <>
      {variant === 'menuItem' && 
        <MenuItem onClick={handleClick} css={styles.menuItem} {...rest}>
          <EditIcon fontSize={iconSize || 'medium'} />
          <span>Edit</span>
        </MenuItem>
      }

      {(variant === 'button' || !variant) &&
        <IconButton onClick={handleClick} size={iconSize} {...rest}>
          <EditIcon fontSize={iconSize} style={{color: (color || '#fff')}} />
        </IconButton>
      }


      <Dialog 
        open={open}
        scroll='body'
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              maxWidth: type === 'performance' ? '1200px' : '1050px'
            }
          }
        }}
        onClose={handleClose}
        fullWidth={type === 'performance' && !isLessThanLargeScreen}
        fullScreen={type === 'performance' ? isLessThanMediumScreen : isSmallScreen}
        TransitionComponent={isLessThanMediumScreen ? SlideTransition : FadeTransition}
      >
        {children}
      </Dialog>
    </>
  )
}


export default EditIconButton



