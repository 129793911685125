import React, { useEffect } from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {useTheme} from '@mui/styles'

import {pick} from 'lodash'

import {NavLink} from 'react-router-dom'


import { 
  destroy, 
  changeNotificationStatusToRead,
  changeNotificationStatusesToSeen
} from '../../../actions'


import Menu from '@mui/material/Menu'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'


import HeaderOrderNotification from './HeaderOrderNotification'
import HeaderMessageNotification from './HeaderMessageNotification'
import HeaderInvitationNotifications from './HeaderInvitationNotification'




const cssStyles = ({theme}) => ({
  menu: css`
    padding: 0 10px;
    max-width: 100%;
    width: 475px;
    min-height: 75px;
  `,
  item: css`
    position: relative;
    min-height: 70px;
    padding: 10px 10px;
    margin-top: 7px;
    margin-bottom: 7px;
    cursor: pointer;
    border-radius: 4px;
    border-bottom: 1px solid ${theme.palette.secondary._300};
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;

    h1 {
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 5px;
      margin-top: 5px;
    }

    p {
      letter-spacing: 0rem;
    }

    span {
      font-size: 0.7rem;
      letter-spacing: 0rem;
      font-weight: 500;
      color: ${theme.palette.primary._400};
    }

    &:hover {
      background-color: ${theme.palette.secondary._200}
    }
  `,
  circle: css`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    flex-shrink: 0;
    position: absolute;
    top: 15px;
    left: 5px;
  `,
  button: css`
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
  `,
  deleteIcon: css`
    width: 15px;
    height: 15px
  `,
  link: css`
    width: 100%;
    color: rgb(62, 166, 255);
    text-decoration: none;

    p {
      font-weight: 500;
      font-size: 0.8rem;
      color: rgb(62, 166, 255);
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `
})




const HeaderNotificationMenu = props => {
  
  const theme = useTheme()

  const styles = cssStyles({theme})


  const {
    anchorEl, 
    setAnchorEl,
    unreadCount,
    destroy,
    hasNextPage,
    notifications,
    changeNotificationStatusToRead,
    changeNotificationStatusesToSeen
  } = props




  useEffect(() => {
    if (Boolean(unreadCount) && Boolean(anchorEl)) {
      changeNotificationStatusesToSeen()
    }
  }, [unreadCount, anchorEl, changeNotificationStatusesToSeen])




  const handleDelete = id => e => {
    e.stopPropagation()
    destroy('notifications/' + id, 'NOTIFICATION')
  }



  const markAsRead = notification => () => {
    if (notification.attributes.status !== 'read') {
      changeNotificationStatusToRead(notification.id)
    }
  }



  function handleClose() {
    setAnchorEl(null)
  }





  const Notification = ({notification}) => {
    switch (notification.attributes.category) {
      case 'invite':
        return <HeaderInvitationNotifications notification={notification} />
      case 'message':
        return <HeaderMessageNotification notification={notification} />
      case 'order':
        return <HeaderOrderNotification notification={notification} />
      default: 
        return null
    }
  }




  return (
    <Menu
      elevation={5}
      anchorEl={anchorEl}
      keepMounted={true}
      disablePortal={true}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      disableScrollLock={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <List css={styles.menu}>
        {Object.values(notifications).map(notification => {
          const key = notification.id
          const status = notification.attributes.status


          return (
            <ListItem key={key} css={styles.item} onClick={markAsRead(notification)}>
              <span 
                css={styles.circle} 
                style={{
                  display: status === 'read' ? 'none' : 'block',
                  backgroundColor: theme.palette.pink._500,
                  zIndex: 10
                }}
              />

              <Notification notification={notification} />
              
              <IconButton css={styles.button} onClick={handleDelete(notification.id)} size='large'>
                <CloseIcon css={styles.deleteIcon} color='disabled' />
              </IconButton>
            </ListItem>
          )
        })}

        {hasNextPage && 
          <NavLink css={styles.link} to='/notifications'>
            <p>MORE</p>
          </NavLink>
        }
      </List>
    </Menu>
  )
}



const mapStateToProps = (state, props) => {

  const links = state.user.notifications?.links || {}

  const threshold = 7

  const hasNextPage = links.count > threshold

  const notificationIds = state.user.notifications.all.slice(0, threshold)


  return {
    unreadCount: state.user.notifications.unread,
    hasNextPage: hasNextPage,
    notifications: pick(state.user.notifications.byId, notificationIds)
  }
}



const actions = { 
  destroy, 
  changeNotificationStatusToRead,
  changeNotificationStatusesToSeen
}



export default connect(mapStateToProps, actions)(HeaderNotificationMenu)







