export function extractArtistID(url) {
    // Regular expression to match Spotify artist URLs
    const artistIdRegex = /https:\/\/open\.spotify\.com\/artist\/([a-zA-Z0-9]+)/

    // Test the URL against the regular expression
    const match = url.match(artistIdRegex)

    // If there's a match, return the artist ID; otherwise, return null
    return match ? match[1] : null
}


export default extractArtistID