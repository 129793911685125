import {createSelector} from 'reselect'


const selectFilters = state => state.search.filters
const selectTagFilter = state => state.search.filters.tags
const selectTimeFilter = state => state.search.filters.time
const selectPriceFilter = state => state.search.filters.price
const selectLocationFilter = state => state.search.filters.location
const selectSearchTypeFilter = state => state.search.filters.types


export const filterSelector = createSelector(selectFilters, filters => filters)
export const filterTagSelector = createSelector(selectTagFilter, filter => filter)
export const filterTimeSelector = createSelector(selectTimeFilter, filter => filter)
export const filterPriceSelector = createSelector(selectPriceFilter, filter => filter)
export const filterLocationSelector = createSelector(selectLocationFilter, filter => filter)
export const filterSearchTypeSelector = createSelector(selectSearchTypeFilter, filter => filter)



// Venue filters
const selectVenueFilters = state => ({
  tags: state.search.filters.tags,
  location: state.search.filters.location
})

 
export const filterVenueSelector = createSelector(selectVenueFilters, filters => filters,
  {
    memoizeOptions: {
      equalityCheck: (a, b) => {
        return (
          a.tags === b.tags &&
          a.location.lat === b.location.lat &&
          a.location.lng === b.location.lng &&
          a.location.radius === b.location.radius
        )
      }
    }
  }
)