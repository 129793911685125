import {v4 as uuidv4} from 'uuid'

import heic2any from 'heic2any'



export function createImageObject(image, imageDerivatives = null) {
  return {
    id: 'tmp_' + uuidv4(),
    type: 'image',
    attributes: {
      image: image,
      imageDerivatives: imageDerivatives || {
        small: image, 
        medium: image,
        large: image
      }
    }
  }
}




export async function convertHEICToJPEG(file, name = 'converted-heic-image.jpeg') {
  if (!file) return null

  let result

  try {
    result = await heic2any({blob: file, toType: 'image/jpeg', quality: 0.8})
  } catch (error) {
    throw error
  }

  return new File([result], name, {type: result.type})
}