import React from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'

import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'




const MoreIconButton = ({onClick, color, size, ...rest}) => {
  
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const iconSize = size || (isExtraSmallScreen ? 'small' : 'medium')


  return (
    <IconButton onClick={onClick} size={iconSize} {...rest}>
      <MoreVertIcon fontSize={iconSize} />
    </IconButton>
  )
}



export default MoreIconButton