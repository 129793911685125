import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import ExpandingText from '../ExpandingText'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
    marginBottom: 30
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontWeight: 500,
    fontSize: props => props.isExtraSmallScreen ? '0.9rem' : '1rem'
  }
}))



const InfoCategory = props => {
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const classes = useStyles({ isExtraSmallScreen })
  const { title, text } = props


  return (
    <div className={ classes.root }>
      <div className={ classes.contentContainer }>
        <div>
          { title && 
              <Typography className={ classes.title }>
                { title }
              </Typography>
          }

          { text &&
              <ExpandingText 
                text={ text }
                component='pre'
                sx={{ 
                  whiteSpace:'pre-wrap',
                  wordWrap: 'break-word',
                  maxWidth: '100%',
                  fontWeight: 400
                }}>
                { text }
              </ExpandingText>
          }
        </div>
      </div>
    </div>
  )
}


export default InfoCategory
