import {useState} from 'react'

import {useSwipeable} from 'react-swipeable'

import Next from '@mui/icons-material/SkipNext'
import Pause from '@mui/icons-material/Pause'
import Dialog from '@mui/material/Dialog'
import Previous from '@mui/icons-material/SkipPrevious'
import PlayArrow from '@mui/icons-material/PlayArrow'
import IconButton from '@mui/material/IconButton'


import {PiSpotifyLogoFill} from 'react-icons/pi'

import MediaPlayerExpanded from './MediaPlayerExpanded'

import {Image, SlideTransition, FavoriteIconButton} from '../generics'

import styles from '../../styles/media-player/mediaPlayerMobile.module.scss'




const MediaPlayerMobile = props => {
  
  const [isExpanded, setIsExpanded] = useState(false)
  

  const {
    track,
    volume,
    setVolume,
    imageRef,
    audioRef,
    isPlaying,
    isMuted,
    setIsMuted,
    handleClose,
    playNextTrack,
    handlePlayClick,
    playPreviousTrack,
    fetchCkordIdAndType
  } = props


  const {
    name, 
    image, 
    composer, 
    isVideoTrack,
    isSpotifyTrack,
    imageDerivatives 
  } = track?.attributes || {} 



  const handlers = useSwipeable({
    onSwipedUp: expandPlayer,
    onSwipedDown: handleClose
  })



  function expandPlayer() {
    setIsExpanded(true)
  }



  function minimizePlayer() {
    setIsExpanded(false)

    if (isVideoTrack) {
      playNextTrack()
    }
  }




  return (
    <div className={styles.root} {...handlers}>      
      <div className={styles.imageContainer}>
        <Image 
          id='audio-player-image'
          ref={imageRef}
          src={imageDerivatives.small || image} 
          alt='track' 
          className={styles.image}
        />

        {isSpotifyTrack &&
          <PiSpotifyLogoFill className={styles.spotifyIcon} /> 
        }
      </div>


      <div className={styles.infoContainer} onClick={expandPlayer}>
        <h3>{name}</h3>
        
        <p>{composer}</p>
      </div>



      {!isSpotifyTrack &&
        <FavoriteIconButton
          id={track.id}
          type='track'
          size='small'
          color='rgba(0, 0, 0, 0.54)'
         />
      }


      <div className={styles.controls}>
        <IconButton
          size='large'
          onClick={playPreviousTrack}
          className={styles.button}
        >
          <Previous color='primary' />
        </IconButton>


        <IconButton
          size='large'
          className={styles.playButton} 
          onClick={handlePlayClick}
        >
          {isPlaying 
            ? <Pause fontSize='small' /> 
            : <PlayArrow fontSize='small' />
          }
        </IconButton>


        <IconButton 
          size='large'
          className={styles.button} 
          onClick={playNextTrack}
        >
          <Next color='primary' />
        </IconButton>
      </div>



      <Dialog 
        open={isExpanded}
        fullScreen={true}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        sx={{
         '& .MuiDialog-container': {
            maxHeight: '100%',
            height: '100%',
            overflow: 'hidden',

            '& .MuiPaper-root': {                    
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxHeight: '100%',
              height: '100%',
              overflow: 'hidden'
            }
          }
        }}>
        <MediaPlayerExpanded
          track={track}
          imageRef={imageRef}
          audioRef={audioRef}
          isPlaying={isPlaying}
          isMuted={isMuted}
          volume={volume}
          setVolume={setVolume}
          setIsMuted={setIsMuted}
          handleClose={handleClose}
          minimizePlayer={minimizePlayer}
          isMobileDevice={true}
          playNextTrack={playNextTrack}
          handlePlayClick={handlePlayClick}
          playPreviousTrack={playPreviousTrack}
          fetchCkordIdAndType={fetchCkordIdAndType}
        />
      </Dialog>
    </div>
  )
}




export default MediaPlayerMobile




