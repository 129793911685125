export function notSubset(set, superset) {
  if (set && set.length) {
    for (const value of set) {
      if (!superset.includes(value)) return true
    }
  }

  return false
}


export function subset(set, superset) {
  if (set && set.length) {
    for (const value of set) {
      if (!superset.includes(value)) return false
    }
  }
  
  return true
}


export function difference(arr1, arr2) {
  return arr1.filter(x => !arr2.includes(x))
}