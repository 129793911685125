
export function showAlert(message, variant = 'standard', action) {
  message = Array.isArray(message) ? message[0] : message

  return dispatch => {
    dispatch({type: 'SHOW_ALERT', payload: {message, variant, action}})
  }
}


export function deleteAlert() {
  return dispatch => dispatch({type: 'DELETE_ALERT'})
}