import {useState, useEffect, useRef, useCallback} from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {useSearchParams, useLocation} from 'react-router-dom'

import {useScrollPosition} from '../../hooks'

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Dialog from '@mui/material/Dialog'

import CloseIcon from '@mui/icons-material/Close'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'


import {AspectImage} from '../generics'


import styles from '../../styles/detail-pages/detailPage.module.scss'




function TabPanel(props) {

  const [isMounted, setIsMounted] = useState(false)

  const {children, value, index, ...other} = props


  useEffect(() => {
    if (!isMounted && value === index) {
      setIsMounted(true)
    }
  }, [isMounted, value, index])



  return (
    <div
      id={`nav-tabpanel-${index}`}
      role='tabpanel'
      hidden={value !== index}
      style={{width: '100%', height: '100%'}}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {isMounted && children}
    </div>
  )
}




let scrollPositions = window.sessionStorage.getItem('scrollPositions') || {}
scrollPositions = (typeof scrollPositions === 'string') ? JSON.parse(scrollPositions) : scrollPositions 



const DetailPage = props => {

  const topRef = useRef()
  const rootRef = useRef()
  const tabsRef = useRef()

  const controlsRef = useRef()
  const expandButtonRef = useRef()

  const videoRef = useRef()
  const videoAspectRef = useRef()
  const closeButtonRef = useRef()

  // Use to load new video if user updates the current one
  const currentVideoURL = useRef(false)

  // Image dialog
  const [open, setOpen] = useState(false)


  // Video parameters
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [didPlayOnce, setDidPlayOnce] = useState(false)


  // Used to save the scroll position when navigating away
  const location = useLocation()
  const locationId = location.key
  const scrollPosition = useScrollPosition()


  const isLargeScreen = useMediaQuery('(min-width:1121px)')
  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down('lg'))
  const isExtraSmallScreen = useMediaQuery('(max-width:768px)')


  const {
    type,
    image,
    imageSrcset,
    video,
    originalImage,
    Broadcast,
    information, 
    tab1,
    tab1Label, 
    tab2,
    tab2Label,
    tab3,
    tab3Label,
    tab4,
    tab4Label,
    tab5,
    tab5Label
  } = props



  const tabs = [tab1, tab2, tab3, tab4, tab5].filter(tab => !!tab)
  const tabLabels = [tab1Label, tab2Label, tab3Label, tab4Label, tab5Label].filter(label => !!label)
  
  // Used to set the aspect ratio of image and video container
  const aspect = ('performance' === type) ? '2:3' : '1:1'

  // A padding top percentage of 177.7% is an aspect ratio of 9:16
  const paddingTop = ('performance' === type) ? '177.7%' : '100%'


  // Used to set the active tab
  const [searchParams, setSearchParams] = useSearchParams()
  
  const initialTab = parseInt(searchParams.get('tab'))

  const [tab, setTab] = useState(initialTab || 0)
  

  // Need to set the initial height to whatever the scroll position was plus the window height so that
  // when a user hits back it goes to where they left off and it also prevent blinking on updates
  const [minHeight, setMinHeight] = useState(
    scrollPositions[locationId] ? (scrollPositions[locationId] + window.innerHeight) : 0
  )


  // The minimum height should never go below this value
  const [absoluteMinimum, setAbsoluteMinimum] = useState(0)




  const hideVideo = useCallback(() => {
    if (!isPlaying || !video) return

    const button = closeButtonRef.current
    const controls = controlsRef.current
    const aspectBox = videoAspectRef.current
    const expandButton = expandButtonRef.current
    const videoElement = videoRef.current

    const videoContainer = document.getElementById('video-container')

    videoElement.pause()
    videoElement.currentTime = 0
    videoElement.style.transform = 'scale(0)'


    if (!(type === 'performance')) {
      aspectBox.style.paddingTop = '100%'
      videoContainer.style.height = `${videoContainer.offsetWidth}px`
    } 
    

    button.style.opacity = '0'


    if (controls) {
      controls.style.opacity = 0
      controls.style.display = 'none'
      expandButton.style.transform = 'rotate(0deg)'

      setIsExpanded(false)
    }
  }, [type, video, isPlaying])




  useEffect(() => {
    if (currentVideoURL.current === video || currentVideoURL.current === false) {
      // Skip the effect on the initial render
      currentVideoURL.current = ''
      return
    }

    if (!video) return

    currentVideoURL.current = video

    const videoElement = videoRef.current

    // Load the new video if there is a new one
    videoElement.load()
    videoElement.style.transform = 'scale(1)'
    videoElement.play().then(() => {
      setIsPlaying(true)
    })
    .catch(error => console.log('visibility change'))

    videoElement.addEventListener('ended', hideVideo, {once: true})
  }, [video, hideVideo])



  // Show the video controls on play
  useEffect(() => {
    const videoElement = videoRef.current
    const controls = controlsRef.current



    function showControls() {
      if (controls) {
        controls.style.opacity = 1
        controls.style.display = 'block'
      }
    }


    function updateMute() {
      setIsMuted(videoElement.volume < 0.1 || videoElement.muted)
    }


    videoElement?.addEventListener('play', showControls)
    videoElement?.addEventListener('volumechange', updateMute)

    
    return () => {
      videoElement?.removeEventListener('play', showControls)
      videoElement?.removeEventListener('volumechange', updateMute)
    }
  }, [video])




  // If there is a video, then play it once after a short delay
  useEffect(() => {
    if (didPlayOnce) return

    const videoElement = videoRef.current
      

    if (video)  {
      setTimeout(() => {
        videoElement.style.transform = 'scale(1)'
        videoElement.play().then(() => {
          setIsPlaying(true)
          setDidPlayOnce(true)
        })
        .catch(error => console.log('visibility change'))
      }, [3000])


      videoElement.addEventListener('ended', hideVideo, {once: true})
    }
  }, [didPlayOnce, hideVideo, video])




  // Set the inital tab
  useEffect(() => {
    if (initialTab < tabs.length) {
      setTab(initialTab)
    }
  }, [initialTab, tabs.length, setTab])




  // Calcualte the absolute minimum
  useEffect(() => {
    if (tabsRef.current && topRef.current) {
      const header = document.getElementById('header')

      const rect = tabsRef.current.getBoundingClientRect()
      const value = window.innerHeight + rect.top - header.offsetHeight


      setAbsoluteMinimum(value)
      setMinHeight(prev => `${Math.max(value, prev)}px`)
    }
  }, [topRef, tabsRef, setMinHeight, setAbsoluteMinimum])




  // Store the scroll position
  useEffect(() => {
    scrollPositions[locationId] = window.pageYOffset 
    window.sessionStorage.setItem('scrollPositions', JSON.stringify(scrollPositions))
  }, [locationId, scrollPosition])




  function handleTabChange(e, newValue) {
    setTab(newValue)
    setMinHeight(absoluteMinimum)
    setSearchParams({tab: newValue}, {replace: true})
  }




  function show() {
    setOpen(true)
  }



  function close() {
    setOpen(false)
  }



  function toggleExpand(e) {
    e?.stopPropagation()

    const aspectBox = videoAspectRef.current
    const videoContainer = document.getElementById('video-container')

    const width = videoContainer.offsetWidth


    if (isExpanded) {
      aspectBox.style.paddingTop = '100%'
      videoContainer.style.height = `${width}px`
      expandButtonRef.current.style.transform = 'rotate(0deg)'
    } else { 
      aspectBox.style.paddingTop = '177.7%'
      videoContainer.style.height = `${width * 3 / 2}px`
      expandButtonRef.current.style.transform = 'rotate(180deg)'
    }

    setIsExpanded(!isExpanded)
  }



  function toggleMute(e) {
    e.stopPropagation()

    const videoElement = videoRef.current

    if (!videoElement) return

    videoElement.muted = !videoElement.muted // Toggle mute
  }



  function handleMouseOver() {
    if (closeButtonRef.current) {
      closeButtonRef.current.style.opacity = '1'
    }
  }


  function handleMouseLeave() {
    if (closeButtonRef.current) {
      closeButtonRef.current.style.opacity = '0'
    }
  }




  return (
    <div className={styles.root} ref={rootRef} style={{minHeight: minHeight}}>
      <div id='detail-profile-modal' className={styles.detailModal} />


      <section className={styles.top} ref={topRef}>
        <div className={styles.topContent}>
          <div 
            className={styles.leftSide} 
            style={{maxWidth: type === 'performance' ? '450px' : '550px'}}
          >
            <div id='image-container' className={styles.imageContainer}>
              {!video &&
                <AspectImage 
                  src={image || originalImage} 
                  srcSet={imageSrcset}
                  alt='profile'
                  borderRadius={isExtraSmallScreen ? '0' : '8px'}
                  onClick={show}
                  aspect={type === 'performance' ? '2:3' : '1:1'}
                />
              }


              {video &&
                <div id='video-container' className={styles.videoContainer}>
                  {'performance' !== type &&
                    <div ref={controlsRef} className={styles.videoControls}>
                      <button
                        ref={expandButtonRef}
                        onClick={toggleExpand} 
                        className={styles.expandButton}
                      >
                        <ExpandMoreIcon color='secondary' fontSize='large' />
                      </button>


                      <button onClick={toggleMute} className={styles.soundButton}>
                        {isMuted 
                          ? <VolumeOffIcon
                              color='secondary' 
                              fontSize='large'
                              style={{padding: '5px'}}
                            />
                          : <VolumeUpIcon 
                              color='secondary' 
                              fontSize='large'
                              style={{padding: '5px'}}
                            />
                        }
                      </button>
                    </div>
                  }



                  <div className={styles.videoImageContainer}>
                    <AspectImage 
                      src={image || originalImage} 
                      alt='profile'
                      borderRadius={isExtraSmallScreen ? '0' : '8px'}
                      onClick={show}
                      aspect={aspect}
                    />
                  </div>


                  <div className={styles.videoAspectOuterContainer}>
                    <div className={styles.videoAspectInnerContainer}>
                      <div 
                        ref={videoAspectRef}
                        style={{paddingTop}}
                        className={styles.videoAspectRatioBox}
                      >
                        {isLargeScreen && 
                          <button 
                            id='profile-video-close-button'
                            ref={closeButtonRef}
                            onClick={hideVideo}
                            onMouseOver={handleMouseOver}
                            onMouseLeave={handleMouseLeave}
                            className={styles.videoCloseButton}
                          >
                            <CloseIcon color='secondary' fontSize='large' />
                          </button>
                        }


                        <div className={styles.videoAspectRatioBoxInside}>
                          <video 
                            id='profile-video'
                            ref={videoRef} 
                            width='100%' 
                            muted
                            playsInline
                            controlsList='nodownload'
                            onClick={toggleMute}
                            onMouseOver={handleMouseOver}
                            onMouseLeave={handleMouseLeave}
                          >
                            <source src={video} type='video/mp4' />
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }



              {!isLargeScreen && 
                <div onClick={toggleMute} className={styles.gradient}>
                  <button 
                    id='profile-video-close-button'
                    ref={closeButtonRef}
                    onClick={hideVideo}
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                    className={styles.videoCloseButton}
                  >
                    <CloseIcon color='secondary' fontSize='large' />
                  </button>


                  {'performance' !== type &&
                    <div ref={controlsRef} className={styles.videoControls}>
                      <button
                        ref={expandButtonRef}
                        onClick={toggleExpand} 
                        className={styles.expandButton}
                      >
                        <ExpandMoreIcon color='secondary' fontSize='large' />
                      </button>


                      <button onClick={toggleMute} className={styles.soundButton}>
                        {isMuted 
                          ? <VolumeOffIcon
                              color='secondary' 
                              fontSize='large'
                              style={{padding: '5px'}}
                            />
                          : <VolumeUpIcon 
                              color='secondary' 
                              fontSize='large'
                              style={{padding: '5px'}}
                            />
                        }
                      </button>
                    </div>
                  }
                </div>
              }
            </div>


            {isLargeScreen && Broadcast}
          </div>

          <div className={styles.rightSide}>
            {information}
          </div>
        </div>
      </section>



      <section className={styles.middle} ref={tabsRef}>
        <div className={styles.middleContent}>
          <Tabs
            value={tab} 
            onChange={handleTabChange}
            textColor='secondary'
            variant={isExtraSmallScreen ? 'fullWidth' : 'standard'}
            sx={{
              '& .MuiTabs-indicator': {
                height: 5,
                backgroundColor: '#f24385'
              }
            }}
          >
            {tabLabels.map((label, index) => (
              <Tab 
                key={index}
                label={label} 
                disableRipple 
                sx={{
                  '&.MuiTab-root': {
                    fontWeight: 400,
                    color: '#fff',
                    fontSize: isMediumScreen ? '0.8rem' : '1rem',
                    transition: 'all 0.1s linear',
                    textTransform: 'uppercase',
                    minWidth: '70px'
                  },
                  '&.Mui-selected': {
                    fontWeight: 600,
                    fontSize: isMediumScreen ? '0.9rem' : '1.1rem'
                  }
                }}
              />
            ))}
          </Tabs>
        </div>
      </section>


      <section className={styles.bottom}>
        <div className={styles.bottomContent}>
          {tabs.map((content, index)  => (
            <TabPanel value={tab} index={index} key={index}>
              {content}
            </TabPanel>
          ))}
        </div>
      </section>


      <Dialog 
        open={open} 
        onClose={close} 
        maxWidth='md'
        BackdropProps={{
          style: {
            overflow: 'hidden',
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
        PaperProps={{
          style: {
            borderRadius: '0',
            maxHeight: '90%',
            overflow: 'hidden'
          }
        }}
      >
        <img className={styles.dialogImage} src={originalImage || image} alt='profile' /> 
      </Dialog>
    </div>
  )
}



export default DetailPage






