

// This counts how many searches were initiated in total and for each type
const INITIAL_STATE = {
  all: 0,
  venue: 0,
  performance: 0
}


const searchCounterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'SEARCH_COMPLETED':
    return {...state, all: state.all + 1}
  case 'VENUES_INDEX_SUCCESS':
    return {...state, venue: state.venue + 1}
  case 'PERFORMANCES_INDEX_SUCCESS':
    return {...state, performance: state.performance + 1}
  case 'RESET_STATE':
    return INITIAL_STATE
  default:
    return state
  }
}


export default searchCounterReducer