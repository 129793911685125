const INITIAL_STATE = {
  isFetching: false,
  id: null,
  type: 'user',
  attributes: {},
  relationships: {}
}



const accountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ACCOUNT':
      const account = action.payload || state
      
      return { ...state,
        ...account, 
        isFetching: false,
      }
    case 'CREATE_ACCOUNT_REQUESTED':
      return { ...state,
        isFetching: true
      }
    case 'CREATE_ACCOUNT_FAILURE':
      return { ...state,
        isFetching: false
      }
    case 'DELETE_ACCOUNT': {
      return INITIAL_STATE
    }
    case 'ACCOUNT_RELATIONSHIPS_REQUESTED': {
      return { ...state,
        isFetching: true
      }
    }
    case 'ACCOUNT_RELATIONSHIPS_SUCCESS': {
      return { ...state,
        isFetching: false,
        relationships: action.payload
      }
    }
    case 'ACCOUNT_RELATIONSHIPS_FAILURE': {
      return { ...state,
        isFetching: false
      }
    }
    case 'ACCOUNT_SETTINGS_UPDATE_REQUESTED':
      return { ...state,
        isFetching: true
      }
    case 'ACCOUNT_SETTINGS_UPDATE_SUCCESS':
      return { ...state,
        isFetching: false,
        attributes: {
          ...state.attributes, 
          settings: action.payload
        }
      }
    case 'ACCOUNT_SETTINGS_UPDATE_FAILURE':
      return { ...state,
        isFetching: false
      }
    case 'ACCOUNT_DESTROY_SUCCESS':
      return INITIAL_STATE
    default:
      return state
  }
}

export default accountReducer