import React, { useState, useEffect  } from 'react'
import { formatMinutes } from '../../utils'
import { useIsMounted } from '../../hooks'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'


const TrackSlider = props => {
  const isMounted = useIsMounted()
  const [trackDuration, setTrackDuration] = useState(0)
  const [trackPosition, setTrackPosition] = useState(0)
  const { audioRef, trackId } = props



  useEffect(() => {    
    if (audioRef.current) {
      audioRef.current.onloadedmetadata = e => {
        if (audioRef.current && isMounted.current) {
          setTrackDuration(audioRef.current.duration)
        }
      }
      audioRef.current.ontimeupdate = e => {
        if (audioRef.current && isMounted.current) {
          setTrackPosition(audioRef.current.currentTime)
        }
      }
    }
  }, [trackId, audioRef, isMounted])



  useEffect(() => {
    if (audioRef.current && audioRef.current.readyState > 0 && isMounted.current) {
      setTrackDuration(audioRef.current.duration)
      setTrackPosition(audioRef.current.currentTime)
    }
  }, [audioRef, isMounted])


  function setNewPosition(e, value) {
    if (isMounted.current) {
      setTrackPosition(value)
      audioRef.current.currentTime = value
    }
  }



  return (
    <div data-draggable='false'>
      <Slider
        max={ trackDuration }
        min={ 0 }
        value={ trackPosition }
        onChange={ setNewPosition }
        aria-labelledby='track-position-slider' 
      />

      <div style={{ width: '100%', justifyContent: 'center', marginTop: '-5px' }}>
        <Typography align='right'>
          { formatMinutes(trackDuration - trackPosition) }
        </Typography>
      </div>
    </div>
  )
}

export default TrackSlider