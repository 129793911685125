import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'



const CloseButton = ({iconProps, ...props}) => {
  return (
    <IconButton
      size='large'
      style={{
        top: 10,
        right: 10,
        cursor: 'pointer',
        position: 'absolute',
      }}
      {...props}
     >
      <CloseIcon color='disabled' fontSize='24px' {...iconProps} />
    </IconButton>
  )
}

export default CloseButton