import React, {useState} from 'react'

import {connect} from 'react-redux'


import Drawer from '@mui/material/Drawer'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'

import AuthenticatedSidebar from './AuthenticatedSidebar'
import UnauthenticatedSidebar from './UnauthenticatedSidebar'



const SideBar = props => {

  const [open, setOpen] = useState(false)
  
  const {menuIconColor, isAuthenticated} = props



  function handleClose() {
    setOpen(false)
  }



  function handleClick() {
    setOpen(true)
  }




  return (
    <div>
      <IconButton onClick={handleClick} aria-label='menu'>
        <MenuIcon color={menuIconColor || 'secondary'} /> 
      </IconButton>


      <Drawer
        open={open}
        onClose={handleClose}
        ModalProps={{
          keepMounted: true,
          disableScrollLock: true
        }}
        PaperProps={{ 
          sx: {
            width: '80%',
            maxWidth: 400,
            overflow: 'hidden',
            backgroundColor: '#000'
          }
        }}
      >
        <>  
          {isAuthenticated 
            ? <AuthenticatedSidebar close={handleClose} /> 
            : <UnauthenticatedSidebar close={handleClose} />
          }
        </>
      </Drawer>
    </div>
  )
}



const mapStateToProps = state => {
  return { 
    isAuthenticated: state.user.isAuthenticated
  }
} 



export default connect(mapStateToProps)(SideBar)




