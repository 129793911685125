import {createSelector} from 'reselect'



const selectMap = state => state.map.map
const selectQueue = state => state.map.markers.queue
const selectVenueMarkers = state => state.map.markers.venues
const selectPerformanceMarkers = state => state.map.markers.performances
const selectClickedMarkerData = state => state.map.markers.clickedMarkerData



export const mapSelector = createSelector(selectMap, map => map)
export const markerQueueSelector = createSelector(selectQueue, queue => queue)
export const venueMarkersSelector = createSelector(selectVenueMarkers, markers => markers)
export const performanceMarkersSelector = createSelector(selectPerformanceMarkers, markers => markers)
export const clickedMarkerDataSelector = createSelector(selectClickedMarkerData, data => data)



// User location
const selectUserMarker = state => state.map.markers.user
const selectIsUserLocated = state => state.user.location.isLocated

export const userMarkerSelector = createSelector(selectUserMarker, marker => marker)
export const isUserLocatedSelector = createSelector(selectIsUserLocated, bool => bool)
