import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { showAlert } from '../../actions'
import { useIsMounted } from '../../hooks'
import makeStyles from '@mui/styles/makeStyles'
import LinkIcon from '@mui/icons-material/Link'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Zoom from '@mui/material/Zoom'
import { 
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share'




const useStyles = makeStyles(theme => ({
  root: {
    padding: 40,
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    justifyContent: 'center',
    position: 'relative'
  },
  button: {
    marginRight: 15
  },
  iconButton: {
    width: 40,
    marginRight: 15,
    backgroundColor: 'grey',
    alignSelf: 'flex-start',
    '&:hover': {
      backgroundColor: 'grey'
    }
  },
  copyContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  message: {
    color: 'grey',
    fontSize: '0.8rem'
  }
}))



const ShareButtons = props => {
  const classes = useStyles()
  const isMounted = useIsMounted()
  const [isCopied, setIsCopied] = useState(false)
  const { url, title, text, showAlert } = props


  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        if (isMounted.current) {
          setIsCopied(false)
        }
      }, 600)
    }
  }, [isCopied, setIsCopied, isMounted])



  function copyToClipboard() {
    if (!window.navigator.clipboard) {
      showAlert('Your browser does not have this capability', 'info')
    } else {
      window.navigator.clipboard.writeText(url).then(() => {
        setIsCopied(true)
      })
      .catch(error => {
        setIsCopied(false)
      })
    }
  }




  return (
    <div className={ classes.root }>
      <FacebookShareButton className={ classes.button } url={ url } quote={ text }>
        <FacebookIcon size={ 40 } round={ true } />
      </FacebookShareButton>

      <TwitterShareButton className={ classes.button } url={ url } title={ title } hashtags={ ['ckord'] }>
        <XIcon size={ 40 } round={ true } />
      </TwitterShareButton>

      <ViberShareButton className={ classes.button } url={ url } title={ title }>
        <ViberIcon size={ 40 } round={ true } />
      </ViberShareButton>

      <WhatsappShareButton className={ classes.button } url={ url } title={ title }>
        <WhatsappIcon size={ 40 } round={ true } />
      </WhatsappShareButton>

      <EmailShareButton className={ classes.button } url={ url } subject={ title } body={ text }>
        <EmailIcon size={ 40 } round={ true } />
      </EmailShareButton>

      <IconButton className={ classes.iconButton } onClick={ copyToClipboard }>
        <LinkIcon color='secondary' />
      </IconButton>

      <Zoom in={ isCopied } className={ classes.copyContainer }>
        <Typography className={ classes.message }>
          COPIED
        </Typography>
      </Zoom>
    </div>
  )
}


export default connect(null, { showAlert })(ShareButtons)


