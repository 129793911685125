
export function formatPaymentMethod(method) {
  switch (method.type) {
    case 'card':
      return {
        icon: '',
        brand: method.card.brand,
        info: method.card.last4
      }
    default:
      return {
        icon: '',
        brand: '',
        info: ''
      }
  }
}