export function getOptions() {
  if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
    return {mimeType: 'video/webm; codecs=vp9'}
  } else if (MediaRecorder.isTypeSupported('video/webm')) {
    return {mimeType: 'video/webm'}
  } else if (MediaRecorder.isTypeSupported('video/mp4;codecs=avc1')) {
    return {mimeType: 'video/mp4;codecs=avc1'}
  } else {
    return {mimeType: ''}
  }
}