import React, {useState, useRef} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect, useDispatch} from 'react-redux'

import * as Yup from 'yup'

import {withFormik, Form} from 'formik'

import {v4 as uuidv4} from 'uuid'

import {makeGetProductById} from '../../../../selectors'

import {createImageObject, formatMoney} from '../../../../utils'

import {create, update, showAlert, addProductsToMerchant} from '../../../../actions'


import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import ProductDetailPage from '../../../product/detail/ProductDetailPage'
import MerchantProductFormDetails from './MerchantProductFormDetails'
import MerchantProductFormImagesSection from './MerchantProductFormImagesSection'
import MerchantProductFormVariantsSection from './MerchantProductFormVariantsSection'

import {ThreeDotProgress, Errors} from '../../../generics'




const styles = {
  root: css`
    margin: 0;
    width: 100%;
    position: relative;
    display: flex;
    align-content: center;
    flex-direction: column;
    max-width: 560px;
    padding-bottom: 70px;
    padding-top: 30px;
  ` ,
  form: css`
    position: relative;
    max-height: 100%;
    width: 100%;
    gap: 30px;
  `,
  innerFormContainer: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
  `,
  buttonsContainer: css`
    width: 100%;
    display: flex;
    padding: 20px 0px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;

    button {
      min-width: 120px;
      height: 45px;
      box-shadow: none;
      color: #000;
      background-color: #fff;
      text-transform: uppercase;
      font-size: 0.9rem;
      border: 2px solid #d9d9d9;

      &:hover {
        border: 2px solid #d9d9d9;
        background-color: #fff;
      }
    }

   @media (max-width: 490px) {
    gap: 10px;

    button {
      min-width: 100px;
    }
   }
  `,
  buttonsContainerRightSide: css`
    display: flex;
    gap: 20px;

    button:last-of-type {
      background-color: #000;
      color: #fff;
      border: 2px solid #000;
    }

    @media (max-width: 490px) {
      gap: 10px;
   }
  `
}



function createProductPreview(values, id, type, product) {
  
  let imageURL = ''

  let images = product ? product.attributes.images : []

  // Convert image files to URLs
  for (let i = 0; i < 10; i++) {
    const image = values['image' + i.toString()]

    if (image) {
      const url = URL.createObjectURL(image)
      const imageObject = createImageObject(url)

      images.push(imageObject)
    }
  }

  if (values.image0) {
    imageURL = URL.createObjectURL(values.image0)
  }


  return { 
    id: 'tmp_' + uuidv4(),
    type: 'product',
    attributes: {
      ...values,
      images,
      image: imageURL,
      imageDerivatives: {
        small: imageURL,
        medium: imageURL,
        large: imageURL
      },
      merchantId: id,
      merchantType: type,
    }
  }
}



const MerchantProductForm = props => {

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))

  const rootRef = useRef()

  const dispatch = useDispatch()

  const [isShowingPreview, setIsShowingPreview] = useState(false)

  const [preview, setPreview] = useState({})


  const {
    cancel,
    errors,
    values,
    product,
    showTips,
    merchantId,
    merchantType,
    handleBlur,
    setFieldValue,
    setFieldError,
    isSubmitting,
    shippingDetails
  } = props





  function closePreview() {
    setIsShowingPreview(false)
  }



  // This creates a product preview by creating a temporary mock product,
  // adding it to the redux store, and displaying it using the ProductDetailPage
  // just as one would with a regular product. After the product is previewed,
  // once the user closes the modal, the product is removed from the redux store.
  function showPreview() {
    const productPreview = createProductPreview(values, merchantId, merchantType, product)


    dispatch({type: 'PRODUCT_SHOW_SUCCESS', payload: {
      data: productPreview,
      isPreview: true 
    }})

    setPreview(productPreview)
    setIsShowingPreview(true)
  }




  return (
    <div css={styles.root} ref={rootRef}>
      <Errors errors={errors?.unmatched} />

      <Form css={styles.form} autoComplete='off'>
        <div css={styles.innerFormContainer}>
          <MerchantProductFormImagesSection
            product={product}
            values={values}
            errors={errors}
            showTips={showTips === undefined ? true : showTips}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
          />


          <MerchantProductFormDetails
            values={values} 
            handleBlur={handleBlur}
            setFieldValue={setFieldValue} 
          />


          <MerchantProductFormVariantsSection 
            variants={values.variants} 
            setFieldValue={setFieldValue} 
          />
        </div>

        <div css={styles.buttonsContainer}>
          <Button variant='outlined' onClick={cancel}>
            Cancel
          </Button>

          <div css={styles.buttonsContainerRightSide}>
            <Button variant='outlined' onClick={showPreview}>
              Preview
            </Button>
            
            <Button css={styles.publishButton} type='submit' variant='outlined' disabled={isSubmitting}>
              {isSubmitting && <ThreeDotProgress color='#fff' radius={5} />}
              
              {!isSubmitting && product ? 'Update' : 'Publish'}
            </Button>
          </div>
        </div>
      </Form>


      <Dialog 
        open={isShowingPreview} 
        onClose={closePreview} 
        fullWidth
        scroll={isSmallScreen ? 'paper' : 'body'}
        fullScreen={isSmallScreen}
        maxWidth='lg'
        PaperProps={{
          style: { 
            minHeight: '100%' 
          }
        }}
      >
        <ProductDetailPage 
          id={preview.id} 
          isPreview={true} 
          shippingDetails={shippingDetails} 
        />
      </Dialog>
    </div>
  )
}



const EnhancedMerchantProductForm = withFormik({
  mapPropsToValues: ({product}) => {
    const { 
      name, 
      caption,
      description, 
      price,
      variants
    } = product ? product.attributes : {}


    return {
      name: name || '',
      caption: caption || '',
      description: description || '',
      price: formatMoney(price).replace('$', '') || '',
      variants: variants || {}
    }
  },

  validationSchema: Yup.object().shape({
    name: Yup.string().required('required').max(50),
    price: Yup.number().min(1).required('required'),
    caption: Yup.string().max(50),
    description: Yup.string().max(3500)
  }),



  handleSubmit: (values, formikBag) => {
    const { 
      product, 
      create, 
      update, 
      cancel,
      showAlert,
      merchantId,
      merchantType,
      addProductsToMerchant
    } = formikBag.props


    const {setSubmitting, setErrors} = formikBag

    
    if (product) {
      // If the product exists then update the record with the new values
      const form = new FormData()


      if (values.image0) form.append('images[]', values.image0)
      if (values.image1) form.append('images[]', values.image1)
      if (values.image2) form.append('images[]', values.image2)
      if (values.image3) form.append('images[]', values.image3)
      if (values.image4) form.append('images[]', values.image4)
      if (values.image5) form.append('images[]', values.image5)
      if (values.image6) form.append('images[]', values.image6)
      if (values.image7) form.append('images[]', values.image7)
      if (values.image8) form.append('images[]', values.image8)
      if (values.image9) form.append('images[]', values.image9)


      form.append('name', values.name)
      form.append('price', values.price)
      form.append('caption', values.caption)
      form.append('description', values.description)
      form.append('variants', JSON.stringify(values.variants))



      update('/products/' + product.id, 'PRODUCT', form).then(response => {          
        showAlert('Information updated', 'success')
        cancel()
      })
      .catch(error => {
        setSubmitting(false)
        setErrors(error.apiFormErrors(values))
      })

    } else { 
      // If the product does not exist create a new record with the form values
      const url = '/merchants/' + merchantType + '/' + merchantId + '/products'

      const form = new FormData()


      if (values.image0) form.append('images[]', values.image0)
      if (values.image1) form.append('images[]', values.image1)
      if (values.image2) form.append('images[]', values.image2)
      if (values.image3) form.append('images[]', values.image3)
      if (values.image4) form.append('images[]', values.image4)
      if (values.image5) form.append('images[]', values.image5)
      if (values.image6) form.append('images[]', values.image6)
      if (values.image7) form.append('images[]', values.image7)
      if (values.image8) form.append('images[]', values.image8)
      if (values.image9) form.append('images[]', values.image9)


      form.append('name', values.name)
      form.append('price', values.price)
      form.append('caption', values.caption)
      form.append('description', values.description)
      form.append('variants', JSON.stringify(values.variants))


      create(url, 'PRODUCT', form).then(response => {
        setSubmitting(false)

        addProductsToMerchant(merchantId, merchantType, response.data)
        showAlert('Product created', 'success')
        cancel()
      })
      .catch(error => {
        setSubmitting(false)
        setErrors(error.apiFormErrors(values))
      })
    }
  }
})(MerchantProductForm)



const makeMapStateToProps = () => {
  const getProductById = makeGetProductById()


  const mapStateToProps = (state, props) => {
    return {
      product: props.productId ? getProductById(state, props.productId) : null
    }
  }

  return mapStateToProps
}


const actions = {create, update, showAlert, addProductsToMerchant}


export default connect(makeMapStateToProps, actions)(EnhancedMerchantProductForm)


