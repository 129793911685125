import { useEffect } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom'

function ScrollToTop() {
  const location = useLocation()
  const navigationType = useNavigationType()

  useEffect(() => {
    if (navigationType === 'POP' || navigationType === 'REPLACE') return

    window.scrollTo({
      top: 0, 
      left: 0
    })
  }, [location.pathname, navigationType])

  return null
}

export default ScrollToTop