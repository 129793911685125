import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {formatDistance} from 'date-fns'



const styles = {
  root: css`
    display: flex;
    flex-direction: column;
  `,
  spanContainer: css`
    display: flex;
    justify-content: flex-end;
    text-transform: uppercase;
  `
}



const HeaderOrderNotification = props => {

  const {notification} = props

  const date = new Date(notification.attributes.updatedAt)



  return (
    <div css={styles.root}>
      <h1>📦&nbsp; New Order Placed</h1>

      <p>
        View the details in your shop and ship the merchandise at your earliest convenience.
      </p>

      <div css={styles.spanContainer}>
        <span>
          {formatDistance(date, new Date()) + ' ago'}
        </span>
      </div>
    </div>
  )
}


export default HeaderOrderNotification