import {mapKeys, camelCase, transform, isArray, isObject} from 'lodash'



export function camelCaseObject(object) {
  return mapKeys(object, function(value, key) {
    return camelCase(key)
  })
}



export function camelCaseNestedObject(object) { 
  return transform(object, (acc, value, key, target) => {
    const camelCaseKey = isArray(target) ? key : camelCase(key)

    acc[camelCaseKey] = isObject(value) ? camelCaseNestedObject(value) : value
  })
}


export default camelCaseObject