import { get } from 'lodash'


const imageSizes = [
  'original', 
  'small', 
  'medium', 
  'large', 
  'small_fallback', 
  'medium_fallback', 
  'large_fallback', 
]


export function getImageOfSize(attributes, size = 'small') {
  let image = get(attributes, 'image', '')

  if (!imageSizes.includes(size)) return image

  const derivatives = get(attributes, 'imageDerivatives', {})


  if (derivatives[size]) {
    return derivatives[size]
  }

  return image
}


export default getImageOfSize