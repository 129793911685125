import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchFavoritesOfType } from '../actions'



export function useFetchFavoritesOfType(type) {
  const pluralType = type + 's'
  const shouldFetchFavorites = useSelector(state => !state.user.favorites.fetched.includes(pluralType))
  const isFetching = useSelector(state => state.user.favorites.isFetching)
  const isAuthenticated = useSelector(state => state.user.isAuthenticated)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuthenticated && shouldFetchFavorites && !isFetching) {
      dispatch(fetchFavoritesOfType(pluralType))
    }
  }, [
    isAuthenticated, 
    shouldFetchFavorites, 
    pluralType, 
    dispatch, 
    isFetching
  ])
}