import React, {useRef, useState, useEffect, useCallback} from 'react'

import {createRoot} from 'react-dom/client'

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {getOptions} from '../../../../utils'

import {crudAPI} from '../../../../apis'

import {showAlert} from '../../../../actions'

import {useIsMounted} from '../../../../hooks'

import {validate} from '../../../../utils/validateVideoFile'

import {UAParser} from 'ua-parser-js'

import MicIcon from '@mui/icons-material/Mic'
import CloseIcon from '@mui/icons-material/Close'
import FolderIcon from '@mui/icons-material/PermMedia'
import VideocamIcon from '@mui/icons-material/Videocam'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'

import CircularProgress from '@mui/material/CircularProgress'


import IconButton from '@mui/material/IconButton'

import VideoTimer from '../../../generics/VideoTimer'



const parser = new UAParser().getDevice()

const isMobile = parser.type === 'mobile'




const styles = {
  root: css`
    width: 100%;
    height: 100%;
    max-height: 889px;
    max-width: 500px;
    position: relative;
    background-color: black;
    overflow: hidden;
  `,
  controlRow: css`
    position: absolute;
    width: 100%;
    bottom: 90px;
    display: flex;
    justify-content: center;
    gap: 40px;
  `,
  button: css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    margin: 0;
    padding: 5px;
    cursor: pointer;
  `,
  circle: css`
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 50%;
    border: 3px solid black; 
  `,
  videoAspectRatioBox: css`
    width: 100%;
    height: ${isMobile ? '100%' : 0};
    padding-top: ${isMobile ? '0' : '177.7%'};
    position: relative;
    overflow: hidden;

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      display: none;
    }
  `,
  videoAspectRatioBoxInside: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;


    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scaleX(-1);
      transition: height 0.3s ease;
    }
  `,
  timerContainer: css`
    position: absolute;
    top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
  `,
  playbackVideoContainer: css`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: black;
  `,
  playbackControls: css`
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      background-color: transparent;
      border: none;
      padding: 10px;
      z-index: 12;
      cursor: pointer;
    }
  `,
  uploadButton: css`
    cursor: pointer;
    color: white;
    font-weight: 500;
    border: 2px solid white;
    height: 50px;
    padding: 10px 25px;
    z-index: 12;
    text-transform: uppercase;
    font-size: 14px;
    font-family: neue-haas-unica, sans-serif;
    display: flex;
    align-items: center;
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.5);
  `,
  deviceSelectionContainer: css`
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    gap: 10px;
  `,
  selectContainer: css`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    label {
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 500;
      color: #9d9d9d;
      padding: 3px;
      display: flex;
      align-items: center;
    }
  `,
  select: css`
    color: white;
    background-color: black;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #434343;
    padding: 0 10px;
    max-width: 170px;
    min-width: 100px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
  `,
  playbackVideo: css`
    z-index: 11;
    position: absolute;
    top: 0;
  `,
  formCloseButton: css`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 9;
  `,
  progressContainer: css`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 200;
    display: flex;
    flex-direction: column;
    gap: 15px;

    p {
      color: white;
    }
  `,
  progressContainerInnerContainer: css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      position: absolute;
    }
  `
}





const VideoForm = props => {

  const isMounted = useIsMounted()

  const rootRef = useRef()
  const inputRef = useRef()
  const videoRef = useRef()
  const canvasRef = useRef()

  const videoStream = useRef()
  const canvasStream = useRef()
  const combinedStream = useRef() // Used to combine canvas video and audio from video
  const animationFrameId = useRef()

  const mediaRecorder = useRef()


  const [step, setStep] = useState('')
  const [progress, setProgress] = useState(0)


  // Used to adjust the video resoltion for recording since
  // artist, band, and venue profiles would look better with
  // squares and events with vertical
  const [aspect, setAspect] = useState('vertical')
  const [height, setHeight] = useState('100%')

  const [videoURL, setVideoURL] = useState(null)
  const [videoFile, setVideoFile] = useState(null)


  const [isRecording, setIsRecording] = useState(false)

  const [isPermissionGranted, setIsPermissionGranted] = useState(false)

  const [micDevices, setMicDevices] = useState([])
  const [videoDevices, setVideoDevices] = useState([])

  const [selectedMic, setSelectedMic] = useState('')
  const [selectedCamera, setSelectedCamera] = useState('')

  const [audioTrack, setAudioTrack] = useState()
  const [videoTrack, setVideoTrack] = useState()



  const {id, type, close, showAlert} = props




  const startRecording = useCallback(() => {

    let recordedChunks = []

    const stream = combinedStream.current
    const options = getOptions()


    mediaRecorder.current?.stop()


    // Set up MediaRecorder and capture the stream
    mediaRecorder.current = new MediaRecorder(stream, options)


    mediaRecorder.current.ondataavailable = function(e) {
      if (e.data.size > 0) {
        recordedChunks.push(e.data)
      }
    }


    mediaRecorder.current.onstop = async function() {
      // Create a blob from the recorded chunks
      const blob = await new Blob(recordedChunks, {type: 'video/mp4'})

      const file = await new File([blob], 'video.mp4', {type: blob.type})

      recordedChunks = [] // Reset chunks

      const videoURL = URL.createObjectURL(blob)

      const video = videoRef.current

      video.src = null
      video.srcObject = null
      video.src = videoURL
      video.muted = false
      video.controls = true
      video.style.transform = 'scaleX(1)'

      await video.play()

      setVideoFile(file)
      setVideoURL(videoURL)
    }


    // Start recording
    mediaRecorder.current.start()

  }, [setVideoFile, setVideoURL])



  // Canvas is recording in background
  const startCanvasCapture = useCallback(stream => {
    const video = videoRef.current
    const canvas = canvasRef.current

    const ctx = canvas.getContext('2d')


    function drawFrame() {
      ctx.save()
      ctx.scale(-1, 1) // Mirror the video horizontally
      ctx.drawImage(video, -canvas.width, 0, canvas.width, canvas.height)
      ctx.restore()
      animationFrameId.current = requestAnimationFrame(drawFrame)
    }

    drawFrame()

    canvasStream.current = canvas.captureStream()

    combinedStream.current = new MediaStream([...canvasStream.current.getVideoTracks(), ...stream.getAudioTracks()])
  }, [])



  // This is where the live stream first begins from the useEffect below
  const startStream = useCallback(async () => {
    const video = videoRef.current
    const canvas = canvasRef.current


    if (video && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      let options = {video: {facingMode: 'user'}, audio: true}


      try {
        const stream = await navigator.mediaDevices.getUserMedia(options)

        videoStream.current = stream

        video.srcObject = stream
        video.muted = true
        video.controls = false


        video.addEventListener('loadedmetadata', () => {
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          startCanvasCapture(stream)
        })

        setIsPermissionGranted(true)
      } catch(e) {
        showAlert(e.message)
      }
    } else {
      showAlert('Couldn\'t access camera', 'info')
    }
  }, [showAlert, startCanvasCapture])




  // Start of live stream
  useEffect(() => {startStream()}, [startStream])



  // Get the loaders new transform values after video is uploaded
  const getTransformValues = useCallback(() => {
    const newLeft = window.innerWidth - 100 - 10 // 10px from the right, considering new width
    const newTop = window.innerHeight - 178 - 10 // 10px from the bottom, considering new height
    const transformX = newLeft - (window.innerWidth / 2)
    const transformY = newTop - (window.innerHeight / 2)

    return {x: transformX, y: transformY}
  }, [])



  // Clean up to ensure webcam is turned off
  useEffect(() => {
    return () => {
      videoStream.current?.getTracks().forEach(track => {track.stop()})
      canvasStream.current?.getTracks().forEach(track => {track.stop()})
      combinedStream.current?.getTracks().forEach(track => {track.stop()})
      window.cancelAnimationFrame(animationFrameId.current)


      const div = document.getElementById('video-processing-container')
      
      if (div) {
        // Ensure that the video loader doesn't shift when form closes due to scroll bar
        // const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
        // div.style.marginLeft = `${scrollbarWidth / 2}px`
      
        // Minimize the loader
        const transform = getTransformValues()


        Object.assign(div.style, {
          width: '100px',
          height: '178px',
          transform: `translate(${transform.x}px, ${transform.y}px) scale(1)`,
        })
      }
    }
  }, [getTransformValues])




  useEffect(() => {
    if (progress >= 1 && step !== 'Uploading...') {
      setTimeout(() => {
        setProgress(0)
      }, [300])
    }
  }, [step, progress, setProgress])




  useEffect(() => {
    return () => {
      if (audioTrack) {
        audioTrack.stop()
      }
    }
  }, [audioTrack])




  useEffect(() => {
    return () => {
      if (videoTrack) {
        videoTrack.stop()
      }
    }
  }, [videoTrack])





  // Get and save available IO devices
  useEffect(() => {
    if (!isPermissionGranted) return


    navigator.mediaDevices.enumerateDevices().then(devices => {
      let mic = []
      let video = []


      for (const device of devices) {
        if (device.kind === 'audioinput') {
          mic.push(device)
        }



        if (device.kind === 'videoinput') {
          video.push(device)
        }
      }


      setMicDevices(mic)
      setVideoDevices(video)
    })
  }, [isPermissionGranted])




  function handleError(e) {
    console.log(e)
  } 



  // Handles case when a user selects a file instead of recording a video
  // --------------------------------------------------------------------
  const fileSelectedHandler = e => {

    const file = e.target.files[0]
    
    const reader = new FileReader()



    reader.onload = () => {
      mediaRecorder.current?.stop()

      const video = videoRef.current

      if (videoURL) {
        URL.revokeObjectURL(videoURL)
      }

      // Create a blob from the recorded chunks
      const objectURL = URL.createObjectURL(file)

      if (isMounted.current && video) {
        setStep('')
        setVideoFile(file)
        setVideoURL(objectURL)

        video.src = null
        video.srcObject = null
        video.src = objectURL
        video.muted = false
        video.style.transform = 'scaleX(1)'
      }
    }



    reader.onprogress = e => {
      if (isMounted.current) {
        setProgress(e.loaded / e.total)
      }
    }



    reader.onerror = e => {
      if (isMounted.current) {
        setStep('')
        setProgress(0)
      }

      URL.revokeObjectURL(videoURL)

      showAlert(e.target.error, 'error')
    }



    
    if (file) {
      const validation = validate(file)


      if (validation.passed) {
        // This kicks off the above events e.g. reader.onload
        reader.readAsDataURL(file)
        setStep('Loading...')
      } else {
        showAlert(validation.message, 'info')
      }
    }
  }
  // --------------------------------------------------------------------





  function record() {
    setIsRecording(true)

    if (videoURL) {
      URL.revokeObjectURL(videoURL)

      setVideoURL(null)
      setVideoFile(null)
    }

    startRecording()
  }




  function stopRecording() {
    setIsRecording(false)
    mediaRecorder.current?.stop()
  }



  function save() {
    if (!videoFile) return

    setStep('Uploading...')


    const url = `videos/${type}/${id}/profile`

    const form = new FormData()

    form.append('video', videoFile)



    const config = {
      onUploadProgress: e => {
        setProgress(e.progress)
      }
    }

    crudAPI.patch(url, form, config).then(response => {
      if (isMounted.current) {
        showProcessingLoader()
        close()
        setStep('')
      }

      showAlert("We're processing your video and will notfiy you when complete in a few minutes.", 'info')
    })
    .catch(error => {
      if (isMounted.current) {
        setStep('')
      }
      
      showAlert(error.apiMessage(), 'error')
    })
  }



  // After the video is uploaded, this is executed to show the user that the video is being processed.
  // This is a small version of the video that gets put in the bottom right and is removed when the 
  // backend returns a message.
  function showProcessingLoader() {
    if (!videoRef.current) return

    const videoElement = videoRef.current


    const canvas = document.createElement('canvas')
    canvas.width = videoElement.videoWidth
    canvas.height = videoElement.videoHeight
    const ctx = canvas.getContext('2d')


   // Draw the first frame to the canvas
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height)

    // Convert the canvas to an image data URL
    const imgDataUrl = canvas.toDataURL('image/png')

    // Create container
    const div = document.createElement('div')
    div.id = 'video-processing-container'

    // Assign styles
    Object.assign(div.style, {
      position: 'fixed',
      width: `${videoElement.offsetWidth}px`,
      height: `${videoElement.offsetHeight}px`,
      left: '50%',
      top: '50%',
      borderRadius: '4px',
      zIndex: '50',
      overflow: 'hidden',
      transform: 'translate(-50%, -50%) scale(1)',
      transformOrigin: 'center center',
      transition: 'all 0.4s ease'
    })


    // Create an img element dynamically
    const img = document.createElement('img')
    img.src = imgDataUrl
    img.alt = 'First frame of video'

    Object.assign(img.style, {
      width: '100%',
      height: '100%',
      zIndex: 1,
      objectFit: 'cover'
    })



    // Loading overlay
    const overlay = document.createElement('div')

    // Apply styles to the overlay
    Object.assign(overlay.style, {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      zIndex: 2,
      fontSize: '12px',
      padding: '10px'
    })


    // Set the overlay content
    overlay.innerHTML = 'Processing...'


    // Create the close  button
    const button = document.createElement('button')

    // Create a React element for Close Material-UI icon
    const closeIconElement = React.createElement(CloseIcon)

    // // Create a container for the React element
    const iconContainer = document.createElement('div')

    // Append the icon
    button.appendChild(iconContainer)

    // Create a root and render the React element to the container
    const root = createRoot(iconContainer)
    root.render(closeIconElement)



    Object.assign(button.style, {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: '5px',
      fontSize: '16px',
      cursor: 'pointer',
      color: '#fff',
      backgroundColor: 'transparent',
      border: 'none'
    })

    // Add button click event
    button.addEventListener('click', () => {
      const loader = document.getElementById('video-processing-container')

      if (loader) {
        const transform = getTransformValues()

        loader.style.transform = `translate(${transform.x}px, ${transform.y}px) scale(0)`


        loader.addEventListener('transitionend', () => {
          loader.remove()
        }, {once: true})
      }
    }, {once: true})


    // Add the elements to the div
    overlay.appendChild(button)
     
    div.appendChild(img)
    div.appendChild(overlay)

    document.body.appendChild(div)

    canvas.remove()
  }




  async function selectMicrophoneDevice(e) {
    if (isRecording) {
      stopRecording()
    }

    // Get the selected device
    const deviceId = e.target.value

    setSelectedMic(deviceId)


    // Close the current audio stream
    combinedStream.current.getTracks().forEach(track => {
      if (track.kind === 'audio') {
        track.stop()
        combinedStream.current.removeTrack(track)
      }
    })



    // Create a new stream with the selected device
    let newStream = await navigator.mediaDevices.getUserMedia({
      audio: {deviceId: {exact: deviceId}},
      video: false
    })


    // Get new track
    const newTrack = newStream.getAudioTracks()[0]


    // Assign the new stream
    if (newTrack) {
      setAudioTrack(newTrack)
      combinedStream.current.addTrack(newTrack)
    }
  }




  async function selectVideoDevice(e) {
    if (isRecording) {
      stopRecording()
    }

    // Get the selected device
    const deviceId = e.target.value

    setSelectedCamera(deviceId)


    // Close the current video stream
    combinedStream.current.getTracks().forEach(track => {
      if (track.kind === 'video') {
        track.stop()
        combinedStream.current.removeTrack(track)
      }
    })


    // Create a new stream with the selected device
    const newStream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {deviceId: {exact: deviceId}}
    })


    // Get new track
    const newTrack = newStream.getVideoTracks()[0]


    // Assign the new stream to the video element
    if (newTrack) {
      setVideoTrack(newTrack)
      combinedStream.current.addTrack(newTrack)
    }
  }




  function toggleAspect() {
    if (aspect === 'vertical') {
      const rootWidth = rootRef.current.getBoundingClientRect().width

      setHeight(`${rootWidth}px`)
      setAspect('square')
    } else {
      setHeight('100%')
      setAspect('vertical')
    }
  }



  // This goes back to the recorder
  function retake() {
    const videoElement = videoRef.current

    videoElement.pause()
    videoElement.src = null
    videoElement.srcObject = videoStream.current
    videoElement.muted = true
    videoElement.controls = false
    videoElement.style.transform = 'scaleX(-1)'


    if (videoURL) {
      URL.revokeObjectURL(videoURL)
    }

    setVideoURL(null)
    setVideoFile(null)
  }



  // This closes the entire form
  function handleFormClose() {
    close()

    if (videoURL) {
      URL.revokeObjectURL(videoURL)
    }
  }




  function selectFile(e) {
    e.stopPropagation()
    inputRef.current.click()
  }




  function handlePlaybackError(e) {
    if (isMounted.current) {
      setProgress(0)
      retake()
    }

    showAlert(e.target.error, 'error')
  }




  return (
    <div ref={rootRef} css={styles.root}>
      <div css={styles.videoAspectRatioBox}>
        {isRecording &&
          <div css={styles.timerContainer}>
            <VideoTimer isRecording={isRecording} />
          </div>
        }



        <button css={styles.formCloseButton} onClick={handleFormClose}>
          <CloseIcon sx={{fontSize: '35px'}} color='secondary' />
        </button>
        

        <canvas ref={canvasRef}></canvas>


        <div css={styles.videoAspectRatioBoxInside}>
          <video
            id='video-stream' 
            ref={videoRef}
            width='100%'
            autoPlay
            playsInline
            onError={handleError}
            style={{height}}
          />
        </div>
      </div>



      <div css={styles.controlRow}>
        {videoURL &&
          <>
            <button css={styles.uploadButton} onClick={retake}>
              Retake
            </button>

            <button css={styles.uploadButton} onClick={save}>
              Upload
            </button>
          </>
        }



        {!videoURL &&
          <>
            <IconButton onClick={toggleAspect}>
              <AspectRatioIcon color='secondary' fontSize='large' />
            </IconButton>

                

            {isPermissionGranted &&
              <button css={styles.button} onClick={isRecording ? stopRecording : record}>
                <div 
                  css={styles.circle}
                  style={{
                    backgroundColor: isRecording ? 'red' : 'white'
                  }}
                />
              </button>
            }


            <IconButton onClick={selectFile}>
              <FolderIcon color='secondary' fontSize='large' />
            </IconButton>
          </>
        }
      </div>



      {!videoURL && 
        <div css={styles.deviceSelectionContainer}>
          {micDevices.length > 1 && isPermissionGranted &&
            <div css={styles.selectContainer}>
              <label htmlFor='mic-select'>
                <MicIcon fontSize='small' />
              </label>
              
              <select
                css={styles.select}
                name='mic-select'
                defaultValue={selectedMic}
                onChange={selectMicrophoneDevice}
              >
                {micDevices.map(device => (
                  <option 
                    key={device.deviceId} 
                    css={styles.option}
                    value={device.deviceId}
                  >
                    {device.label}
                  </option>
                ))}
              </select>
            </div>
          }




          {videoDevices.length > 1 && isPermissionGranted &&
            <div css={styles.selectContainer}>
              <label htmlFor='video-select'>
                <VideocamIcon fontSize='small' />
              </label>
              
              <select
                css={styles.select}
                name='video-select'
                defaultValue={selectedCamera}
                onChange={selectVideoDevice}
              >
                {videoDevices.map(device => (
                  <option 
                    key={device.deviceId} 
                    css={styles.option}
                    value={device.deviceId}
                  >
                    {device.label}
                  </option>
                ))}
              </select>
            </div>
          }
        </div>
      }



      <input
        ref={inputRef} 
        style={{display: 'none'}}
        type='file'
        accept='video/*'
        onChange={fileSelectedHandler}
      />




      {(progress || step) &&
        <div css={styles.progressContainer}>
          <div css={styles.progressContainerInnerContainer}>
            <CircularProgress
              size={60}
              variant='determinate' 
              value={progress * 100}
              color='secondary'
            />
            <p>{`${Math.round(progress * 100)}%`}</p>
          </div>

          <p>{step}</p>
        </div>
      }
    </div>
  )
}




export default connect(null, {showAlert})(VideoForm)





