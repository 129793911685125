import React, { useState, useEffect } from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import stringColorsToHex from '../../../helpers/stringColorsToHex'
import ProductVariantSelect from './ProductVariantSelect'



const cssStyles = props => ({
  root: css`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
  `,
  outerCircle: css`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  `,
  innerCircle: css`
    height: 23px;
    width: 23px;
    border-radius: 50%;
  `
}) 







const ProductColorOptions = props => {

  const styles = cssStyles()


  const [selection, setSelection] = useState()
  const [allColorsPresent, setAllColorsPresent] = useState(true)


  const { colors, callback } = props


  useEffect(() => {
    for (const color of colors) {
      if (!(color.toLowerCase() in stringColorsToHex)) {
        setAllColorsPresent(false)
      }
    }
  }, [colors])



  function selectColor(color) {
    setSelection(color)
    if (callback) callback(color) 
  }


  return (
    <>
      { allColorsPresent 
        ? colors.map(color => (
            <div 
              key={ color } 
              css={ styles.root } 
              onClick={ () => selectColor(color) }
            >
              <div 
                css={ styles.outerCircle }
                style={{ border: color === selection ? '1px solid #000' : '1px solid #ccc' }}
              >
                <div 
                  css={ styles.innerCircle }
                  style={{ backgroundColor: stringColorsToHex[color.toLowerCase()] }}
                />
              </div>
            </div>
          )) 
        : <ProductVariantSelect options={ colors } />
      }
    </>
  )
}


export default ProductColorOptions

