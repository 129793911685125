import {filter} from 'lodash'


const INITIAL_STATE = {
  items: [],
  isLoaded: false,
  isFetching: false,
  shouldSave: false
}


const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'CART_REQUESTED':
    return {...state, isFetching: true}
  case 'SET_CART':
    return {
      isFetching: false,
      isLoaded: true,
      items: action.payload || []
    }
  case 'ADD_TO_CART':
    return { 
      ...state,
      isFetching: false,
      shouldSave: true,
      items: [...state.items, {
        id: action.payload.id,
        ...action.payload.item
      }]
    }
  case 'REMOVE_FROM_CART':
    return {...state,
      shouldSave: true,
      items: filter(state.items, item => {
        return item.id !== action.payload.id
      })
    }
  case 'INCREMENT_QUANTITY':
    return { ...state,
      shouldSave: true,
      items: state.items.map(item => {
        if (item.id === action.payload.id) {
          return { ...item,
            quantity: item.quantity += 1
          }
        } else return item
      })
    }
  case 'DECREMENT_QUANTITY':
    return { ...state,
      shouldSave: true,
      items: state.items.map(item => {
        if (item.id === action.payload.id) {
          return { ...item,
            quantity: Math.max(item.quantity -= 1, 1)
          }
        } else return item
      })
    }
  case 'CART_SHOW_SUCCESS':
    return {
      isFetching: false,
      isLoaded: true,
      items: action.payload || state.items
    }
  case 'CART_SAVE_SUCCESS':
  case 'CART_ERROR':
    return { ...state, isFetching: false }
  case 'EMPTY_CART':
    return {...state,
      items: [],
      shouldSave: true 
    }
  default:
    return state
  }
}


export default cartReducer
