import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {makeGetProductById, makeGetMerchantShop} from '../../../selectors'




const styles = {
  list: css`
    margin-block-start: 0em;
    padding-left: 0;
    list-style-position: inside;

    li {
      margin-bottom: 5px;
    }
  `
}



function rateArrayToText(rate) {
  let [start, end, price] = rate

  if (start === '0') {
    return `Orders less thant $${end} — $${price}`
  
  
  } else if (end === '') {

    const startDifference = (parseFloat(start) - parseInt(start)).toFixed(2)

    if (Math.abs(startDifference) <= 0.01) {
      start = Math.round(start)
    }

    if (price === '' || price === '0') {
      price = 'Free'
    } else {
      price = `$${price}`
    }

    return `Orders greater than $${start} — ${price}`


  } else {
    
    const endDifference = (parseFloat(end) - parseInt(end)).toFixed(2)
    const startDifference = (parseFloat(start) - parseInt(start)).toFixed(2)


    if (Math.abs(startDifference) <= 0.01) {
      start = Math.round(start)
    }

    if (Math.abs(endDifference) <= 0.01) {
      end = Math.round(end)
    }

    return `Orders between $${start} and $${end} — $${price}`
  }
}




const ProductShippingDetails = props => {

  const {shippingDetails} = props

  const {rateType, rate, rate1, rate2, rate3, rate4, rate5} = shippingDetails

  const priceBasedRates = [rate1, rate2, rate3, rate4, rate5].filter(e => Array.isArray(e))



  return (
    <div>
      <ul css={styles.list}>
        {rateType === 'free' && <li>Free Shipping</li>}

        {rateType === 'flat-rate' && <li>Shipping: ${rate}</li>}

        {rateType === 'price-based' && priceBasedRates.map((r, index) => (
          <li key={index}>{rateArrayToText(r)}</li>
        ))}

        <li>Typically ships within {shippingDetails.processingTime}</li>
      </ul>
    </div>
  )
}


const makeMapStateToProps = () => {
  const getProductById = makeGetProductById()
  const getMerchantShop = makeGetMerchantShop()


  const mapStateToProps = (state, props) => {    
    const product = getProductById(state, props.productId)

    const merchantId = product.attributes.merchantId
    const merchantType = product.attributes.merchantType.toLowerCase()

    const shop = getMerchantShop(state, merchantId, merchantType)


    return {
      shippingDetails: props.shippingDetails || shop?.attributes.shippingDetails || {}
    }
  }

  return mapStateToProps
}



export default connect(makeMapStateToProps)(ProductShippingDetails)




