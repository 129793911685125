import React, {useRef, useEffect, useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import Skeleton from '@mui/material/Skeleton'



const styles = {
  root: css`
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `,
  titleContainer: css`
    position: relative;  
  `,
  caption: css`
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    color: rgb(255, 0, 149);
    margin-bottom: 3px;
  `,
  title: css`
    font-family: 'Gravity';
    line-height: 0.95;
    text-transform: uppercase;
    font-stretch: semi-condensed;
    margin: 0;
    display: inline;
  `,
  subtitle: css`
    color: #C9C9C9;
    margin-top: -5px;
    margin-bottom: 10px;
    font-size: 0.9rem;
    text-transform: uppercase;
  `
}



const DetailPageTitle = ({title, subtitle, caption}) => {

  const ref = useRef()

  const titleRef = useRef()


  const [isLoading, setIsLoading] = useState(true)

  const [attempts, setAttempts] = useState(0)

  const [fontSize, setFontSize] = useState(3.4)




  useEffect(() => {
    if (!ref.current || !titleRef.current || !isLoading) return


    if (attempts > 9) {
      setIsLoading(false)
    }

    const lineHeight = parseFloat(window.getComputedStyle(titleRef.current).lineHeight)


    if (
      ref.current.scrollWidth > ref.current.offsetWidth ||
      titleRef.current.offsetHeight > lineHeight * 4
    ) {
      setAttempts(attempts + 1)
      setFontSize(fontSize - 0.1)
    } else {
      setIsLoading(false)
    }
  }, [
    ref,
    titleRef,
    fontSize,
    setFontSize,
    attempts,
    setAttempts,
    isLoading, 
    setIsLoading
  ])





  return (
    <div ref={ref} css={styles.root}>
      <p css={styles.caption}>{caption}</p>


      <div css={styles.titleContainer}>
        {isLoading &&
          <Skeleton 
            variant='text' 
            sx={{
              fontSize: '5rem', 
              width: '100%',
              position: 'absolute',
              top: '0px'
            }} 
          />
        }

        <h1 
          ref={titleRef} 
          css={styles.title} 
          style={{
            fontSize: `${fontSize}rem`,
            visibility: isLoading ? 'hidden' : 'visible'
          }}
        >
          {title}
        </h1>
      </div>


      {typeof subtitle === 'string' &&
        <p css={styles.subtitle}>
          {subtitle}
        </p>
      }

      {React.isValidElement(subtitle) &&
        subtitle
      }
    </div>
  )
}



export default DetailPageTitle




