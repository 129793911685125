import React, { useState, useEffect } from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {range} from 'lodash'

import {connect} from 'react-redux'

import {getErrorMessage} from '../../../../utils'

import {update, destroy, showAlert} from '../../../../actions'

import DeleteIcon from '@mui/icons-material/Delete'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import {ImageField, AspectImage} from '../../../generics'




const styles = {
  root: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #d9d9d9;

    @media (max-width: 800px) {
      padding: 20px;
    }
  ` ,
  header: css`
    margin-bottom: 20px;
    font-size: 15px;
    letter-spacing: 0;

    & > h2 {
      font-weight: 600;
    }
  `,
  body: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 20px;
    max-width: 500px;
  `,
  infoContainer: css`
    width: 100%; 
    min-width: 200px;
    font-size: 1rem;

    & > h3 {
      font-weight: 600;
      margin-bottom: 5px;
    }
  `,
  tipContainer: css`
    position: absolute;
    left: 550px;
    min-width: 250px;
    max-width: 350px;
    width: 100%;

    & > h3 {
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 1.2rem;
    }

    @media (max-width: 1000px) {
      display: none;
    }
  `,
  imagesSection: css`
    display: flex;
    gap: 25px;
    position: relative;
  `,
  previewSection: css`
    display: flex;
    gap: 25px;
    font-size: 1rem;
    flex-direction: column;
  `,
  previewImage: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  `,
  list: css`
    padding-left: 20px;
    width: 100%;
    margin: 0;

    & > li {
      color: #7b7b7b;
      padding-bottom: 10px;
      font-size: 0.95rem;
      font-weight: 500; 
    }

    @media (max-width: 800px) {
      min-width: 0px;
      max-width: 100%;
      font-size: 1rem;
    }
  `,
  chevronContainer: css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 20;
    cursor: pointer;
  `,
  leftChevron: css`
    width: 45px;
    height: 45px;
    background: rgba(255, 255, 255, 0.6);
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
  `,
  rightChevron: css`
    width: 45px;
    height: 45px;
    background: rgba(255, 255, 255, 0.6);
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
  `,
  aspectRatioBox: css`
    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;
  `,
  aspectRatioBoxInside: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
  imagesContainer: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px;
  `,
  smallImageContainer: css`
    position: relative;
    width: width;
    min-width: calc(100% / 4 - 8px);
    max-width: 200px;
    overflow: hidden;
    border-radius: 4px;
    cursor: pointer;


    @media (max-width: 600px) {
      min-width: calc(100% / 3 - 7px);
    }
  `,
  smallImage: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  imageButtonContainer: css`
    position: absolute;
    bottom: 5px;
    right: 5px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.4);
  ` 
}



const placeholderStyles = {
  placeholder: css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
  `,
  placeholderIcon: css`
    color: #979797;
    width: 20%;
    height: auto;
    max-width: 45px;
  `
}




const Placeholder = () => (
  <div css={placeholderStyles.placeholder}>
    <AddAPhotoIcon css={placeholderStyles.placeholderIcon} />
  </div>
)





const MerchantProductFormImagesSection = props => {


  const { 
    values,
    product,
    update,  
    destroy,
    showTips,
    showAlert,
    setFieldValue, 
    setFieldError
  } = props



  const [index, setIndex] = useState(0)

  
  const [images, setImages] = useState([])

  const [previewImages, setPreviewImages] = useState([])


  const imageCount = images.filter(i => i !== null).length 
  


  useEffect(() => {
    if (images.length === 0) {
      const savedImages = product?.attributes?.images || []

      const newImages = range(0, 10).map(i => {
        return (i < savedImages.length) ? savedImages[i] : null
      })

      const newPreviewImages = newImages.map(image => {
        if (image) {
          return image?.attributes?.imageDerivatives?.large || image?.attributes?.image
        }

        return null
      })


      setImages(newImages)
      setPreviewImages(newPreviewImages)
    }
  }, [images, product])






  const selectionCallback = i => (image, file) => {
    let newImages = [...images]
    let newPreviewImages = [...previewImages]

    newImages[i] = image
    newPreviewImages[i] = image

    setImages(newImages)
    setPreviewImages(newPreviewImages)
    setIndex(i)


    if (i === 0 && images[0] && product.id) {
      const formData = new FormData()

      formData.append('image', file, file.name, file.size)


      update('/products/' + product.id, 'PRODUCT', formData).then(response => {
        showAlert('Image uploaded', 'success')
      })
      .catch(error => {
        showAlert('Error updating image', 'error')
      })
    }
  }



  function previousImage() {
    for (let i = index - 1; i !== index; i--) {
      if (i < 0) {
        i = images.length - 1
      }

      if (images[i]) {
        setIndex(i)
        return
      }
    }
  }



  function nextImage() {
    const offset = index + 1

    // Loop the entire array starting at a specific index to find the next image
    for (let i = 0; i < images.length; i++) {
      const pointer = (i + offset) % images.length

      if (images[pointer]) {
        setIndex(pointer)
        return
      }
    }
  }



  const deleteImage = i => () => {
    const image = images[i]

    if (image) {
      let newImages = [...images]
      let newPreviewImages = [...previewImages]


      // Handles case if the image is a saved image in the database
      if (image?.id) {
        // The first image is required and is not able to be deleted. It can only be updated.
        if (i === 0) return

        destroy('/images/' + image.id, 'PRODUCT_IMAGES').then(response => {
          newImages[i] = null
          newPreviewImages[i] = null

          setImages(newImages)
          setPreviewImages(newPreviewImages)
          showAlert('Image deleted', 'success')
        })
        .catch(error => {
          const message = getErrorMessage(error)
          
          showAlert(message, 'error')
        })

        return 

      // Handles case if the image is a newly selected image
      } else {

        newImages[i] = null
        newPreviewImages[i] = null

        setImages(newImages)
        setPreviewImages(newPreviewImages)
        setFieldValue(`image${i}`, null)

        nextImage()
      }
    }
  }



  return (
    <div css={styles.root}>
      <div css={styles.header}>
        <h2>Images</h2>
        <p>Add up to ten images of your proudct by clicking on the smaller squares below.</p>
      </div>

      <div css={styles.body}>
        <div css={styles.imagesSection}>
          {showTips && 
            <div css={styles.tipContainer}>
               <h3>Tips</h3>
              
               <ul css={styles.list}>
                 <li>Try to use natural light and avoid using the flash.</li>
                 <li>Where applicable, show the item in use.</li>
                 <li>Use a clean simple background as the backdrop.</li>
                 <li>If scale is relevant, show the item next to something familar such as a penny.</li>
               </ul>
             </div>
          }


          <div css={styles.imagesContainer}>
            {images.map((image, i) => (
              <div key={i} css={styles.smallImageContainer}>
                {image && i !== 0 &&
                  <div css={styles.imageButtonContainer} onClick={deleteImage(i)}>
                    <DeleteIcon fontSize='small' sx={{fill: '#fff'}} />
                  </div>
                }

                <div css={styles.aspectRatioBox}>
                  <div css={styles.aspectRatioBoxInside}>
                    <ImageField
                      imageId={image?.id}
                      imageType='PRODUCTS'
                      showIcon={false}
                      fieldName={`image${i}`}
                      fieldValue={
                        values[`image${i}`] || 
                        image?.attributes?.imageDerivatives.small ||
                        image?.attributes?.image ||
                        image
                      }
                      setFieldValue={setFieldValue} 
                      setFieldError={setFieldError}
                      callback={selectionCallback(i)}
                      placeholder={<Placeholder />}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>


        <div css={styles.previewSection}>
          <div css={styles.infoContainer}>
            <h3>Large Image Preview</h3>
            
            <p>
              As you add images, you can preview the larger versions of the image here.
            </p>
          </div>

          <div css={styles.previewImage}>
            {imageCount > 1 &&
              <div css={styles.chevronContainer}>
                <div css={styles.leftChevron} onClick={previousImage}>
                  <ChevronLeftIcon 
                    fontSize='large'
                    sx={{fill: '#434343', strokeWidth: 0}} 
                  />
                </div>


                <div css={styles.rightChevron} onClick={nextImage}>
                  <ChevronRightIcon 
                    fontSize='large'
                    sx={{fill: '#434343', strokeWidth: 0}} 
                  />
                </div>
              </div>
            }


            <AspectImage src={previewImages[index]} alt='product' />
          </div>
        </div>
      </div>
    </div>
  )
}



export default connect(null, {showAlert, update, destroy})(MerchantProductFormImagesSection)




