import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import FindPerformers from '../performers/FindPerformers'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%', 
    height: '100%',
    minHeight: 600,
    padding: '40px 30px', 
    backgroundColor: 'white' 
  },
  title: {
    fontSize: '1.7rem',
    marginBottom: 24,
  },
  bold: {
    fontWeight: 800,
  },
  text: {
    marginBottom: 24
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: 10,
    right: 10
  }
}))


const InvitePerformers = props => {
  const classes = useStyles()
  const { performanceId, performers, handleClose } = props


  return (
    <div className={ classes.root }>
      <IconButton 
        className={ classes.closeButton } 
        onClick={ handleClose } 
        size="large">
        <CloseIcon color='disabled' />
      </IconButton>

      <Typography align='left' className={ classes.title }>
        <span className={ classes.bold }>
          Invite
        </span> Performers
      </Typography>

      <Typography className={ classes.text } variant='body2'>
        Search for artists or bands and invite them to perform at this performance.
        Just start typing the artist's or band's name below.
      </Typography>

      <FindPerformers 
        performanceId={ performanceId }
        performers={ performers } 
        placeholder='Artists or bands' 
      />
    </div>
  );
}

export default InvitePerformers