import React, {useState, useMemo, useRef} from 'react'

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {useIsMounted} from '../../hooks'

import BandCard from './BandCard'
import TrackCard from './TrackCard'
import VenueCard from './VenueCard'
import ArtistCard from './ArtistCard'
import ProductCard from './ProductCard'
import PerformanceCard from './PerformanceCard'

import {VirtuosoGrid} from 'react-virtuoso'

import ThreeDotProgress from '../generics/ThreeDotProgress'

import styled from '@emotion/styled'



export const ItemContainer = styled.div`
  width: 20%;
  height: 350px;
  display: flex;
  flex: none;
  align-content: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: 25%;
  }

  @media (max-width: 900px) {
    width: 33%;
  }

  @media (max-width: 700px) {
    width: 50%;
  }
`



export const ProductItemContainer = styled.div`
  width: 25%;
  height: auto;
  display: flex;
  flex: none;
  align-content: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;


  @media (max-width: 1130px) {
    width: 33%;
  }

  @media (max-width: 700px) {
    width: 50%;
  }
`



export const PerformanceItemContainer = styled.div`
  width: 20%;
  height: 400px;
  display: flex;
  flex: none;
  align-content: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: 25%;
  }

  @media (max-width: 900px) {
    width: 33%;
  }

  @media (max-width: 700px) {
    width: 50%;
  }
`


const ListContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
`



const styles = {
  root: css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  `,
  footer: css`
    padding: 2rem;
    padding-bottom: 50px;
    min-height: 15px;
    display: flex;
    justify-content: center;
  `
}




const isEqual = (prevProps, nextProps) => (
  prevProps.type === nextProps.type &&
  prevProps.hasNextPage === nextProps.hasNextPage &&
  prevProps.cards.length === nextProps.cards.length &&
  prevProps.fetchMoreItems === nextProps.fetchMoreItems
)




const CardList = props => {

  const isMounted = useIsMounted()

  const ref = useRef()

  const {
    type,
    cards,
    cardProps,
    fetchMoreItems, 
    hasNextPage
  } = props


  const [isFetching, setIsFetching] = useState(false)

  const itemCount = cards.length


  const Item = useMemo(() => {
    switch (type) {
      case 'performance':
        return PerformanceItemContainer
      case 'product':
        return ProductItemContainer
      default:
        return ItemContainer
    }
  }, [type])



  const Cards = useMemo(() => (
    cards.map(card => {
      if (React.isValidElement(card)) {
        return card
      }

      switch (card.type) {
      case 'artist':
         return <ArtistCard artist={card} />
      case 'band':
        return <BandCard band={card} />
      case 'track':
        return <TrackCard track={card} />
      case 'venue':
        return <VenueCard venue={card} />
      case 'product':
        return <ProductCard product={card} {...cardProps} />
      case 'performance':
        return <PerformanceCard performance={card} />
      default:
        return null
      }
    })
  ), [cards, cardProps]) 




  return (
    <>
      <VirtuosoGrid
        ref={ref}
        css={styles.root}
        style={{height: '100%', width: '100%'}}
        totalCount={itemCount}
        overscan={800}
        itemContent={index => Cards[index]}
        computeItemKey={index => cards[index].id || index}
        endReached={index => {
          if (hasNextPage) {
            setIsFetching(true)
            fetchMoreItems({index, setIsFetching, isMounted}) 
          }
        }}
        useWindowScroll
        components={{ 
          Item: Item,
          List: ListContainer
        }}
      />

      <div css={styles.footer}>
        {isFetching && <ThreeDotProgress radius={6} />}
      </div>
    </>
  )
}



export default React.memo(CardList, isEqual)


