import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import CurrentPerformancePermissions from './CurrentPerformancePermissions'
import AddNewPerformancePermissions from './AddNewPerformancePermissions'
import { CloseButton } from '../../generics'

const useStyles = makeStyles(theme => ({
  root: {
    height: 700,
    padding: 30
  },
  close: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  title: {
    fontSize: '1.7rem',
    marginBottom: 24,
    marginTop: 24
  },
  bold: {
    fontWeight: 800,
  },
  text: {
    marginBottom: 24
  }
}))


const PerformancePermissions = props => {
  const classes = useStyles()
  const [users, setUsers] = useState({})
  const { performanceId, handleClose } = props


  return (
    <div className={ classes.root }>
      <CloseButton onClick={ handleClose } />

      <Typography align='left' className={ classes.title }>
        <span className={ classes.bold }>
          Performance
        </span> Permissions
      </Typography>

      <Typography className={ classes.text } variant='body2'>
        You can grant other users access to edit this performance. To do so, 
        search for the user by username or full name and grant them access in
        the dropdown.
      </Typography>
      
      <AddNewPerformancePermissions 
        performanceId={ performanceId }
        users={ users }
        setUsers={ setUsers } 
        placeholder='Username or full name' 
      />
      
      <CurrentPerformancePermissions 
        performanceId={ performanceId } 
        users={ users }
        setUsers={ setUsers } 
      />
    </div>
  )
}

export default PerformancePermissions