import { combineReducers } from 'redux'

import user from './user'

import performances from './performancesReducer'
import bands from './bandsReducer'
import alert from './alertReducer'
import player from './playerReducer'
import tracks from './tracksReducer'
import artists from './artistsReducer'
import venues from './venuesReducer'
import search from './search/searchReducer'
import map from './mapReducer'
import form from './formReducer'

import shops from './ecommerce/shopsReducer'
import products from './ecommerce/productsReducer'


const appState = combineReducers({
  user,
  artists,
  venues,
  bands,
  tracks,
  products,
  performances,
  player,
  search,
  alert,
  map,
  form,
  shops
})



const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined
  }
      
  return appState(state, action)
}

export default rootReducer