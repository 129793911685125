import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import Dialog from '@mui/material/Dialog'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import BarChartIcon from '@mui/icons-material/BarChart'

import Stats from '../Stats'



const cssStyles = ({color}) => ({
  menuItem: css`
    color: ${color ? color : 'rgba(0, 0, 0, 0.54)'};
    
    svg {
      width: 24px;
      margin-right: 10px;
    }

    span {
      color: #434343;
    }
  `
})


const StatsIconButton = props => {

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const [open, setOpen] = useState(false)

  const {id, type, color, variant, size, ...rest} = props

  const styles = cssStyles({color})

  const iconSize = size || (isExtraSmallScreen ? 'small' : 'medium')
  



  function handleClick() {
    setOpen(true)
  }



  function close() {
    setOpen(false)
  }



  return (
    <>
      {variant === 'menuItem' && 
        <MenuItem onClick={handleClick} css={styles.menuItem} {...rest}>
          <BarChartIcon fontSize={iconSize || 'medium'} />
          <span>Stats</span>
        </MenuItem>
      }
      

      {(variant === 'button' || !variant) &&
        <IconButton onClick={handleClick} size={iconSize} {...rest}>
          <BarChartIcon fontSize={iconSize} style={{color: (color || '#fff')}} />
        </IconButton>
      }


      <Dialog 
        fullWidth
        maxWidth='xs'
        open={open} 
        onClose={close} 
        disableScrollLock={isExtraSmallScreen ? false : true }
        sx={{backdropFilter: 'blur(10px)'}}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            boxShadow: 'none'
          }
        }}
      >
        <Stats id={id} type={type} />
      </Dialog>
    </>
  )
}



export default StatsIconButton


