import { index, show } from '../apis'



export function fetchFavoritesAndAssociationsOfType(type, data) {
  return dispatch => {
    dispatch({ type: 'FAVORITES_REQUESTED' })

    return index('/favorite-' + type, data)
      .then(response => {
        const objects = response.data.included
        const links = response.data.links
        const orderedFavorites = response.data.data.map(e => e.attributes.favoritableId)
        
        const favorites = response.data.data.reduce((acc, favorite) => {
          const objectId = favorite.attributes.favoritableId
          acc[objectId] = favorite
          return acc
        }, {})


        dispatch({ type: type.toUpperCase() + '_INDEX_SUCCESS', payload: { data: objects }})

        dispatch({ type: 'FAVORITES_WITH_ASSOCIATIONS_INDEX_SUCCESS', payload: { 
          type, 
          links,
          favorites,
          orderedFavorites
        }})

        return response.data
      })
      .catch(error => {
        dispatch({ type: 'FAVORITES_ERROR', payload: { type }})
        throw(error)
      })
  }
}





export function fetchIsFavoriteOfTypeAndId(type, id) {
  return dispatch => {
    dispatch({ type: 'FAVORITES_REQUESTED' })

    return show('/' + type.slice + '/' + id + '/details').then(response => {
      const data = response.data.data

      dispatch({ type: 'FAVORITE_SHOW_SUCCESS', payload: { id, type, data }})
    })
    .catch(error => {
      dispatch({ type: 'FAVORITES_ERROR', payload: { id, type }})
      throw(error)
    })
  }
}



export function fetchFavoritesOfType(type) {
  return dispatch => {
    dispatch({ type: 'FAVORITES_REQUESTED' })

    return index('/user/favorite-' + type).then(response => {
      const favorites = response.data.data.reduce((acc, favorite) => {
        const objectId = favorite.attributes.favoritableId

        acc[objectId] = favorite
        return acc
      }, {})

      dispatch({ type: 'FAVORITES_INDEX_SUCCESS', payload: { type, favorites }})
    })
    .catch(error => {
      dispatch({ type: 'FAVORITES_ERROR', payload: { type }})
      throw(error)
    })
  }
}


export function orderFavoritesOfType(type, objects) {
  return dispatch => {  
    const objectIds = objects.map(e => e.id)

    dispatch({ type: 'ORDER_FAVORTIES_OF_TYPE', payload: { type, objectIds }})
  }
}


