import React, {useState, useEffect} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import parse from 'autosuggest-highlight/parse'

import {userCoordinatesSelector} from '../../../selectors'

import {useIsMounted, usePlaceDetails, usePlacePredictions} from '../../../hooks'

import {updateLocationFilter, showAlert, initiateSearch, panTo} from '../../../actions'

import Zoom from '@mui/material/Zoom'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'


import {HiLocationMarker} from 'react-icons/hi'




const styles = {
  root: css`
    position: relative;
    height: 100%;
  `,
  messageContainer: css`
    height: 100%;
    position: absolute;
    right: 2px;
    display: flex;
    align-items: center;
    z-index: 10;

    div {
      height: calc(100% - 2px);
      padding: 0 10px;
      border-radius: 20px;
      background-color: #e5f8e5;
      display: flex;
      align-items: center;
    }
  `,
  message: css`
    color: green;
    font-size: 0.9rem;
    font-weight: 500;
  `,
  dropdown: css`
    margin-top: 15px;
    padding-bottom: 150px;
    min-height: calc(100vh - 165px);
    box-shadow: none;
    background-color: transparent;
  `,
  locationUpdateMessageContainer: css`
    height: 100%;
    position: absolute;
    right: 0;
  `,
  prediction: css`
    letter-spacing: 0rem;
    margin-bottom: 10px;
  `,
  predictionAdornment: css`
    margin-right: 10px;
  `,
  textContainer: css`

    span {
      font-size: 1rem;
    }
  `
}



const currentLocation = {
  id: 1,
  place_id: 'current_location',
  description: 'Current Location',
  structured_formatting: {
    main_text_matched_substrings: [],
    main_text: 'Current Location',
    secondary_text: 'Your current location'
  }
}



const LocationSearchMobile = props => {

  const isMounted = useIsMounted()


  const {
    name,
    panTo,
    open,
    setOpen,
    showAlert,
    locationRef,
    containerRef,
    isUserLocated,
    initiateSearch,
    shouldInitiateSearch,
    userCoordinates,
    updateLocationFilter
  } = props


  const [value, setValue] = useState(null)

  const [locationChanged, setLocationChanged] = useState(false)

  // Location search 
  const [placeId, setPlaceId] = useState()

  const [inputValue, setInputValue] = useState('')

  const [predictions] = usePlacePredictions(inputValue)

  const place = usePlaceDetails(placeId)

  const predictionsWithCurrentLocation = [currentLocation, ...predictions]
  // ---------------------------------------------------------------------



  useEffect(() => {
    if (place) {
      const id = place.place_id

      const name = place.displayName

      const lat = place.location.lat()
      const lng = place.location.lng()


      updateLocationFilter({lat, lng}, name, id)
      // initiateSearch()

      panTo({lat, lng})

    }
  }, [
    panTo, 
    place, 
    initiateSearch, 
    updateLocationFilter
  ])






  function handleChange(_, newValue) {
    if (newValue && newValue.place_id) {
      const placeId = newValue.place_id

      // This handles if a user selects 'Current Location'
      if (placeId === 'current_location') {
        if (isUserLocated) {
          
          updateLocationFilter(userCoordinates, 'Current Location', 1)
          initiateSearch()
          panTo(userCoordinates)
        
        } else {

          const message = `Your location couldn't be found. If you denied access to your location, 
            you can grant access in your browser settings.`
          
          showAlert(message, 'info')
          return
        }


        // Because 'Current Location' does not have an actual placeId,
        // we only set the value and return
        setValue(newValue)
        return
      }

      setValue(newValue)
      setPlaceId(placeId)
      setLocationChanged(true)
    }
  }




  function handleKeyPress(e) {
    e.stopPropagation()

    if (e.key === 'Enter') {
      if (predictions[0]) {
        handleChange(null, predictions[0])
      }
    }
  }



  function handleInputChange(_, newInputValue) {
    if (newInputValue === 'Current Location' && !isUserLocated) {
      return
    }

    setInputValue(newInputValue)
  }



  function predictionText(prediction) {
    return typeof prediction === 'string' ? prediction : prediction.description
  }



  function isOptionEqualToValue(prediction, value) {
    return prediction.place_id === value.place_id
  }



  function handleFocus() {
    setOpen('location')
  }



  function clearNotice() {
    setTimeout(() => {
      if (isMounted.current) {
        setLocationChanged(false)
      }
    }, 2000)
  }



  return (
    <div css={styles.root}>
{/*      <Zoom in={locationChanged} addEndListener={clearNotice}>
        <div css={styles.messageContainer }>
          <div>
            <p css={styles.message}>UPDATED</p>
          </div>
        </div>
      </Zoom>*/}


      <Autocomplete
        freeSolo
        onFocus={handleFocus}
        autoComplete
        sx={{
          height: '100%',

          '& .MuiAutocomplete-endAdornment': {
            marginRight: '10px'
          }
        }}
        value={value}
        inputValue={inputValue}
        includeInputInList
        filterSelectedOptions
        getOptionLabel={predictionText}
        options={predictionsWithCurrentLocation}
        filterOptions={option => option}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        onInputChange={handleInputChange}
        open={open === 'location'}
        PaperComponent={params => (
          <Paper {...params} css={styles.dropdown} />
        )}
        PopperComponent={params => (
          <Popper 
            {...params}
            anchorEl={containerRef.current}
            container={containerRef.current}
          />
        )}
        ListboxProps={{sx: {maxHeight: '100%'}}} 
        renderInput={params => (
          <TextField
            {...params}
            inputRef={locationRef}
            variant='standard'
            placeholder={name}
            InputProps={{
              ...params.InputProps,

              disableUnderline: true,

              onTouchStart: e => e.stopPropagation(),


              sx: {
                backgroundColor: '#fff',
                height: '40px',
                borderRadius: '20px',

                '&.MuiInput-root': {
                  height: '40px',
                  padding: '3px 9px 3px 3px'
                }
              },
              
              startAdornment: (
                <InputAdornment position='start' sx={{width: '36px'}}>
                  <IconButton>
                     <HiLocationMarker
                      size={27} 
                      color={open === 'location' ? 'rgb(62, 166, 255)' : '#000'}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        )}
        renderOption={(props, prediction, {selected}) => {
          const matches = prediction.structured_formatting.main_text_matched_substrings

          const parts = parse(
            prediction.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          )

          const isDisabled = (prediction.place_id === 'current_location' && !isUserLocated)


          return (
            <div {...props} style={{opacity: isDisabled ? 0.3 : 1}} css={styles.prediction}>
              <div css={styles.predictionAdornment}>
                <HiLocationMarker size={20} />
              </div>

              <div css={styles.textContainer}>
                {parts.map((part, index) => (
                  <span key={index} style={{fontWeight: part.highlight ? 600 : 400}}>
                    {part.text}
                  </span>
                ))}

                <p>{prediction.structured_formatting.secondary_text}</p>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}


const mapStateToProps = state => {
  
  const coordinates = userCoordinatesSelector(state)

  return {
    name: state.search.filters.location.name,
    isUserLocated: state.user.location.isLocated,
    userCoordinates: coordinates
  }
}


const actions = {showAlert, updateLocationFilter, initiateSearch, panTo}



export default connect(mapStateToProps, actions)(LocationSearchMobile)






