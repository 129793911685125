import { omit } from 'lodash'
import { capitalizeFirstLetter } from '../../utils'


// The below objects have a format of objectId : favoriteId
const INITIAL_STATE = {
  performances: {},
  orderedPerformances: [],
  performanceLinks: {},
  artists: {},
  orderedArtists: [],
  artistLinks: {},
  bands: {},
  orderedBands: [],
  bandLinks: {},
  tracks: {},
  orderedTracks: [],
  trackLinks: {},
  venues: {},
  orderedVenues: [],
  venueLinks: {},
  fetched: [],
  isFetching: false
}



 const favoriteReducer = (state = INITIAL_STATE, action) => {
  let id, type, orderedType

  switch (action.type) {
    case 'FAVORITES_REQUESTED':
      return { ...state,
        isFetching: true
      }
    case 'FAVORITES_WITH_ASSOCIATIONS_INDEX_SUCCESS':
      type = action.payload.type
      orderedType = 'ordered' + capitalizeFirstLetter(action.payload.type)

      return { ...state,
        isFetching: false,
        [type]: {
          ...state[type],
          ...action.payload.favorites
        },
        [orderedType]: [...state[orderedType], ...action.payload.orderedFavorites],
        [type.slice(0, -1) + 'Links']: action.payload.links
      }
    case 'FAVORITES_INDEX_SUCCESS':
      type = action.payload.type

      return { ...state,
        isFetching: false,
        fetched: [...state.fetched, type],
        [type]: action.payload.favorites
      }
    case 'FAVORITES_SAVE_LINKS':
      type = action.payload.type

      return { ...state,
        [type.slice(0, -1) + 'Links']: action.payload.links
      }
    case 'ORDER_FAVORTIES_OF_TYPE':
      orderedType = 'ordered' + capitalizeFirstLetter(action.payload.type)

      return { ...state,
        [orderedType]: [...state[orderedType], ...action.payload.objectIds]
      }
    case 'FAVORITE_SHOW_SUCCESS':
      id = action.payload.id
      type = action.payload.type

      return { ...state,
        isFetching: false,
        [type]: { 
          ...state[type],
          [id]: action.payload.data
        },
      }
    case 'FAVORITE_CREATE_SUCCESS':
      id = action.payload.data.attributes.favoritableId
      type = action.payload.data.attributes.favoritableType.toLowerCase() + 's'
      orderedType = 'ordered' + capitalizeFirstLetter(type)

      return { ...state,
        isFetching: false,
        [orderedType]: [ id, ...state[orderedType]],
        [type]: { 
          ...state[type],
          [id]: action.payload.data
        }
      }
    case 'FAVORITE_DESTROY_SUCCESS':
      id = action.payload.data.attributes.favoritableId
      type = action.payload.data.attributes.favoritableType.toLowerCase() + 's'
      orderedType = 'ordered' + capitalizeFirstLetter(type)

      return { ...state,
        isFetching: false,
        [type]: omit(state[type], id),
        [orderedType]: state[orderedType].filter(e => e !== id)
      }
    case 'FAVORITES_ERROR':
      id = action.payload.id
      type = action.payload.type

      if (id) {
        return { ...state,
          isFetching: false,
          [type]: { 
            ...state[type],
            [id]: false
          }
        }
      } else {
        return { ...state,
          isFetching: false,
          fetched: [type, ...state.fetched]
        }
      }
    default:
      return state
  }
}

export default favoriteReducer



