import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {useNavigate} from 'react-router-dom'

import {cartQuantitySelector} from '../../selectors'

import Badge from '@mui/material/Badge'
import BagIcon from '@mui/icons-material/LocalMall'
import IconButton from '@mui/material/IconButton'
import NotificationsIcon from '@mui/icons-material/Notifications'

import CartMenu from './cart/CartMenu'
import SearchBar from './search-bar/SearchBar'
import HeaderNotificationMenu from './notifications/HeaderNotificationMenu'

import {CkordLogo} from '../generics'




const styles = {
  root: css`
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    gap: 25px;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
  `,
  leftSide: css`
    display: flex;
    align-items: center;
    gap: 5px;
  `,
  rightSide: css`
    display: flex;
    gap: 10px;
    align-items: center;
  `
}




const AuthenticatedHeader = props => {

  const navigate = useNavigate()

  const isMobileSearchBar = useMediaQuery('(max-width: 1200px)')
  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.only('md'))
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))



  const [cartAnchorEl, setCartAnchorEl] = useState(null)

  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null)


  const {Sidebar, unreadCount, itemCount} = props


  
  function goHome() {
    navigate('/home')
  }


  function showCart(e) {
    setCartAnchorEl(e.currentTarget)
  }



  function showNotifications(e) {
    setNotificationAnchorEl(e.currentTarget)
  }
  


  return (
    <div css={styles.root}>
      <div css={styles.leftSide}>
        {Sidebar}

        <div onClick={goHome}>
          <CkordLogo style={{color: '#fff', fontSize: '21px', cursor: 'pointer'}} />
        </div>
      </div>

      <SearchBar />

      <div css={styles.rightSide}>
        <IconButton onClick={showNotifications}>
          <Badge
            sx={{
              '.MuiBadge-badge': {
                top: -1,
                right: -1,
                color: '#fff',
                backgroundColor: '#ff0055'
              }
            }}
            overlap='circular' 
            badgeContent={unreadCount} 
          >
            <NotificationsIcon color='secondary' />
          </Badge>
        </IconButton>


        <IconButton onClick={showCart}> 
          <Badge 
            sx={{
              '.MuiBadge-badge': {
                top: -1,
                right: -1,
                color: '#fff',
                backgroundColor: '#ff0055'
              }
            }}
            overlap='circular'
            badgeContent={itemCount} 
          >
            <BagIcon color='secondary' />
          </Badge>
        </IconButton>
      </div>


      <HeaderNotificationMenu
        anchorEl={notificationAnchorEl} 
        setAnchorEl={setNotificationAnchorEl}
        isExtraSmallScreen={isExtraSmallScreen}
      />


      <CartMenu anchorEl={cartAnchorEl} setAnchorEl={setCartAnchorEl} />
    </div>
  )
}



const mapStateToProps = state => {
  return {
    itemCount: cartQuantitySelector(state),
    unreadCount: state.user.notifications.unread
  }
}



export default connect(mapStateToProps)(AuthenticatedHeader)




