import { css } from '@emotion/react'

export const artistCardStyles = () => (
  {
    card: css`
      width: 200px;
      height: 330px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      border-radius: 6px;
      overflow: hidden;
      cursor: pointer;
      transition: 0.3s;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px;
    `,
    imageContainer: css`
      width: 200px;
      height: 200px;
      border-radius: 6px 6px 0px 0px;
      position: relative;
      overflow: hidden;
    `,
    image: css`
      width: 100%;
      height: 100%;
      object-fit: cover;
    `,
    iconContainer: css`
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s;
      z-index: 10;
    `,
    iconSubContainer: css`
      width: 100%;
      height: 100%;
      position: relative;
    `,
    iconCircle: css`
      position: absolute;
      bottom: 5px;
      right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.6);
    `,
    infoContainer: css`
      padding: 12px 12px 0px 12px;
      flex-grow: 1; 
      min-width: 0;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: flex-start;
    `,
    title: css`
      width: 100%;
      max-width: 100%;
      font-weight: 700;
      font-size: 1.2rem;
      padding-right: 5px;
      margin-bottom: 2px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
    `,
    buttons: css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-height: 52px;
      padding: 4px
    `,
    link: css`
      text-decoration: none;
      color: inherit;
    `
  }
)


export default artistCardStyles

