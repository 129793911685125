import {imageMimes} from '../helpers/mimeTypes' 


export function validate(file) {

  const type = file.type
  
  const size = file.size
  
  const result = {passed: true, message: ''}



  if (!imageMimes.includes(type)) {
    result.passed = false
    result.message = 'Image must be of type jpeg, png, or webp' 
  
  } else if (size > 15*1024*1024) {
    result.passed = false
    result.message = 'Maximum allowable image size is 15MB'
  }

  return result
}