import {useParams, useSearchParams} from 'react-router-dom'



const withRouter = Component => props => {
  
  const {id} = useParams()

  const [queryParameters] = useSearchParams()


  return (
    <Component id={id} queryParameters={queryParameters} {...props} />
  )
}



export default withRouter