import { css } from '@emotion/react'

export const headerMessageNotificationStyles = ({ theme }) => (
  {
    root: css`
      width: 100%;
      display: flex;
      position: relative;
    `,
    avatarContainer: css`
      margin-right: 12px;
    `,
    avatar: css`
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
    `,
    contentContainer: css`
      flex-grow: 1;
      overflow: hidden;
      text-overflow: hidden;
    `,
    title: css`
      font-size: 0.9rem;
      font-weight: 600;
      padding-bottom: 3px;
      padding-right: 25px;
      color: ${theme.palette.primary._700}
    `,
    message: css`
      font-size: 0.9rem;
      padding-right: 25px;
      color: ${theme.palette.primary._700}
    `,
    time: css`
      font-size: 0.75rem;
      font-weight: 500;
      color: ${theme.palette.primary._400};
    `,
  }
)



export default headerMessageNotificationStyles