export function convertVideoToTrackFormat(videoURL, composer, composerId, composerType = 'Artist') {
  return {
    id: crypto.randomUUID(),
    type: 'track',
    attributes: {
      isVideoTrack: true,
      isSpotifyTrack: false,
      name: '',
      audio: null,
      previewAudio: null,
      video: videoURL,
      composer: composer,
      composerId: composerId,
      composerType: composerType,
      image: '',
      imageDerivatives: {
        small: ''
      },
      tags: []
    }
  }
}



export default convertVideoToTrackFormat