import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { connect } from 'react-redux'
import { cartQuantitySelector } from '../../../selectors'

import NotificationsIcon from '@mui/icons-material/Notifications'
import HeaderNotificationMenu from '../../header/notifications/HeaderNotificationMenu'
import CartMenu from '../cart/CartMenu'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import BagIcon from '@mui/icons-material/LocalMall'

import {CkordLogo} from '../../generics'



const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    paddingLeft: 20,
    paddingRight: 20
  },
  leftSide: {
    display: 'flex',
    gap: 5,
    alignItems: 'center'
  },
  badge: {
    backgroundColor: theme.palette.pink._500,
    color: 'white',
    top: 1,
    right: 1
  }
}))



const HomePageAuthenticatedHeader = props => {

  const classes = useStyles()

  const [cartAnchorEl, setCartAnchorEl] = useState(null)
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null)

  const {Sidebar,  unread, itemCount } = props


  return (
    <div className={ classes.root }>

      <div className={ classes.leftSide }>
        {Sidebar}

        <CkordLogo style={{fontSize: '21px', cursor: 'pointer'}} />
      </div>

      <div>
        <IconButton onClick={ e => setNotificationAnchorEl(e.currentTarget) } size='large'>
          <Badge badgeContent={ unread } overlap='circular' classes={{ badge: classes.badge }}>
            <NotificationsIcon fontSize='medium' color='primary' />
          </Badge>
        </IconButton>

        <IconButton onClick={ e => setCartAnchorEl(e.currentTarget) }> 
          <Badge 
            badgeContent={ itemCount } 
            overlap='circular' 
            classes={{ badge: classes.badge }}
          >
            <BagIcon fontSize='medium' color='primary' />
          </Badge>
        </IconButton>
      </div>

      <HeaderNotificationMenu
        anchorEl={ notificationAnchorEl } 
        setAnchorEl={ setNotificationAnchorEl } 
      />

      <CartMenu anchorEl={ cartAnchorEl } setAnchorEl={ setCartAnchorEl } />
    </div>
  )
}


const mapStateToProps = state => {
  return {
    unread: state.user.notifications.unread,
    itemCount: cartQuantitySelector(state)
  }
}

export default connect(mapStateToProps)(HomePageAuthenticatedHeader)





