import { show } from '../apis'



export function fetchLFFForTypeAndId(type, id) {
  return dispatch => {
    dispatch({ type: 'LFF_REQUESTED' })

    return show('/' + type + '/' + id + '/lff').then(response => {
      const like = response.data.data.like
      const favorite = response.data.data.favorite
      const follow = response.data.data.follow

      dispatch({ type: 'LIKE_SHOW_SUCCESS', payload: { id, type, data: like }})
      dispatch({ type: 'FAVORITE_SHOW_SUCCESS', payload: { id, type, data: favorite }})
      dispatch({ type: 'FOLLOW_SHOW_SUCCESS', payload: { id, type, data: follow }})
      dispatch({ type: 'LFF_SUCCESS' })
    })
    .catch(error => {
      dispatch({ type: 'LFF_FAILURE' })
      throw(error)
    })
  }
}