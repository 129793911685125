function prepareData(data) {
  return Array.isArray(data) ? data : [{id: data.id, type: data.type}]
}

//////////////////////////////////////////////////////////////////////////////
// Artist Relationships
//////////////////////////////////////////////////////////////////////////////

export function addTracksToArtist(artistId, tracks = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_TRACKS_TO_ARTIST', 
      payload: { id: artistId, data: prepareData(tracks) } 
    })
  }
}


export function addTrackToArtist(artistId, tracks = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_TRACK_TO_ARTIST', 
      payload: { id: artistId, data: prepareData(tracks) } 
    })
  }
}


export function addPerformancesToArtist(artistId, performances = [], showPast = false) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_PERFORMANCES_TO_ARTIST', 
      payload: { 
        id: artistId, 
        data: prepareData(performances),
        showPast: showPast
      } 
    })
  }
}


export function addNearbyPerformancesToArtist(artistId, performances = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_NEARBY_PERFORMANCES_TO_ARTIST', 
      payload: { id: artistId, data: prepareData(performances) } 
    })
  }
}



export function addPerformanceToArtist(artistId, performances = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_PERFORMANCE_TO_ARTIST', 
      payload: { id: artistId, data: prepareData(performances) } 
    })
  }
}


export function addBandsToArtist(artistId, bands = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_BANDS_TO_ARTIST', 
      payload: { id: artistId, data: prepareData(bands) } 
    })
  }
}


export function addProductsToArtist(artistId, products = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_PRODUCTS_TO_ARTIST', 
      payload: { id: artistId, data: prepareData(products) } 
    })
  }
}



export function removeBandFromArtist(artistId, bandId) {
  return dispatch => {
    dispatch({ 
      type: 'REMOVE_BAND_FROM_ARTIST', 
      payload: { id: artistId, relationshipId: bandId } 
    })
  }
}


export function removeTrackFromArtist(artistId, trackId) {
  return dispatch => {
    dispatch({ 
      type: 'REMOVE_TRACK_FROM_ARTIST', 
      payload: { id: artistId, relationshipId: trackId } 
    })
  }
}


export function removePerformanceFromArtist(artistId, performanceId) {
  return dispatch => {
    dispatch({ 
      type: 'REMOVE_PERFORMANCE_FROM_ARTIST', 
      payload: { id: artistId, relationshipId: performanceId } 
    })
  }
}


export function removeSpotifyTracksFromArtist(artistId) {
  return dispatch => {
    dispatch({type: 'REMOVE_SPOTIFY_TRACKS_FROM_ARTIST', payload: artistId})
  }
}

//////////////////////////////////////////////////////////////////////////////
// Venue Relationships
//////////////////////////////////////////////////////////////////////////////


export function addPerformancesToVenue(venueId, performances = [], showPast = false) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_PERFORMANCES_TO_VENUE', 
      payload: { 
        id: venueId, 
        data: prepareData(performances),
        showPast: showPast
      } 
    })
  }
}


export function addPerformanceToVenue(venueId, performances = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_PERFORMANCE_TO_VENUE', 
      payload: { id: venueId, data: prepareData(performances) } 
    })
  }
}


export function removePerformanceFromVenue(venueId, performanceId) {
  return dispatch => {
    dispatch({ 
      type: 'REMOVE_PERFORMANCE_FROM_VENUE', 
      payload: { id: venueId, relationshipId: performanceId } 
    })
  }
}


export function addPerformersToVenue(venueId, performers = []) {
  const payload = { data: performers.included }

  return dispatch => {
    dispatch({ type: 'BANDS_INDEX_SUCCESS', payload })
    dispatch({ type: 'ARTISTS_INDEX_SUCCESS', payload })
    dispatch({ 
      type: 'ADD_PERFORMER_TO_VENUE', 
      payload: { id: venueId, data: performers.included }
    })
  }
}

//////////////////////////////////////////////////////////////////////////////
// Band Relationships
//////////////////////////////////////////////////////////////////////////////

export function addArtistsToBand(bandId, artists = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_ARTIST_TO_BAND', 
      payload: { id: bandId, data: prepareData(artists) } 
    })
  }
}


export function addTracksToBand(bandId, tracks = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_TRACKS_TO_BAND', 
      payload: { id: bandId, data: prepareData(tracks) } 
    })
  }
}


export function addTrackToBand(bandId, tracks = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_TRACK_TO_BAND', 
      payload: { id: bandId, data: prepareData(tracks) } 
    })
  }
}


export function addPerformancesToBand(bandId, performances = [], showPast = false) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_PERFORMANCES_TO_BAND', 
      payload: { 
        id: bandId, 
        data: prepareData(performances),
        showPast: showPast
      } 
    })
  }
}


export function addNearbyPerformancesToBand(bandId, performances = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_NEARBY_PERFORMANCES_TO_BAND', 
      payload: { id: bandId, data: prepareData(performances) } 
    })
  }
}


export function addPerformanceToBand(bandId, performances = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_PERFORMANCE_TO_BAND', 
      payload: { id: bandId, data: prepareData(performances) } 
    })
  }
}


export function removePerformanceFromBand(bandId, performanceId) {
  return dispatch => {
    dispatch({ 
      type: 'REMOVE_PERFORMANCE_FROM_BAND', 
      payload: { id: bandId, relationshipId: performanceId } 
    })
  }
}


export function removeTrackFromBand(bandId, trackId) {
  return dispatch => {
    dispatch({ 
      type: 'REMOVE_TRACK_FROM_BAND', 
      payload: { id: bandId, relationshipId: trackId } 
    })
  }
}


export function removeSpotifyTracksFromBand(bandId) {
  return dispatch => {
    dispatch({type: 'REMOVE_SPOTIFY_TRACKS_FROM_BAND', payload: bandId})
  }
}


export function removeBandmate(bandId, artistId) {
  return dispatch => {
    dispatch({
      type: 'REMOVE_ARTIST_FROM_BAND',
      payload: { id: bandId, relationshipId: artistId }
    })
    dispatch({
      type: 'REMOVE_BAND_FROM_ARTIST',
      payload: { id: artistId, relationshipId: bandId }
    })
  }
}

//////////////////////////////////////////////////////////////////////////////
// Performance Relationships
//////////////////////////////////////////////////////////////////////////////

export function addTracksToPerformance(performanceID, tracks = []) {
  return dispatch => {
    dispatch({type: 'ADD_TRACK_TO_PERFORMANCE', payload: {
      id: performanceID, 
      data: prepareData(tracks) 
    }})
  }
}


export function addPerformersToPerformance(performanceId, performers = []) {
  const payload = { data: performers.included }

  return dispatch => {
    dispatch({ type: 'BANDS_INDEX_SUCCESS', payload })
    dispatch({ type: 'ARTISTS_INDEX_SUCCESS', payload })
    dispatch({ 
      type: 'ADD_PERFORMER_TO_PERFORMANCE', 
      payload: { id: performanceId, data: performers.included }
    })
  }
}


export function addPerformerToPerformance(performanceId, performer) {
  return dispatch => {
    dispatch({type: 'ADD_PERFORMER_TO_PERFORMANCE', payload: {id: performanceId, data: [performer]}})
  }
}


export function removePerformerFromPerformance(performance, performerID, performerType) {
  return dispatch => {
    dispatch({type: 'REMOVE_PERFORMER_FROM_PERFORMANCE', payload: {
      id: performance.id, 
      relationshipId: performerID
    }})
    
    dispatch({
      type: 'REMOVE_PERFORMANCE_FROM_' + performerType.toUpperCase(),  
      payload: {id: performerID, relationshipId: performance.id}
    })
  }
}


export function setFetchedAssociations(type, id, value) {
  return dispatch => {
    dispatch({ 
      type: 'SET_' + type + '_FETCHED_ASSOCIATIONS', 
      payload: { id, value }
    })
  }
}


//////////////////////////////////////////////////////////////////////////////
// Merchant Relationships
//////////////////////////////////////////////////////////////////////////////

export function addProductsToMerchant(id, type, products = []) {
  return dispatch => {
    dispatch({ 
      type: 'ADD_PRODUCTS_TO_' + type.toUpperCase(), 
      payload: { id: id, data: prepareData(products) } 
    })
  }
}



export function addShopToMerchant(id, type, shopId) {
  return dispatch => {
    dispatch({type: 'ADD_SHOP_TO_' + type.toUpperCase(), payload: {id, shopId}})
  }
}



export function addRelationToX(relation, relationType, xId, xType) {
  const type = 'ADD_' + relationType.toUpperCase() + '_TO_' + xType.toUpperCase()

  return dispatch => dispatch({type, payload: {id: xId, data: prepareData(relation)}})
}








