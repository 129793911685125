import {startOfDay} from 'date-fns'

import {combineDateAndTime} from '../../utils'


const date = new Date()



const INITIAL_STATE = {
  tags: '',
  types: ['performances', 'venues'],
  price: {
    isActive: false,
    min: null, 
    max: null
  },
  time: {
    isDateActive: false,
    isTimeActive: false,
    start: startOfDay(date), 
    end: null
  },
  location: {
    isRadiusActive: false,
    id: '',
    name: 'Venue, address, city...',
    lat: null,
    lng: null,
    radius: null
  }
}




const filterReducer = (state = INITIAL_STATE, action) => {
  let start

  switch (action.type) {
    case 'UPDATE_LOCATION_FILTER':
      return {
        ...state,
        location: {
          ...state.location,
          id: action.payload.id,
          lat: action.payload.lat,
          lng: action.payload.lng,
          name: action.payload.name
        }
      }
    case 'UPDATE_CENTER_FILTER':
      return {
        ...state,
        location: {
          ...state.location,
          lat: action.payload.lat,
          lng: action.payload.lng
        }
      }
    case 'UPDATE_DATE_FILTER':
      start = action.payload.start

      // If there is already a start time and date saved, this takes the existing
      // time and combines it with the new date that is being assigned
      if (state.time.start) {
        start = combineDateAndTime(start, state.time.start)
      }


      return {
        ...state,
        time: {
          ...state.time,
          isDateActive: true,
          start: start,
          end: action.payload.end,
        }
      }
    case 'CLEAR_DATE_FILTER':
      return {
        ...state, 
        time: {
          ...state.time,
          isDateActive: false,
          start: combineDateAndTime(date, state.time.start),
          end: null
        }
      }
    case 'UPDATE_TIME_FILTER':
      start = action.payload

      // If there is already a start time and date saved, this takes the existing
      // date and combines it with the new time that is being assigned
      if (state.time.start) {
        start = combineDateAndTime(state.time.start, start)
      }

      return {
        ...state,
        time: {
          ...state.time,
          isTimeActive: true,
          start: start
        }
      }
    case 'CLEAR_TIME_FILTER':
      return {
        ...state,
        time: {
          ...state.time,
          isTimeActive: false,
          start: combineDateAndTime(state.time.start, startOfDay(date))
        }
      }
    case 'UPDATE_PRICE_FILTER':
      return {
        ...state,
        price: {
          isActive: true,
          min: Array.isArray(action.payload) ? action.payload[0] : action.payload.min,
          max: Array.isArray(action.payload) ? action.payload[1] : action.payload.max
        }
      }
    case 'CLEAR_PRICE_FILTER':
      return {
        ...state, 
        price: {
          isActive: false,
          min: null, 
          max: null
        }
      }
    case 'UPDATE_RADIUS_FILTER': 
      return {
        ...state,
        location: {
          ...state.location,
          isRadiusActive: true,
          radius: action.payload
        }
      }
    case 'UPDATE_RADIUS_FILTER_BUT_DONT_ACTIVATE': 
      return {
        ...state,
        location: {
          ...state.location,
          radius: action.payload
        }
      }
    case 'SET_RADIUS_FILTER': 
      return {
        ...state,
        location: {
          ...state.location,
          isRadiusActive: false,
          radius: action.payload
        }
      }
    case 'CLEAR_RADIUS_FILTER':
      return {
        ...state,
        location: {
          ...state.location,
          isRadiusActive: false,
          radius: 50
        }
      }
    case 'UPDATE_TAGS_FILTER':
      return {
        ...state,
        tags: action.payload
      }
    case 'CLEAR_TAGS_FILTER':
      return {
        ...state,
        tags: ''
      }
    case 'UPDATE_TYPES_FILTER':
      return {
        ...state, 
        types: action.payload
      }
    case 'RESET_FILTERS':
      return {
        ...state,
        tags: state.tags,
        price: {
          isActive: false,
          min: null, 
          max: null
        },
        time: {
          isDateActive: false,
          isTimeActive: false,
          start: startOfDay(date), 
          end: null
        },
        location: {
          ...state.location,
          isRadiusActive: false,
          radius: 50
        }
      }
   case 'RESET_STATE':
      return INITIAL_STATE
    default:
      return state
  }
}



export default filterReducer

