import { createSelector } from 'reselect'


const selectUserId = state => state.user.id
const selectUserAttributes = state => state.user.attributes
const selectUserSettings = state => state.user.settings
const selectUserAssociations = state => {
  switch (state.user.account.type) {
    case null:
      return { user: state.user.id }
    case 'artist':
    case 'venue':
      const associations = {
        user: state.user.id,
        [state.user.account.type]: state.user.account.id
      }

      if (state.user.account.type === 'artist' && state.user.account.relationships['bands']) {
        associations['bands'] = state.user.account.relationships['bands']
      }

      return associations
    default: 
      return { user: state.user.id }
  }
}


const selectUserCoordinates = state => {
  return {lat: state.user.location.lat, lng: state.user.location.lng}
}


export const userSelector = createSelector(
  [selectUserId, selectUserAttributes],
  (id, attributes) => ({ id, attributes, type: 'user' })
)


export const userCoordinatesSelector = createSelector(
  selectUserCoordinates, coordinates => coordinates
)


export const userAssociationsSelector = createSelector(
  selectUserAssociations, associations => associations
)


export const userSettingsSelector = createSelector(
  selectUserSettings, settings => settings
)
