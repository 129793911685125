import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'




const cssStyles = ({isExtraSmallScreen}) => ({
  root: css`
    display: flex;
    min-height: 36px;
    gap: 8px;
    justify-content: flex-start;

    & > button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #262626;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      margin-bottom: 7px;
      overflow: hidden;

      &:hover {
        background-color: #262626;

        & svg {
          transform: scale(1.3);
        }
      }
    }

    & svg {
      transition: all .1s ease-in-out;
      width: 20px;
      height: 20px;
    }
  `
})




const ButtonGroupRound = ({children, ...rest}) => {

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const styles = cssStyles({isExtraSmallScreen})


  return (
    <div id='button-row' css={styles.root} {...rest}>
      {children}
    </div>
  )
}

export default ButtonGroupRound