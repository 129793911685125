import React from 'react'

import styles from '../../../styles/detail-pages/detailPageSection.module.scss'



const DetailPageSection = props => {
  

  const { 
    icon,
    title, 
    text,
    subtext,
    Content, 
    SubContent,
  } = props


  const passedStyles = props.styles || {}



  return (
    <div className={styles.root} style={{...passedStyles}}>
      <div className={styles.contentContainer}>
        {icon && 
          <div className={styles.icon}>
            {icon}
          </div> 
        }
        
        <div>
          {title && 
            <h4 className={styles.title}>
              {title}
            </h4>
          }


          {text &&
            <pre className={styles.text}>
              {text}
            </pre>
          }


          {subtext &&
            <p className={styles.text}>
              {subtext}
            </p>
          }

          {Boolean(Content) && Content}
          {Boolean(SubContent) && SubContent}
        </div>
      </div>
    </div>
  )
}


export default DetailPageSection




