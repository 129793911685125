import { invitationAPI } from '../apis'


function setObject(type, object) { 
  return { 
    type,
    payload: { 
      data: { 
        ...object 
      } 
    } 
  } 
}

function setRelationship(type, parent, child) {
  return {
    type,
    payload: {
      id: parent.id,
      data: [{ id: child.id, type: child.type }]
    }
  }
}


function acceptanceMessage(invitable) {
  const name = invitable.attributes.name
  const type = invitable.type.toLowerCase()

  if (type === 'band') {
    return 'You have joined the band ' + name
  }
  if (type === 'performance') {
    return 'You will be performing at ' + name 
  }
}




export function accept(id) {
  return dispatch => {
    return invitationAPI.accept(id).then(response => {
      const invitable = response.data.data.relationships.invitable.data
      const invitee = response.data.data.relationships.invitee.data
      const invitableDetails = response.data.included.find(e => e.id === invitable.id)
      const inviteeDetails = response.data.included.find(e => e.id === invitee.id)
      const notificationId = response.data.meta.notificationId


      switch (invitable.type.toLowerCase()) {
        case 'band':
          dispatch(setObject('BAND_SHOW_SUCCESS', invitableDetails))
          dispatch(setObject('ARTIST_SHOW_SUCCESS', inviteeDetails))
          dispatch(setRelationship('ADD_ARTIST_TO_BAND', invitable, invitee))
          dispatch(setRelationship('ADD_BAND_TO_ARTIST', invitee, invitable))
          break
        case 'performance':
          const inviteeUppercase = invitee.type.toUpperCase()
          dispatch(setObject('PERFORMANCE_SHOW_SUCCESS', invitableDetails))
          dispatch(setObject(inviteeUppercase + '_SHOW_SUCCESS', inviteeDetails))
          dispatch(setRelationship('ADD_PERFORMER_TO_PERFORMANCE', invitable, invitee))
          dispatch(setRelationship('ADD_PERFORMANCE_TO_' + inviteeUppercase, invitee, invitable))
          break
        default: 
          break
      }


      if (notificationId) {
        dispatch({ type: 'INVITATION_NOTIFICATION_RESPONSE_SUCCESS', payload: { 
          id: notificationId, 
          message: acceptanceMessage(invitableDetails), 
          accepted: true
        }})
      }

      return { invitable: invitableDetails }
    })
  }
}




export function decline(id) {
  return dispatch => {
    return invitationAPI.decline(id).then(response => {
      const invitable = response.data.data.relationships.invitable.data
      const invitableDetails = response.data.included.find(e => e.id === invitable.id)
      const notificationId = response.data.meta.notificationId

      if (notificationId) {
        dispatch({ type: 'INVITATION_NOTIFICATION_RESPONSE_SUCCESS', payload: { 
          id: notificationId, 
          message: 'Invitation declined', 
          accepted: false 
        }})
      }

      return { invitable: invitableDetails }
    })
  } 
}



