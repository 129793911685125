// LFF stands for likes, favorites, and follows for an
// individual record such as an artist

const INITIAL_STATE = {
  isFetching: false
}


 const lffReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LFF_REQUESTED':
      return { 
        isFetching: true 
      }
    case 'LFF_SUCCESS':
    case 'LFF_FAILURE':
      return { 
        isFetching: false 
      }
    default:
      return state
  }
}

export default lffReducer