import {useEffect, useRef, useState} from 'react'




export function usePlaceDetails(id, session) {

  const service = useRef()

  const [place, setPlace] = useState()



  useEffect(() => {
    if (!service.current) {
      initPlaces()


      async function initPlaces() {
        const {Place} = await window.google.maps.importLibrary('places')

        service.current = Place
      }
    }
  }, [service])




  useEffect(() => {
    if (service.current && id) {
      fetchPlace()


      async function fetchPlace() {
        const place = new service.current({id})

        await place.fetchFields({fields: ['location', 'displayName']})

        setPlace(place)
      }
    } 
  }, [id, session, service])



  return place
}


export default usePlaceDetails