
const options = { 
  timeout: 10000,
  maximumAge: 10 * 60 * 1000
}



export function fetchUserLocation() {
  return dispatch => {
    dispatch({type: 'REQUESTING_CURRENT_LOCATION'})

    return new Promise((resolve, reject) => { 
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(success, error, options)
      } else {
        reject('Your browser does not support this feature')
      }


      function success(location) {
        const method = 'navigator'
        
        const lat = location.coords.latitude
        const lng = location.coords.longitude


        dispatch({type: 'SET_USER_LOCATION', payload: {lat, lng, method}})
        resolve(location.coords)
      }


      function error(error) {
        let message = ''
        let variant = 'info'


        switch (error.code) {
          case 1:
            message = `Permission for your location has been denied. Without 
            your location we cannot provide performance suggestions near
            you, but you can still search for performances using the search bar.`
            break
          case 2:
            message = 'Your current location is unavailable.'
            break
          case 3:
            message = 'Location not found. Your request timed out.'
            break
          default: 
            message = 'An unknown error occured.'
        }
    
        dispatch({type: 'SHOW_ALERT', payload: {message, variant}})
        reject(error)
      }
    })
  }
}


export function setUserLocation(lat, lng, method, isLocated = true) {
  return dispatch => {
    dispatch({type: 'SET_USER_LOCATION', payload: {lat, lng, method, isLocated}})
  }
}


export function tryNextGeolocationMethod(method) {
  return dispatch => {
    dispatch({type: 'TRY_NEXT_GEOLOCATION_METHOD', payload: method})
  }
}


