import { combineReducers } from 'redux'
import { filter, omit, map, uniq, get } from 'lodash'
import normalize from 'json-api-normalizer'

const byId = (state = {}, action) => {
  let id = ''

  switch (action.type) {
    case 'TRACKS_INDEX_SUCCESS':
      if (action.payload.data.length) {
        return { ...state,
          ...normalize(action.payload).track,
        }
      } else {
        return state
      }
    case 'TRACK_SHOW_SUCCESS':
    case 'TRACK_UPDATE_SUCCESS':
    case 'TRACK_CREATE_SUCCESS':
      return { ...state,
        ...normalize(action.payload).track,
      }
    case 'TRACK_IMAGE_UPDATE_SUCCESS':
      id = action.payload.data.id

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            image: action.payload.data.attributes.image,
            imageDerivatives: action.payload.data.attributes.imageDerivatives,
          }
        }
      }
    case 'TRACK_DESTROY_SUCCESS':
      return omit(state, action.payload.data.id)
    case 'TRACK_RESET_STATE':
      return {}
    default:
      return state
  }
}

const all = (state = [], action) => {
  switch (action.type) {
    case 'TRACKS_INDEX_SUCCESS':
      return uniq([...state, ...map(action.payload.data, 'id')])
    case 'TRACK_SHOW_SUCCESS':
    case 'TRACK_CREATE_SUCCESS':
      return [...state, action.payload.data.id]
    case 'TRACK_DESTROY_SUCCESS':
      return filter(state, (e) => e !== action.payload.data.id)
    case 'TRACK_RESET_STATE':
      return []
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case 'TRACKS_REQUESTED':
    case 'TRACK_REQUESTED':
      return true
    case 'TRACKS_INDEX_SUCCESS':
    case 'TRACK_SHOW_SUCCESS':
    case 'TRACK_CREATE_SUCCESS':
    case 'TRACK_DESTROY_SUCCESS':
    case 'TRACK_RESET_STATE':
    case 'TRACK_SEARCH_INDEX_SUCCESS':
    case 'TRACKS_ERROR':
    case 'TRACK_ERROR':
      return false
    default:
      return state
  }
}

export default combineReducers({
  byId,
  all,
  isFetching
})


