import React, { useState } from 'react'
/** @jsxImportSource @emotion/react */
import { useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from '../../../utils'
import { patch } from '../../../apis'
import { useIsMounted } from '../../../hooks'
import { formatDistance } from 'date-fns'
import { useTheme } from '@mui/styles'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'


import headerMessageNotificationStyles from '../../../styles/header/headerMessageNotificationStyles'


const HeaderMessageNotification = props => {
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const { notification } = props
  const { details, title, message, updatedAt } = notification.attributes
  const { image, type } = details.messenger
  const dateNotificationUpdated = new Date(updatedAt)

  const [updatedImage, setUpdatedImage] = useState()
  

  const theme = useTheme()
  const styles = headerMessageNotificationStyles({ theme })


  function handleListClick() {
    navigate('/messages')
  }


  function fetchNewImageAndUpdateNotification() {
    if (updatedImage) return 
      
    patch('/notifications/' + notification.id + '/update-messenger-image', { image_url: image })
      .then(response => {
        if (isMounted.current && response.statusText === 'OK') {
          setUpdatedImage(response.data)
        }
      })
  }



  return (
    <div css={ styles.root } onClick={ handleListClick }>
      <div css={ styles.avatarContainer }>
        <Avatar 
          css={ styles.avatar }
          alt={ title } 
          src={ updatedImage || image }
          imgProps={{
            onError: fetchNewImageAndUpdateNotification
          }}
          variant={ (type === 'band' || type === 'venue') ? 'rounded' : 'circular' } 
        />
      </div>

      <div css={ styles.contentContainer }>
        <Typography css={ styles.title } noWrap>
          { title }
        </Typography>
        <Typography css={ styles.message } noWrap gutterBottom>
          { message }
        </Typography>
        <Typography css={ styles.time } noWrap>
          { capitalizeFirstLetter(formatDistance(dateNotificationUpdated, new Date())) + ' ago' }
        </Typography>
      </div>
    </div>
  )
}

export default HeaderMessageNotification


