import {useMemo, useEffect, useRef, useState} from 'react'

import {debounce} from 'lodash'

import useIsMounted from './useIsMounted'





export function usePlacePredictions(input) {

  const isMounted = useIsMounted()

  const autocomplete = useRef()

  const [status, setStatus] = useState('')

  const [predictions, setPredictions] = useState([])





  const getPlacePredictions = useMemo(() => {
    return input => {
      autocomplete.current.getPlacePredictions(
        {input},
        (predictions, status) => {
          if (isMounted.current) {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              setStatus('OK')
              setPredictions(predictions)
            } else {
              setStatus(status)
            }
          }
        }
      )
    }
}, [isMounted])



  const debouncedGetPlacePredictions = useMemo(
    () => debounce(getPlacePredictions, 300), [getPlacePredictions]
  )



  useEffect(() => {
    if (!autocomplete.current) {
      initPlaces()


      async function initPlaces() {
        await window.google.maps.importLibrary('places')

        autocomplete.current = new window.google.maps.places.AutocompleteService()
      }
    }
  }, [autocomplete])
  



  useEffect(() => {
    if (input && input !== 'Current Location') {
      debouncedGetPlacePredictions(input)
    }
  }, [input, debouncedGetPlacePredictions])



  useEffect(() => {
    return () => { 
      const googleContainer = document.getElementsByClassName('pac-container')

      if (googleContainer.length) {
        googleContainer[0].remove()
      }
    }
  }, [])


  return [predictions, status]
}


export default usePlacePredictions



