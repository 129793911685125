import React, {useEffect, useState, useCallback} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {get} from 'lodash'

import {useIsMounted} from '../../../hooks'

import {index} from '../../../apis'

import { 
  makeGetPerformanceById,
  makeGetPerformanceLinks, 
  makeGetPerformancePerformers,
  makeGetPerformanceFetchedAssociations
} from '../../../selectors'

import { 
  saveLinks,
  setFetchedAssociations,
  addPerformersToPerformance
} from '../../../actions'


import Dialog from '@mui/material/Dialog'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import LockIcon from '@mui/icons-material/Lock'

import InvitePerformers from '../performers/InvitePerformers'
import RemovePerformers from '../performers/RemovePerformers'
import PerformancePermissions from '../permissions/PerformancePermissions'

import CardList from '../../cards/CardList' 
import CardListMobile from '../../cards/CardListMobile'


import GroupedButton from '../../generics/buttons/GroupedButton'
import ThreeDotProgress from '../../generics/ThreeDotProgress'
import SlideTransition from '../../generics/SlideTransition'
import FadeTransition from '../../generics/FadeTransition'
import DetailPageTabMessage from '../../generics/detail-page/DetailPageTabMessage'
import DetailPageTabButtonContainer from '../../generics/detail-page/DetailPageTabButtonContainer'



const cssStyles = ({isExtraSmallScreen}) => ({
  root: css`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
  `,
  section: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    h4 {
      font-size: 1.3rem;
      font-weight: 600;
      color: #7b7b7b;
      letter-spacing: 0;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid #d9d9d9;
      margin-top: 10px;
    }
  `,
  cardsContainer: css`
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;

    .message {
      width: 80%;
      font-size: 1rem;
      text-align: left;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px;
    }
  `,
  progressContainer: css`
    height: 100%;
    width: 100%;
    margin-top: 20px;
  `,
  dialog: css`
    backdrop-filter: blur(10px);
  `
})




const PerformancePerformers = props => {

  const isMounted = useIsMounted()

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const [modal, setModal] = useState(false)


  const { 
    performanceId,
    performers,
    permission,
    addPerformersToPerformance,
    setFetchedAssociations,
    isLoaded,
    saveLinks,
    stateLinks
  } = props


  const [links, setLinks] = useState(stateLinks)

  const [isFetched, setIsFetched] = useState(isLoaded)

  const [isFetching, setIsFetching] = useState(false)

  const hasNextPage = (links.pages !== links.page)


  const styles = cssStyles({isExtraSmallScreen})




  const fetchMorePerformers = useCallback(props => {
    if (hasNextPage) {
      const url = links.next_url.slice(4)

      index(url, 'PERFORMANCES').then(response => {
        addPerformersToPerformance(performanceId, response.data)

        if (isMounted.current) {
          if (props.isMounted && props.isMounted.current && props.setIsFetching) {
            props.setIsFetching(false)
          }

          if (response.links) {
            setLinks(response.links)
          }
          
          setIsFetched(true)  
        }
      })
    }
  }, [
    addPerformersToPerformance,
    performanceId,
    isMounted,
    links.next_url,
    hasNextPage
  ])



  useEffect(() => {
    return () => saveLinks('PERFORMANCE', performanceId, 'performers', links)
  }, [performanceId, links, saveLinks])




  useEffect(() => {
    if (!isLoaded) {

      setIsFetching(true)

      setFetchedAssociations('PERFORMANCE', performanceId, 'performers')
      
      index('performances/' + performanceId + '/performers').then(response => {
        addPerformersToPerformance(performanceId, response.data)

        if (isMounted.current) {
          if (response.data.links) {
            setLinks(response.data.links)
          }
          
          setIsFetched(true)
          setIsFetching(false)
        }
      })
      .catch(error => {
        if (isMounted.current) {
          setIsFetched(true)
          setIsFetching(false)
        }
      })
    }
  }, [
    performanceId,
    isLoaded,
    isMounted,
    setFetchedAssociations,
    addPerformersToPerformance
  ])




  const open = content => () => {
    setModal(content)
  }



  function close() {
    setModal('')
  }





  return (
    <div css={styles.root}>
      <DetailPageTabButtonContainer>
        {['owner', 'editor'].includes(permission) &&
          <>
            {['owner', 'editor'].includes(permission) &&
              <>
                <GroupedButton 
                  text='New'
                  tip='Invite performer to performance'
                  icon={<AddIcon />}
                  onClick={open('invite')}  
                /> 

                <GroupedButton 
                  text='Remove'
                  tip='Remove performer'
                  icon={<RemoveIcon />}
                  onClick={open('remove')} 
                />
              </>
            }


            <GroupedButton
              text='Permissions'
              tip='Change performance permissions'
              icon={<LockIcon />}
              onClick={open('permissions')}
            />
          </>
        }
      </DetailPageTabButtonContainer>



      <section css={styles.cardsContainer}>
        {Boolean(performers.length) &&
          <>
            {isExtraSmallScreen &&
              <CardListMobile
                type='artist'
                cards={performers}
                hasNextPage={hasNextPage}
                fetchMoreItems={fetchMorePerformers}
              />
            }


            {!isExtraSmallScreen &&
              <CardList
                type='artist'
                cards={performers}
                hasNextPage={hasNextPage}
                fetchMoreItems={fetchMorePerformers}
              /> 
            }
          </>
        }



        {!Boolean(performers.length) && isFetched &&
          <DetailPageTabMessage>
            {['owner', 'editor'].includes(permission) 
              ? 'To invite performers to this performance, click the plus icon above' 
              : `This performance doesn't have any performers listed yet.`
            }
          </DetailPageTabMessage>
        }



        {isFetching && 
          <div css={styles.progressContainer}>
            <ThreeDotProgress position='sticky' /> 
          </div>
        }
      </section>



      <Dialog
        open={Boolean(modal)} 
        onClose={close} 
        maxWidth='xs' 
        fullWidth 
        css={styles.dialog}
        disableScrollLock
        fullScreen={isExtraSmallScreen}
        TransitionComponent={isExtraSmallScreen ? SlideTransition : FadeTransition}
      >
       {modal === 'invite' &&
          <InvitePerformers 
            handleClose={close}
            performers={performers}
            performanceId={performanceId} 
          />
        }

        {modal === 'remove' &&
          <RemovePerformers
            handleClose={close} 
            performers={performers}
            performanceId={performanceId}
          />
        }

        {modal === 'permissions' &&
          <PerformancePermissions
            handleClose={close}
            performanceId={performanceId}  
          />
        }
      </Dialog>
    </div>
  )
}



const makeMapStateToProps = () => {
  const getPerformanceById = makeGetPerformanceById()
  const getPerformanceLinks = makeGetPerformanceLinks()
  const getPerformancePerformers = makeGetPerformancePerformers()
  const getFetchedAssociations = makeGetPerformanceFetchedAssociations()
  

  const mapStateToProps = (state, props) => {
    
    const id = props.performanceId
    
    const performance = getPerformanceById(state, id).attributes

    const performers = getPerformancePerformers(state, id)

    const links = getPerformanceLinks(state, id, 'performers')

    const role = get(state, ['user', 'attributes', 'role'])
    


    return {
      stateLinks: links,
      performers: performers,
      isLoaded: getFetchedAssociations(state, id).includes('performers'),
      permission: ['admin', 'editor'].includes(role) ? 'owner' : performance?.permission,
    }
  }

  return mapStateToProps
}



const actions = {saveLinks, addPerformersToPerformance, setFetchedAssociations}



export default connect(makeMapStateToProps, actions)(PerformancePerformers)



