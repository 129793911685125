import { createSelector } from 'reselect'
import { get, pick, map } from 'lodash'



const emptyArray = []


const selectShops = state => state.shops.byId
const selectProducts = state => state.products.byId

const selectMerchant = (state, id, type) => state[type + 's'].byId[id]


const selectMerchantProducts = (state, id, type) => {
  return get(state[type + 's'].byId[id], ['relationships', 'products'], emptyArray)
}


const selectMerchantShop = (state, id, type) => {
  return get(state[type + 's'].byId[id], ['relationships', 'shop'], '')
}


const selectMerchantFetchedAssociations = (state, id, type) => {
  return get(state[type + 's'].byId[id], 'fetched', emptyArray)
}



export const makeGetMerchantProducts = () => {
  return createSelector([selectMerchantProducts, selectProducts],
    (merchantProducts, products) => {
      const productIds = map(merchantProducts, 'id')

      return Object.values(pick(products, productIds))
    }, 
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}



export const makeGetMerchantShop = () => {
  return createSelector([selectMerchantShop, selectShops],
    (merchantShop, shops) => {
      return shops[merchantShop]
    }, 
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          return (
            a.merchantShop === b.merchantShop &&
            Object.keys(a).length === Object.keys(b).length
          )
        }
      }
    }
  )
}



export const makeGetMerchantFetchedAssociations = () => {
  return createSelector(
    selectMerchantFetchedAssociations, 
    fetchedAssociations => fetchedAssociations
  )
}



export const merchantSelectors = {
  merchant: selectMerchant
}
