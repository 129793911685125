import { createSelector } from 'reselect'
import { get, pick } from 'lodash'


const selectTrackId = (state, id) => {
  return get(state.tracks.byId[id], 'id', false)
}

const selectTrackAttributesById = (state, id) => {
  return get(state.tracks.byId[id], 'attributes')
}

export const selectTracks = state => state.tracks.byId
export const selectTrackIds = state => state.tracks.byId



export const makeGetTrackById = () => {
  return createSelector(
    [selectTrackId, selectTrackAttributesById], 
    (id, attributes) => ({ id, attributes, type: 'track' })
  )
}


export const selectTracksByIDs = () => {
  return createSelector(
    [selectTracks, (state, ids) => ids],
    (tracks, ids) => Object.values(pick(tracks, ids))
  )
}



const trackSelectors = {selectTrackIds, makeGetTrackById}


export default trackSelectors