import React, {useState, useMemo} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import styled from '@emotion/styled'

import {useIsMounted} from '../../hooks'

import {VirtuosoGrid } from 'react-virtuoso'

import ProductCard from './ProductCard'

import {ThreeDotProgress} from '../generics'




const ItemContainer = styled.div`
  width: 50%;
  display: flex;
  flex: none;
  align-content: center;
  justify-content: center;
  padding: 10px 5px;
  box-sizing: border-box;
`

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`




const styles = {
  root: css`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    &::-webkit-scrollbar {
      width: 1px; 
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  `
}




const isEqual = (prevProps, nextProps) => (
  prevProps.hasNextPage === nextProps.hasNextPage &&
  prevProps.cards.length === nextProps.cards.length
)



const CardListMobileGrid = props => {

  const isMounted = useIsMounted()

  const [isFetching, setIsFetching] = useState(false)

  
  const {cards, fetchMoreItems, hasNextPage, cardProps} = props

  const itemCount = cards.length



  function handleEndReached(index) {
    if (hasNextPage) {
      setIsFetching(true)
      fetchMoreItems({index, setIsFetching, isMounted}) 
    }
  }




  const Cards = useMemo(() => (
    cards.map(card => {
      if (React.isValidElement(card)) {
        return card
      }

      switch (card.type) {
      case 'product':
        return <ProductCard product={card} {...cardProps} />
      default:
        return null
      }
    })
  ), [cards, cardProps]) 



  const Card = ({index}) => Cards[index]



  const Footer = () => (
    <div
      style={{
        display: 'flex',
        padding: '1rem',
        paddingBottom: '100px',
        justifyContent: 'center',
      }}
    >
      {isFetching && <ThreeDotProgress radius={4} />}
    </div>
  )



  return (
    <VirtuosoGrid
      css={styles.root}
      style={{height: '100%', width: '100%'}}
      overscan={320}
      useWindowScroll
      totalCount={itemCount}
      itemContent={index => <Card index={index} />}
      computeItemKey={index => cards[index].id || index}
      endReached={handleEndReached}
      components={{
        Item: ItemContainer,
        List: ListContainer,
        Footer: hasNextPage ? Footer : null
      }}
    />
  )
}

export default React.memo(CardListMobileGrid, isEqual)






