import {useState, useEffect} from 'react'

import {throttle} from 'lodash'

import {useIsMounted} from './useIsMounted'



export function useScrollPosition() {
  const isMounted = useIsMounted()

  const [position, setPosition] = useState(0)


  useEffect(() => {
    function handleScroll() {
      if (isMounted.current) setPosition(window.pageYOffset)
    }
    
    window.addEventListener('scroll', throttle(handleScroll, 300))
    
    return () => { 
      window.removeEventListener('scroll', throttle(handleScroll, 300))
    }
  }, [isMounted])


  return position
}