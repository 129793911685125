import React, {useState, useMemo} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {makeGetProductById} from '../../../selectors'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import {Image, Carousel} from '../../generics'



const cssStyles = ({isSmallScreen}) => ({
  root: css`
    width: 100%;
    display: flex;
    flex-direction: ${isSmallScreen ? 'column-reverse' : 'row'};
    flex-wrap: nowrap;
    gap: 10px;
  ` ,
  chevronContainer: css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    z-index: 20;
  `,
  leftChevron: css`
    width: 45px;
    height: 45px;
    background: rgba(255, 255, 255, 0.5);
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
  rightChevron: css`
    width: 45px;
    height: 45px;
    background: rgba(255, 255, 255, 0.5);
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
  smallImagesContainer: css`
    height: 100%;
    display: flex;
    flex-direction: ${isSmallScreen ? 'row' : 'column'};
    margin-top: ${isSmallScreen ? '20px' : '0'};
    gap: 10px;
  `,
  smallImage: css`
    width: 80px;
    height: 80px;
    object-fit: cover;
    cursor: pointer;
  `,
  mainImageContainer: css`
    max-width: 720px;
    width: 100%;
    height: 100%;
    position: relative;
  `,
  mainImage: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  aspectRatioBox: css`
    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;
  `,
  aspectRatioBoxInside: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
})





const ProductDetailImages = props => {

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))


  const {images} = props


  const [index, setIndex] = useState(0)



  const styles = useMemo(() => cssStyles({isSmallScreen}), [isSmallScreen])


  const items = useMemo(() => {
    return images.map(image => (
      <div css={styles.aspectRatioBox}>
        <div css={styles.aspectRatioBoxInside}>
          <Image css={styles.mainImage} src={image.attributes.image} />
        </div>
      </div>
    ))
  }, [images, styles])



  const selectImage = image => () => {
    const newIndex = images.findIndex(e => e.id === image.id)
    setIndex(newIndex)
  }



  function previousImage() {
    if (index === 0) {
      setIndex(images.length - 1)
    } else {
      setIndex(index - 1)
    }
  }



  function nextImage() {
    if (index === images.length - 1) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }



  return (
    <div css={styles.root}>
      <div css={styles.smallImagesContainer}>
        {images.map(image=> (
          <Image
            key={image.id} 
            css={styles.smallImage}
            src={image.attributes.imageDerivatives.small || image.attributes.image}
            onClick={selectImage(image)}
          />
        ))}
      </div>


      <div css={styles.mainImageContainer}>
        {images.length > 1 && 
          <div css={styles.chevronContainer}>
            <div css={styles.leftChevron} onClick={previousImage}>
              <ChevronLeftIcon fontSize='large' sx={{fill: '#434343', strokeWidth: 0}} />
            </div>


            <div css={styles.rightChevron} onClick={nextImage}>
              <ChevronRightIcon fontSize='large' sx={{fill: '#434343', strokeWidth: 0}} />
            </div>
          </div>
        }

        <Carousel step={index} items={items} />
      </div>
    </div>
  )
}



const makeMapStateToProps = () => {
  const getProductById = makeGetProductById()

  const mapStateToProps = (state, props) => {
    const product = getProductById(state, props.id)
    
    return {
      images: product?.attributes?.images || []
    }
  }

  return mapStateToProps
}




export default connect(makeMapStateToProps)(ProductDetailImages)


