import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getImageOfSize, sentenceCase, formatError } from '../../utils'
import {
  showAlert,
  addBandsToArtist,
  addArtistsToBand,
  addPerformerToPerformance,
  addPerformanceToBand,
  addPerformanceToArtist
} from '../../actions'
import { create } from '../../apis'
import { Link } from 'react-router-dom'
import { useIsMounted } from '../../hooks'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'


const useStyles = makeStyles({
  link: {
    color: '#040404',
    transition: 'all 0.2s ease',
    '&:visited': {
      color: '#040404'
    },
    '&:hover': {
      background: '#F5F5F5'
    }
  },
  primary: {
    fontWeight: 700
  },
  secondary: {
    textTransform: 'uppercase',
    fontSize: '0.7rem'
  },
  actionContainer: {
    width: 68,
    paddingLeft: 8,
    zIndex: 10
  },
  button: {
    background: '#040404',
    color: '#fff',
    '&:hover': {
      background: '#040404'
    }
  },
  text: {
    textAlign: 'center'
  },
  image: {
    maxHeight: 40,
    maxWidth: 40
  }
})

const InvitationListItem = props => {

  const dispatch = useDispatch()

  const classes = useStyles()
  const isMounted = useIsMounted()
  const [sent, setSent] = useState(false)
  const { 
    inviter,
    inviterId, 
    suggestion, 
    showAlert, 
    disabled, 
    member,
    addBandsToArtist,
    addArtistsToBand,
    addPerformerToPerformance,
    addPerformanceToBand,
    addPerformanceToArtist
  } = props
  const { type, id } = suggestion
  const imageURL = getImageOfSize(suggestion.attributes)


  function invite(e) {
    e.preventDefault()
    
    create(`/invitations/${inviter}s/${inviterId}/invite/${type}/${id}`)
      .then(response => {

        // This section handles if admin is adding the performer or bandmate
        // ----------------------------------------------------------------------
        // If the the admin is adding performers: 
        if (inviter === 'performance' && response?.data?.meta) {
          const performance = response.data.data
          const performer = response.data.meta.data


          if (performer.type === 'artist') {
            dispatch({type: 'ARTIST_SHOW_SUCCESS', payload: response.data.meta})

            addPerformerToPerformance(performance.id, performer)
            addPerformanceToArtist(performer.id, [performance])

          } else if (performer.type === 'band') {
            dispatch({type: 'BAND_SHOW_SUCCESS', payload: response.data.meta})

            addPerformerToPerformance(performance.id, performer)
            addPerformanceToBand(performer.id, [performance])
          }

          showAlert('Performer added', 'success')
          return 
        }


        // If the performer is adding bandmates
        if (inviter === 'band' && response?.data?.meta) {
          const band = response.data.data
          const artist = response.data.meta.data

          dispatch({type: 'ARTIST_SHOW_SUCCESS', payload: response.data.meta})

          addBandsToArtist(artist.id, [band])
          addArtistsToBand(band.id, [artist])

          showAlert('Band member added', 'success')
          return
        }
        // ----------------------------------------------------------------------



        // This handles if an admin is not adding the performer or bandmate
        if (isMounted.current) setSent(true)
        showAlert('Invite sent', 'success')
      })
      .catch(error => {
        console.log(error)
        const message = formatError(error).error.messages[0]
        showAlert(message, 'error')
      })
  }


  return (
    <ListItem
      disabled={ sent || disabled }
      component={ Link }
      className={ classes.link }
      to={ (sent || disabled) ? '#' : ('/' + type + 's/' + id) }>
      <ListItemAvatar>
        <Avatar 
          alt='invitation' 
          src={ imageURL }
          variant={ type === 'artist' ? 'circular' : 'rounded' } 
        /> 
      </ListItemAvatar>
      <ListItemText
        classes={{
          primary: classes.primary,
          secondary: classes.secondary
        }}
        primary={ suggestion.attributes.name } 
        secondary={ sentenceCase(type) } />
        <div className={ classes.actionContainer }>
        {(() => {
          if (member || sent || disabled) {
            return (
              <p className={ classes.text }>
                { member ? 'Member' : 'Sent' }
              </p>
            )
          } else {
            return (
              <Button className={ classes.button } onClick={ invite }>
                Invite
              </Button>
            )
          }
        })()}
        </div>
    </ListItem>
  )
}

const actions = {
  showAlert,
  addBandsToArtist,
  addArtistsToBand,
  addPerformerToPerformance,
  addPerformanceToBand,
  addPerformanceToArtist
}

export default connect(null, actions)(InvitationListItem)










