import {useState, useEffect, useMemo} from 'react'
import {debounce} from 'lodash'




export function useResize(ref) {
  
  const [width, setWidth] = useState(0)
  
  const [height, setHeight] = useState(0)



  const debouncedResize = useMemo(() => {
    return debounce(() => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth)
        setHeight(ref.current.offsetHeight)
      }
    }, 300)
  }, [ref])


  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth)
      setHeight(ref.current.offsetHeight)
    }

    window.addEventListener('resize', debouncedResize)

    return () => {
      window.removeEventListener('resize', debouncedResize)
      debouncedResize.cancel()
    }
  }, [ref, debouncedResize])



  return {width, height}
}




export default useResize