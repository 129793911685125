import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button'


const DeleteButton = withStyles(theme => ({
  root: {
    minWidth: '130px',
    height: '50px',
    marginTop: '15px',
    boxShadow: 'none',
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
    }
  }
}))(Button)


export default DeleteButton