import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'


const styles = {
  root: css`
    color: #000;
    font-size: 2.7rem;
    font-weight: 500;
    letter-spacing: -0.15rem;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1;
  `
}


const FormTitle = ({title}) => {
  return <h1 css={styles.root}>{title}</h1>
}



export default FormTitle