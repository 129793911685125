import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {getErrorMessage} from '../../utils'

import {destroy, showAlert, deleteAccount} from '../../actions'

import Menu from '@mui/material/Menu'

import VenueForm from '../venue/VenueForm'


import { 
  MoreIconButton,
  FavoriteIconButton,
  StatsIconButton,
  EditIconButton,
  DeleteIconButton
} from '../generics'




const styles = {
  buttonsContainer: css`
    display: flex;
    gap: 10px;
  `,
  menu: css`
    li {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `
}



const VenueCardButtons = props => {

  const [open, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  

  const { 
    venueId,
    imageURL,
    destroy, 
    isOwner,
    showAlert,
    deleteAccount
  } = props





  function deleteVenue() {
    destroy('/venues/' + venueId, 'VENUE').then(response => {
      deleteAccount()
      showAlert('Venue deleted', 'success')
    })
    .catch(error => {
      const message = getErrorMessage(error)
      showAlert(message, 'error')
    })
  }




  function handleMoreClick(e) {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }




  function close(e) {
    e.stopPropagation()
    setAnchorEl(null)
  }



  function closeForm() {
    setOpen(false)
  }



  return (
    <>
      <div css={styles.buttonsContainer}>
        <FavoriteIconButton 
          key='favorite'
          id={venueId} 
          type='venue' 
          color='rgba(0, 0, 0, 0.54)' 
        />

        {isOwner && 
          <MoreIconButton
            key='more'
            color='rgba(0, 0, 0, 0.54)'
            onClick={handleMoreClick}
          />
        }
      </div>


       <Menu css={styles.menu} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={close}>
        {isOwner &&
          <>
            <StatsIconButton id={venueId} type='venue' variant='menuItem'/>

            <EditIconButton open={open} setOpen={setOpen} variant='menuItem'>
              <VenueForm venueId={venueId} close={closeForm} />
            </EditIconButton>

            <DeleteIconButton
              imageURL={imageURL}
              variant='menuItem'
              title='Delete Venue'
              text='Are you sure you want to delete your venue account?'
              primaryAction={deleteVenue}
            />
          </>
        }
      </Menu>
    </>
  )
}





const mapStateToProps = (state, props) => {
  
  const id = props.venueId

  const isOwner = state.user.account.id === id 


  return {
    isOwner: isOwner
  }
}


const actions = {destroy, showAlert, deleteAccount}



export default connect(mapStateToProps, actions)(VenueCardButtons)




