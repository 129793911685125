import { capitalizeFirstLetter } from '../../utils'


// The below objects have a format of objectId : likeId
const INITIAL_STATE = {
  artists: {},
  orderedArtists: [],
  bands: {},
  orderedBands: [],
  tracks: {},
  orderedTracks: [],
  venues: {},
  orderedVenues: [],
  fetched: [],
  isFetching: false
}


 const likeReducer = (state = INITIAL_STATE, action) => {
  let id, type, orderedType

  switch (action.type) {
    case 'LIKES_REQUESTED':
      return { ...state,
        isFetching: true
      }
    case 'LIKES_INDEX_SUCCESS':
      type = action.payload.type

      return { ...state,
        isFetching: false,
        fetched: [...state.fetched, type],
        [type]: action.payload.likes,
      }
    case 'ORDER_LIKES_OF_TYPE':
      orderedType = 'ordered' + capitalizeFirstLetter(action.payload.type)

      return { ...state,
        [orderedType]: [
          ...state[orderedType], 
          ...action.payload.objectIds
        ]
      }
    case 'LIKE_SHOW_SUCCESS':
      id = action.payload.id
      type = action.payload.type

      return { ...state,
        isFetching: false,
        [type]: { 
          ...state[type],
          [id]: action.payload.data
        },
      }
    case 'LIKE_CREATE_SUCCESS':
      id = action.payload.data.attributes.likableId
      type = action.payload.data.attributes.likableType.toLowerCase() + 's'
      orderedType = 'ordered' + capitalizeFirstLetter(type)

      return { ...state,
        isFetching: false,
        [orderedType]: [ id, ...state[orderedType]],
        [type]: { 
          ...state[type],
          [id]: action.payload.data
        },
      }
    case 'LIKE_DESTROY_SUCCESS':
      id = action.payload.data.attributes.likableId
      type = action.payload.data.attributes.likableType.toLowerCase() + 's'
      orderedType = 'ordered' + capitalizeFirstLetter(type)

      return { ...state,
        isFetching: false,
        [orderedType]: state[orderedType].filter(e => e !== id),
        [type]: { 
          ...state[type],
          [id]: null 
        },
      }
    case 'LIKES_ERROR':
      id = action.payload.id
      type = action.payload.type

      if (id) {
        return { ...state,
          isFetching: false,
          [type]: { 
            ...state[type],
            [id]: false
          }
        }
      } else {
        return { ...state,
          isFetching: false
        }
      }
    default:
      return state
  }
}

export default likeReducer



