import React, {useState, useEffect, useRef}  from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {debounce} from 'lodash'

import useMediaQuery from '@mui/material/useMediaQuery'





const cssStyles = ({lineCount}) => ({
  clamp: css`
    width: 100%;
    letter-spacing: -0.02rem;
    line-height: 1.5;
    margin-bottom: 0;
    overflow: hidden;
    white-space: pre-wrap;
    -webkit-line-clamp: ${lineCount};
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  `,
  text: css`
    width: 100%;
    letter-spacing: -0.02rem;
    line-height: 1.5;
    margin-bottom: 0;
    overflow: hidden;
    white-space: pre-wrap;
  `,
  expandButton: css`
    font-size: 0.9rem;
    color: rgb(62, 166, 255);
    cursor: pointer;
    margin-top: 10px;
    font-weight: 600;
    text-transform: uppercase;

  `
})




const ExpandingText = ({text, ...rest}) => {

  const ref = useRef()

  const [isTruncated, setIsTruncated] = useState(true)
  
  const [isShowingButton, setIsShowingButton] = useState(false)


  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('lg'))
  
  const lineCount = rest.lineCount || (isSmallScreen ? 7 : 15)


  const styles = cssStyles({lineCount})




  useEffect(() => {
    if (!ref.current) return


    const isClamped = e => {
      const {clientHeight, scrollHeight} = e

      return Math.abs(clientHeight - scrollHeight) > 5
    }


    function checkButtonAvailability() {
      setIsShowingButton(isClamped(ref.current))
    }



    const debouncedCheck = debounce(checkButtonAvailability, 300)
    checkButtonAvailability()


    window.addEventListener('resize', debouncedCheck)

    return () => {
      window.removeEventListener('resize', debouncedCheck)
    }
  }, [ref])




  function toggleExpand() {
    setIsTruncated(!isTruncated)
  }




  return (
    <div>
      <p ref={ref} css={isTruncated ? styles.clamp : styles.text} {...rest}>
        {text}
      </p>

      {isShowingButton && 
        <p css={styles.expandButton} onClick={toggleExpand}>
          {isTruncated ? 'Expand' : 'Collapse'}
        </p> 
      }
    </div>
  )
}




export default ExpandingText



