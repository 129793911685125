import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import GeneralSearch from '../search-bar/GeneralSearch'
import LocationSearch from '../search-bar/LocationSearch'


const styles = {
  root: css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    z-index: 10;
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 25px; 
    background-color: rgb(244, 245, 251);
  `,
  separator: css`
    border: 1px solid #d9d9d9;
    width: 1px;
    height: 24px;
    display: inline-block;
  `,
  container: css`
    flex: 1 0 50%; 
  `
}



const HomePageSearch = props => (
  <div css={styles.root}>
    <div css={styles.container}>
      <GeneralSearch />
    </div>

    <hr css={styles.separator} />

    <div css={styles.container}>
      <LocationSearch />
    </div>
  </div>
)



export default HomePageSearch