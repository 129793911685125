import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {showAlert} from '../../actions'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'


const styles = {
  root: css`
    margin-bottom: 5px;
  `,
  button: css`
    color: rgb(62, 166, 255);
    text-transform: uppercase;
    font-weight: 500;
  `,
  invitedButton: css`
    color: grey;
    cursor: default;
    text-transform: uppercase;
    font-weight: 500;

    &:hover {
      background-color: #fff;
    }
  `
}




const PerformanceFormPerformerInvitation = props => {


  const { 
    suggestion, 
    isInvited,
    performers,
    setIsOpen,
    textfieldRef,
    setFieldValue,
  } = props



  const {id, type} = suggestion
  
  const {name} = suggestion.attributes
  

  const imageURL = suggestion.attributes.imageDerivatives?.small




  function invite(e) {
    e.stopPropagation()

    setFieldValue('performers', [...performers, {
      id: id,
      type: type,
      image: imageURL,
      name: name
    }])


    if (textfieldRef.current) {
      textfieldRef.current.value = ''
      textfieldRef.current.focus()
    }

    setIsOpen(false)
  }




  return (
    <ListItem css={styles.root}>
      <ListItemAvatar>
        <Avatar 
          alt='invitation' 
          src={imageURL}
          variant={type === 'artist' ? 'circular' : 'rounded'} 
          sx={{width: 45, height: 45}}
        /> 
      </ListItemAvatar>


      <ListItemText
        primary={name} 
        secondary={type} 
        sx={{
          '& span': {
            fontWeight: 500,
            fontSize: '1.1rem'
          },

          '& p': {
            fontSize: '0.7rem',
            textTransform: 'uppercase',
            fontWeight: 500
          }
        }}
      />


      {!isInvited &&
        <Button variant='text' css={styles.button} onClick={invite}>
          Invite
        </Button>
      }


      {isInvited &&
        <Button variant='text' css={styles.invitedButton}>
          Invited
        </Button>
      }
    </ListItem>
  )
}


const actions = {showAlert}


export default connect(null, actions)(PerformanceFormPerformerInvitation)


