import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {useNavigate} from 'react-router-dom'

import {connect} from 'react-redux'

import {isEmpty} from 'lodash'

import {addToCart, showAlert} from '../../actions'

import {getImageOfSize} from '../../utils'

import BagIcon from '@mui/icons-material/LocalMall'
import Typography from '@mui/material/Typography'

import ProductCardButtons from './ProductCardButtons'

import {AspectImage} from '../generics'




const cssStyles = ({isSmallScreen, isExtraSmallScreen}) => ({
  root: css`
    width: ${isSmallScreen ? '100%' : '250px'};
    height: ${isExtraSmallScreen ? '300px' : isSmallScreen ? 'auto' : '380px'};
    min-height: ${isExtraSmallScreen ? '300px' : isSmallScreen ? '300px' : '380px'};
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    border-radius: 7px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px;
  `,
  header: css`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2px;
  `,
  title: css`
    flex-grow: 1;
    max-width: 100%;
    font-weight: 600;
    font-size: 1.1rem;
    padding-right: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
  `,
  imageContainer: css`
    width: ${isSmallScreen ? '100%' : '250px'};
    height: ${isSmallScreen ? 'auto' : 'auto'};
    max-width: 100%;
    border-radius: 6px 6px 0px 0px;
    position: relative;
    overflow: hidden;
  `,
  iconContainer: css`
    position: absolute;
    display: flex;
    justify-content: flex-end;
    transition: opacity 0.3s;
    z-index: 25;
    bottom: 0;
    right: 0;
    padding: 10px;
  `,
  iconCircle: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
  `,
  iconCapsule: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(0, 0, 0, 0.6);

    & > p {
      color: #fff;
    }
  `,
  infoContainer: css`
    padding: 12px 12px 0px 12px;
    flex-grow: 1; 
    min-width: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
  `,
  footer: css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 52px;
    padding: 4px
  `,
  link: css`
    text-decoration: none;
    color: inherit;
  `
})




const ProductCard = props => {

  const navigate = useNavigate()

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const styles = cssStyles({isSmallScreen, isExtraSmallScreen})


  const [expand, setExpand] = useState(false)

  const {product, addToCart, edit, isOwner, showAlert} = props
  
  const { 
    name,  
    price,
    caption, 
    variants,
    merchantId, 
    merchantType
  } = product.attributes


  const imageURL = getImageOfSize(product.attributes, 'small')



  function navigateToProductPage() {
    navigate('/products/' + product.id)
  }



  function addToShoppingCart(e) {
    e.stopPropagation()
    e.preventDefault()

    if (isOwner) {
      showAlert('Can\'t add your own products to your cart', 'info')
      return
    }

    // Because the card can only quick add items to the card that have no variants, 
    // we need to create selectedVariants key that has an empty object, signifying no variants
    const selectedProduct = {
      ...product,
      attributes: {
        ...product.attributes,
        selectedVariants: {}
      }
    }

    addToCart(selectedProduct)
    showAlert('Item added to cart', 'success')
  }


  function handleMouseOver() {
    setExpand(true)
  }


  function handleMouseLeave() {
    setExpand(false)
  }



  return (
    <div css={styles.root}>
      <div css={styles.imageContainer} onClick={navigateToProductPage}>
        <AspectImage src={imageURL} alt='product' borderRadius='0px' />

        {isEmpty(variants) &&
          <div 
            css={styles.iconContainer}
            onClick={addToShoppingCart}
            onMouseLeave={handleMouseLeave}
            onMouseOver={handleMouseOver}
          >
            {expand &&
              <div css={styles.iconCapsule} onClick={addToShoppingCart}>
                <p>Add to Bag</p>
              </div>
            }
            
            {!expand &&
              <div css={styles.iconCircle}>
                <BagIcon fontSize='medium' color='secondary' />
              </div>
            }
          </div>
        }
      </div>


      <div css={styles.infoContainer} onClick={navigateToProductPage}>
        <div css={styles.header}>
          <p css={styles.title}>{name}</p>
        </div>

        <Typography    
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
         }}>
          {caption}
        </Typography>
      </div>
      

      <div css={styles.footer}>
        <ProductCardButtons
          productId={product.id}
          name={name}
          price={price}
          imageURL={imageURL}
          merchantId={merchantId}
          merchantType={merchantType}
          edit={edit}
        />
      </div>
    </div>
  )
}



const mapStateToProps = (state, props) => {
  return {
    product: state.products.byId[props.product.id]
  }
}


const actions = {addToCart, showAlert}


export default connect(mapStateToProps, actions)(ProductCard)

