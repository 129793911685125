import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {connect} from 'react-redux'

import {isEmpty} from 'lodash'

import { 
  removeFromCart, 
  incrementQuantity,
  decrementQuantity
} from '../../../actions'
import {formatMoney} from '../../../utils'

import {convertSelectedVariantsToString} from '../../../helpers'

import {Image} from '../../generics'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import MinusIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'



const cssStyles = props => ({
  root: css`
    width: 100%;
    height: 75px;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
  `,
  imageContainer: css`
    min-width: 75px;
    width: 75px;
    height: 75px;
    border-radius: 3px;
    overflow: hidden;
  `,
  image: css`
    width: 75px;
    height: 75px;
    object-fit: cover;
  `,
  infoContainer: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  name: css`
    font-size: 15px;
    font-weight: 600;
  `,
  caption: css`
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #848884
  `,
  top: css`
    display: flex;
    justify-content: space-between;
  `,
  xmark: css`
    margin-left: 5px;
  `,
  bottom: css` 
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  price: css`
    font-size: 16px;
    font-weight: 400;
  `,
  controls: css`
    display: flex;
    flex-wrap: nowrap;
  `,
  quantity: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    border: 1px solid #e5e5e5;
    & > p {
      font-size: 13px;
      font-weight: 500
    }
  `,
  button: css`
    color: #000; 
    min-width: 25px;
    width: 25px;
    height: 25px;
    padding: 0;
    box-shadow: none;
    border: none;
    background-color: #f0f0f0;
    border-radius: 0;
    &:hover {
      color: #000; 
      background-color: #f0f0f0;
      border: none;
    }
  `,
  minusButtonContainer: css`
    border-radius: 3px 0px 0px 3px;
    border: 1px solid #ededed;
    overflow: hidden;
  `,
  plusButtonContainer: css`
    border-radius: 0px 3px 3px 0px;
    border: 1px solid #ededed;
    overflow: hidden;
  `
})



const CartItem = props => {

  const styles = cssStyles()

  const { 
    id,
    name,
    price, 
    image,
    caption,
    quantity,
    selectedVariants,
    removeFromCart,
    incrementQuantity,
    decrementQuantity
  } = props




  function remove() {
    removeFromCart(id)
  }


  function increment() {
    incrementQuantity(id)
  }


  function decrement() {
    decrementQuantity(id)
  }



  return (
    <div css={styles.root}>
      <div css={styles.imageContainer}>
        <Image src={image} css={styles.image} />
      </div>

      <div css={styles.infoContainer}>
        <div css={styles.top}>
          <div>
            <Typography css={styles.name} noWrap>
              {name}
            </Typography>

            <Typography css={styles.caption} gutterBottom noWrap>
              {caption}
            </Typography>


            {!isEmpty(selectedVariants) && 
              <Typography css={styles.caption} gutterBottom noWrap>
                {convertSelectedVariantsToString(selectedVariants)}
              </Typography>
            }
          </div>

          <div css={styles.xmark} onClick={remove}>
            <ClearIcon fontSize='small' style={{color: '#c4c4c4'}} />
          </div>
        </div>

        <div css={styles.bottom}>
          <Typography css={styles.price}>
            {formatMoney(price)}
          </Typography>

          <div css={styles.controls}>
            <div css={styles.minusButtonContainer}>
              <Button css={styles.button} variant='outlined' onClick={decrement}>
                <MinusIcon fontSize='13px' />
              </Button>
            </div>

            <div css={styles.quantity}>
              <Typography align='center'>
                {quantity}
              </Typography>
            </div>

            <div css={styles.plusButtonContainer}>
              <Button css={styles.button} variant='outlined' onClick={increment}>
                <AddIcon fontSize='13px' />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const actions = {removeFromCart, incrementQuantity, decrementQuantity}


export default connect(null, actions)(CartItem)




