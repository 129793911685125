import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withFormik, Form } from 'formik'
import { formatError } from '../../../../utils'
import { useIsMounted } from '../../../../hooks'
import makeStyles from '@mui/styles/makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'


import ImageField from './ImageField'
import FormButton from '../FormButton'
import CloseButton from '../CloseButton'
import ThreeDotProgress from '../../ThreeDotProgress'


import { 
  create, 
  showAlert,
  formSubmittedSuccessfully,
  formSubmittedWithErrors,
  resetFormState
} from '../../../../actions'


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 15,
    overflowX: 'hidden',
    position: 'relative'
  },
  form: {
    width: '100%'
  },
  imageFieldContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: props => props.isExtraSmallScreen ? null : 500,
  },
  aspectRatioBox: {
    width: '100%',
    borderRadius: 7,
    height: 0,
    paddingTop: '100%',
    position: 'relative'
  },
  aspectRatioBoxInside: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  }
}))


const ImageForm = props => {
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))
  const classes = useStyles({ isExtraSmallScreen })
  const isMounted = useIsMounted()
  const {
    values,
    setOpen,
    setFieldValue,
    setFieldError,
    setSubmitting,
    setErrors,
    isSubmitting,
    formStatus,
    formErrors,
    showAlert,
    resetFormState
  } = props



  useEffect(() => {
    if (isMounted.current) {
      if (formStatus === 'success') {
        setOpen(false)
      } else if (formStatus === 'error') {
        setSubmitting(false)
        showAlert('Something went wrong', 'error')
        setErrors(formErrors)
      }
    }
    return () => resetFormState()
  }, [
    isMounted, 
    formStatus, 
    formErrors,
    setErrors, 
    setOpen, 
    setSubmitting,
    showAlert,
    resetFormState
  ])


  return (
    <div className={ classes.root }>
      { isExtraSmallScreen && 
          <div>
            <CloseButton onClick={ () => setOpen(false) } />
          </div>
      }

      <Form className={ classes.form } autoComplete='off'>
        <div className={ classes.imageFieldContainer }>
          <div className={ classes.aspectRatioBox }>
            <div className={ classes.aspectRatioBoxInside }>
              <ImageField
                image={ null }
                fieldValue={values.image}
                setFieldValue={ setFieldValue } 
                setFieldError={ setFieldError } 
              />
            </div>
          </div>
        </div>

        <div className={ classes.buttonContainer }>
          <FormButton 
            type='submit' 
            variant='contained' 
            color='primary' 
            disabled={ isSubmitting }>
            { isSubmitting 
              ? <ThreeDotProgress color='#fff' radius={ 5 } />  
              : 'Submit' 
            }
          </FormButton>
        </div>
      </Form>
    </div>
  )
}


const EnhancedImageForm = withFormik({
  handleSubmit: (values, { props }) => {
    const { 
      creatorId, 
      creatorType, 
      create,
      formSubmittedSuccessfully,
      formSubmittedWithErrors
    } = props

    const form = new FormData()
    const path = `/${creatorType}s/${creatorId}/images`
    const reducerType = creatorType.toUpperCase() + '_IMAGES'

    form.append('image', values.image)

    create(path, reducerType, form).then(response => {
      formSubmittedSuccessfully()
    })
    .catch(error => {
      error = formatError(error)
      formSubmittedWithErrors(error.messages, values)
    })
  }
})(ImageForm)


const mapStateToProps = state => {
  return {
    formStatus: state.form.status,
    formErrors: state.form.errors
  }
}

const actions = {
  create,
  showAlert,
  formSubmittedSuccessfully,
  formSubmittedWithErrors,
  resetFormState
}


export default connect(mapStateToProps, actions)(EnhancedImageForm)



