const INITIAL_STATE = {
  status: 'pending',
  errors: {}
}

 const formReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'RESET_FORM_STATE':
      return INITIAL_STATE
    case 'FORM_SUBMITTED_SUCCESS':
      return { 
        status: 'success', 
        errors: {} 
      }
    case 'FORM_SUBMITTED_ERROR':
      return {
        status: 'error',
        errors: action.payload
      }
    default: 
      return state
  }
}

export default formReducer