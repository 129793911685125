import { index, patch, userAPI } from '../apis'
import { addBandsToArtist, setFetchedAssociations } from './relationshipActions'
import {get} from 'lodash'


export function fetchArtistRelationships(artistId) {
  const url = '/artists/' + artistId + '/bands'

  return dispatch => {
    dispatch({ type: 'ACCOUNT_RELATIONSHIPS_REQUESTED' })

    return index(url).then(response => {
      dispatch({ type: 'BANDS_INDEX_SUCCESS', payload: response.data })
      dispatch(addBandsToArtist(artistId, response.data.data))
      dispatch(setFetchedAssociations('ARTIST', artistId, 'bands'))
      dispatch({ 
        type: 'ACCOUNT_RELATIONSHIPS_SUCCESS', 
        payload: { bands: response.data.data.map(band => band.id) }
      })
    })
    .catch(error => {
      dispatch({ type: 'ACCOUNT_RELATIONSHIPS_FAILURE' })
      throw(error)
    })
  }
}


export function deleteAccount() {
  return dispatch => {
    dispatch({ type: 'ACCOUNT_DESTROY_SUCCESS'})
  }
}


export function createAccount(data) {
  return dispatch => {
    dispatch(createAccountRequested())

    return userAPI.createAccount(data).then(response => {
      const account = get(response.data, 'included', [])[0]

      if (account) {
        dispatch(setAccount(account))
      }
      
      return {}
    })
    .catch(error => {
      dispatch(createAccountFailure())
      throw(error)
    })
  }

  function createAccountRequested() { return { type: 'CREATE_ACCOUNT_REQUESTED' }}
  function createAccountFailure() { return { type: 'CREATE_ACCOUNT_FAILURE' }}
}


export function setAccount(account) {
  return dispatch => {
    if (account) {
      dispatch({ type: 'SET_ACCOUNT', payload: account })

      if (account.type === 'artist') {
        dispatch({ type: 'ARTIST_SHOW_SUCCESS', payload: { data: account }})
      } else if (account.type === 'venue') {
        dispatch({ type: 'VENUE_SHOW_SUCCESS', payload: { data: account }})
      }
    }
  }
}


export function updateAccountSettings(url, data) {
  return dispatch => {
    dispatch({ type: 'ACCOUNT_SETTINGS_UPDATE_REQUESTED' })

    return patch(url, data).then(response => {
      dispatch({ type: 'ACCOUNT_SETTINGS_UPDATE_SUCCESS', payload: response.data.data })
      return response.data
    })
    .catch(error => {
      dispatch({ type: 'ACCOUNT_SETTINGS_UPDATE_ERROR' })
      throw(error)
    })
  }
}

