import getImageOfSize from './imageGetter'

// This function take three inputs: Product, quantity, and selected variant and
// combines the three into one object
export function createOrderItem(product, quantity, variant = {}) {
  
  
  const {name, caption, price, merchantId, merchantType} = product.attributes

  const image = getImageOfSize(product.attributes)



  return ({
    productId: product.id,
    name: name,
    image: image,
    price: price,
    caption: caption,
    quantity: quantity,
    merchantId: merchantId,
    merchantType: merchantType,
    selectedVariants: variant 
  })
}