import React, { useState } from 'react'
import { sentenceCase } from '../../../utils'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'





const ProductVariantSelect = props => {

  const [selection, setSelection] = useState('')

  const { variant, options, placeholder, callback } = props


  const handleChange = e => {
    setSelection(e.target.value)
    if (callback) callback(variant, e.target.value)
  }


  return (
    <FormControl sx={{minWidth: 330, width: '100%'}}>
      <Select 
        value={selection} 
        onChange={handleChange}
        sx={{
          '& .MuiSelect-select .notranslate::after': 
            placeholder
            ? {content: `"${placeholder}"`, opacity: 0.42}
            : {},
        }}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            {sentenceCase(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}


export default ProductVariantSelect