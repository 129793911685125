import React, { useState } from 'react'
/** @jsxImportSource @emotion/react */
import { connect } from 'react-redux'
import { useTheme } from '@mui/styles'
import { formatDistance } from 'date-fns'
import { useIsMounted } from '../../../hooks'
import { patch } from '../../../apis'
import { formatError } from '../../../utils'
import { showAlert, accept, decline } from '../../../actions'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'


import headerInvitationNotificationStyles from '../../../styles/header/headerInvitationNotificationStyles'



const HeaderInvitationNotification = props => {
  const isMounted = useIsMounted()
  const navigate = useNavigate()
  const [disabled, setDisabled] = useState(false)
  const { notification, showAlert, accept, decline } = props
  const { sourceId, message, title, details, updatedAt } = notification.attributes
  const { invitable, accepted } = details
  const invitationId = sourceId
  const dateNotificationUpdated = new Date(updatedAt)

  const [updatedImage, setUpdatedImage] = useState()

  const theme = useTheme()
  const styles = headerInvitationNotificationStyles({ theme, type: invitable.type.toLowerCase() })


  function handleClick() {
    navigate('/' + invitable.type.toLowerCase() + 's/' + invitable.id)
  }


  function acceptInvite(e) {
    e.stopPropagation()

    setDisabled(true)

    accept(invitationId).then(response => {
      let message = ''

      const name = response.invitable.attributes.name
      const type = invitable.type.toLowerCase()
      
      if (type === 'band') {
        message = 'You have joined the band ' + name
      } else if (type === 'performance') {
        message = 'You will be performing at ' + name
      }

      showAlert(message, 'success')

      if (isMounted.current) {
        setDisabled(false)
      }
    })
    .catch(error => {
      error = formatError(error)
      showAlert(error.messages, 'error')
    })
  }



  function declineInvite(e) {
    e.stopPropagation()
    
    setDisabled(true)

    decline(invitationId).then(response => {
      if (isMounted.current) {
        setDisabled(false)
      }
    })
    .catch(error => {
      error = formatError(error)
      showAlert(error.messages, 'error')
    })
  }


  function fetchNewImageAndUpdateNotification() {
    if (updatedImage) return

    const path = '/notifications/' + notification.id + '/update-invitation-image'
      
    patch(path, { image_url: invitable.image }).then(response => {
      if (isMounted.current && response.statusText === 'OK') {
        setUpdatedImage(response.data)
      }
    })
  }



  return (
    <div css={ styles.root } onClick={ handleClick }>
      <div css={ styles.contentRow }>
        <div css={ styles.avatarContainer }>
          <Avatar
            css={ styles.avatar }
            alt={ title } 
            src={ updatedImage || invitable.image } 
            imgProps={{
              onError: fetchNewImageAndUpdateNotification
            }}
            variant='rounded' 
          />
        </div>

        <div css={ styles.contentContainer }>       
          <Typography css={ styles.title } noWrap>
            { title }
          </Typography> 
          <Typography css={ styles.message } gutterBottom>
            { message }
          </Typography>
          <Typography css={ styles.time } noWrap>
            { formatDistance(dateNotificationUpdated, new Date()) + ' ago' }
          </Typography>
        </div>
      </div>

      <div css={ styles.actionRow }>
        { accepted === undefined && 
            <div css={ styles.actions }>
              <Button
                css={ styles.accept }
                key='accept'
                disabled={ disabled }
                onClick={ acceptInvite }
              >
                Accept
              </Button>
              <Button
                css={ styles.decline }
                key='decline'
                disabled={ disabled }
                onClick={ declineInvite }
              >
                Decline
              </Button>
            </div>
        }
      </div>
    </div>
  )
}


const actions = { showAlert, accept, decline }

export default connect(null, actions)(HeaderInvitationNotification)




