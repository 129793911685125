import React, { useState, useEffect  } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { show } from '../../apis'
import { useIsMounted } from '../../hooks'
import { abbreviateNumber } from '../../utils'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'



const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: 'auto',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column'
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'fixed',
    top: 10,
    right: 10,
    color: '#fff',
    fontSize: 40
  },
  top: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  bottom: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  stat: {
    flexGrow: 1,
    maxWidth: 200,
    marginTop: 30,
    marginBottom: 30,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start'
  },
  title: {
    color: '#fff',
    fontSize: '1.2rem'
  },
  number: {
    maxWidth: '100%',
    color: '#fff',
    fontSize: '1.8rem',
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis'
  },
  lineBlue: {
    width: 90,
    marginLeft: 0,
    borderColor: '#3EC1F2',
    borderWidth: 5,
    borderStyle: 'solid',
    borderRadius: 5
  },
  lineGreen: {
    width: 90,
    marginLeft: 0,
    borderColor: '#C5F7DD',
    borderWidth: 5,
    borderStyle: 'solid',
    borderRadius: 5
  },
  lineYellow: {
    width: 90,
    marginLeft: 0,
    borderColor: '#FAD25A',
    borderWidth: 5,
    borderStyle: 'solid',
    borderRadius: 5
  },
  linePink: {
    width: 90,
    marginLeft: 0,
    borderColor: '#F24385',
    borderWidth: 5,
    borderStyle: 'solid',
    borderRadius: 5
  }
}))


const Stats = props => {
  const classes = useStyles()
  const isMounted = useIsMounted()
  const { type, id, close } = props
  const maxNumber = 99_999_999
  const [stats, setStats] = useState({ 
    views: '',
    likes: '',
    favorites: '',
    followers: ''
  })


  useEffect(() => {
    show('/' + type + 's/' + id + '/stats')
      .then(response => {
        if (isMounted.current) {
          setStats(response.data.data)
        }
      })
  }, [id, type, isMounted])


  return (
    <div className={ classes.root }>
      { close && 
          <CloseIcon 
            className={ classes.closeIcon } 
            onClick={ close } 
          /> 
      }

      <div className={ classes.top }>
        { !isNaN(stats.views) && 
            <div className={ classes.stat }>
              <Typography className={ classes.title }>
                VIEWS
              </Typography>
              <hr className={ classes.lineBlue }/>
              <Typography className={ classes.number }>
                { stats.views > maxNumber ? 
                    abbreviateNumber(stats.views) : 
                    stats.views.toLocaleString() 
                }
              </Typography>
            </div>
        }

        { !isNaN(stats.likes) && 
          <div className={ classes.stat }>
            <Typography className={ classes.title }>
              LIKES
            </Typography>
            <hr className={ classes.lineGreen }/>
            <Typography className={ classes.number }>
              { stats.likes > maxNumber ? 
                  abbreviateNumber(stats.likes) : 
                  stats.likes.toLocaleString() 
              }
            </Typography>
          </div>
        }
      </div>


      <div className={ classes.bottom }>
        { !isNaN(stats.favorites) && 
            <div className={ classes.stat }>
              <Typography className={ classes.title }>
                FAVORITES
              </Typography>
              <hr className={ classes.linePink }/>
              <Typography className={ classes.number }>
                { stats.favorites > maxNumber ? 
                    abbreviateNumber(stats.favorites) : 
                    stats.favorites.toLocaleString() 
                }
              </Typography>
            </div>
        }

        { !isNaN(stats.followers) && 
            <div className={ classes.stat }>
              <Typography className={ classes.title }>
                FOLLOWERS
              </Typography>
              <hr className={ classes.lineYellow }/>
              <Typography className={ classes.number }>
                { stats.followers > maxNumber ? 
                    abbreviateNumber(stats.followers) : 
                    stats.followers.toLocaleString() 
                }
              </Typography>
            </div>
        }
      </div>
    </div>
  )
}


export default Stats


