import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'


const cssStyles = props => ({
  carouselContainer: css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  carouselWrapper: css`
    overflow: hidden;
    width: 100%;
    height: 100%;
  `,
  carouselContent: css`
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;
    & > * {
      width: 100%;
      flex-shrink: 0;
      flex-grow: 1;
    }
  `,
  contentWrapper: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `
})



const Carousel = props => {

  const styles = cssStyles()

  const {items, step} = props


  return (
    <div css={styles.carouselContainer}>
      <div css={styles.carouselWrapper}>
        <div css={styles.carouselContentWrapper}>
          <div css={styles.carouselContent} style={{transform: `translateX(-${step * 100}%)`}}>
            {items.map((item, index) => (
              <div key={index} css={styles.contentWrapper}>
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}


export default Carousel