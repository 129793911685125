import {omit} from 'lodash'


const INITIAL_STATE = {}


const attributesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_USER':
    case 'USER_UPDATE_SUCCESS':
      return omit(action.payload.attributes, ['settings', 'spotify'])
    case 'DELETE_USER':
      return INITIAL_STATE
    default:
      return state
  }
}



export default attributesReducer