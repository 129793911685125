import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {useNavigate, Link} from 'react-router-dom'

import {signOut} from '../../actions'

import {userSelector} from '../../selectors'

import {getImageOfSize, sentenceCase} from '../../utils'

import Avatar from '@mui/material/Avatar'
import Collapse from '@mui/material/Collapse'
import MenuList from '@mui/material/List'
import MenuItem from '@mui/material/ListItem'
import LogoutIcon from '@mui/icons-material/Logout'
import IconButton from '@mui/material/IconButton'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'


import {CkordLogo} from '../generics'




const cssStyles = ({isExtraSmallScreen}) => ({
  root: css`
    width: 100%;
    height: 100%;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      color: #fff;
      text-decoration: none;
    }
  `,
  logoContainer: css`
    width: 100%;
    padding-left: 80px;
    color: #fff;
    cursor: pointer;
  `,
  list: css`
    width: 100%;
    margin-top: 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;


    & > a, & > li {
      width: 100%;
      padding: ${isExtraSmallScreen ? '10px 15px 10px 80px' : '17px 15px 17px 80px'};
      font-size: 1rem;
      font-weight: 300;
      color: #fff;
      cursor: pointer;
      flex-grow: 1;
      max-height: 50px;

      &:hover {
        font-weight: 500;
      }
    }
  `,
  secondLevelList: css`
    & > a, & > li {
      width: 100%;
      padding: ${isExtraSmallScreen ? '10px 15px 10px 100px' : '17px 15px 17px 100px'};
      font-size: 1rem;
      font-weight: 300;
      color: #fff;
      cursor: pointer;
      flex-grow: 1;
      max-height: 50px;

      &:hover {
        font-weight: 500;
      }
    }
  `,
  expandItem: css`
    display: flex;
    gap: 30px;

  `,
  line: css`
    width: 100%;
    border: 1px solid #434343;
    margin-left: 80px;
  `,
  linkContainer: css`
    width: 100%;
    display: flex;
    padding: 20px;
    align-items: center;
    background-color: #1B1B1B;

    a {
      display: flex;
      width: 100%;
      gap: 15px;
      align-items: center;

      h2 {
        font-size: 1.1rem;
        font-weight: 500;
      }

      p {
        font-size: 10px;
        text-transform: uppercase;
        color: #f24385;
        letter-spacing: 1px;
        font-weight: 400;
        text-align: center;
      }
    }
  `,
  linkTextContainer: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `
})



const AuthenticatedSidebar = props => {

  const navigate = useNavigate()

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))


  const [expand, setExpand] = useState(true)


  const styles = cssStyles({isExtraSmallScreen})


  const {
    role,
    close,
    signOut,
    imageURL,
    username,
    accountId,
    accountType,
    isMerchant
  } = props



  const isArtistOrVenue = ['artist', 'venue'].includes(accountType)



  function goHome() {
    navigate('/home')
  }
  


  function handleSignOut() {
    navigate('/sign-in', {replace: true})
    signOut()
  }



  function toggleExpand() {
    setExpand(!expand)
  }




  return (
    <div css={styles.root}>
      <div onClick={goHome} css={styles.logoContainer}>
        <CkordLogo 
          style={{
            color: '#fff', 
            cursor: 'pointer', 
            fontSize: isSmallScreen ? '21px' : '24px'
          }} 
        />
      </div>



      <MenuList css={styles.list}>
        <MenuItem onClick={close} component={Link} to='/home'>
          Home
        </MenuItem>


        {isArtistOrVenue &&
          <MenuItem onClick={close} component={Link} to={'/' + accountType + 's/' + accountId}>
            {sentenceCase(accountType) + ' Profile'}
          </MenuItem>
        }




        {isMerchant &&
          <>
            <MenuItem css={styles.expandItem} onClick={toggleExpand}>
              Merchant

              {expand ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>

            <Collapse in={expand} timeout='auto' unmountOnExit>
              <MenuList css={styles.secondLevelList} component='div' disablePadding>
                <MenuItem to='/shop' onClick={close} component={Link}>
                  My Shop
                </MenuItem>

                <MenuItem to='/contributions' onClick={close} component={Link}>
                  Contributions
                </MenuItem>
              </MenuList>
            </Collapse>
          </>
        }


        <MenuItem onClick={close} component={Link} to='/orders'>
          My Orders
        </MenuItem>

        <MenuItem onClick={close} component={Link} to='/favorites'>
          Favorites
        </MenuItem>

        <MenuItem onClick={close} component={Link} to='/following'>
          Following
        </MenuItem>
        
        <MenuItem onClick={close} component={Link} to='/messages'>
          Messages
        </MenuItem>

        <MenuItem component='a' href='https://www.blog.ckord.com/about'>
          About
        </MenuItem>
        
        <MenuItem onClick={close} component={Link} to='/settings'>
          Settings
        </MenuItem>


        {isArtistOrVenue && !isExtraSmallScreen &&
          <MenuItem onClick={close} component={Link} to='/widget'>
            Widget
          </MenuItem>
        }

        {!isExtraSmallScreen && 
          <MenuItem onClick={handleSignOut}>
            Sign Out
          </MenuItem>
        }

        
        <hr css={styles.line} />


        {!isArtistOrVenue &&
          <MenuItem sx={{color: '#fbde54'}} onClick={close} component={Link} to='/settings/account'>
            Artists & Venues
          </MenuItem>
        }


        {['admin', 'editor'].includes(role) && !isExtraSmallScreen &&
          <MenuItem onClick={close} component={Link} to='/admin'>
            Admin
          </MenuItem>
        }

        <MenuItem onClick={close} component={Link} to='/legal'>
          Legal
        </MenuItem>
      </MenuList>


      <div css={styles.linkContainer}>
        <Link css={styles.link} onClick={close} to='/settings'>
          <Avatar 
            sx={{width: isSmallScreen ? 30 : 50, height: isSmallScreen ? 30 : 50}} 
            src={imageURL} 
            alt='username'
          />

          <div css={styles.linkTextContainer}>
            <h2 css={styles.username}>{username}</h2>
            
            {isArtistOrVenue && <p css={styles.type}>{accountType}</p>}
          </div>
        </Link>

        <IconButton onClick={handleSignOut} size='large'>
          <LogoutIcon color='secondary' />
        </IconButton>
      </div>
    </div>

  )
}



const mapStateToProps = state => {

  const user = userSelector(state)
  
  const imageURL = getImageOfSize(user.attributes, 'small')


  return { 
    role: user.attributes.role,
    imageURL: imageURL,
    username: user.attributes.username,
    accountId: state.user.account.id,
    accountType: state.user.account.type,
    isMerchant: state.user.account.attributes.isMerchant
  }
} 



export default connect(mapStateToProps, {signOut})(AuthenticatedSidebar)



