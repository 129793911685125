import {useState, useEffect} from 'react'

import {useSelector, useDispatch} from 'react-redux'

import {fetchLFFForTypeAndId} from '../actions'





export function useFetchLFFForTypeAndId(type, id, ignore = false) {
  const pluralType = type + 's'
  
  const isFavoriteFetched = useSelector(state => id in state.user.favorites[pluralType])
  const isLikeFetched = useSelector(state => id in state.user.likes[pluralType])


  const isFetching = useSelector(state => state.user.lff.isFetching)
  const isAuthenticated = useSelector(state => state.user.isAuthenticated)

  const dispatch = useDispatch()


  const [fetched, setFetched] = useState(new Set())


  useEffect(() => {
    if (ignore) return

    if (id && isAuthenticated && !isFetching && !isFavoriteFetched && !isLikeFetched && !fetched.has(id)) {
      let newSet = new Set(fetched)
      newSet.add(id)

      setFetched(newSet)

      dispatch(fetchLFFForTypeAndId(pluralType, id))
    }
  }, [
    id,
    ignore, // Instances where a fetch shouldn't happen such as proxy tracks
    fetched,
    dispatch,
    pluralType, 
    setFetched,
    isFetching,
    isLikeFetched,
    isAuthenticated, 
    isFavoriteFetched
  ])
}