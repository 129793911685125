import React, {useState} from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'

import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import ShareIcon from '@mui/icons-material/Share'

import ShareButtons from '../../generics/ShareButtons'



const ShareIconButton = props => {

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const [open, setOpen] = useState(false)

  const {id, type, size, name, ...rest} = props

  const iconSize = size || (isExtraSmallScreen ? 'small' : 'medium')



  function handleClick() {
    setOpen(true)
  }



  function close() {
    setOpen(false)
  }



  return (
    <>
      <IconButton onClick={handleClick} {...rest} size={iconSize || 'medium'}>
        <ShareIcon fontSize={iconSize || 'medium'} style={{color: '#fff'}} />
      </IconButton>


      <Dialog 
        open={open}
        onClose={close}
        disableScrollLock={isExtraSmallScreen ? false : true}
      >
        <ShareButtons 
          title={name}
          text={`Check out this ${type} on Ckord`}
          url={process.env.REACT_APP_BASE_URL + `/${type}s/${id}`} 
        />
      </Dialog>
    </>
  )
}



export default ShareIconButton