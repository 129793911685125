import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js'

import {useLocation} from 'react-router-dom'

import * as Yup from 'yup'

import {formatInputToCurrency, validateCurrency} from '../../utils'

import {useIsMounted} from '../../hooks'

import {crudAPI} from '../../apis'

import {showAlert} from '../../actions'


import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'


import ThreeDotProgress from '../generics/ThreeDotProgress'
import SlideTransition from '../generics/SlideTransition'

import PricingAndFeesPage from '../ecommerce/notices/PricingAndFeesPage'




const cssStyles = ({isExtraSmallScreen}) => ({
  root: css`
    max-width: 400px;
    padding: ${isExtraSmallScreen ? '0px 40px 40px 40px' : '40px'};
  `,
  titleContainer: css`
    margin-bottom: 25px;

    h2 {
      font-size: 1.7rem;
      font-weight: 500;
      letter-spacing: -0.1rem;
    }

    p {
      font-size: 15px;
    }
  `,
  tipsContainer: css`
    display: flex;
    justify-content: space-between;
    gap: 5px;
  `,
  tipContainer: css`
    width: 70px;
    height: 70px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 30px;
    cursor: pointer;
  `,
  button: css`
    height: 50px;
    width: 100%;
    margin-top: 30px;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  `,
  caption: css`
    margin-top: 25px;
    text-align: center;

    span {
      color: rgb(62, 166, 255);
      font-weight: 500;
      text-decoration: underline;
      letter-spacing: 0rem;
      cursor: pointer;
    }
  `,
  adornment: css`
    p {
      font-size: 24px;
    }
  `,
  message: css`
    margin-top: 15px;
  `
})



const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email().required()
})




const tips = [1, 2, 5, 10]



const StripeTipForm = props => {

  const isMounted = useIsMounted()

  // Stripe elements
  const stripe = useStripe()
  const elements = useElements()


  const location = useLocation()


  const [tip, setTip] = useState(1)

  const tipFloat = parseFloat(tip.toString().replace(',', ''))

  const [message, setMessage] = useState('')

  const [email, setEmail] = useState('')

  const [emailError, setEmailError] = useState('')

  const [error, setError] = useState('')


  const [open, setOpen] = useState(false)
  
  const [isFetching, setIsFetching] = useState(true)


 
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const styles = cssStyles({isExtraSmallScreen})


  const {tipId, name, close, isAuthenticated, showAlert, setOptions} = props




  // TODO: Detect card form errors
  // useEffect(() => {
  //   if (isStripeReady) {
  //     const cardElement = document.getElementById('card-element')

  //     console.log(cardElement)

  //     if (cardElement) {
  //       cardElement.on('change', e => {
  //         if (e.complete) {
  //           console.log('complete')
  //         } else if (e.error) {
  //           console.log('here')
  //         }
  //       })
  //     }
  //   }
  // }, [isStripeReady])




    // Taken from Stripe example: https://stripe.com/docs/stripe-js/react
  const submit = async () => {

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    // TODO: Update receipt email
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: process.env.REACT_APP_BASE_URL + location.pathname + '?tip_id=' + tipId
        // receipt_email: 'dmijato1@gmail.com'
      }
    })


    // TODO:
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      showAlert('Something went worng')

      if (isMounted.current) {
        setIsFetching(false)
      }
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      crudAPI.patch('/tips/complete/' + tipId).then(response => {
        showAlert(`Thank you for supporting ${name} and live music!`, 'success')

        if (isMounted.current) {
          setIsFetching(false)
          close()
          setOptions({clientSecret: false})
        }
      })
      .catch(error => {
        showAlert('Something went wrong. Contact support@ckord.com if issue persists.', 'error')

        if (isMounted.current) {
          setIsFetching(false)
        }
      })
    }
  }




  function updateAndSubmit() {
    const isValid = validateCurrency(tip)

    if (isValid && tipFloat >= 0.50 && message.length <= 3500) {

      let params = {amount: tip, message}

      // If this is a guest user and an email has been provided,
      // ensure that he email is valid before adding it to the params
      if (!isAuthenticated && Boolean(email)) {
        if (Boolean(emailError)) {
          showAlert('Invalid email.', 'error')
          return
        }

        params['email'] = email
      }


      setIsFetching(true)

      crudAPI.patch('/tips/' + tipId, params).then(response => {
        submit()
      })
      .catch(error => {
        if (isMounted.current) {
          setIsFetching(false)
        }
      })
    } else {
      setError(tipFloat < 0.50 ? 'Minimum amount is $0.50' : 'Invalid money format')
    }
  }


  
  function handleBlur(e) {
    const amount = formatInputToCurrency(e.target.value)

    if (1 > Number(amount)) {
      setError('Minimum value is $1') 
    }
  }



  function handleChange(e) {
    if (error) setError('')

    setTip(formatInputToCurrency(e.target.value))
  }



  function handleMessageChange(e) {
    setMessage(e.target.value)
  }



  const handleClick = value => () => {
    setTip(value)
  }



  function handleReady(e) {
    setIsFetching(false)
  }



  function handleClose() {
    setOpen(false)
  }



  function showNotice() {
    setOpen(true)
  }


  function handleEmailChange(e) {
    if (emailError) {
      setEmailError('')
    }

    setEmail(e.target.value)
  }



  function validate() {
    emailValidationSchema.validate({email: email}).catch(error => {
      setEmailError(error.message)
    })
  }




  return (
    <div css={styles.root}>
      {isFetching && <ThreeDotProgress position='absolute' background={true} />}

      <div css={styles.titleContainer}>
        <h2>Contribute</h2>
        <p>Contribute to {name} and live music</p>
      </div>

      <div css={styles.tipsContainer}>
        {tips.map(value => 
          <div 
            key={value}
            onClick={handleClick(value)}
            css={styles.tipContainer}
            style={{
              border: tipFloat === value ? '2px solid rgb(62, 166, 255)' : '1px solid #d9d9d9',
              backgroundColor: tipFloat === value ? 'rgba(62, 166, 255, 0.1)' : '#fff',
              fontWeight: tipFloat === value ? '500' : '400'
            }}
          >
            <p>${value}</p>
          </div>  
        )}
      </div>

      <TextField 
        fullWidth
        value={tip}
        label='Amount'
        variant='filled'
        error={Boolean(error)}
        helperText={error}
        autoComplete='off'
        onBlur={handleBlur}
        onChange={handleChange}
        sx={{
          marginBottom: '20px',
          
          '.MuiInputBase-input': {
              fontSize: '24px',
              textAlign: 'right'
          }
        }}
        InputProps={{
          startAdornment: 
            <InputAdornment position='start' css={styles.adornment}>
              $
            </InputAdornment>
        }}
      />

      <div id='card-element'>
        <PaymentElement onReady={handleReady} />
      </div>


      <TextField
        variant='filled'
        label='Message'
        fullWidth
        value={message}
        multiline={true}
        rows={10}
        sx={{marginTop: '20px'}}
        onChange={handleMessageChange}
        error={message.length > 3500}
        helperText={
          new Intl.NumberFormat().format(3500 - message.length).toString() + ' characters left'
        }
      />


      {!isAuthenticated &&
        <TextField
          variant='filled'
          label='Contact Email (Optional)'
          fullWidth
          value={email}
          sx={{marginTop: '20px'}}
          onBlur={validate}
          onChange={handleEmailChange}
          error={Boolean(emailError)}
          helperText={
            emailError || 
            `Provide an email so we can send you a recipt and so ${name} can send you a message.`
          }
        />
      }


      <Button 
        css={styles.button} 
        onClick={updateAndSubmit} 
        disabled={isFetching || Boolean(error) || Boolean(emailError)}
      >
        {isFetching ? <ThreeDotProgress radius={5} color='#fff' /> : 'Send'}
      </Button>


      <p css={styles.caption} onClick={showNotice}>
        Learn more about our <span>pricing and fees</span>.
      </p>


      <Dialog 
        open={open}
        onClose={handleClose}
        fullScreen={true}
        TransitionComponent={SlideTransition}
      >
        <PricingAndFeesPage close={handleClose} />
      </Dialog>
    </div>
  )
}



const mapStateToProps = state => {
  return {
    isAuthenticated: state.user.isAuthenticated
  }
}



export default connect(mapStateToProps, {showAlert})(StripeTipForm)



