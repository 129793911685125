import React from 'react'

import styles from '../../../styles/detail-pages/detailPageTabButtonContainer.module.scss'




const DetailPageTabButtonContainer = props => {
  return (
    <div className={styles.root}>
      {React.isValidElement(props.children) && 
        <div className={styles.container}>
          {props.children}
        </div>
      }
    </div>
  )
}



export default DetailPageTabButtonContainer