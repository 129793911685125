import { map, get } from 'lodash'
import {difference} from './arrayFunctions'


export function mergeObjects(objects, state) {
  if (state) {
    return Object.keys(objects).reduce((acc, id) => {
      const existingRecord = state[id]

      if (existingRecord) {
        acc[id] = mergeRelationships(objects[id], existingRecord)
        acc[id] = mergeLinks(objects[id], existingRecord)
      } else {
        acc[id] = objects[id]
      }

      return acc
    }, {})
  }

  return objects
}



export function mergeRelationships(newObject, prevObject) {
  const newRelationships = get(newObject, 'relationships', {}) 
  const prevRelationships = get(prevObject, 'relationships', {})


  Object.keys(prevRelationships).forEach(key => {
    if (key in newRelationships) {
      const previous = map(prevRelationships[key], 'id')
      const current = map(newRelationships[key], 'id')
      const elementsToAdd = difference(current, previous)

      elementsToAdd.forEach(id => {
        const relationship = prevRelationships[key].find(e => e.id = id)
        newRelationships[key].push(relationship)
      })
    }
    else {
      newRelationships[key] = prevRelationships[key]
    }
  })

  newObject['relationships'] = newRelationships
  newObject['fetched'] = get(prevObject, 'fetched', [])
  return newObject
}



export function mergeLinks(newObject, prevObject) {
  newObject['links'] = get(prevObject, 'links', {})

  return newObject
}




export function removeDataKeyFromRelationships(objects = {}) {
  const result = Object.keys(objects).reduce((acc, id) => {
    const relationships = objects[id].relationships || {}

    acc[id] = { ...objects[id] }
    acc[id]['relationships'] = {}

    Object.keys(relationships).forEach(key => {
      acc[id].relationships[key] = relationships[key].data
    })

    return acc
  }, {})


  return result
}



export function compareAndAddNewValues(prevArray, newArray) {
  let array = [ ...prevArray ]

  const prevIds = map(prevArray, 'id')
  const newIds = map(newArray, 'id')


  const elementsToAdd = difference(newIds, prevIds)

  elementsToAdd.forEach(id => {
    const record = newArray.find(e => e.id === id)
    array.push(record)
  })

  return array
}






