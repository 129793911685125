import React, {useState} from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'

import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import QrCodeIcon from '@mui/icons-material/QrCode2'




const QRCodeIconButton = props => {

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const [open, setOpen] = useState(false)

  const {size, children, ...rest} = props

  const iconSize = size || (isExtraSmallScreen ? 'small' : 'medium')



  function handleClick() {
    setOpen(true)
  }



  function close() {
    setOpen(false)
  }




  return (
    <>
      <IconButton onClick={handleClick} {...rest} size={iconSize || 'medium' }>
        <QrCodeIcon size={iconSize || 'medium'} style={{color: '#fff'}} />
      </IconButton>

      <Dialog 
        open={open} 
        onClose={close} 
        fullWidth
        maxWidth='md'
        disableScrollLock={isExtraSmallScreen ? false : true}
        sx={{backdropFilter: 'blur(10px)'}}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
          {children}
        </Dialog>

    </>
  )
}



export default QRCodeIconButton