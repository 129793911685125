import React, { useState } from 'react'
import { connect } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { pick } from 'lodash'
import { sentenceCase } from '../../utils'
import { 
  userSelector,
  accountAttributesSelector,
  accountRelationshipsSelector
} from '../../selectors'
import Typography from '@mui/material/Typography'



const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 20px'
  },
  list: {
    maxHeight: 188,
    overflowY: 'auto'
  },
  title: {
    fontWeight: 600,
    fontSize: '1.1rem',
    paddingBottom: 15,
    borderBottom: '1px solid ' + theme.palette.secondary._300
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 15px',
    cursor: 'pointer',
    borderBottom: '1px solid ' + theme.palette.secondary._300,
    transition: 'all .2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.primary._100
    }
  },
  secondary: {
    fontWeight: 300,
    color: theme.palette.pink.main,
    fontStyle: 'italic'
  }
}))


const MessageSenderSelection = props => {
  const classes = useStyles()
  const [selected, setSelected] = useState()
  const { 
    userId, 
    username,
    accountId,
    accountType,
    accountName,
    selectSender,
    relationships
  } = props


  const handleSenderSelect = (id, type) => e => {
    selectSender(id, type)

    if (selected) {
      selected.style.backgroundColor = '#fff'
      e.currentTarget.style.backgroundColor = '#3a00eb1A'
      setSelected(e.currentTarget)
    } 
    else {
      e.currentTarget.style.backgroundColor = '#3a00eb1A'
      setSelected(e.currentTarget)
    }
  }


  return (
    <div className={ classes.root }>
      <Typography className={ classes.title }>
        Send As
      </Typography>

      <div className={ classes.list }>
        <div className={ classes.item } onClick={ handleSenderSelect(userId, 'User') }>
          <Typography className={ classes.primary }>
            { username }
          </Typography>
          <Typography className={ classes.secondary }>
            User (default)
          </Typography>
        </div>

        <div className={ classes.item } onClick={ handleSenderSelect(accountId, accountType) }>
          <Typography className={ classes.primary }>
            { accountName }
          </Typography>
          <Typography className={ classes.secondary }>
            { sentenceCase(accountType) }
          </Typography>
        </div>

        { Object.values(relationships).map(relation => (
            <div 
              key={ relation.id }
              className={ classes.item } 
              onClick={ handleSenderSelect(relation.id, sentenceCase(relation.type)) }>
              <Typography className={ classes.primary }>
                { relation.attributes.name }
              </Typography>
              <Typography className={ classes.secondary }>
                { sentenceCase(relation.type) }
              </Typography>
            </div>
          ))
        }
      </div>
    </div>
  )
}



const mapStateToProps = state => {
  const user = userSelector(state)
  const accountId = state.user.account.id
  const accountType = state.user.account.type
  const account = accountAttributesSelector(state)
  const relationshipState = accountRelationshipsSelector(state)
  let relationships = []

  if (accountType === 'artist') {
    const bandIds = relationshipState.bands || []
    relationships = pick(state.bands.byId, bandIds)
  }

  return {
    userId: state.user.id,
    username: user.attributes.username,
    accountId: accountId,
    accountType: state.user.account.type,
    accountName: account.name,
    relationships: relationships
  }
}


export default connect(mapStateToProps)(MessageSenderSelection)


