import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { useResize } from '../../hooks'
import { destroy, showAlert } from '../../actions'
import { get } from 'lodash'
import useMediaQuery from '@mui/material/useMediaQuery'
import withSwipableImages from '../hocs/withSwipableImages'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Dialog from '@mui/material/Dialog'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginBottom: 20,
    flexDirection: 'column'
  },
  imageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'hidden'
  },
  image: {
    objectFit: 'contain',
    objectPosition: 'center center',
    maxWidth: '100%',
    maxHeight: '80vh'
  },
  leftIconButton: {
    position: 'fixed',
    left: 10,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  rightIconButton: {
    position: 'fixed',
    right: 10,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  deleteIcon: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    bottom: 15,
    right: 15,
    '&:hover': {
      backgroundColor: 'rgb(255 , 0, 0, 0.8)'
    }
  }
}))


const Images = props => {
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const classes = useStyles({ isExtraSmallScreen, isLargeScreen })
  const ref = useRef()
  const dimensions = useResize(ref)
  const cols = isExtraSmallScreen ? 2 : 3
  const gap = isLargeScreen ? 20 : 4
  const rowHeight = isExtraSmallScreen ? (dimensions.width - 4) / 2 : (dimensions.width - 8) / 3
  const { 
    images, 
    canDelete,
    destroy,
    showImage,
    index,
    setIndex,
    setRef,
    nextImage,
    previousImage,
    showAlert
  } = props



  useEffect(() => {
    if (images.length === 0) {
      setIndex(null)
    } else if (images.length === index) {
      setIndex(images.length - 1)
    }
  }, [images.length, index, setIndex])



  useEffect(() => {
    document.onkeydown = nextOrPreviousImage

    function nextOrPreviousImage(e) {
      switch (e.key) {
        case 'ArrowLeft':
          previousImage()
          break
        case 'ArrowRight':
          nextImage()
          break
        default:
          return
      }
    }
  }, [previousImage, nextImage])



  function handleDelete(e) {
    const imageItem = images[index]
    const imageId = imageItem.id
    const type = imageItem.attributes.imagableType.toLowerCase()
    const id = imageItem.attributes.imagableId
    const url = `/${type}s/${id}/images/${imageId}`
    const reducerType = type.toUpperCase() + '_IMAGES'
    

    destroy(url, reducerType).catch(error => {
      showAlert('Something went wrong', 'error')
    })
  }


  function getImageURL() {
    if (index === null) return

    const image = images[index]
    const url = get(image, ['attributes', 'imageDerivatives', 'original'])
    const fallback = get(image, ['attributes', 'imageDerivatives', 'large'])
    const originalImageURL = get(image, ['attributes', 'image'], '')


    return url || fallback || originalImageURL
  }



  return (
    <div>
      <ImageList ref={ ref } cols={ cols } rowHeight={ rowHeight } gap={ gap }>
        { images.map(item => {
            const imageURL = item.attributes.imageDerivatives.medium || item.attributes.image

            return (
              <ImageListItem 
                onClick={ showImage(item) }
                key={ imageURL } 
                sx={{ 
                  overflow: 'hidden',
                  cursor: 'pointer'
                }}
              >
                <img
                  src={ imageURL }
                  loading='lazy'
                  alt='artist'
                />
              </ImageListItem>
            )
          }) 
        }
      </ImageList>

      <Dialog
        open={ index !== null } 
        onClose={ () => setIndex(null) }
        maxWidth='md'
        PaperProps={{ 
          style: {
            backgroundColor: 'transparent'
          }
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.95)'
          }
        }}
      >
        <div className={ classes.imageContainer }>
          { !isExtraSmallScreen && 
              <IconButton
                className={ classes.leftIconButton }
                onClick={ previousImage }
                size='large'
              >
                <ChevronLeftIcon fontSize='large' />
              </IconButton>
          }

          <img 
            ref={ setRef } 
            className={ classes.image }
            src={ getImageURL() } 
            loading='eager'
            alt='' 
          />

          { !isExtraSmallScreen &&
              <IconButton
                className={ classes.rightIconButton }
                onClick={ nextImage }
                size='large'
              >
                <ChevronRightIcon fontSize='large' />
              </IconButton>
          }

          { canDelete && 
              <IconButton
                className={ classes.deleteIcon } 
                onClick={ handleDelete }
                size='large'
              >
                <DeleteIcon color='secondary' fontSize='large' />
              </IconButton> 
          }
        </div>
      </Dialog>
    </div>
  )
}

export default withSwipableImages(connect(null, { destroy, showAlert })(Images))



