import { index, show } from '../apis'



export function fetchIsLikedOfTypeAndId(type, id) {
  return dispatch => {
    dispatch({ type: 'LIKES_REQUESTED' })

    return show('/' + type + '/' + id + '/lff').then(response => {
      dispatch({ type: 'LIKE_SHOW_SUCCESS', payload: { id, type, data: response.data.data }})}
    )
    .catch(error => {
      dispatch({ type: 'LIKES_ERROR', payload: { id, type }})
      throw(error)
    })
  }
}




export function fetchLikesOfType(type) {
  return dispatch => {
    dispatch({ type: 'LIKES_REQUESTED' })

    return index('/user/liked-' + type).then(response => {
      const likes = response.data.data.reduce((acc, like) => {
        const objectId = like.attributes.likableId

        acc[objectId] = like
        return acc
      }, {})

      dispatch({ type: 'LIKES_INDEX_SUCCESS', payload: { type, likes }})
    })
    .catch(error => {
      dispatch({ type: 'LIKES_ERROR' })
      throw(error)
    })
  }
}



export function orderLikesOfType(type, objects) {
  return dispatch => {  
    const objectIds = objects.map(e => e.id)

    dispatch({ type: 'ORDER_LIKES_OF_TYPE', payload: { type, objectIds }})
  }
}