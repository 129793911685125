import React, {useState, useRef} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {validate} from '../../../../utils/validateImageFile'

import {formatError, convertHEICToJPEG} from '../../../../utils'

import {update, showAlert} from '../../../../actions'

import {useIsMounted} from '../../../../hooks'

import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import EditIcon from '@mui/icons-material/Edit'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'

import Image from '../../Image'
import ThreeDotProgress from '../../../generics/ThreeDotProgress'




const styles = {
  root: css`
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
  `,
  image: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  placeholder: css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
  `,
  icon: css`
    color: #979797;
    width: 30%;
    max-width: 45px;
    height: auto;
  `,
  editIcon: css`
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
  `,
  button: css`
    z-index: 15;
    color: rgb(62, 166, 255);
    text-transform: uppercase;
    padding-left: 0;
    padding-top: 0px;
    font-weight: 500;

    &:hover {
      background-color: transparent;
    }
  `
}



const DefaultPlaceholder = props => (
  <div css={styles.placeholder}>
    <AddAPhotoIcon css={styles.icon} />
  </div>
)




// This is designed to work with Formik
const ImageField = ({showIcon = true, ...props}) => {

  const input = useRef()

  const isMounted = useIsMounted()

  const [isLoading, setIsLoading] = useState(false)

  let {fieldName, fieldValue} = props

  fieldName = fieldName || 'image'

  fieldValue = fieldValue instanceof File ? URL.createObjectURL(fieldValue) : fieldValue
  

  const {
    image,
    imageId,
    imageType,
    update,
    callback,
    showAlert,
    placeholder,
    setFieldValue, 
    setFieldError
  } = props




  const fileSelectedHandler = async e => {

    let file = e.target.files[0]

    const reader = new FileReader()


  
    if (file.type === 'image/heic' || file.type === 'image/heif') {
      try {
        file = await convertHEICToJPEG(file)
      } catch (error) {
        showAlert('Error converting HEIC to JPEG', 'error')
        return
      }
    }



    // Finishes here
    reader.onload = () => {
      if (isMounted.current) {
        if (callback) callback(reader.result, file)

        setIsLoading(false)
      }


      // If there is an image id, then update the image with that id
      if (imageId) {

        const formData = new FormData()

        formData.append('image', file, file.name, file.size)


        update('/images/' + imageId, imageType, formData).then(response => {
          showAlert('Image uploaded', 'success')
        })
        .catch(error => {
          error = formatError(error)
          showAlert(error.messages?.image, 'error')
        })

      
      // Otherwise its a new image so add it to the form 
      } else {

        setFieldValue(fieldName, file)

        // Reset the input so the same file can be loaded if deleted
        e.target.value = ''
      }
    }



    // Starts here
    if (file) {
      const validation = validate(file)


      if (validation.passed) {
        reader.readAsDataURL(file)
      } else {
        setIsLoading(false)
        setFieldError(fieldName, validation.message)
        showAlert(validation.message, 'error')
      }
    }
  }



  function handleClick(e) {
    e.stopPropagation()
    input.current.click()
  }




  return (
    <div css={styles.root} onClick={handleClick}>
      <Image
        css={styles.image}
        src={fieldValue || image}
        alt='product'
        placeholder={placeholder || <DefaultPlaceholder />} 
      />

      {showIcon && 
        <IconButton css={styles.editIcon} size='large' onClick={handleClick}>
          <EditIcon style={{color: '#fff'}} />
        </IconButton>
      }


      <Button css={styles.button} variant='text' onClick={handleClick}>
        {(fieldValue || image) ? 'Edit Image' : 'Add Image'}
      </Button>
      

      <input
        ref={input} 
        style={{display: 'none'}}
        type='file'
        accept='image/png, image/jpeg, image/heic, image/webp, image/jpg'
        onChange={fileSelectedHandler}
      />

      {isLoading && <ThreeDotProgress position='absolute' radius={4} />}
    </div>  
  )
}


export default connect(null, {update, showAlert})(ImageField)


