import React, {useRef, useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {omitBy, isEmpty} from 'lodash'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import MerchantProductFormVariant from './MerchantProductFormVariant'



const styles = {
  root: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #d9d9d9;

    @media (max-width: 800px) {
      padding: 30px;
    }
  `,
  body: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 20px;
    max-width: 500px;
  `,
  header: css`
    margin-bottom: 20px;
    font-size: 15px;
    letter-spacing: 0;

    & > h2 {
      font-weight: 600;
    }
  `,
  variantButton: css`
    max-width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid black;
    text-transform: uppercase;

    &:hover {
      border: 2px solid black;
    }
  `,
  variantFields: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  sideMessageContainer: css`
    width: 150px;
    min-width: 150px;
    padding-right: 15px;

    & > p {
      margin-top: 5px;
    }

    & > h3 {
      font-weight: 600;
      margin: 0;
    }

    & > ul {
      padding-left: 15px;
      margin-top: 5px;
    }

    @media (max-width: 600px) {
      display: flex;
      justify-content: space-between; 
      align-items: center;
      margin-bottom: 15px;
      width: 100%;

      p {
        margin-top: 0;
      }
    }
  `,
  field: css`
    flex-grow: 1;
  `,
  buttonContainer: css`
    display: flex;
    gap: 10px;
    margin-top: 15px;
    justify-content: flex-end;
    flex-direction: row-reverse;
    max-width: 500px;

    @media (max-width: 550px) {
      justify-content: flex-end;
      flex-direction: row;
    }
  `,
  cancelButton: css`
    font-size: 15px;
    height: 45px;
    min-width: 95px;
    box-shadow: none;
    border: 2px solid #c4c4c4;
    color: #7b7b7b;
    text-transform: uppercase;
    font-size: 0.8rem;
  `,
  button: css`
    font-size: 15px;
    height: 45px;
    min-width: 95px;
    box-shadow: none;
    text-transform: uppercase;
    font-size: 0.8rem;

    &:hover {
      box-shadow: none;
    }
  `,
  variantPreviewSection: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  previewContainer: css`
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  `,
  deleteButton: css`
    color: red;
    cursor: pointer;
    margin-top: 5px;
    display: inline-block;
    text-transform: uppercase;
  `
}




const MerchantProductFormVariantsSection = props => {

  const variantRef = useRef()
  const optionsRef = useRef()

  const [showFields, setShowFields] = useState(false)

  const [variantError, setVariantError] = useState(false)
  const [optionsError, setOptionsError] = useState(false)

  const {variants, setFieldValue} = props


  const helperText = `
  Examples: S, M, L, XL or Cotton, Wool. Enter each option separated by a comma e.g. Red, Blue, Green, Orange.
  `



  function createVariant() {
    const variant = variantRef.current.value
    const options = optionsRef.current.value

    if (!variant || variant === '') {
      setVariantError('Field can\'t be blank')
      return
    }


    if (!options || options === '') {
      setOptionsError('Field can\'t be blank')
      return
    }


    const optionsArray = options.split(',').map(option => option.trim()) 

    setFieldValue('variants', { ...variants, [variant]: optionsArray })

    variantRef.current.value = ''
    optionsRef.current.value = ''

    setOptionsError(false)
    setVariantError(false)
  }



  function handleClick() {
    setShowFields(true)
  }



  function cancel() {
    setShowFields(false)
  } 



  function handleVariantChange(e) {
    if (!variantError) return

    const variant = e.target.value

    if (!variant || variant === '') {
      setVariantError('Field can\'t be blank')
    } else {
      setVariantError(false)
    }
  }



  function handleOptionsChange(e) {
    if (!optionsError) return

    const options = e.target.value

    if (!options || options === '') {
      setOptionsError('Field can\'t be blank')
    } else {
      setOptionsError(false)
    }
  }



  const deleteVariant = variant => () => {
    setFieldValue('variants', omitBy(variants, (value, key) => key === variant))
  }



  return (
    <div css={styles.root}>
     <div css={styles.header}>
        <h2>Product Variants / Options</h2>
        <p>Add product variants (options) for users to select from such as colors and sizes.</p>
      </div>

      <div css={styles.body}>
        {!showFields &&
          <Button variant='outlined' css={styles.variantButton} onClick={handleClick}>
            Add Variant
          </Button>
        }

        {showFields && 
          <div css={styles.variantFields}>

            <TextField
              css={styles.field}
              inputRef={variantRef}
              name='variant'
              label='Variant'
              variant='filled'
              placeholder='Size, Color, Material...'
              error={Boolean(variantError)}
              helperText={variantError || 'Examples: Size, Color, Material...'}
              onChange={handleVariantChange}
            />

            <TextField
              css={styles.field}
              inputRef={optionsRef}
              name='options'
              label='Variant Options'
              placeholder='S, M, L, XL'
              variant='filled'
              helperText={optionsError || helperText}
              error={Boolean(optionsError)}
              onChange={handleOptionsChange}
            />

            <div css={styles.buttonContainer}>
              <Button css={styles.cancelButton}variant='outlined' onClick={cancel}>
                Cancel
              </Button>

              <Button css={styles.button} onClick={createVariant}>
                Add Variant
              </Button>
            </div>
          </div>
        }
      </div>


      {!isEmpty(variants) && <Divider style={{marginTop: 35, marginBottom: 35}} />}


      <div css={styles.variantPreviewSection}>
        {Object.keys(variants).map((variant, index) => (
          <div css={styles.previewContainer} key={variant}>
            <div css={styles.sideMessageContainer}>
              <h3>{`Variant ${index + 1}`}</h3>

              <p aria-label='delete' css={styles.deleteButton} onClick={deleteVariant(variant)}>
               Delete
              </p>
            </div>

            <MerchantProductFormVariant variant={variant} options={variants[variant]} />
          </div>
        ))}
      </div>
    </div>
  )
}


export default MerchantProductFormVariantsSection


