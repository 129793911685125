import {useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import cable from '../cable'



export function useCableNotifications() {
  const dispatch = useDispatch()

  const userID = useSelector(state => state.user.id)



  useEffect(() => {
    if (!userID) {
      return
    }

    const channel = cable.subscriptions.create(
      {channel: 'NotificationsChannel', user_id: userID},
      {
        connected() {
          console.log('Connected to NotificationsChannel')
        },
        disconnected() {
          console.log('Disconnected from NotificationsChannel')
        },
        received(data) {
          const {
            type,
            status,
            content,
            message,
            record_id,
            record_type
          } = data || {}


          // If this is a video upload notification, then update the account video and
          // remove the loader element if its present
          if (type === 'video-upload') {
            dispatch({
              type: record_type.toUpperCase() + '_UPDATE_VIDEO',
              payload: {
                id: record_id,
                video: content.video,
                videoDerivatives: content.video_derivatives
              }
            })

            // Scale and remove video loader if its on the page
            const loader = document.getElementById('video-processing-container')

            if (loader) {
              const loaderWidth = loader.offsetWidth
              const loaderHeight = loader.offsetHeight

              // Copied from VideoForm.js element
              const newLeft = window.innerWidth - loaderWidth - 10 // 10px from the right, considering new width
              const newTop = window.innerHeight - loaderHeight - 10 // 10px from the bottom, considering new height
              const transformX = newLeft - (window.innerWidth / 2)
              const transformY = newTop - (window.innerHeight / 2)

              loader.style.transform = `translate(${transformX}px, ${transformY}px) scale(0)`

              loader.addEventListener('transitionend', () => {
                loader.remove()
              }, {once: true})
            }
          }

          
          // Show any alerts passed from the backend
          dispatch({
            type: 'SHOW_ALERT', 
            payload: {message, variant: status || 'standard'}
          })
        }
      }
    )


    return () => {
      channel.unsubscribe()
    }
  }, [userID, dispatch])
}



export default useCableNotifications