import { createTheme } from '@mui/material/styles'




const theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    },


    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(33, 33, 33)',
        }
      }
    },


    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#818181',
          fontSize: '0.9rem',
          letterSpacing: '0.01rem'
        }
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500,

          '&.Mui-focused.MuiInputLabel-filled': {}
        }, 

        shrink: {
          fontWeight: 500
        },

        filled: {
          position: 'static',
          marginBottom: '5px',
          transform: 'none',
          'WebkitTransform': 'none',
        }
      }
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiFilledInput-root': {
            border: '2px solid #E0E3E7',
            borderRadius: '4px',
            backgroundColor: 'rgb(244, 245, 251)',

            '&.Mui-focused:not(.Mui-error)': {
              border: '2px solid rgb(62, 166, 255)',
              backgroundColor: 'rgb(244, 245, 251)'
            },

            '&.Mui-error': {
              border: '2px solid red'
            },


            '&:hover:not(.Mui-disabled)': {
              backgroundColor: 'rgb(244, 245, 251)'
            },

            '&.MuiInputBase-multiline': {
              padding: '4px'
            }
          },


          '&.MuiFilledInput-underline': {
            '&&&:before': {
              borderBottom: 'none'
            },
            '&&:after': {
              borderBottom: 'none'
            }
          }
        },

        input: {
          fontSize: '16px',

          '&.MuiFilledInput-input': {
            padding: '12px 12px',
          }
        }
      }
    },

    MuiInputAdornment: {
      styleOverrides: {
        filled: {
          margin: '0 !important',
        }
      }
    },
    
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          fontSize: '15px',
          fontWeight: 400,
          '&.MuiButton-contained': {
            color: '#fff',
            backgroundColor: '#000',
            '&:hover': {
              color: '#fff',
              backgroundColor: '#000'
            },
          },
          '&.Mui-disabled': {
            backgroundColor: '#9d9d9d',
            color: '#fff'
          }
        },
      }
    }
  },

  typography: {
    button: {
      textTransform: 'capitalize'
    },
    fontFamily: [
      'neue-haas-unica, sans-serif',
      '"Helvetica Neue"',
      'Roboto, sans-serif', 
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial'
    ].join(','),
    h1: {
      fontWeight: 900,
      fontSize: '2.8rem',
      letterSpacing: '-0.03rem'
    },
    h2: {
      fontWeight: 900,
      fontSize: '2.4rem',
      letterSpacing: '-0.03rem'
    },
    h3: {
      fontWeight: 900,
      fontSize: '2.0rem',
      letterSpacing: '-0.03rem'
    },
    h4: {
      fontWeight: 900,
      fontSize: '1.6rem',
      letterSpacing: '-0.03rem'
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.4rem',
      letterSpacing: '-0.03rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1rem',
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '0.92rem',
      letterSpacing: '-0.03rem',
      lineHeight: 1,
    },
    subtitle2: {
      fontSize: '0.8rem',
      color: '#474A51'
    },
    body1: {
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.84rem',
      letterSpacing: '-0.03rem',
      lineHeight: 1.2
    },
    caption: {
      fontSize: '0.8rem',
      letterSpacing: '-0.03rem',
      lineHeight: 1.2
    }
  },
  palette: {
    primary: {
      main: '#000000',
      grey: 'rgba(0, 0, 0, 0.54)',
      _900: '#000000',
      _800: '#262626',
      _700: '#434343',
      _600: '#555555',
      _500: '#7b7b7b',
      _400: '#9d9d9d',
      _300: '#c4c4c4',
      _200: '#d9d9d9',
      _100: '#e9e9e9',
       _50: '#f5f5f5'
    },
    secondary: {
      main: '#fff',
      _900: '#3c3c3c',
      _800: '#606060',
      _700: '#818181',
      _600: '#979797',
      _500: '#c2c2c2',
      _400: '#dedede',
      _300: '#f0f0f0',
      _200: '#f5f5f5',
      _100: '#fafafa',
       _50: '#ffffff'
    },
    pink: {
      main: 'rgb(255, 0, 149)', // hex - #ff0095
      secondary: '#ff0055',
      tertiary: '#f24385',
      _900: '#a2004b',
      _800: '#c5004f',
      _700: '#d90051',
      _600: '#ee0054',
      _500: '#ff0055',
      _400: '#ff386e',
      _300: '#ff5f89',
      _200: '#ff8fab',
      _100: '#ffbbcc',
       _50: '#ffe4eb'
    },
    yellow: {
      main: '#fbde54',
      gold: '#fad25a',
      _900: '#ff6c00',
      _800: '#ff8d00',
      _700: '#ff9f00',
      _600: '#ffb200',
      _500: '#ffc000',
      _400: '#ffc919',
      _300: '#ffd448',
      _200: '#ffe07e',
      _100: '#ffecb1',
       _50: '#fff8e0'
    },
    purple: {
      main: '#4834AF',
      _900: '#0000d8',
      _800: '#0e00e6',
      _700: '#3a00eb',
      _600: '#5300f2',
      _500: '#6100f8',
      _400: '#8039fb',
      _300: '#9b62fc',
      _200: '#b992fb',
      _100: '#d6befc',
       _50: '#f0e5fe'
    },
    blue: {
      primary: '#0080ff',
      secondary: 'rgb(62, 166, 255)'
    }
  }
})

export default theme


