

export function updateLocationFilter(coordinates = {lat: 40.730610, lng: -73.935242}, name, id = '') {
  const {lat, lng} = coordinates

  return dispatch => {
    dispatch({type: 'UPDATE_LOCATION_FILTER', payload: {lat, lng, name, id}})
  }
}


export function updateCenterFilter(coordinates = {lat: 40.730610, lng: -73.935242}) {
  const {lat, lng} = coordinates

  return dispatch => {
    dispatch({type: 'UPDATE_CENTER_FILTER', payload: {lat, lng}})
  }
}



export function updateDateFilter(dateRange) {
  return dispatch => {
    dispatch({type: 'UPDATE_DATE_FILTER', payload: dateRange})
  }
}



export function updateTimeFilter(time) {
  return dispatch => {
    dispatch({type: 'UPDATE_TIME_FILTER', payload: time})
  }
}



export function updatePriceFilter(priceRange) {
  return dispatch => {
    dispatch({type: 'UPDATE_PRICE_FILTER', payload: priceRange})
  }
}



export function updateRadiusFilter(radius) {
  return dispatch => {
    dispatch({type: 'UPDATE_RADIUS_FILTER', payload: radius})
  }
}



export function updateTagFilter(tags) {
  return dispatch => {
    dispatch({type: 'UPDATE_TAGS_FILTER', payload: tags})
  }
}



export function updateTypesFilter(types) {
  return dispatch => {
    dispatch({type: 'UPDATE_TYPES_FILTER', payload: types})
  }
}



export function clearFilter(filter) {
  return dispatch => {
    dispatch({type: 'CLEAR_' + filter + '_FILTER'})
  }
}



export function resetFilters() {
  return dispatch => {
    dispatch({type: 'RESET_FILTERS'})
  }
}