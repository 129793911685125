import {useState, useRef, useEffect} from 'react'



const VideoTimer = ({isRecording}) => {


  const intervalRef = useRef(null)

  const [seconds, setSeconds] = useState(0)



  useEffect(() => {
    if (isRecording) {
      intervalRef.current = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds + 1)
      }, 1000)
    } else {
      clearInterval(intervalRef.current)
    }


    return () => clearInterval(intervalRef.current)
  }, [isRecording])




  const formatTime = seconds => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60

    const hoursString = hours > 0 ? `${String(hours).padStart(2, '0')}:` : ''
    
    return `${hoursString}${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`
  }



  return (
    <div style={{color: 'white', fontSize: '19px'}}>
      {formatTime(seconds)}
    </div>
  )
}



export default VideoTimer