import {useState, useRef} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'


import {artistRegEx as spotifyArtistRegex} from '../../../../helpers/spotify.js'

import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'

import {PiSpotifyLogoFill} from 'react-icons/pi'




const styles = {
  linksContainer: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  `,
  linkItem: css`
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
  button: css`
    font-size: 0.9rem;
    color: rgb(62, 166, 255);
    cursor: pointer;
  `,
  spotifyIcon: css`
    width: 32px;
    height: 32px;
    color: #1DB954;
  `,
  closeIcon: css`
    cursor: pointer;
    margin-left: 10px;
  `
}







const SpotifyLinksField = ({field, form, disabled, ...props}) => {

  const inputRef = useRef()


  const {name, value} = field

  const {
    touched, 
    errors, 
    isSubmitting, 
    setFieldValue, 
    setFieldError,
    setFieldTouched
  } = form


  const error = errors[name]
  const isError = touched[name] && Boolean(error)


  const [isShowing, setIsShowing] = useState(false)



  function validURL(url) {
    return spotifyArtistRegex.test(url)
  }




  function handleKeyPress(e) {
    if (inputRef.current === document.activeElement && e.key === 'Enter') {
      e.preventDefault()

      addLink()
    }
  }




  function addLink() {
    const newValue = inputRef.current.value

    const isAlreadyIncluded = value.includes(newValue)

    if (validURL(newValue) && !isAlreadyIncluded) {
      setFieldValue(name, [...value, newValue])
      inputRef.current.value = ''

      if (error) {
        setFieldError(name, false)
      }
    } else {
      if (isAlreadyIncluded) return
      setFieldError(name, 'Invalid URL')
    }
  }




  function handleFocus() {
    setFieldTouched(name)
  }



  function handleChange(e) {
    if (e.target.value) {
      setIsShowing(true)
    } else {
      setIsShowing(false)
    }


    if (isError && validURL(e.target.value)) {
      setFieldError(name, false)
    }
  }




  const removeLink = link => () => {
    const newvalues = value.filter(l => l !== link)
    setFieldValue(name, newvalues)
  }



  return (
    <div>
      <TextField
        {...props}
        name={name}
        inputRef={inputRef}
        onFocus={handleFocus}
        error={isError}
        helperText={isError ? error : props.helperText}
        disabled={disabled || isSubmitting}
        onKeyDown={handleKeyPress}
        onChange={handleChange}
        InputProps={{
          endAdornment: 
            <div css={styles.button} onClick={addLink}>
              {isShowing && 'ENTER'}
            </div>
        }}
      />


      <div css={styles.linksContainer}>
        {value.map(link => (
          <div key={link} css={styles.linkItem}>
            <PiSpotifyLogoFill css={styles.spotifyIcon} />
            <p>{link}</p>
            <CloseIcon css={styles.closeIcon} onClick={removeLink(link)} />
          </div>
        ))}
      </div>
    </div>
  )
}



export default SpotifyLinksField




