import { index } from '../apis'



export function fetchFollowsAndAssociationsOfType(type) {
  return dispatch => {
    dispatch({ type: 'FOLLOWS_REQUESTED' })

    return index('/followed-' + type).then(response => {
      const objects = response.data.included
      const links = response.data.links

      const orderedFollows = response.data.data.map(e => e.attributes.followableId)

      const follows = response.data.data.reduce((acc, follow) => {
        const objectId = follow.attributes.followableId

        acc[objectId] = follow
        return acc
      }, {})


      dispatch({ type: type.toUpperCase() + '_INDEX_SUCCESS', payload: { data: objects }})
      dispatch({ type: 'FOLLOWS_WITH_ASSOCIATIONS_INDEX_SUCCESS', payload: { 
        type, 
        links,
        follows,
        orderedFollows
      }})

      return response.data
    })
    .catch(error => {
      dispatch({ type: 'FOLLOWS_ERROR', payload: { type }})
      throw(error)
    })
  }
}



export function fetchFollowsOfType(type) {
  return dispatch => {
    dispatch({ type: 'FOLLOWS_REQUESTED' })

    return index('/user/following-' + type).then(response => {
      const following = response.data.data.reduce((acc, follow) => {
        const objectId = follow.attributes.followableId

        acc[objectId] = follow
        return acc
      }, {})

      dispatch({ type: 'FOLLOWS_INDEX_SUCCESS', payload: { type, following }})
    })
    .catch(error => {
      dispatch({ type: 'FOLLOWS_ERROR' })
      throw(error)
    })
  }
}


export function orderFollowsOfType(type, objects) {
  return dispatch => {  
    const objectIds = objects.map(e => e.id)

    dispatch({ type: 'ORDER_FOLLOWS_OF_TYPE', payload: { type, objectIds }})
  }
}


