import instance from './instance'

export const crudAPI = {
  index,
  show,
  create,
  destroy,
  update,
  patch
}

export function index(url, data = {}, options = {}) {
  return instance.get(url, {params: data}, options)
}

export function show(url, data = {}, options = {}) {
  return instance.get(url, {params: data}, options)
}

export function destroy(url, data = {}, options = {}) {
  return instance.delete(url, {params: data}, options)
}

export function create(url, data = {}, options = {}) {
  return instance.post(url, data, options)
}

export function update(url, data, options) {
  return instance.put(url, data, options)
}

export function patch(url, data, options) {
  return instance.patch(url, data, options)
}

