import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {NavLink} from 'react-router-dom'

import {cartTotalSelector} from '../../../selectors'

import {objectToString, formatMoney} from '../../../utils'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import CartItem from './CartItem'

import {ThreeDotProgress} from '../../generics'



const cssStyles = props => ({
  contentContainer: css`
    display: flex;
    flex-direction: column;
  `,
  itemContainer: css`
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 30px;
  `,
  menuItem: css`
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  `,
  progressContainer: css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  more: css`
    color: #7b7b7b;
    margin-bottom: 10px;
    text-decoration: none;
  `,
  bottom: css`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  `,
  totalContainer: css`
    display: flex;
    justify-content: flex-end;
    padding: 10px 0px;
    border-top: 1px solid #e5e5e5;
    margin-bottom: 15px;
  `,
  total: css`
    font-size: 19px;
    font-weight: 500;
    margin-right: 20px;
  `,
  checkout: css`
    width: 100%;
    background-color: #000;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
    padding: 10px;
  `
})




const CartMenu = props => {

  const styles = cssStyles()

  const { 
    isLoaded, 
    isFetching,
    items,
    total,
    anchorEl, 
    setAnchorEl
  } = props

  const firstNItems = items.slice(0, 4)


  function handleClose() {
    setAnchorEl(null)
  }


  return (
    <Menu
      elevation={ 5 }
      component='div'
      anchorEl={anchorEl}
      keepMounted={true}
      disablePortal={true}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      disableScrollLock={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          width: 350,
          zIndex: 100,
          position: 'relative',
          padding: '30px 20px'
        }
      }}
      MenuListProps={{
        disablePadding: true,

        style: {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <div>
        {items.length > 0 &&
          <div css={styles.contentContainer}>
            <div css={styles.itemContainer}>

              {firstNItems.map(item => (
                  <MenuItem key={item.id} css={styles.menuItem}>
                    <CartItem
                      id={item.id}
                      name={item.name}
                      caption={objectToString(item.variant) || item.caption}
                      price={item.price}
                      image={item.image}
                      quantity={item.quantity}
                      selectedVariants={item.selectedVariants}
                    />
                  </MenuItem>
              ))}         
            </div>


            <div css={styles.totalContainer}>
              <h2 css={styles.total}>
                Total &nbsp;&nbsp;{formatMoney(total)}
              </h2>
            </div>


            <NavLink css={styles.checkout} onClick={handleClose} to='/checkout'>
              <Typography align='center'>
                {items.length > 4 ? 'Check Out / View All' : 'Check Out'}
              </Typography>
            </NavLink>


            {(!isLoaded || isFetching) &&
              <div css={styles.progressContainer}>
                <ThreeDotProgress position='absolute' radius={5} />
              </div>
            }
          </div>
        }
      </div>
    </Menu>
  )
}


const mapStateToProps = state => {
  return {
    items: state.user.cart.items,
    total: cartTotalSelector(state),
    isLoaded: state.user.cart.isLoaded,
    isFetching: state.user.cart.isFetching
  }
}




export default connect(mapStateToProps)(CartMenu)


