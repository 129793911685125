import {isEmpty} from 'lodash'

import {store} from '../helpers'

import {crudAPI} from '../apis'

import {objectToString, createOrderItem, camelCaseObject} from '../utils'



export function addToCart(product, quantity = 1) {
  
  const cart = store.getState().user.cart.items

  let cartProductId = ''

  let selectedVariants = {}

  // If the product has no variants, then selected variants will be empty as well,
  // therefore the cart item id is the same as the product id...
  if (isEmpty(product.attributes.variants)) {
    cartProductId = product.id
  
  // ...but if the product has variants, then there needs to be selected variants
  // (choices the user made) and therefore the cart item id is the product id followed
  // by the selected variants converted to a string format 
  } else {
    selectedVariants = product.attributes.selectedVariants
    cartProductId = product.id + '_' + objectToString(selectedVariants)
  }

  // This check to see if there is already the same item in the cart, and if 
  // there is then only increment the quantity of the item in the cart, otherwise
  // add the item to the cart.
  for (const item of cart) {
    if (item.id === cartProductId) {
      return incrementQuantity(item.id)
    }
  }



  return dispatch => {
    dispatch({
      type: 'ADD_TO_CART', 
      payload: {
        id: cartProductId, 
        item: createOrderItem(product, quantity, selectedVariants)
      }})
  }
}



export function removeFromCart(id) {
  return dispatch => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { id }})
  }
}



export function incrementQuantity(id) {
  return dispatch => {
    dispatch({ type: 'INCREMENT_QUANTITY', payload: { id }})
  }
}



export function decrementQuantity(id) {
  return dispatch => {
    dispatch({ type: 'DECREMENT_QUANTITY', payload: { id }})
  }
}



export function setCart(cart) {
  return dispatch => {
    dispatch({ type: 'SET_CART', payload: cart })
  }
}



export function fetchCart() {
  return dispatch => {
    dispatch({type: 'CART_REQUESTED'})

    return crudAPI.show('user/cart').then(response => {
      if (Array.isArray(response.data)) {
        let camelCased = response.data.map(item => camelCaseObject(item))

        dispatch({type: 'CART_SHOW_SUCCESS', payload: camelCased})
        
        return camelCased
      } 
    })
    .catch(error => {
      dispatch({type: 'CART_ERROR'})
      
      throw(error)
    })
  }
}



export function saveCart(data) {
  return dispatch => {
    dispatch({ type: 'CART_REQUESTED' })

    return crudAPI.update('user/cart', { cart: data }).then(response => {
      dispatch({ type: 'CART_SAVE_SUCCESS' })
      return response.data
    })
    .catch(error => {
      dispatch({ type: 'CART_ERROR' })
      throw(error)
    })
  }
}



export function emptyCart() {
  window.localStorage.setItem('cart', '')

  return dispatch => {
    dispatch({ type: 'EMPTY_CART' })
  }
}




