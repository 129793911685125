import React from 'react'
import { connect } from 'react-redux'
import { searchUsers, patch } from '../../../apis'
import { showAlert } from '../../../actions'
import makeStyles from '@mui/styles/makeStyles'
import { useIsMounted } from '../../../hooks'
import withSearchAutocomplete from '../../hocs/withSearchAutocomplete'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    zIndex: 10,
    width: '100%'
  },
  action: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  select: {
    position: 'relative'
  },
  selectMenu: {
    position: 'relative'
  },
  button: {
    fontSize: 11,
    padding: '10px 6px 10px 6px',
    backgroundColor: 'black',
    color: 'white',
    marginLeft: 24
  }
}))


const AddNewPerformancePermissions = props => {
  const classes = useStyles()
  const isMounted = useIsMounted()
  const { suggestions, showAlert } = props
  const { performanceId, setUsers, users } = props.passedProps


  function grantPermission(userId, username) {
    patch('/performances/' + performanceId + '/permissions', { 
      permission: 'member',
      user_id: userId
    })
    .then(response => {
      if (isMounted.current) {
        setUsers({ ...users, [userId]: response.data })
        showAlert(username + ' has been granted member access', 'success')
      }
    })
    .catch(error => {
      showAlert('Something went wrong', 'error')
    })
  }



  return (
    <List className={ classes.root }>
      { suggestions.map(user => {
          const { username, fullName } = user.attributes 
          const imageURL = user.attributes.imageDerivatives.small || user.attributes.image

          return (
            <ListItem key={ user.id }>
              <ListItemAvatar>
                <Avatar alt='user' src={ imageURL } />
              </ListItemAvatar>
              <ListItemText primary={ username } secondary={ fullName } />
              <ListItemSecondaryAction className={ classes.action }>
                { !Object.values(users).some(e => e.id === user.id)
                  ? <Button
                      className={ classes.button }
                      variant='contained'
                      disableElevation
                      onClick={ () => grantPermission(user.id, username) }>
                      GRANT
                    </Button>
                  : <Typography variant='body2'>
                      <em>permission granted</em>
                    </Typography>
                }
              </ListItemSecondaryAction>
            </ListItem>
          )
        })
      }
    </List>
  )
}

const componentWithRedux = connect(null, { showAlert })(AddNewPerformancePermissions)

export default withSearchAutocomplete(componentWithRedux, searchUsers)

