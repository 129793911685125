import {createSelector} from 'reselect'

import {get, pick, map} from 'lodash'


import bandSelectors from './bandSelectors'
import artistSelectors from './artistSelectors'
import trackSelectors from './trackSelectors'



const emptyArray = []
const emptyObject = {}


const selectPerformanceId = (state, id) => {
  return get(state.performances.byId[id], 'id', false)
}

const selectPerformanceAttributesById = (state, id) => {
  return get(state.performances.byId[id], 'attributes')
}

const selectPerformanceTracks = (state, id) => {
  return get(state.performances.byId[id], ['relationships', 'tracks'], emptyArray)
}

const selectPerformancePerformers = (state, id) => {
  return get(state.performances.byId[id], ['relationships', 'performers'], emptyArray)
}

const selectPerformanceFetchedAssociations = (state, id) => {
  return get(state.performances.byId[id], 'fetched', emptyArray)
}

const selectPerformanceLinks = (state, id, association) => {
  return get(state.performances.byId[id], ['links', association], emptyObject)
}

export const selectPerformances = state => state.performances.byId

export const makeGetPerformanceById = () => {
  return createSelector(
    [selectPerformanceId, selectPerformanceAttributesById], 
    (id, attributes) => ({ id, attributes, type: 'performance' })
  )
}

export const makeGetPerformanceFetchedAssociations = () => {
  return createSelector(
    selectPerformanceFetchedAssociations, 
    fetchedAssociations => fetchedAssociations
  )
}


export const makeGetPerformanceTracks = () => {
  return createSelector([selectPerformanceTracks, trackSelectors.selectTrackIds],
    (performanceTracks, tracks) => {
      const trackIds = map(performanceTracks, 'id')

      return Object.values(pick(tracks, trackIds))
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetPerformancePerformers = () => {
  return createSelector(
    [selectPerformancePerformers, bandSelectors.selectBandIds, artistSelectors.selectArtistIds],
    (performancePerformers, bands, artists) => { 
      const performersArray = performancePerformers ? performancePerformers : emptyArray
      
      const performers = performersArray.reduce((acc, performer) => {
        if (performer.type === 'artist') {
          acc.push(artists[performer.id])
        }
        if (performer.type === 'band') {
          acc.push(bands[performer.id])
        }
        return acc
      }, [])

      return performers
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetPerformanceLinks = () => {
  return createSelector([selectPerformanceLinks], (performanceLinks) => performanceLinks)
}



const performanceSelectors = {
  selectPerformances,
  makeGetPerformanceById,
  makeGetPerformanceLinks,
  makeGetPerformanceTracks,
  makeGetPerformancePerformers,
  makeGetPerformanceFetchedAssociations,
}


export default performanceSelectors

