export function relativeComplement(a, b) {
  const complement = {}

  for (const key in a) {
    if (b.hasOwnProperty(key)) {
      continue
    } else {
      complement[key] = a[key]
    }
  }

  return complement
}



export function objectToString(object) {
  let string = ''

  for (const key in object) {
    const value = object[key]

    if (typeof key === 'string' && typeof value === 'string') {
      string += `${key}-${value}:`
    }
  }

  string = (string === '') ? string : string.substring(0, string.length - 1)

  return string
}



export function shallowCompare(a, b) {
  return (
    Object.keys(a).length === Object.keys(b).length &&
    Object.keys(a).every(key => b.hasOwnProperty(key) && a[key] === b[key])
  )
}


export function hasSameKeys(a, b) {
  return (
    Object.keys(a).length === Object.keys(b).length &&
    Object.keys(a).every(key => b.hasOwnProperty(key))
  )
}