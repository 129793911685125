import React, { useState, useRef, useEffect, useMemo } from 'react'
import { debounce, without } from 'lodash'
import { 
  useIsMounted,
  useClickedOutsideComponent
} from '../../../../hooks'
import { index } from '../../../../apis'
import makeStyles from '@mui/styles/makeStyles'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import CancelIcon from '@mui/icons-material/Cancel'



const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  suggestions: {
    zIndex: 100,
    padding: 10,
    position: 'absolute',
    width: '100%',
    margin: 0,
  },
  chipsContainer: {
    minHeight: 20,
    marginTop: 10,
    marginBottom: 10
  },
  chip: {
    marginRight: 2,
    marginBottom: 2,
    borderRadius: 4,
    color: '#fff',
    backgroundColor: '#262626',
    textTransform: 'uppercase'
  }
})


const TagField = ({ field, form, disabled, ...props }) => {
  const isMounted = useIsMounted()
  const inputRef = useRef()
  const containerRef = useRef()
  const clickedOutsideCounter = useClickedOutsideComponent(containerRef)
  const classes = useStyles()
  const [tagSuggestions, setTagSuggestions] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const { name, value } = field
  const [selectedTags, setSelectedTags] = useState(value)
  const { touched, errors, setFieldValue, isSubmitting } = form 
  const error = errors[name]
  const showError = touched[name] && !!error


  const getTags = useMemo(() => {
    return e => index('/tags/search', { query: e.target.value })
      .then(response => {
        if (isMounted.current) {
          const tags = response.data.data.map(tag => tag.attributes.tag)
          setTagSuggestions(tags)
        }
      })
  }, [isMounted])


  const debouncedGetTags = useMemo(
    () => debounce(getTags, 300), [getTags]
  )


  useEffect(() => {
    setIsOpen(false)
  }, [clickedOutsideCounter])


  useEffect(() => {
    setSelectedTags(value)
  }, [value])


  function clearSuggestions() {
    setTagSuggestions([])
    inputRef.current.focus()
  }

  function removeFromSelection(tag) {
    setSelectedTags(without(selectedTags, tag))
    setFieldValue(name, without(selectedTags, tag))
  }

  function addToSelection(tag) {
    if (selectedTags.length === 5 || selectedTags.includes(tag)) {
      inputRef.current.value = ''
      return
    }

    setSelectedTags([...selectedTags, tag])
    setFieldValue(name, [...selectedTags, tag])
    inputRef.current.value = ''
  }


  function handleClick(tag) {
    addToSelection(tag)
    clearSuggestions()
  }


  function openDropDown() {
    setIsOpen(true)
  }


  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      const value = e.target.value

      const found = tagSuggestions.find(tag => tag === value)

      if (found) {
        handleClick(value)
      }
    }
  }
  
  
  return (
    <div className={ classes.root } ref={ containerRef } >
      <TextField
        name={ name }
        onFocus={ openDropDown }
        error={ showError }
        helperText={ showError ? error : props.helperText }
        disabled={ disabled !== undefined ? disabled : isSubmitting }
        onChange={ debouncedGetTags }
        onKeyDown={handleKeyPress}
        inputRef={ inputRef }
        { ...props } 
      />
        { isOpen &&
          <Paper
            className={ classes.suggestions }
            elevation={ 2 }>
            { tagSuggestions.map(tag => (
                <Chip
                  classes={{ root: classes.chip }}
                  key={ tag }
                  label={ tag }
                  onClick={ () => handleClick(tag) } 
                />
              ))
            }
          </Paper>
        }
      <div className={ classes.chipsContainer }>
        { selectedTags.map(tag => (
            <Chip 
              classes={{ root: classes.chip }}
              deleteIcon={ 
                <CancelIcon style={{ color: 'grey' }} />
              }
              key={ tag }
              size='small'
              label={ tag }
              onDelete={ () => removeFromSelection(tag) } 
            />
          ))
        }
      </div>
    </div>
  )
}

export default TagField

