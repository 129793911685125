
const INITIAL_STATE = null


 const idReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_USER':
    case 'USER_UPDATE_SUCCESS':
      return action.payload.id
    case 'DELETE_USER':
      return INITIAL_STATE
    default:
      return state
  }
}



export default idReducer