import React from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {useTheme} from '@mui/styles'

import {useNavigate} from 'react-router-dom'

import {get} from 'lodash'

import {create, destroy, showAlert} from '../../../actions'

import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import {RiUserUnfollowLine} from 'react-icons/ri'


import {closeSnackbar} from 'notistack'




const FollowButton = props => {
  
  const theme = useTheme()

  const navigate = useNavigate()

  const isLargeScreen = useMediaQuery('(min-width:1120px)')


  const {
    id, 
    type, 
    size, 
    create, 
    destroy,
    followId, 
    showAlert,
    isFollowing, 
    isAuthenticated, 
    ...rest
  } = props




  function navigateToSignupPage() {
    navigate('/sign-up', {
      state: {
        redirect: `/${type}s/${id}`
      }
    })
  }




  function handleClick() {
    if (!isAuthenticated) {
      const action = snackbarID => (
        <>
          <Button 
            variant='text' 
            sx={{
              fontWeight: 600,
              color: 'rgb(62, 166, 255)'
            }}
            onClick={navigateToSignupPage}
          >
            Sign Up
          </Button>

          <IconButton 
            aria-label='close'
            sx={{
              fontWeight: 600,
              color: '#fff'
            }}
            onClick={() => closeSnackbar(snackbarID)}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </>
      )

      showAlert(`Sign up to follow this ${type}`, 'standard', action)
      return
    }


    if (isFollowing) {
      destroy('/following/' + followId, 'FOLLOW')
    } else {
      create(`/follows/${type}/${id}`, 'FOLLOW')
    }
  }




  return (
    <Button
      id='follow-button'
      variant='filled'
      onClick={handleClick}
      sx={{
        height: isLargeScreen ? '44px' : '36px',
        background: isFollowing ? '#262626' : 'linear-gradient(to right, #ff0095, #FFBF00)', 
        color: isFollowing ? '#d9d9d9' : '#fff',
        fontWeight: 500,
        fontSize: '13px',
        minWidth: isLargeScreen ? '44px' : '36px',
        borderRadius: isFollowing ? '50%' : isLargeScreen ? '22px' : '18px',
        padding: isFollowing ? '0px' : isLargeScreen ? '0 40px' : '0 25px',

        '&:hover': {
          backgroundColor: isFollowing ? '#262626' : ''
        }
      }} 
      {...rest}
    >
      {isFollowing 
        ? <RiUserUnfollowLine style={{width: '20px'}} /> 
        : 'FOLLOW'
      }
    </Button>
  )
}




const mapStateToProps = (state, props) => {
  
  const {id, type} = props

  const pluralType = type + 's'

  const followId = get(state, ['user', 'following', pluralType, id, 'id'])


  return {
    followId: followId,
    isFollowing: Boolean(followId),
    isAuthenticated: state.user.isAuthenticated
  }
}


const actions = {create, destroy, showAlert}


export default connect(mapStateToProps, actions)(FollowButton)




