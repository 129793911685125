import {useEffect} from 'react'



export function useDraggableNode(id) {


  useEffect(() => {
    const node = document.getElementById(id)


    node?.addEventListener('mousedown', handleMouseDown)


    function handleMouseDown(e) {
      let domLevel = 0
      let parentNode = e.target.parentNode


      while (domLevel <= 5) {
        if (parentNode?.dataset?.draggable === 'false') {
          return
        }

        domLevel += 1
        parentNode = parentNode?.parentNode
      }


      e.preventDefault()


      window.addEventListener('mousemove', move)
      window.addEventListener('mouseup', endMove, {once: true})
      window.addEventListener('contextmenu', endMove, {once: true})


      let left = parseInt(window.getComputedStyle(node).getPropertyValue('left'))
      let top  = parseInt(window.getComputedStyle(node).getPropertyValue('top'))

      let mouseX = e.clientX
      let mouseY = e.clientY


      function move(e) {
        const dx = mouseX - e.clientX
        const dy = mouseY - e.clientY


        if (node) {
          node.style.top = top - dy + 'px'
          node.style.left = left - dx + 'px'
        }
      }


      function endMove() {
        window.removeEventListener('mousemove', move)
      }
    }


    return () => {
      node?.removeEventListener('mousedown', handleMouseDown)
    }
  }, [id])

}