import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {useNavigate} from 'react-router-dom'

import Button from '@mui/material/Button'


import {ReactComponent as StoreFront} from '../../../svgs/StoreFront.svg'



const styles = {
  root: css`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  `,
  contentContainer: css`
    width: 80%;
    max-width: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      letter-spacing: -0.1rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem;
      letter-spacing: 0;
      font-weight: 400;
      color: #606060;
    }

    span {
      font-weight: 500;
      color: #000;
    }
  `,
  imageContainer: css`
    width: 70%;
    margin-bottom: 50px;
    padding-top: 20px;
  `,
  buttonContainer: css`
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  button: css`
    margin-top: 20px;
    width: 250px;
    height: 60px;
    border-radius: 30px;
  `
}



const MerchantAccountTab = props => {
  
  const navigate = useNavigate()

  const {id, type, name, stripeId} = props


  function createMerchantAccount() {
    navigate('/merchants/' + type + '/' + id + '/registration', { 
      replace: false
    })
  }



  return (
    <div css={styles.root}>
      <div css={styles.contentContainer}>
        <div css={styles.imageContainer}>
          <StoreFront />
        </div>


        <h2>Sell Merchandise</h2>

        <p>
          If you want to sell <span>{name}</span> merchandise on Ckord or recieve 
          contributions from users, create a merchant account by clicking the button below. 
        </p>


        <div css={styles.buttonContainer}>
          <Button css={styles.button} variant='contained' onClick={createMerchantAccount}>
            {stripeId ? 'Complete Registration' : 'Find Out More'}
          </Button>
        </div>
      </div>
    </div>
  )
}


export default MerchantAccountTab



