import React, { useState, useMemo, useEffect, useCallback } from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useFonts } from '../../hooks'
import { range, isEmpty } from 'lodash'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Virtuoso } from 'react-virtuoso'
import styled from '@emotion/styled'
import { GOOGLE_FONTS_URL } from '../../helpers'

import ThreeDotProgress from '../generics/ThreeDotProgress'


export const ItemContainer = styled.div`
  height: 50px;
  width: 100%;
  box-sizing: border-box;
`


const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`


const cssStyles = ({ fontFamily }) => (
  {
    root: css`
      display: flex;
      gap: 10px;


      @media (max-width: 900px) {
        width: 100%;
      }
    `,
    dropdown: css`
      flex-grow: 1
    `,
    fetchingContainer: css`
      position: absolute;
      top: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: #fff;
      z-index: 50;
    `,
    button: css`
      background-color: #fff;
      width: 200px;
      color: #000;
      text-transform: none;
      font-size: 1rem;
      font-weight: 400;
      justify-content: space-between;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: ${fontFamily}, 'neue-haas-unica, sans-serif', "Helvetica Neue", 'Roboto, sans-serif';
      border: 1px solid black;
      &:hover {
        background-color: #fff;
        color: #000;
      }

      @media (max-width: 900px) {
        min-width: 0px;
        max-width: 100%;
        width: 100%;
        height: 55px;
      }
    `,
    label: css`
      font-weight: 600;
      padding-bottom: 15px;
    `,
    noScrollbars: css`
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
      &::-webkit-scrollbar {
        width: 1px; 
        display: none;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    `
  }
)



const FontPicker = props => {
  const [fonts, defaultFont, isFetching] = useFonts(500)
  const [selectedFont, setSelectedFont] = useState({})
  const [fontWeights, setFontWeights] = useState([])
  const [anchorEl1, setAnchorEl1] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const [anchorEl3, setAnchorEl3] = useState(null)
  const { 
    fontFamily,
    setFontFamily,
    fontWeight,
    setFontWeight,
    fontSize,
    setFontSize,
    linkId 
  } = props


  const styles = cssStyles({ fontFamily })

  const itemCount = fonts.length


  useEffect(() => {
    if (isEmpty(defaultFont)) return

    if (isEmpty(selectedFont)) {
      setSelectedFont(defaultFont)
    }
  }, [defaultFont, selectedFont, setSelectedFont])



  useEffect(() => {
    let link = document.getElementById('ckord-font-samples')

    if (fonts.length > 0 && link === null) {
      const familyNames = fonts.map(font => {
        const name = font.family.split(' ').join('+')
        return `family=${name}` 
      }).join('&')

      const text = fonts.map(font => font.family)
        .join('')
        .split('')
        .reduce((acc, c) => {
          if (c === ' ') return acc
          return acc.includes(c) ? acc : acc + c
        })

      const link = document.createElement('link')
      link.id = 'ckord-font-samples'
      link.rel = 'stylesheet'
      link.href = `${GOOGLE_FONTS_URL}?${familyNames}&text=${text}1234567890`
      document.head.appendChild(link)
    }
  }, [fonts])



  useEffect(() => {
    if (isEmpty(selectedFont.variants)) return 

    const variants = selectedFont.variants
    let weights = new Set()
    let styles = new Set()


    variants.forEach(variant => {
      switch (variant) {
        case '100':
          weights.add('100')
          break
        case '100italic':
          weights.add('100')
          styles.add('italic')
          break
        case '200':
          weights.add('200')
          break
        case '200italic':
          weights.add('200')
          styles.add('italic')
          break
        case '300':
          weights.add('200')
          break
        case '300italic':
          weights.add('200')
          styles.add('italic')
          break
        case 'regular':
          weights.add('400')
          break
        case 'italic':
          weights.add('400')
          styles.add('italic')
          break
        case '500':
          weights.add('500')
          break
        case '500italic':
          weights.add('500')
          styles.add('italic')
          break
        case '600':
          weights.add('600')
          break
        case '600italic':
          weights.add('600')
          styles.add('italic')
          break
        case '700':
          weights.add('700')
          break
        case '700italic':
          weights.add('700')
          styles.add('italic')
          break
        case '800':
          weights.add('800')
          break
        case '800italic':
          weights.add('800')
          styles.add('italic')
          break
        case '900':
          weights.add('900')
          break
        case '900italic':
          weights.add('900')
          styles.add('italic')
          break
        default:
          break;
      }
    })


    if (!weights.has(fontWeight)) {
      if (weights.has('400')) setFontWeight('400')
      else setFontWeight(weights[0])
    }

    setFontWeights([...weights])
  }, [fontWeight, selectedFont, setFontWeight])



  function getFontId(fontFamily) {
    return fontFamily.replace(/\s+/g, '-').toLowerCase();
  }


  function openFontMenu(e) {
    setAnchorEl1(e.currentTarget)
  }


  function closeFontFamilies() {
    setAnchorEl1(null)
  }


  function openFontWeightMenu(e) {
    setAnchorEl2(e.currentTarget)
  }


  function closeFontWeights() {
    setAnchorEl2(null)
  }


  function openFontSizeMenu(e) {
    setAnchorEl3(e.currentTarget)
  }


  function closeFontSizes() {
    setAnchorEl3(null)
  }


  const selectFontSize = size => e => {
    setFontSize(size)
  }


  const selectFontWeight = useCallback(newWeight => e => {
    setFontWeight(newWeight)

    let link = document.getElementById(linkId || 'ckord-font-family')
    
    if (link) {
      link.href = `${GOOGLE_FONTS_URL}?family=${fontFamily}:wght@${newWeight}`
    } else {
      link = document.createElement('link')
      link.id = linkId || 'ckord-font-family'
      link.rel = 'stylesheet'
      link.href = `${GOOGLE_FONTS_URL}?family=${fontFamily}:wght@${newWeight}`
      document.head.appendChild(link)
    }
  }, [fontFamily, linkId, setFontWeight])



  const selectFont = useCallback(newFont => e => {
    setSelectedFont(newFont)
    setFontFamily(newFont.family)


    const font = newFont.family.split(' ').join('+')
    let link = document.getElementById(linkId || 'ckord-font-family')
    
    if (link) {
      link.href = `${GOOGLE_FONTS_URL}?family=${font}`
    } else {
      link = document.createElement('link')
      link.id = linkId || 'ckord-font-family'
      link.rel = 'stylesheet'
      link.href = `${GOOGLE_FONTS_URL}?family=${font}`
      document.head.appendChild(link)
    }
  }, [setFontFamily, linkId, setSelectedFont])



  const Items = useMemo(() => (
    fonts.map(font => {
      const fontId = getFontId(font.family)

      return (
        <MenuItem 
          key={ fontId } 
          value={ font.family }
          onClick={ selectFont(font) }
          selected={ fontFamily === font.family }
          style={{ 
            fontFamily: fontId,
            height: 50,
            width: '100%',
            fontSize: '1.2rem'
          }}
        >
          { font.family }
        </MenuItem>
      )
    })
  ), [fonts, selectFont, fontFamily])


  const Item = ({ index }) => Items[index]


  return (
    <div css={ styles.root }>
      <div css={ styles.dropdown }>
        <Typography css={ styles.label }>
          Font
        </Typography>

        <Button
          css={ styles.button }
          disableRipple
          aria-haspopup='true'
          aria-expanded={ Boolean(anchorEl1) ? 'true' : undefined }
          variant='contained'
          disableElevation
          onClick={ openFontMenu }
          endIcon={ <KeyboardArrowDownIcon /> }
        >
          { fontFamily ? fontFamily : 'Select Font' }
        </Button>

        <Menu
          css={ styles.menu }
          anchorEl={ anchorEl1 }
          open={ Boolean(anchorEl1) }
          onClose={ closeFontFamilies }
          keepMounted
          PaperProps={{
            style: {
              width: 250
            }
          }}
          MenuListProps={{
            'aria-labelledby': 'button'
          }}
        >
          <div>
            { isFetching && Boolean(anchorEl1) && 
                <div css={ styles.fetchingContainer }>
                  <ThreeDotProgress radius={ 7 } />
                </div>
            }
            <Virtuoso
              css={ styles.noScrollbars }
              style={{ height: 400, width: '100%' }}
              totalCount={ itemCount }
              fixedItemHeight={ 50 }
              increaseViewportBy={{
                top: 500,
                bottom: 500
              }}
              itemContent={ index => <Item index={ index } /> }
              computeItemKey={ index => fonts[index].family }
              components={{ 
                Item: ItemContainer,
                List: ListContainer
              }}
            />
          </div>
        </Menu>
      </div>

      <div css={ styles.dropdown }>
        <Typography css={ styles.label }>
          Font Weight
        </Typography>

        <Button
          css={ styles.button }
          disableRipple
          aria-haspopup='true'
          aria-expanded={ Boolean(anchorEl2) ? 'true' : undefined }
          variant='contained'
          disableElevation
          onClick={ openFontWeightMenu }
          endIcon={ <KeyboardArrowDownIcon /> }
        >
          { fontWeight ? fontWeight : 'Font Weight' }
        </Button>

        <Menu
          anchorEl={ anchorEl2 }
          open={ Boolean(anchorEl2) }
          onClose={ closeFontWeights }
          PaperProps={{
            style: {
              width: 250
            }
          }}
          MenuListProps={{
            'aria-labelledby': 'button'
          }}
        >
          <div>
            { isFetching && Boolean(anchorEl2) && 
                <div css={ styles.fetchingContainer }>
                  <ThreeDotProgress radius={ 7 } />
                </div>
            }

            { fontWeights.map(weight => (
                <MenuItem 
                  key={ weight } 
                  value={ weight }
                  selected={ fontWeight === weight }
                  onClick={ selectFontWeight(weight) }
                  style={{ 
                    height: 50,
                    width: '100%',
                    fontSize: '1.2rem'
                  }}
                >
                  { weight }
                </MenuItem>
              ))
            }
          </div>
        </Menu>
      </div>

      <div css={ styles.dropdown }>
        <Typography css={ styles.label }>
          Font Size
        </Typography>

        <Button
          css={ styles.button }
          disableRipple
          aria-haspopup='true'
          aria-expanded={ Boolean(anchorEl3) ? 'true' : undefined }
          variant='contained'
          disableElevation
          onClick={ openFontSizeMenu }
          endIcon={ <KeyboardArrowDownIcon /> }
        >
          { fontSize ? `${fontSize}px` : 'Font Size' }
        </Button>

        <Menu
          anchorEl={ anchorEl3 }
          open={ Boolean(anchorEl3) }
          onClose={ closeFontSizes }
          keepMounted
          PaperProps={{
            style: {
              width: 250,
              maxHeight: 300
            }
          }}
          MenuListProps={{
            'aria-labelledby': 'button'
          }}
        >
          <div>
            { range(10, 65).map(int => (
                <MenuItem 
                  key={ int } 
                  value={ int }
                  selected={ fontSize === int }
                  onClick={ selectFontSize(int) }
                  style={{ 
                    height: 50,
                    width: '100%',
                    fontSize: '1.2rem'
                  }}
                >
                  { `${int}px` }
                </MenuItem>
              ))
            }
          </div>
        </Menu>

      </div>
    </div>
  )
}


export default FontPicker


