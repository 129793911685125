export function convertSpotifyTrackToCkordFormat(track, composerID, composerType = 'Artist', link) {
  return {
    id: track.id,
    type: 'track',
    attributes: {
      name: track.name,
      video: null,
      audio: track.preview_url,
      previewAudio: track.preview_url,
      composer: track.artists?.[0].name,
      composerId: composerID || track.artists?.[0].id,
      composerType: composerType,
      image: track.album?.images?.[0]?.url,
      imageDerivatives: {
        small: track.album?.images?.[0]?.url
      },
      tags: [],
      spotifyLink: link,
      isSpotifyTrack: true
    }
  }
}




export function convertSpotifyTracksToCkordFormat(tracks, composerID, composerType = 'Artist') {
  return tracks.filter(track => Boolean(track.preview_url)).map(track => {
    const externalURL = track.artists?.[0].external_urls.spotify

    return convertSpotifyTrackToCkordFormat(track, composerID, composerType, externalURL)
  })
}



export function convertSpotifyLinkToTrackForEvent(link, event) {
  return {
    id: event.id,
    type: 'track',
    link: link,
    attributes: {
      isSpotifyTrack: true,
      name: '',
      video: null,
      audio: null,
      previewAudio: '',
      composer: '',
      composerId: event.id,
      composerType: 'Event',
      image: '',
      imageDerivatives: {
        small: ''
      },
      tags: []
    }
  }
}




export default convertSpotifyTracksToCkordFormat