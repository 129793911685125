import {useEffect} from 'react'

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {connect} from 'react-redux'

import {deleteAlert} from '../../actions'

import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import WarningIcon from '@mui/icons-material/Warning'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import {SnackbarProvider, enqueueSnackbar, closeSnackbar} from 'notistack'




const styles = {
  icon: css`
    margin-right: 15px
  `
}




const Alert = props => {

  const {
    action,
    message,
    variant,
    isShowing,
    deleteAlert
  } = props





  useEffect(() => {
    if (!isShowing) return


    // If the caller sent a custom action then enqueue that action...
    if (action) {
      enqueueSnackbar(message, {action})
    
    // ...otherwise and the action to clost the snackbar
    } else {

      const close = snackbarId => () => {
        deleteAlert()
        closeSnackbar(snackbarId)
      }


      enqueueSnackbar(message, {
        action: snackbarId => {
          return (
            <IconButton aria-label='close' color='inherit' onClick={close(snackbarId)}>
              <CloseIcon fontSize='small' />
            </IconButton>
          )
        }
      })
    }
  }, [isShowing, message, action, deleteAlert])




  function handleClose() {
    deleteAlert()
  }



  return (
    <div id='alert'>
      <SnackbarProvider
        maxSnack={3} 
        onClose={handleClose} 
        preventDuplicate={true}
        variant={variant}
        iconVariant={{
          success: <CheckCircleIcon fontSize='small' css={styles.icon} />,
          error: <ErrorOutlineIcon fontSize='small' css={styles.icon} />,
          warning: <WarningIcon fontSize='small' css={styles.icon} />,
          info: <InfoIcon fontSize='small' css={styles.icon} />
        }}
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          maxWidth: 420,
          fontWeight: 400
        }}
      />
    </div>
  )
}


const mapStateToProps = state => {
  const {isShowing, message, variant, action} = state.alert


  return {
    action: action,
    message: message,
    variant: variant,
    isShowing: isShowing,
  }
}



export default connect(mapStateToProps, {deleteAlert})(Alert)



