import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {useNavigate} from 'react-router-dom'

import {cartQuantitySelector} from '../../../selectors'

import Badge from '@mui/material/Badge'
import BagIcon from '@mui/icons-material/LocalMall'
import IconButton from '@mui/material/IconButton'

import CartMenu from '../cart/CartMenu'
import SearchBar from '../search-bar/SearchBar'
import SearchBarMobile from './SearchBarMobile'


import {CkordLogo} from '../../generics'




const cssStyles = ({isMobileSearchBar, isExtraSmallScreen}) => ({
  root: css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    background-color: #000;
    padding-left: ${isExtraSmallScreen ? '15px' : '20px'};
    padding-right: ${isExtraSmallScreen ? '15px' : '20px'};
    gap: ${isExtraSmallScreen ? '10px' : '25px'};
  `,
  leftSide: css`
    display: flex;
    align-items: center;
    gap: 5px;

    .logoContainer {
      padding-bottom: 5px;
    }
  `,
  center: css`
    width: 100%;
  `,
  rightSide: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,
  menu: css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    a {
      color: #fff;
      font-weight: 400;
      white-space: nowrap;
      padding-left: 10px;
      padding-right: 10px;
    }
  `
})




const UnauthenticatedHeaderMobile = props => {

  const navigate = useNavigate()

  const isMobileSearchBar = useMediaQuery('(max-width: 800px)')
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))


  const styles = cssStyles({isExtraSmallScreen})


  const [cartAnchorEl, setCartAnchorEl] = useState(null)


  const {Sidebar, showSearchModal, itemCount} = props



  function goHome() {
    navigate('/home')
  }



  function showCart(e) {
    setCartAnchorEl(e.currentTarget)
  }



  return (
    <div css={styles.root}>
      <div css={styles.leftSide}>
        {Sidebar}


        {!isExtraSmallScreen &&
          <div css={styles.logo} onClick={goHome}>
            <CkordLogo style={{fontSize: '21px', color: '#fff', cursor: 'pointer'}} />
          </div>
        }
      </div>


      <div css={styles.center}>
        {!isMobileSearchBar ? 
          <SearchBar /> : 
          <SearchBarMobile showSearchModal={showSearchModal} />
        }
      </div>

      <div css={styles.rightSide}>
        <IconButton onClick={showCart}> 
          <Badge 
            sx={{
              '.MuiBadge-badge': {
                top: -1,
                right: -1,
                color: '#fff',
                backgroundColor: '#ff0055'
              }
            }}
            overlap='circular'
            badgeContent={itemCount}
          >
            <BagIcon color='secondary' />
          </Badge>
        </IconButton>
      </div>

      <CartMenu anchorEl={cartAnchorEl} setAnchorEl={setCartAnchorEl} />
    </div>
  )
}



const mapStateToProps = state => {
  return {
    itemCount: cartQuantitySelector(state)
  }
}


export default  connect(mapStateToProps)(UnauthenticatedHeaderMobile)

