import React from 'react'
import { connect } from 'react-redux'
import { formatDate } from '../../utils'
import { showAlert, removePerformerFromPerformance } from '../../actions'
import { destroy } from '../../apis'
import makeStyles from '@mui/styles/makeStyles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { CloseButton, FormDeleteButton } from '../generics'



const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 30
  },
  title: {
    fontSize: '1.7rem',
    marginBottom: 24,
    marginTop: 24
  },
  bold: {
    fontWeight: 800,
  },
  avatar: {
    width: 51,
    height: 76,
    marginRight: 15,
    borderRadius: 4
  },
  item: {
    minWidth: 320,
    padding: 12,
    height: 100,
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#dfdfdf'
    }
  },
  primary: {
    fontWeight: 600,
    fontSize: '0.9rem',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
    overflow: 'hidden'
  },
  button: {
    marginTop: 0,
    marginLeft: 24,
    minWidth: 74,
    width: 74,
    height: 36,
    fontSize: '0.8rem'
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: 10,
    right: 10
  }
}))

const LeavePerformance = props => {
  const classes = useStyles()
  const { 
    performances,
    removePerformerFromPerformance,
    showAlert,
    performer,
    handleClose
  } = props
  


  const leavePerformance = performance => () => {
    destroy('/performances/' + performance.id + '/leave', { 
      performer_id: performer.id,
      performer_type: performer.type 
    })
    .then(() => {
      removePerformerFromPerformance(performance.id, performer.id, performer.type)
      showAlert(`Successfully left ${performance.attributes.name}`, 'success')
    })
    .catch(error => {
      showAlert('Something went wrong', 'error')
    })
  }


  return (
    <div className={ classes.root }>
      <CloseButton onClick={ handleClose } />

      <Typography className={ classes.title }>
        <span className={ classes.bold }>Leave</span> Performance
      </Typography>

      <List>
        { Object.values(performances).map(performance => {
            const { name, image, imageDerivatives, start } = performance.attributes
            const date = formatDate(start, 'PP')
            const imageURL = imageDerivatives.small || image

            return (
              <ListItem key={ performance.id } className={ classes.item }>
                <ListItemAvatar>
                  <Avatar className={ classes.avatar } alt={ name } src={ imageURL } />
                </ListItemAvatar>

                <ListItemText
                  primary={ name }
                  secondary={ date } 
                  classes={{
                    primary: classes.primary
                  }}/>
                <FormDeleteButton
                  className={ classes.button } 
                  onClick={ leavePerformance(performance) }>
                  LEAVE
                </FormDeleteButton>
              </ListItem>
            )
          })
        }
      </List>
    </div>
  )
}

const actions = { showAlert, removePerformerFromPerformance }

export default connect(null, actions)(LeavePerformance)