import React from 'react'
/** @jsxImportSource @emotion/react */
import { connect, useDispatch } from 'react-redux'
import { 
  makeGetBandTracks, 
  makeGetBandFetchedAssociations 
} from '../../selectors'
import { 
  pause, 
  getQueueAndPlayTracks,
  queue,
  enqueue,
  showAlert,
  addTracksToBand
} from '../../actions'

import {show} from '../../apis'

import {convertSpotifyTracksToCkordFormat} from '../../helpers'

import { getImageOfSize } from '../../utils'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import BandCardButtons from './BandCardButtons'
import Typography from '@mui/material/Typography'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import { Image } from '../generics'


import bandCardStyles from '../../styles/cards/desktop/bandCardStyles'
import bandCardMobileStyles from '../../styles/cards/mobile/bandCardMobileStyles'



const BandCard = props => {

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))
  const { 
    band,
    tracks,
    isLoaded,
    isPlaying,
    pause,
    getQueueAndPlayTracks,
    queue,
    showAlert,
    addTracksToBand,
    enqueue
  } = props
  const { name, permission, trackCount, image, links } = band.attributes
  const imageURL = getImageOfSize(band.attributes, 'small') || image
  const href = process.env.REACT_APP_BASE_URL + '/bands/' + band.id

  const styles = isExtraSmallScreen ? bandCardMobileStyles()
                                    : bandCardStyles()



  const icons = {
    play: <PlayArrowIcon color='secondary' fontSize={ isExtraSmallScreen ? 'medium' : 'large' } />,
    pause: <PauseIcon color='secondary' fontSize={ isExtraSmallScreen ? 'medium' : 'large' } />
  }
  const icon = (isPlaying ? 'pause' : 'play')


  function handleClick(e) {
    e.preventDefault()
    e.stopPropagation()
    navigate('/bands/' + band.id)
  }


  function handlePlayPress(e) {
    e.preventDefault()
    e.stopPropagation()

    if (icon === 'play') {
      if (isLoaded) {
        queue(tracks, band.id, 'band')
      } else {
        fetchAndPlayAudio()
        fetchSpotifyTracks()
      }
    } else {
      pause()
    }
  }


  function fetchAndPlayAudio() {
    const url = '/bands/' + band.id + '/tracks'

    getQueueAndPlayTracks(url, band.id, 'band').then(response => {
      if (response === 'error') {
        showAlert('Something went wrong loading this band\'s tracks', 'error')
      }
    })
  }



  function fetchSpotifyTracks() {
    if (!links.spotify) return

      console.log('hello')

    
    const spotifyArtistID = links.spotify.split('/').reverse()[0]

    if (!spotifyArtistID) return


    show('/services/spotify/artists/' + spotifyArtistID + '/tracks').then(response => {
      const data = response.data

      // Format the Spotify tracks to match the Ckord tracks
      const newTracks = convertSpotifyTracksToCkordFormat(data, band.id, 'Band', links.spotify)


      // Update the store
      dispatch({type: 'TRACKS_INDEX_SUCCESS', payload: {data: newTracks}})
      addTracksToBand(band.id, newTracks)


      // Add the tracks to the player queue
      enqueue(newTracks, band.id, 'band')
    })
    .catch(error => {})
  }





  return (
    <div css={ styles.card }>
      <a css={ styles.link } href={ href } onClick={ e => e.preventDefault() }>
        <div css={ styles.imageContainer } onClick={ handleClick }>
          { (trackCount > 0 || links.spotify) &&  
              <div css={ styles.iconContainer }>
                <div css={ styles.iconSubContainer }>
                  <div css={ styles.iconCircle } onClick={ handlePlayPress }>
                    { icons[icon] }
                  </div>
                </div>
              </div>
          }

          <Image css={ styles.image } src={ imageURL } alt='band' />
        </div>
      </a>

      <div css={ styles.infoContainer } onClick={ handleClick }>
        <a css={ styles.link } href={ href } onClick={ e => e.preventDefault() }>
          <Typography css={ styles.title } noWrap >
            { name }
          </Typography>
          <Typography noWrap>
            Band
          </Typography>
        </a>
      </div>

      <div css={ styles.buttons }>
        <BandCardButtons 
          bandId={ band.id }
          name={ name }
          permission={ permission }
          imageURL={ imageURL }
        />
      </div>
    </div>
  )
}


const makeMapStateToProps = () => {
  const getBandTracks = makeGetBandTracks()
  const getFetchedAssociations = makeGetBandFetchedAssociations()
  const mapStateToProps = (state, props) => {
    const id = props.band.id
    const tracks = getBandTracks(state, id)
    const isLoaded = getFetchedAssociations(state, id).includes('tracks')
    const isPlaying = state.player.isPlayRequested && state.player.callerID === id

    return {
      isLoaded: isLoaded,
      tracks: tracks,
      isPlaying: isPlaying
    }
  }

  return mapStateToProps
}


const actions = { 
  pause,
  getQueueAndPlayTracks,
  queue,
  enqueue,
  showAlert,
  addTracksToBand
}


export default connect(makeMapStateToProps, actions)(BandCard)

