import { css } from '@emotion/react'

export const venueCardMobileStyles = () => (
  {
    card: css`
      width: 100%;
      margin: 5px 0px;
      padding: 10px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      border-radius: 6px;
      overflow: hidden;
      cursor: pointer;
      transition: 0.3s;
    `,
    imageContainer: css`
      width: 80px;
      height: 80px;
      border-radius: 6px;
      position: relative;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px;
    `,
    image: css`
      width: 100%;
      height: 100%;
      object-fit: cover;
    `,
    infoContainer: css`
      padding: 12px;
      flex-grow: 1; 
      min-width: 0;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
    `,
    title: css`
      width: 100%;
      max-width: 100%;
      font-weight: 700;
      font-size: 1.1rem;
      padding-right: 5px;
      margin-bottom: 2px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
    `,
    buttons: css`
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-height: 52px;
      padding: 4px
    `,
    link: css`
      text-decoration: none;
      color: inherit;
    `
  }
)


export default venueCardMobileStyles
