import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'




const cssStyles = ({paddingTop}) => ({
  aspectRatioBox: css`
    width: 100%;
    height: 0;
    padding-top: ${paddingTop};
    position: relative;
  `,
  aspectRatioBoxInside: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
})




const AspectBox = ({aspect, children, ...props}) => {

  const aspectRatio = aspect || '1:1'

  const [denominator, numerator] = aspectRatio.split(':')

  const paddingTop = (parseInt(numerator) / parseInt(denominator) * 100).toString() + '%'

  const styles = cssStyles({paddingTop})



  return (
    <div css={styles.aspectRatioBox}>
      <div css={styles.aspectRatioBoxInside}>
        {children}
      </div>
    </div>
  )
}



export default AspectBox




