import { useEffect, useState } from 'react'
import { useIsMounted } from './useIsMounted'
import { isEmpty } from 'lodash'
import { crudAPI } from '../apis'
import { camelCaseNestedObject } from '../utils'



export function useStripeAccount(id, type) {

  const isMounted = useIsMounted()

  const [isFetching, setIsFetching] = useState(true)
  
  const [stripeAccount, setStripeAccount] = useState({})


  useEffect(() => {
    if (!isEmpty(stripeAccount)) return

    setIsFetching(true)

    const url = '/merchants/' + type + '/' + id + '/stripe-account'

    // TODO: Should the entire account object be sent to the frontend? 
    crudAPI.show(url).then(response => {
      if (isMounted.current) {
        if (response.data) {
          const camelCased = camelCaseNestedObject(response.data)

          setStripeAccount(camelCased)
        }

        setIsFetching(false)
      }
    })
    .catch(error => {
      if (isMounted.current) {
        setIsFetching(false)
      }
    })
  }, [id, type, isMounted, stripeAccount])



  return [isFetching, stripeAccount]
}