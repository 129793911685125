import React, {useRef, useEffect} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {useTheme} from '@mui/material/styles'





const rootStyles = {
  root: css`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 100%;
  `
}


const blockStyles = ({size, type, margin, theme}) => ({
  tag: css`
    height: 25px;
    display: inline-flex;
    align-Items: center;
    justify-content: center;
    background-color: ${type === 'overlay' ? '#fff' : '#262626'};
    border-radius: 4px;
    border: ${type === 'overlay' ? '1px solid ' + theme.palette.primary._700 : null};
    padding: 5px 8px;
    margin-right: 5px;
    margin-bottom: ${margin === 'top' ? '0' : '5px'};
    margin-top: ${margin === 'top' ? '5px' : '0'};
  `,
  text: css`
    font-size: 12px;
    font-weight: 400;
    color: ${type === 'overlay' ? theme.palette.primary._700 : '#fff'};
    text-transform: uppercase;
  `,
})



const textStyles = {
  text: css`
    font-size: 12px;
    text-transform: uppercase;
    color: rgb(62, 166, 255);
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre;
  `
}




const Tags = ({tags, size, margin, type, ...rest}) => {

  const ref = useRef()

  const theme = useTheme()

  const styles = rest.variant === 'text' ? textStyles : blockStyles({size, margin, type, theme})



  useEffect(() => {
    if (ref.current) {
      const node = ref.current
      const scrollHeight = node.scrollHeight
      const visibleHeight = node.clientHeight

      if (scrollHeight > visibleHeight) {
        const rows = Math.trunc(visibleHeight / 25)

        node.style.height = `${rows * 25}px`
      }
    }
  }, [ref])
  



  return (
    <div ref={ref} css={rootStyles.root} {...rest}>
      {rest.variant === 'text' &&
        <p css={styles.text}>
          {tags.map(tag => `#${tag} `).join(' ')}
        </p>
      }


      {!Boolean(rest.variant) && tags.map(tag => (
        <div css={styles.tag} key={tag}>
            <span css={styles.text}>
              {tag}
            </span>
          </div>
        ))
      }
    </div>
  )
}




export default Tags



