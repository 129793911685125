import React, {useRef, useState} from 'react'

import {connect} from 'react-redux'

import {snakeCaseObject} from '../../utils'

import {create, showAlert} from '../../actions'

import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'

import MessageSenderSelection from './MessageSenderSelection'

import {CloseButton, FormButton} from '../generics'


import styles from '../../styles/chat/message.module.scss'





const MessageForm = props => {

  const messageRef = useRef()

  const {
    userId,
    image, 
    recipientId, 
    recipientName, 
    recipientType,
    close,
    create, 
    showAlert
  } = props


  const [sender, setSender] = useState({id: userId, type: 'User'})




  function send() {
    const content = messageRef.current.value
    
    const data = snakeCaseObject({
      content,
      recipientId,
      recipientType,
      senderId: sender.id,
      senderType: sender.type, 
    })


    create('/messages', 'MESSAGE', data).then(response => {
      close()
      showAlert('Message sent', 'success')
    })
    .catch(error => showAlert('Something went wrong', 'error'))
  }




  function selectSender(id, type) {
    setSender({ id, type })
  }




  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Avatar className={styles.avatar} src={image} />
        
        <div>
          <p className={styles.caption}>
            MESSAGE
          </p>

          <h5 className={styles.title}>
            {recipientName}
          </h5>
        </div>

        <CloseButton 
          onClick={close} 
          iconProps={{
            style: {
              color: '#fff',
              width: '36px',
              height: '36px'
            }
          }}/>
      </div>


      <div className={styles.messageContainer}>
        <TextField
          inputRef={messageRef}
          aria-label='message' 
          placeholder='Message'
          variant='outlined'
          fullWidth
          multiline
          required
          InputProps={{
            classes: { 
              inputMultiline: styles.input
            }
          }}
        />
      </div>


      <div className={styles.senderSelectionContainer}>
        <MessageSenderSelection selectSender={selectSender} />
      </div>



      <div className={styles.buttonContainer}>
        <FormButton onClick={send} variant='contained' color='primary'>
          Send
        </FormButton>
      </div>
    </div>
  )
}



const mapStateToProps = state => {
  return {
    userId: state.user.id
  }
}




export default connect(mapStateToProps, {create, showAlert})(MessageForm)

