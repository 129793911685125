import React, {useState, useEffect} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {get} from 'lodash'

import {connect} from 'react-redux'

import {makeGetPerformanceById} from '../../../selectors'

import {index} from '../../../actions'

import {useIsMounted} from '../../../hooks'


import Dialog from '@mui/material/Dialog'
import AddIcon from '@mui/icons-material/Add'


import {
  Images,
  ImageForm,
  GroupedButton,
  SlideTransition,
  FadeTransition,
  ThreeDotProgress,
  DetailPageTabMessage,
  DetailPageTabButtonContainer
} from '../../generics'




const styles = {
  root: css`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
  `,
  imagesContainer: css`
    width: 100%;
    flex-grow: 1;
    position: relative;
  `,
  message: css`
    width: 100%;
    font-size: 1rem;
    text-align: left;
    padding-left: 15px;
    margin-bottom: 15px;
  `,
  progressContainer: css`
    height: 100%;
    width: 100%;
    margin-top: 20px;
  `,
  dialog: css`
    backdrop-filter: blur(10px);
  `
}





const PerformanceImages = props => {

  const isMounted = useIsMounted()

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const [open, setOpen] = useState(false)


  const {
    index,
    images,
    isLoaded,
    isFetching, 
    permission,
    performanceId
  } = props
  

  const [isFetched, setIsFetched] = useState(isLoaded)
  
  const isOwnerOrEditor = ['owner', 'editor'].includes(permission)




  useEffect(() => {
    if (!isLoaded) {
      const url = '/performances/' + performanceId + '/images'

      index(url, 'PERFORMANCE_IMAGES').then(response => {
        if (isMounted.current) {
          setIsFetched(true)
        }
      })
    }
  }, [isLoaded, performanceId, index, isMounted])
  



  function handleClick() {
    setOpen(true)
  }



  function close() {
    setOpen(false)
  }




  return (isLoaded &&
    <div css={styles.root}>
      <DetailPageTabButtonContainer>
        {['owner', 'editor'].includes(permission) && 
          <GroupedButton 
            text='New'
            tip='Add new product'
            icon={<AddIcon />}
            onClick={handleClick}  
          /> 
        }
      </DetailPageTabButtonContainer>



      <section css={styles.imagesContainer}>
        {(() => {
          if (Boolean(images.length)) {
            return <Images images={images} canDelete={isOwnerOrEditor } /> 
          
          } else if (isFetched) {
            return (
              <DetailPageTabMessage>
                {isOwnerOrEditor
                  ? 'To upload an image, click the plus icon above.'
                  : 'This performance doesn\'t have any images yet.'
                }
              </DetailPageTabMessage>
            )
          }
        })()}
        

        {isFetching && 
          <div css={styles.progressContainer}>
            <ThreeDotProgress position='sticky' /> 
          </div>
        }
      </section>



      <Dialog
        open={open}
        onClose={close}
        fullScreen={isExtraSmallScreen}
        fullWidth={isExtraSmallScreen}
        disableScrollLock
        TransitionComponent={isExtraSmallScreen ? SlideTransition : FadeTransition}
      >
        <ImageForm creatorId={performanceId} creatorType='performance' setOpen={setOpen} />
      </Dialog>
    </div>
  )
}




const makeMapStateToProps = () => {
  const getPerformanceById = makeGetPerformanceById()

  const mapStateToProps = (state, props) => {
    
    const id = props.performanceId
    
    const performance = getPerformanceById(state, id)
    
    const images = get(performance, ['attributes', 'images'], false)
    
    const role = get(state, ['user', 'attributes', 'role'])
    


    return {
      images: images,
      isLoaded: images !== false,
      permission: ['admin', 'editor'].includes(role) ? 'owner' : performance?.permission
    }
  }

  return mapStateToProps
}



export default connect(makeMapStateToProps, {index})(PerformanceImages)

