import { css } from '@emotion/react'

export const performanceCardStyles = ({ theme }) => (
  {
    card: css`
      width: 188px;
      height: 380px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      border-radius: 6px;
      cursor: pointer;
      overflow: hidden;
      transition: 0.3s;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px;
    `,
    imageContainer: css`
      width: 188px;
      height: 250px;
      border-radius: 6px 6px 0px 0px;
      position: relative;
      flex-shrink: 0;
    `,
    image: css`
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
    `,
    infoContainer: css`
      padding: 12px 12px 0px 12px;
      flex-grow: 1;
      display: flex;
      min-width: 0;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-start;
    `,
    title: css`
      width: 100%;
      font-weight: 600;
      font-size: 1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 1.1;
    `,
    city: css`
      font-size: 0.9rem;
      color: #606060;
      font-weight: 500;
      padding-top: 3px;
    `,
    buttons: css`
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 52px;
      padding: 4px;
    `,
    overlay: css`
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      position: absolute;
      bottom: 15px;
      left: 15px;
      width: 65px;
      height: 75px;
      background-color: white;
      border-radius: 5px;
      padding: 5px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px;
    `,
    dayAndMonth: css`
      font-size: 0.75rem;
      color: #303030;
      text-transform: uppercase;
    `,
    day: css`
      font-weight: 900;
      font-size: 1.6rem;
      line-height: 0.9;
    `,
    dateMobile: css`
      font-weight: 500;
      font-size: 0.8rem;
      color: ${theme.palette.pink.main};
    `,
    link: css`
      text-decoration: none;
      color: inherit;
    `,
    tags: css`
      overflow: hidden;
      display: flex;
      width: 100%;
    `
  }
)



export default performanceCardStyles


