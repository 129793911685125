import {map, shuffle, uniq} from 'lodash'


const INITIAL_STATE = {
  links: {},
  results: []
}



const serarchTracksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'TRACK_SEARCH_SUCCESS':
      return {
        links: action.payload.links || {},
        results: map(shuffle(action.payload.data), 'id')
      }
    case 'TRACK_SEARCH_MERGE':
      return {
        ...state,
        results: uniq([...state.results, ...action.payload.map(track => track.id)])
      }
    case 'TRACK_PAGINATION_SEARCH_SUCCESS':
      return {
        links: action.payload.links || {},
        results: [...state.results, ...map(shuffle(action.payload.data), 'id')]
      }
    default:
      return state
  }
}


export default serarchTracksReducer
