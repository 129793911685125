import { userAPI } from '../apis'
import { setAccount } from './accountActions'
import {get} from 'lodash'

export const userActions = {
  signIn,
  signInWithGoogle,
  signOut,
  fetchAuthStatus,
  signUp
}


function setUser(user)       { return { type: 'SET_USER', payload: user } }
function userSigningUp()     { return { type: 'SIGNING_UP' } }
function userSigningIn()     { return { type: 'SIGNING_IN'} }
function userSignUpFailure() { return { type: 'SIGN_UP_FAILURE' } }
function userSignInFailure() { return { type: 'SIGN_IN_FAILURE' } }


function handleLoginSuccess(dispatch, response, success) {
  const user = get(response.data, 'data', {})
  const account = get(response.data, 'included', [])[0]

  dispatch(setUser(user))
  dispatch(setAccount(account))
  dispatch(success())
}


export function signUp(user, hasNextStep = false) {
  return dispatch => {
    dispatch(userSigningUp())

    return userAPI.signUp(user).then(response => { 
      const apiUser = get(response.data, 'data', {})

      dispatch(setUser(apiUser))

      if (user.method === 'email') {
        dispatch(userEmailSignUpSuccess())
      } else if (!hasNextStep) {
        dispatch(userOAuthSignUpSuccess())
      }

      return {}
    })
    .catch(error => {
      dispatch(userSignUpFailure())
      throw(error)
    })
  }

  function userEmailSignUpSuccess() { return { type: 'EMAIL_SIGN_UP_SUCCESS' } }
  function userOAuthSignUpSuccess() { return { type: 'OAUTH_SIGN_UP_SUCCESS' } }
}


export function signIn(user) {
  return dispatch => {
    dispatch(userSigningIn())
  
    return userAPI.signIn(user).then(response =>  {
      handleLoginSuccess(dispatch, response, success)
      return {}
    })
    .catch(error => {
      dispatch(userSignInFailure())
      throw(error)
    })
  }

  function success() { return { type: 'SIGN_IN_WITH_EMAIL_SUCCESS' } }
}


export function signInWithGoogle(token) {
  return dispatch => {
    dispatch(userSigningIn())
  
    return userAPI.signInWithGoogle(token).then(response =>  {
      handleLoginSuccess(dispatch, response, success)
      return {}
    })
    .catch(error => {
      dispatch(userSignInFailure())
      throw(error)
    })
  }

  function success() { return { type: 'SIGN_IN_WITH_GOOGLE_SUCCESS' } }
}


export function signInWithApple(token) {
  return dispatch => {
    dispatch(userSigningIn())
  
    return userAPI.signInWithApple(token).then(response =>  {
      handleLoginSuccess(dispatch, response, success)
      return {}
    })
    .catch(error => {
      dispatch(userSignInFailure())
      throw(error)
    })
  }

  function success() { return { type: 'SIGN_IN_WITH_APPLE_SUCCESS' } }
}


export function signOut() {
  return dispatch => {
    dispatch(signingOut())

    return userAPI.signOut().then(() => {
      dispatch(signOutSuccess())
      dispatch(resetState())
      return true
    })
    .catch(error => {
      dispatch(signOutFailure())
      throw(error)
    })
  }

  function signingOut()     { return { type: 'SIGNING_OUT' } }
  function signOutSuccess() { return { type: 'SIGN_OUT_SUCCESS' } }
  function signOutFailure() { return { type: 'SIGN_OUT_FAILURE' } }
  function resetState()     { return { type: 'RESET_STATE' } }
}



export function fetchAuthStatus() {
  return dispatch => {
    dispatch(requested())

    return userAPI.getAuthStatus().then(response => {
      const isAuthenticated = response.data

      if (isAuthenticated) {
        handleLoginSuccess(dispatch, response, success)
      } else {
        dispatch(failure())
      }

      return Boolean(isAuthenticated)
    })
    .catch(error => {
      dispatch(failure())
      throw(error)
    })
  }

  function requested() { return { type: 'AUTH_STATUS_REQUESTED' } }
  function failure()   { return { type: 'AUTH_STATUS_FAILURE' } }
  function success()   { return { type: 'AUTH_STATUS_SUCCESS' } }
}




export function updateUserSettings(data) {
  return dispatch => {
    dispatch({ type: 'USER_SETTINGS_UPDATE_REQUESTED' })

    return userAPI.updateSettings(data).then(response => {
      dispatch({ type: 'USER_SETTINGS_UPDATE_SUCCESS', payload: response.data.data })
      return response.data
    })
    .catch(error => {
      dispatch({ type: 'USER_SETTINGS_UPDATE_ERROR' })
      throw(error)
    })
  }
}




