import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {getErrorMessage } from '../../utils'

import {destroy, showAlert, deleteAccount} from '../../actions'

import Menu from '@mui/material/Menu'

import ArtistForm from '../artist/ArtistForm'

import { 
  MoreIconButton,
  FavoriteIconButton,
  StatsIconButton,
  EditIconButton,
  DeleteIconButton
} from '../generics'




const styles = {
  buttonsContainer: css`
    display: flex;
    gap: 10px;
  `,
  menu: css`
    li {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `
}




const ArtistCardButtons = props => {

  const [open, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  
  const {artistId, imageURL, destroy, isOwner, showAlert, deleteAccount} = props





  function deleteArtist() {
    destroy('/artists/' + artistId, 'ARTIST').then(response => {
      deleteAccount()
      showAlert('Artist deleted', 'info')
    })
    .catch(error => {
      const message = getErrorMessage(error)
      showAlert(message, 'error')
    })
  }



  function handleMoreClick(e) {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }



  function close(e) {
    e.stopPropagation()
    setAnchorEl(null)
  }



  function closeForm() {
    setOpen(false)
  }



  return (
    <>
      <div css={styles.buttonsContainer}>
        <FavoriteIconButton
          key='favorite'
          id={artistId} 
          type='artist' 
          color='rgba(0, 0, 0, 0.54)' 
        />

        {isOwner &&
          <MoreIconButton
            key='more'
            onClick={handleMoreClick}
            color='rgba(0, 0, 0, 0.54)'
          />
        } 
      </div>

      <Menu css={styles.menu} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={close}>
        {isOwner && [
          <StatsIconButton key='stats' id={artistId} type='artist' variant='menuItem'/>,

          <EditIconButton key='edit' open={open} setOpen={setOpen} variant='menuItem'>
            <ArtistForm artistId={artistId} close={closeForm} />
          </EditIconButton>,

          <DeleteIconButton
            key='delete'
            imageURL={imageURL}
            variant='menuItem'
            title='Delete Artist'
            text='Are you sure you want to delete your artist account?'
            primaryAction={deleteArtist}
          />
        ]}
      </Menu>
    </>
  )
}




const mapStateToProps = (state, props) => {
  
  const id = props.artistId

  const isOwner = state.user.account.id === id 


  return {
    isOwner: isOwner
  }
}



const actions = {destroy, showAlert, deleteAccount}


export default connect(mapStateToProps, actions)(ArtistCardButtons)





