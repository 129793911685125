import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'



const cssStyles = props => ({
  root: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
  `,
  absolute: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
    z-index: 25;
    background-color: ${
      props.background === true 
        ? 'rgba(255,255,255, 0.7)'
        : props.background ? props.background
        : null
    }
  `,
  sticky: css`
    position: sticky;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 25;
    background-color: ${
      props.background === true 
        ? 'rgba(255,255,255, 0.7)'
        : props.background ? props.background
        : null
    }
  `
})



const ThreeDotProgress = props => {

  const {background, position} = props


  const styles = cssStyles({background})


  const color  = props.color || '#ff0055'
  const radius = props.radius || 7

  const scaledRadius = radius * 1.5

  const height = scaledRadius * 2
  const width  = scaledRadius * 6

  const values = `${radius}; ${scaledRadius}; ${radius}`



  return (
    <div css={position ? styles[position] : styles.root}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        xmlns='http://www.w3.org/2000/svg'
        fill={color}
        aria-label='loader'
      >
        <circle cx={scaledRadius} cy={height / 2} r={radius}>
          <animate
            id='first'
            attributeName='r'
            from={radius}
            to={radius}
            begin='0s;third.end - 0.2s'
            dur='0.5s'
            values={values}
            calcMode='linear' 
          />
        </circle>

        <circle cx={scaledRadius * 3} cy={height / 2} r={radius}>
          <animate
            id='second'
            attributeName='r'
            from={radius}
            to={radius}
            begin='first.end - 0.2s'
            dur='0.5s'
            values={values}
            calcMode='linear' 
          />
        </circle>

        <circle cx={scaledRadius * 5} cy={height / 2} r={radius}>
          <animate
            id='third'
            attributeName='r'
            from={radius}
            to={radius}
            begin='second.end - 0.2s'
            dur='0.5s'
            values={values}
            calcMode='linear' 
          />
        </circle>
      </svg>
    </div>
  )
}

export default ThreeDotProgress

