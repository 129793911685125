export function panTo(coordinates) {
  return dispatch => {
    dispatch({type: 'PAN_TO', payload: coordinates})
  }
}



export function setClickedMarkerData(data) {
  return dispatch => {
    dispatch({type: 'SET_CLICKED_MARKER_DATA', payload: data})
  }
}




export function setMarkers(markers, type) {
  return dispatch => {
    if (!Boolean(type)) throw new Error('Type not set')

    dispatch({type: 'SET_' + type.toUpperCase() + '_MARKERS', payload: markers})
  }
}



export function addMarkers(markers, type) {
  return dispatch => {
    if (!Boolean(type)) throw new Error('Type not set')

    dispatch({type: 'ADD_NEW_' + type.toUpperCase() + '_MARKERS', payload: markers})
  }
}



export function addUserMarker(marker) {
  return dispatch => {
    dispatch({type: 'SET_USER_MARKER', payload: marker})
  }
}


export function clearMarkersFor(type) {
  return dispatch => {
    dispatch({type: 'CLEAR_' + type.toUpperCase() + '_MARKERS'})
  }
}