import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {useNavigate, useLocation, Link} from 'react-router-dom'

import MenuList from '@mui/material/List'
import MenuItem from '@mui/material/ListItem'


import {CkordLogo} from '../generics'



const styles = {
  root: css`
    width: 100%;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  list: css`
    width: 100%;
    margin-top: 30px;

    a {
      width: 100%;
      padding: 17px 15px 17px 80px;
      font-size: 1rem;
      font-weight: 300;
      color: #fff;

      &:hover {
        font-weight: 500;
      }
    }
  `,
  line: css`
    border: 0.1px solid #434343;
    margin-left: 80px;
    margin-top: 20px;
  `
}




const UnauthenticatedSidebar = props => {

  const navigate = useNavigate()

  const location = useLocation()

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))


  const {close} = props


  function goHome() {
    navigate('/home')
  }



  return (
    <div css={styles.root}>
      <div onClick={goHome}>
        <CkordLogo 
          style={{
            color: '#fff', 
            cursor: 'pointer', 
            fontSize: isSmallScreen ? '21px' : '24px'
          }} 
        />
      </div>



      <MenuList css={styles.list}>
        <MenuItem onClick={close} component={Link} to='/home'>
          Home
        </MenuItem>


        <MenuItem 
          onClick={close} 
          component={Link} 
          to='/sign-up'
          state={{redirect: location.pathname ? location.pathname : false}}
        >
          Sign Up
        </MenuItem>

        <MenuItem 
          onClick={close} 
          component={Link} 
          to='/sign-in'
          state={{redirect: location.pathname ? location.pathname : false}}
        >
          Sign In
        </MenuItem>

        <MenuItem component='a' href='https://www.blog.ckord.com/about'>
          About
        </MenuItem>
        

        <hr css={styles.line} />


        <MenuItem onClick={close} component={Link} to='/legal'>
          Legal
        </MenuItem>
      </MenuList>
    </div>

  )
}




export default UnauthenticatedSidebar



