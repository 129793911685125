
const statuses = {
  initializing: 'initializing',
  idle: 'idle',
  requested: 'requested',
  mergeRequested: 'mergeRequested',
  fetching: 'fetching'
}


const INITIAL_STATE = statuses.initializing



const searchStatusReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'SEARCH_REQUESTED':
    return statuses.requested
  case 'SEARCH_MERGE_REQUESTED':
    return statuses.mergeRequested
  case 'SEARCH_FETCHING':
    return statuses.fetching
  case 'SEARCH_COMPLETED':
    return statuses.idle
  case 'SEARCH_INITIALIZING':
    return statuses.initializing
  case 'RESET_STATE':
    return INITIAL_STATE
  default: 
    return state
  }
}


export default searchStatusReducer