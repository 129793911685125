import { css } from '@emotion/react'

export const headerInvitationNotificationStyles = ({ type, theme }) => (
  {
    root: css`
      width: 100%;
      display: flex;
      flex-direction: column;
      color: #000;
    `,
    contentRow: css`
      display: flex;
      padding-bottom: 5px;
    `,
    actionRow: css`
      width: 100%;
    `,
    avatarContainer: css`
      margin-right: 15px;
    `,
    avatar: css`
      width: ${theme.spacing(6)};
      height: ${type === 'band' ? theme.spacing(6) : '64px'};
    `,
    contentContainer: css`
      flex-grow: 1;
    `,
    title: css`
      font-size: 0.9rem;
      font-weight: 600;
      padding-bottom: 3px;
      padding-right: 25px;
      color: ${theme.palette.primary._700}
    `,
    message: css`
      font-size: 0.9rem;
      padding-right: 25px;
      color: ${theme.palette.primary._800}
    `,
    time: css`
      font-size: 0.75rem;
      font-weight: 500;
      color: ${theme.palette.primary._400};
    `,
    actions: css`
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 3px
    `,
    accept: css`
      width: 65px;
      margin-right: 12px;
      font-size: 0.7rem;
      font-weight: 400;
      background-color: #000;
      color: #fff;
      &:hover {
        background-color: #000;
        font-weight: 600;
        color: #fff;
      }
    `,
    decline: css`
      width: 65px;
      color: grey;
      border: 1px solid grey;
      font-size: 0.7rem;
      font-weight: 400;
      background-color: #fff;
      &:hover {
        background-color: #fff;
        color: #000;
      }
    `
  }
)



export default headerInvitationNotificationStyles



