import {useEffect, useMemo, useCallback} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {debounce} from 'lodash'

import {
  mapSelector,
  filterLocationSelector
  // userCoordinatesSelector
} from '../selectors'




function calculateViewportRadius(map) {
  // Get the bounds of the map
  const bounds = map.getBounds()
  const center = map.getCenter()


  // Get the northeast corner of the bounds
  const ne = bounds.getNorthEast()

  // Calculate the distance from the center to the northeast corner
  const radius = window.google.maps.geometry.spherical.computeDistanceBetween(center, ne)


  return radius * 0.00062137 // return radius in miles
}





export function useMaps() {

  const dispatch = useDispatch()

  const storeMap = useSelector(mapSelector)

  const location = useSelector(filterLocationSelector)

  // const coordinates = useSelector(userCoordinatesSelector)



  const debouncedSearch = useMemo(() => {
    return debounce((lat, lng) => {
      dispatch({type: 'UPDATE_CENTER_FILTER', payload: {lat, lng}})
      dispatch({type: 'SEARCH_MERGE_REQUESTED'})
    }, [300])}, [dispatch])




  const searchArea = useCallback(() => {
    const newCenter = storeMap?.getCenter()

    if (!newCenter) return

    const currentCenter = new window.google.maps.LatLng(location.lat, location.lng)

    const radius = window.google.maps.geometry.spherical.computeDistanceBetween(newCenter, currentCenter)

    // Only do a new search if a user pans over two miles
    if (radius * 0.00062137 > 2) {
      const lat = newCenter.lat()
      const lng = newCenter.lng()

      debouncedSearch(lat,lng)
    }
  }, [storeMap, location.lat, location.lng, debouncedSearch])




  // Attach a listener to the map to listen to search a new area if a user
  // pans or moves the map to a new area
  useEffect(() => {
    let listener

    if (storeMap) {
      listener = window.google.maps.event.addListener(storeMap, 'idle', searchArea)
    }

    return () => listener?.remove()
  }, [storeMap, searchArea])




  useEffect(() => {
    if (!storeMap) return

    const handleZoomChange = () => {
      const radius = calculateViewportRadius(storeMap)
      dispatch({type: 'UPDATE_RADIUS_FILTER_BUT_DONT_ACTIVATE', payload: radius})
    }


    storeMap.addListener('zoom_changed', handleZoomChange)
  }, [storeMap, dispatch])





  useEffect(() => {
    // If a map is loaded then no need to continue
    if (storeMap) return

    // If there are no coordinates then also return
    if (!location.lat || !location.lng) return


    const container = document.getElementById('map-container')

    // Load map
    initMap()


    async function initMap() {
      try {
        const {Map} = await window.google.maps.importLibrary('maps')
        const {LatLng} = await window.google.maps.importLibrary('core')
        const {Geometry} = await window.google.maps.importLibrary('geometry')

        const latlng = new LatLng(location.lat, location.lng)


        const promise = new Map(container, {
          zoom: window.innerWidth <= 800 ? 11 : 12,
          maxZoom: 21,
          minZoom: 4,
          center: latlng,
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: false,
          gestureHandling: 'greedy',
          styles: [
            {
              featureType: 'poi', // Points of interest (landmarks) are under "poi"
              elementType: 'labels', // Hides the labels of the POI
              stylers: [{visibility: 'off'}] // Turns off visibility for landmarks
            }
          ]
        })

        const map = await promise


        // Save the map
        dispatch({type: 'SET_MAP', payload: map})


        // Listen for when a map is panned
        window.google.maps.event.addListenerOnce(map, 'tilesloaded', function() {
          const viewportRadius = calculateViewportRadius(map)


          dispatch({type: 'SET_RADIUS_FILTER', payload: viewportRadius})
          dispatch({type: 'SEARCH_MERGE_REQUESTED'})
        })
      } catch (error) {
        console.error('Error loading Google Maps:', error)
      }
    }
  }, [
    storeMap, 
    dispatch,
    location.lat, 
    location.lng
  ])


  return storeMap
}




export default useMaps



