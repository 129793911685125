import { omit } from 'lodash'
import { capitalizeFirstLetter } from '../../utils'

// The below objects have a format of objectId : follwerId
const INITIAL_STATE = {
  artists: {},
  orderedArtists: [],
  artistLinks: {},
  bands: {},
  orderedBands: [],
  bandLinks: {},
  venues: {},
  orderedVenues: [],
  venueLinks: {},
  fetched: [],
  isFetching: false
}



 const followReducer = (state = INITIAL_STATE, action) => {
  let id, type, orderedType

  switch (action.type) {
    case 'FOLLOWS_REQUESTED':
      return { ...state,
        isFetching: true
      }
    case 'FOLLOWS_WITH_ASSOCIATIONS_INDEX_SUCCESS':
      type = action.payload.type
      orderedType = 'ordered' + capitalizeFirstLetter(action.payload.type)

      return { ...state,
        isFetching: false,
        [type]: {
          ...state[type],
          ...action.payload.follows
        },
        [orderedType]: [...state[orderedType], ...action.payload.orderedFollows],
        [type.slice(0, -1) + 'Links']: action.payload.links
      }
    case 'FOLLOWS_INDEX_SUCCESS':
      type = action.payload.type

      return { ...state,
        isFetching: false,
        fetched: [...state.fetched, type],
        [type]: action.payload.following
      }
    case 'FOLLOWS_SAVE_LINKS':
      type = action.payload.type

      return { ...state,
        [type.slice(0, -1) + 'Links']: action.payload.links
      }
    case 'ORDER_FOLLOWS_OF_TYPE':
      orderedType = 'ordered' + capitalizeFirstLetter(action.payload.type)

      return { ...state,
        [orderedType]: [...state[orderedType], ...action.payload.objectIds]
      }
    case 'FOLLOW_SHOW_SUCCESS':
      id = action.payload.id
      type = action.payload.type

      return { ...state,
        isFetching: false,
        [type]: { 
          ...state[type],
          [id]: action.payload.data
        },
      }
    case 'FOLLOW_CREATE_SUCCESS':
      id = action.payload.data.attributes.followableId
      type = action.payload.data.attributes.followableType.toLowerCase() + 's'
      orderedType = 'ordered' + capitalizeFirstLetter(type)

      return { ...state,
        isFetching: false,
        [orderedType]: [ id, ...state[orderedType]],
        [type]: { 
          ...state[type],
          [id]: action.payload.data
        }
      }
    case 'FOLLOW_DESTROY_SUCCESS':
      id = action.payload.data.attributes.followableId
      type = action.payload.data.attributes.followableType.toLowerCase() + 's'
      orderedType = 'ordered' + capitalizeFirstLetter(type)

      return { ...state,
        isFetching: false,
        [type]: omit(state[type], id),
        [orderedType]: state[orderedType].filter(e => e !== id)
      }
    case 'FOLLOWS_ERROR':
      id = action.payload.id
      type = action.payload.type

      if (id) {
        return { ...state,
          isFetching: false,
          [type]: { 
            ...state[type],
            [id]: false
          }
        }
      } else {
        return { ...state,
          isFetching: false
        }
      }
    default:
      return state
  }
}

export default followReducer



