import React, { useState } from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {formatMoney, hasSameKeys, relativeComplement} from '../../../utils'

import {makeGetProductById} from '../../../selectors'

import {addToCart, showAlert} from '../../../actions'


import Button from '@mui/material/Button'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import ProductVariant from '../variants/ProductVariant'


import ProductShippingDetails from './ProductShippingDetails'



const styles = {
  root: css`
    width: 100%;
    min-width: 330px;
    display: flex;
    flex-direction: column;
  ` ,
  name: css`
    font-size: 25px;
    font-weight: 500;
  `,
  caption: css`
    font-size: 15px;
    margin-bottom: 10px;
  `,
  price: css`
    font-size: 1.4em;
  `,
  variantContainer: css`
    margin: 30px 0;
  `,
  button: css`
    background-color: #000;
    border-radius: 0;
    height: 48px;
    color: #fff;
    font-size: 13px;
    margin-bottom: 30px;
    margin-top: 30px;
    &:hover {
      background-color: #000;
      color: #fff;
    }
  `,
  accordionContainer: css`
    border-top: 1px solid #e1e1eb;
  `,
  accordion: css`
    border-bottom: 1px solid #e1e1eb;
    & .MuiButtonBase-root {
      padding: 0;
    },
    & .MuiAccordionDetails-root {
      padding-left: 0;
      padding-right: 0;
    }
  `,
  accordionSummary: css`
    font-weight: 500;
  `
}





const ProductDetailInfo = props => {

  // Shipping details are only passed from MerchantProducts if the merchant
  // is previewing the product they are going to add
  const {product, shippingDetails, isPreview, addToCart, showAlert} = props
  
  const { 
    name, 
    price, 
    caption, 
    variants, 
    description
  } = product.attributes



  const [selectedVariants, setSelectedVariants] = useState({})



  const variantKeys = Object.keys(variants).reduce((acc, key) => {
    if (['color', 'colors'].includes(key)) {
      acc.unshift(key)
    } else {
      acc.push(key)
    }

    return acc
  }, [])



  function addToShoppingCart() {
    if (isPreview) return


    if (!hasSameKeys(variants, selectedVariants)) {
      const missingVariants = Object.keys(relativeComplement(variants, selectedVariants))

      let message = 'You need to make a selection for the following fields: '

      message += missingVariants?.join(', ')
      

      showAlert(message, 'info')
      return
    }


    const selectedProduct = {
      ...product,
      attributes: {
        ...product.attributes,
        selectedVariants: selectedVariants
      }
    }
    

    addToCart(selectedProduct)
    showAlert('Item added to cart', 'success')
  }



  function selectVariant(variant, value) {
    setSelectedVariants(prev => ({ ...prev, [variant]: value }))
  }



  return (product &&
    <div css={styles.root}>

      <p css={styles.name}>{name}</p>

      <p css={styles.caption}>{caption}</p>

      <p css={styles.price}>{formatMoney(price)}</p>

      
      {Boolean(variantKeys.length) &&
        <div css={styles.variantContainer}>
          {variantKeys.map(variant => (
            <ProductVariant 
              key={variant}
              variant={variant}
              options={variants[variant]}
              callback={selectVariant}
            />
          ))}
        </div>
      }


      <Button css={styles.button} onClick={addToShoppingCart}>
        Add to Cart
      </Button>


      <div css={styles.accordionContainer}>
        <Accordion
          css={styles.accordion}
          square 
          disableGutters 
          elevation={0} 
          defaultExpanded={true}
        >
          <AccordionSummary 
            css={styles.accordionSummary} 
            expandIcon={<ArrowDropUpIcon sx={{fontSize: '1.5rem'}} />}
          >
            Description
          </AccordionSummary>
          <AccordionDetails>
            <p>{description}</p>
          </AccordionDetails>
        </Accordion>

        <Accordion 
          css={styles.accordion}
          square
          disableGutters 
          elevation={0} 
          defaultExpanded={true}
        >
          <AccordionSummary 
            css={styles.accordionSummary} 
            expandIcon={<ArrowDropUpIcon sx={{fontSize: '1.5rem'}} />}
          >
            Shipping
          </AccordionSummary>
          <AccordionDetails>
            <ProductShippingDetails productId={product.id} shippingDetails={shippingDetails} />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}



const makeMapStateToProps = () => {
  const getProductById = makeGetProductById()

  const mapStateToProps = (state, props) => {
    const product = getProductById(state, props.productId)

    return {
      product: product
    }
  }

  return mapStateToProps
}



const actions = {addToCart, showAlert}



export default connect(makeMapStateToProps, actions)(ProductDetailInfo)


