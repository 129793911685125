import React, {useState, useRef, useEffect} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {validate} from '../../../../utils/validateVideoFile'

import {formatError} from '../../../../utils'

import {update, showAlert} from '../../../../actions'

import {useIsMounted} from '../../../../hooks'

import IconButton from '@mui/material/IconButton'

import EditIcon from '@mui/icons-material/Edit'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'

import ThreeDotProgress from '../../../generics/ThreeDotProgress'




const styles = {
  root: css`
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
  `,
  video: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  placeholder: css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
  `,
  icon: css`
    color: #979797;
    width: 30%;
    max-width: 45px;
    height: auto;
  `,
  editIcon: css`
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;

    &:hover {
      background-color: rgba(0, 0, 0);
    }
  `
}



const DefaultPlaceholder = props => (
  <div css={styles.placeholder}>
    <AddAPhotoIcon css={styles.icon} />
  </div>
)




// This is designed to work with Formik
const VideoField = ({showIcon = true, ...props}) => {

  const input = useRef()

  const videoRef = useRef()

  const isMounted = useIsMounted()

  const [isLoading, setIsLoading] = useState(false)


  let {fieldName, fieldValue} = props

  fieldName = fieldName || 'video'

  fieldValue = fieldValue instanceof File ? URL.createObjectURL(fieldValue) : fieldValue
  

  const {
    video,
    videoId,
    videoType,
    update,
    callback,
    showAlert,
    placeholder,
    setFieldValue, 
    setFieldError
  } = props



  useEffect(() => {    
    videoRef.current?.load()
  }, [fieldValue])


  const fileSelectedHandler = e => {

    const file = e.target.files[0]
    
    const reader = new FileReader()




    reader.onload = () => {
      if (isMounted.current) {
        if (callback) {
          callback(reader.result, file)
        }

        setIsLoading(false)
      }

      // If there is a video id, then update the video with that id
      if (videoId) {

        const formData = new FormData()

        formData.append('video', file, file.name, file.size)


        update('/videos/' + videoId, videoType, formData).then(response => {
          showAlert('Video uploaded', 'success')
        })
        .catch(error => {
          error = formatError(error)
          showAlert(error.messages?.video, 'error')
        })

      
      // Otherwise its a new video so add it to the form 
      } else {

        setFieldValue(fieldName, file)

        // Reset the input so the same file can be loaded if deleted
        e.target.value = ''
      }
    }



    
    if (file) {
      const validation = validate(file)


      if (validation.passed) {
        reader.readAsDataURL(file)
      
      } else {
        setIsLoading(false)
        setFieldError(fieldName, validation.message)
        showAlert(validation.message, 'error')
      }
    }
  }



  function handleClick(e) {
    e.stopPropagation()
    input.current.click()
  }



  return (
    <div css={styles.root} onClick={handleClick}>
      <video ref={videoRef} controls playsInLine width='100%'>
        <source src={fieldValue || video} type='video/mp4' />
      </video>
      

      <input
        ref={input} 
        style={{display: 'none'}}
        type='file'
        accept='video/*'
        onChange={fileSelectedHandler}
      />

      {isLoading && <ThreeDotProgress position='absolute' radius={4} />}
    </div>  
  )
}


export default connect(null, {update, showAlert})(VideoField)



