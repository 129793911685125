import {useEffect} from 'react'

import {useSelector, useDispatch} from 'react-redux'

import {fetchCart, saveCart, setCart} from '../actions'

import {snakeCaseObject} from '../utils'


export function useCart() {
  
  const dispatch = useDispatch()

  const items = useSelector(state => state.user.cart.items)
  
  const isLoaded = useSelector(state => state.user.cart.isLoaded)
  
  const shouldSave = useSelector(state => state.user.cart.shouldSave)

  const isAuthenticated = useSelector(state => state.user.isAuthenticated)



  // Load cart
  useEffect(() => {
    if (isAuthenticated === null) {
      return
    }

    if (!isLoaded) {
      // If a user is signed in then fetch the user's cart from the backend
      if (isAuthenticated) {

        dispatch(fetchCart())
      
      // Otherwise check if there is a cart saved in local storage
      } else {

        const localStorageCart = window.localStorage.getItem('cart')

        if (localStorageCart) {
          dispatch(setCart(JSON.parse(localStorageCart)))
        }
      }
    }
  }, [isLoaded, isAuthenticated, dispatch])




  // Save cart
  useEffect(() => {
    window.addEventListener('beforeunload', saveUserCart)


    function saveUserCart() {
      if (shouldSave) {
        const snakeCased = items.map(item => snakeCaseObject(item))

        const stringified = JSON.stringify(snakeCased)


        if (isAuthenticated) {
          dispatch(saveCart(stringified))
        } else {
          window.localStorage.setItem('cart', stringified)
        }
      }
    }

    return () => {
      window.removeEventListener('beforeunload', saveUserCart)
    }
  }, [items, dispatch, shouldSave, isAuthenticated])

}