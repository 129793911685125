import React, {useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect} from 'react-redux'

import {destroy, showAlert} from '../../actions'

import Menu from '@mui/material/Menu'

import PerformanceForm from '../performance/PerformanceForm'

import MoreIconButton from '../generics/buttons/MoreIconButton'
import EditIconButton from '../generics/buttons/EditIconButton'
import StatsIconButton from '../generics/buttons/StatsIconButton'
import DeleteIconButton from '../generics/buttons/DeleteIconButton'
import FavoriteIconButton from '../generics/buttons/FavoriteIconButton'





const styles = {
  buttonsContainer: css`
    display: flex;
    gap: 5px;
  `,
  menu: css`
    li {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `
}




const PerformanceCardButtons = props => {
  
  const [open, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  

  const {performanceId, permission, imageURL, destroy, showAlert} = props




  function deletePerformance() {
    destroy('/performances/' + performanceId, 'PERFORMANCE').then(response => {
      showAlert('Performance deleted', 'info')
    })
    .catch(error => {
      showAlert('Couldn\'t delete performance', 'error')
    })
  }




  function handleMoreClick(e) {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }



  function close(e) {
    e.stopPropagation()
    setAnchorEl(null)
  }



  function closeForm() {
    setOpen(false)
  }




  return (
    <>
      <div css={styles.buttonsContainer}>
        <FavoriteIconButton 
          key='favorite'
          id={performanceId}
          type='performance'
          color='rgba(0, 0, 0, 0.54)'
        />

        {['owner', 'editor', 'member'].includes(permission) &&
          <MoreIconButton
            key='more'
            onClick={handleMoreClick}
            color='rgba(0, 0, 0, 0.54)'
          />
        }
      </div>

      
      <Menu css={styles.menu} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={close}>
        {['owner', 'editor', 'member'].includes(permission) && 
          <StatsIconButton id={performanceId} type='performance' variant='menuItem'/>
        }


        {['owner', 'editor'].includes(permission) && [
          <EditIconButton 
            key='edit' 
            open={open} 
            setOpen={setOpen} 
            variant='menuItem'
            type='performance'
          >
            <PerformanceForm performanceId={performanceId} close={closeForm} />
          </EditIconButton>,


          <DeleteIconButton
            key='delete'
            imageURL={imageURL}
            variant='menuItem'
            title='Delete Performance'
            text='Are you sure you want to delete this performance?'
            primaryAction={deletePerformance}
          />
        ]}
      </Menu>
    </>
  )
}


const actions = {destroy, showAlert}


export default connect(null, actions)(PerformanceCardButtons)





