import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import {ReactComponent as StripeLogo} from '../../../svgs/StripeLogo.svg'

import Footer from '../../footer/Footer'




const cssStyles = ({isSmallScreen, isLessThanLargeScreen}) => ({
  root: css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    section:nth-of-type(even) {
      background-color: rgb(242, 67, 133, 0.2);
    }

    section {
      width: 100%;
      padding-top: 4rem;
      padding-bottom: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-size: 2.5rem;
        font-weight: 500;
        letter-spacing: -0.1rem;
        margin-bottom: 20px;
      }
    }


    h1 {
      font-size: ${isLessThanLargeScreen ? '3rem' : '4.5rem'};
      font-weight: 500;
      letter-spacing: ${isLessThanLargeScreen ? '-0.2rem' : '-0.3rem'};
      margin-bottom: 20px;
      margin-top: 10px;
    }

    h4 {
      text-transform: uppercase;
      color: #7b7b7b;
      margin-bottom: 0px;
    }
  `,
  header: css`
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    padding: 15px 15px 0px 15px;
    justify-content: flex-end;
  `,
  closeButton: css`
    svg {
      font-size: 60px;
      stroke-width: 1;
    }
  `,
  innerContainer: css`
    display: flex;
    width: 100%;
    max-width: 1100px;
    flex-direction: column;
    padding: 0px 40px;
  `,
  footer: css`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 5rem;
    padding-left: 40px;
    padding-right: 40px;
    overflow: hidden;
    background-color: ${isLessThanLargeScreen ? '#000' : '#000'};
  `,
  columns: css`
    display: flex;
    flex-direction: ${isSmallScreen ? 'column' : 'row'};

    div:first-of-type {
      padding-right: ${isSmallScreen ? '0' : isLessThanLargeScreen ? '3rem' : '6.875rem'};
      border-right: ${isSmallScreen ? 'none' : '1px solid #c4c4c4'};
      margin-bottom: ${isSmallScreen ? '20px' : '0px'};
    }

    div:last-of-type {
      padding-left: ${isSmallScreen ? '0' : isLessThanLargeScreen ? '3rem' : '6.875rem'};
    }

    div {
      flex: 1 1 0;
      width: ${isSmallScreen ? '100%' : '0'};
      display: flex;
      flex-direction: column;


      h1 {
        line-height: ${isLessThanLargeScreen ? '3rem' : '5.1rem'};
      }

      span {
        color: #f24385;
      }
    }
  `,
  text: css`
    max-width: 600px;
    font-size: 1.5rem;
  `,
  caption: css`
    font-size: 1.1rem;
    max-width: 300px;
  `,
  largeCaption: css`
    font-size: ${isLessThanLargeScreen ? '1.1rem' : '1.3rem'};
  `,
  spacer: css`
    margin-top: 70px;
  `,
  link: css`
    display: inline-flex;
    align-items: flex-end;
    line-height: 1;
  `
})



const PricingAndFeesPage = props => {

  const isLessThanLargeScreen = useMediaQuery(theme => theme.breakpoints.down('lg'))
  const isSmallScreen = useMediaQuery('(max-width: 700px)')

  const styles = cssStyles({isSmallScreen, isLessThanLargeScreen})


  const {close} = props



  return (
    <div css={styles.root}>
      <div css={styles.header}>
        <IconButton css={styles.closeButton} onClick={close} size='large'>
          <CloseIcon color='disabled' />
        </IconButton>
      </div>

        <section>
          <div css={styles.innerContainer}>
            <h4>Ckord Pricing And Fees</h4>

            <h1>Simple Pricing</h1>

            <p css={styles.text}>
{/*              We collect one small transaction fee per sale or contribution—that's it. Everything else 
              goes directly to the artist, band, or venue. For more details see below.*/}
              It's real simple. All money goes directly to the artist, band, or venue. You read it right.
              All proceeds go directly to the artist, band, or venue.
            </p>
          </div>
        </section>


        <section>
          <div css={styles.innerContainer}>
            <h2>Fees</h2>

            <div css={styles.columns}>
              <div>
                <h4>No fee to get started...</h4>

                <h1>$0.00</h1>

                <p css={styles.caption}>
                  There is no fee to start or manage merchant account.
                </p>
              </div>


              <div>
                <h4>...and no transaction fees</h4>


                <h1>0.0%</h1>

                <p css={styles.caption}>
                 All money collected from a contribution or merchandise sale is send to the artist, band, or venue.
                </p>

{/*                <h1>4.9% + $0.30</h1>

                <p css={styles.caption}>
                  This fee is only charged when someone makes a purchase or provides a contribution.
                </p>*/}
              </div>
            </div>
          </div>
        </section>


        <section>
          <div css={styles.innerContainer}>
            <h2>Example 1</h2>

            <div css={styles.columns}>
              <div css={styles.column}>
                <h1>
                  If you sold <span>$60</span> worth of merchandise 
                  {/*to <span>2</span> people*/}
                </h1>
              </div>


              <div css={styles.column}>
                <h1>You would get <span>$60</span> sent to your bank</h1>

                {/*<p css={styles.largeCaption}>Transaction fee: <strong>$3.54</strong></p>*/}
              </div>
            </div>
          </div>


          <div css={styles.spacer} />


          <div css={styles.innerContainer}>
            <h2>Example 2</h2>

            <div css={styles.columns}>
              <div css={styles.column}>
                <h1>
                  If <span>3</span> people contributed <span>$3, $5 and $1</span> to you
                </h1>
              </div>


              <div css={styles.column}>
                <h1>You would get <span>$9.00</span> sent to your bank</h1>

                {/*<p css={styles.largeCaption}>Transaction fee: <strong>$0.56</strong></p>*/}
              </div>
            </div>

          </div>
        </section>


        <section>
          <div css={styles.innerContainer}>
            <div css={styles.columns}>
              <div css={styles.column}>
                {/*<h2>Why the transaction fee?</h2>*/}
                <h2>How do we process payments and is it safe?</h2>
              </div>

              <div css={styles.column}>
                <p css={styles.largeCaption}>
                  We partner with the industry-leading payment processor
                  <a css={styles.link} href='https://stripe.com/' target='_blank' rel='noopener noreferrer'>
                    <StripeLogo width='70px' viewBox='0 0 468 160.5' overflow='visible' />
                  </a> 
                  to safely and securley handle payment transactions. We don't collect any financial data. 
                  All financial exchanges are handled by Stripe.
                </p>
              </div>
            </div>
          </div>
        </section>


      <div css={styles.footer}>
        <Footer />
      </div>
    </div>
  )
}



export default PricingAndFeesPage



