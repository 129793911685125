import {createSelector} from 'reselect'


const selectItems = state => state.user.cart.items



export const cartQuantitySelector = createSelector(
  selectItems, 
  items => items.reduce((quantity, item) => (quantity += item.quantity), 0)
)




export const cartTotalSelector = createSelector(
  selectItems, 
  items => items.reduce((total, item) => (total += item.quantity * item.price), 0)
)