import instance from './instance'

export const userAPI = {
  signIn,
  signInWithGoogle,
  signInWithApple,
  signOut, 
  signUp,
  getAuthStatus,
  createAccount,
  confirmEmail,
  updateEmail,
  updateEmailConfirmation,
  sendPasswordResetEmail,
  resetPassword,
  updateSettings,
  validatePasswordToken,
  checkIsEmailAvailableUsingToken
}




function signIn(user) {
  return instance.post('/sign-in', {
    email: user.email,
    password: user.password,
    method: 'default'
  })
}


function signInWithGoogle(token) {
  return instance.post('/sign-in', {
    token: token,
    method: 'google'
  })
}


function signInWithApple(token) {
  return instance.post('/sign-in', {
    token: token,
    method: 'apple'
  })
}


function signUp(user) {
  return instance.post('/user', user)
}

function signOut() {
  return instance.delete('/sign-out')
}

function getAuthStatus() {
  return instance.get('/user')
}

function createAccount(data) {
  return instance.post('/user/account', data)
}

function confirmEmail(token) {
  return instance.patch('/user/confirm-email/' + token)
}

function updateEmail(email) {
  return instance.post('/user/update-email', email)
}

function updateEmailConfirmation(token) {
  return instance.patch('/user/update-email/' + token)
}

function sendPasswordResetEmail(email) {
  return instance.get('/user/reset-password', {params: {email}})
}

function validatePasswordToken(token) {
  return instance.get('/user/validate-password-token', {params: {token}})
}

function resetPassword(data) {
  return instance.patch('/user/reset-password', data)
}

function updateSettings(data) {
  return instance.patch('/user/settings', data)
}

export function searchUsers(query) {
  return instance.get('/users/search', {params: {query}})
}

export function checkIsEmailAvailableUsingToken(token) {
  return instance.post('/users/is-email-available', {token})
}


