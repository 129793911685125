import {get} from 'lodash'



export function formatError(error, defaultMessage = 'Something went wrong') {
  return { 
    title: get(error, ['response', 'data', 'error', 'title'], ''),
    status: get(error, ['response', 'data', 'error', 'status'], 0),
    messages: get(error, ['response', 'data', 'error', 'messages'], [defaultMessage])
  }
}


export function getErrorMessage(error, defaultMessage = 'Something went wrong') {
  const messages = get(error, ['response', 'data', 'error', 'messages'], defaultMessage)

  if (Array.isArray(messages)) {
    return messages[0]
  } else if (typeof messages === 'string') {
    return messages
  } else {
    return defaultMessage
  }
}



export function reduceFormErrors(errors, values) {
  return Object.keys(errors).reduce((acc, key) => {
    if (key in values) {
      acc[key] = errors[key][0]
      return acc
    } 

    if (key === 'coordinates') {
      acc['location'] = errors[key][0]
    } else {
      acc['unlisted'] = acc['unlisted'].concat(errors[key])
    }

    return acc
  }, { unlisted: [] })
}



export function retrieveMessageFrom(error) {
  const messages = get(error, ['response', 'data', 'error', 'messages'], [])
  const message = messages.length > 0 ? messages[0] : 'Something went wrong'

  return message
}