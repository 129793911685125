import React from 'react'
import TextField from '@mui/material/TextField'


const FormikTextField = React.forwardRef(({ field, form, disabled, startAdornment, ...props }, ref) => {
  const { name, onBlur, onChange, value } = field
  const { touched, errors, isSubmitting } = form
  const fieldError = errors[name]
  const showError = touched[name] && !!fieldError



  return (
    <TextField
      inputRef={ref}
      name={ name }
      value={ value }
      error={ showError }
      helperText={ showError ? fieldError : props.helperText }
      disabled={ disabled !== undefined ? disabled : isSubmitting }
      onChange={ onChange } 
      onBlur={ onBlur }
      InputProps={{ 
        startAdornment: startAdornment,
        style: {
          fontSize: 16
        }
      }}
      { ...props }
    />
  )
})


export default FormikTextField