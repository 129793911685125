import { combineReducers } from 'redux'
import { filter, omit, map, uniq, get } from 'lodash'
import {
  mergeObjects,
  mergeRelationships,
  mergeLinks, 
  removeDataKeyFromRelationships,
  compareAndAddNewValues
} from '../utils'
import normalize from 'json-api-normalizer'



const byId = (state = {}, action) => {
  let id = ''

  switch (action.type) {
    case 'PERFORMANCES_INDEX_SUCCESS':
      if (action.payload.data.length) {
        let normalizedResponse = normalize(action.payload).performance
        normalizedResponse = removeDataKeyFromRelationships(normalizedResponse)
        normalizedResponse = mergeObjects(normalizedResponse, state)

        return { ...state, ...normalizedResponse }
      } 
      else return state
    case 'PERFORMANCE_SHOW_SUCCESS':
    case 'PERFORMANCE_UPDATE_SUCCESS':
    case 'PERFORMANCE_CREATE_SUCCESS':
      id = action.payload.data.id
      
      const existingRecord = state[id]
      const normalizedResponse = removeDataKeyFromRelationships(normalize(action.payload).performance)


      if (existingRecord) {
        normalizedResponse[id] = mergeRelationships(normalizedResponse[id], existingRecord)
        normalizedResponse[id] = mergeLinks(normalizedResponse[id], existingRecord)
      }
      
      return { ...state, ...normalizedResponse }
    case 'PERFORMANCE_UPDATE_VIDEO':
      id = action.payload.id

      return {
        ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...state[id].attributes,
            video: action.payload.video,
            videoDerivatives: action.payload.videoDerivatives
          }
        }
      }
    case 'PERFORMANCE_DESTROY_SUCCESS':
      return omit(state, action.payload.data.id)
    case 'PERFORMANCE_RESET_STATE':
      return {}
    case 'ADD_TRACK_TO_PERFORMANCE':
      const existingTrackArray = get(state[action.payload.id], ['relationships', 'tracks'], [])
      const newTrackArray = compareAndAddNewValues(existingTrackArray, action.payload.data)

      if (newTrackArray.length !== existingTrackArray.length) {
        return { ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            relationships: {
              ...get(state[action.payload.id], 'relationships', {}),
              tracks: [ ...newTrackArray ]
            }
          }
        }
      }
      else return state
    case 'ADD_PERFORMER_TO_PERFORMANCE':
      const existingPerformerArray = get(state[action.payload.id], ['relationships', 'performers'], [])
      const newPerformerArray = compareAndAddNewValues(existingPerformerArray, action.payload.data)

      if (newPerformerArray.length !== existingPerformerArray.length) {
        return { ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            relationships: {
             ...get(state[action.payload.id], 'relationships', {}),
              performers: [ ...newPerformerArray ]
            }
          }
        }
      }
      else return state
    case 'REMOVE_PERFORMER_FROM_PERFORMANCE':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            performers: filter(state[action.payload.id].relationships.performers, e => {
              return e.id !== action.payload.relationshipId
            })
          }
        }
      }
    case 'SET_PERFORMANCE_FETCHED_ASSOCIATIONS':
      let fetched = state[action.payload.id].fetched
      
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          fetched: fetched ? [ ...fetched, action.payload.value ] : [action.payload.value],
        }
      }
    case 'PERFORMANCE_INCREMENT_VIEW_COUNT_UPDATE_SUCCESS':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          attributes: { 
            ...state[action.payload.id].attributes,
            views: action.payload.count
          }
        }
      }
    case 'PERFORMANCE_IMAGE_UPDATE_SUCCESS':
      id = action.payload.data.id

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            image: action.payload.data.attributes.image,
            imageDerivatives: action.payload.data.attributes.imageDerivatives,
          }
        }
      }
    case 'PERFORMANCE_IMAGES_INDEX_SUCCESS':
      id = action.payload.meta.imagableId

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            images: action.payload.data
          }
        }
      }
    case 'PERFORMANCE_IMAGES_CREATE_SUCCESS':
      id = action.payload.data.attributes.imagableId

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            images: [
              action.payload.data, 
              ...get(state[id], ['attributes', 'images'], [])
            ] 
          }
        }
      }
    case 'PERFORMANCE_IMAGES_DESTROY_SUCCESS':
      id = action.payload.data.attributes.imagableId

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            images: filter(state[id].attributes.images, e => {
              return e.id !== action.payload.data.id
            }) 
          }
        }
      }
    case 'PERFORMANCE_SAVE_LINKS':
      return { ...state, 
        [action.payload.id]: {
          ...state[action.payload.id],
          links: { ...(state[action.payload.id]?.links || {}),
            [action.payload.association]: action.payload.links
          }
        }
      }
    default:
      return state
  } 
}

const all = (state = [], action) => {
  switch (action.type) {
    case 'PERFORMANCES_INDEX_SUCCESS':
      return uniq([...state, ...map(action.payload.data, 'id')])
    case 'PERFORMANCE_SHOW_SUCCESS':
    case 'PERFORMANCE_CREATE_SUCCESS':
      if (state.includes(action.payload.data.id)) {
        return state
      }
      
      return [...state, action.payload.data.id]
    case 'PERFORMANCE_DESTROY_SUCCESS':
      return filter(state, e => e !== action.payload.data.id)
    case 'PERFORMANCE_RESET_STATE':
      return []
    case 'PERFORMANCE_SEARCH_INDEX_SUCCESS':
      return uniq([...state, ...map(action.payload.data, 'id')])
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case 'PERFORMANCES_REQUESTED':
    case 'PERFORMANCE_REQUESTED':
      return true
    case 'PERFORMANCES_INDEX_SUCCESS':
    case 'PERFORMANCE_SHOW_SUCCESS':
    case 'PERFORMANCE_CREATE_SUCCESS':
    case 'PERFORMANCE_DESTROY_SUCCESS':
    case 'PERFORMANCE_RESET_STATE':
    case 'PERFORMANCE_ERROR':
    case 'PERFORMANCES_ERROR':
      return false
    default:
      return state
  }
}

export default combineReducers({
  byId,
  all,
  isFetching
})

